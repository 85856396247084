import de from 'languages/german/german';

export const getLocalizeText = (
  message: string,
  defaultLanguage = localStorage.getItem("language")
) => {
  if (defaultLanguage === "de") {
    return de[message] || message;
  }

  return message;
};

export default getLocalizeText;