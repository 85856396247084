import React from "react";
// components
import ImageGallery from "react-image-gallery";
import InfoBox from "react-google-maps/lib/components/addons/InfoBox";
// functions
import {
  getIcon,
  getLocalizeText,
  setPlaceholderImg,
  setPlaceholderImgForImgGallary,
} from "utils";
import englishText from "languages/english/english";
import { openObjectDetail } from "components/dashboard/utils/commonFunctions";
import { IMapWindowProps } from "../interface/map.interface";
// assets
import tipTile from "assets/images/placeholder.png";

const SingleMapWindow: React.FC<IMapWindowProps> = (props) => {
  const { dataObj } = props;

  return (
    <>
      {dataObj.isOpen && dataObj.map && (
        <InfoBox
          onCloseClick={dataObj.closeInfo}
          defaultPosition={
            // eslint-disable-next-line no-undef
            new google.maps.LatLng(
              parseFloat(dataObj.lat),
              parseFloat(dataObj.lon)
            )
          }
          options={{ closeBoxURL: ``, enableEventPropagation: true }}
          zIndex={10}
        >
          <div
            className="infowin-container  pointer"
            ref={dataObj.setWrapperRefForInfowindow}
            onClick={(event) => openObjectDetail(event, dataObj.map.id)}
          >
            <div className="card-img map-img-gallary">
              {parseInt(dataObj.map.label) > 0 && (
                <div
                  className="score-icon-map"
                  style={{ backgroundColor: getIcon(dataObj.map.label) }}
                >
                  <p>{Math.round(dataObj.map.label)}</p>
                </div>
              )}
              <div
                className="like-icon pointer"
                onClick={() =>
                  dataObj.addFavourites(
                    dataObj.map.id,
                    dataObj.favouritesList.includes(dataObj.map.id)
                      ? "remove"
                      : "add"
                  )
                }
              >
                <i
                  className={
                    dataObj.favouritesList.includes(dataObj.map.id)
                      ? "fa fa-heart"
                      : "fa fa-heart-o"
                  }
                  aria-hidden="true"
                />
              </div>
              {dataObj.map.imgList && dataObj.map.imgList.length <= 1 ? (
                <img
                  className="infowin-div-left single-img"
                  src={dataObj.map.coverImg || tipTile}
                  onError={setPlaceholderImg}
                  alt="img"
                />
              ) : (
                <ImageGallery
                  items={dataObj.map.imgList}
                  showPlayButton={false}
                  showThumbnails={false}
                  showFullscreenButton={false}
                  lazyLoad={true}
                  disableKeyDown={true}
                  onImageError={setPlaceholderImgForImgGallary}
                />
              )}
            </div>
            <div
              className="d-flex justify-content-between map-label box-shadow pointer"
              onClick={(event) => openObjectDetail(event, dataObj.map.id)}
            >
              <p className="map-label-adr ml-2">
                <li>{dataObj.map.propertyType || "-"}</li>
                <li>{dataObj.map.adr}</li>
              </p>
              <p className="mr-2">
                <li className="map-label-price"> {dataObj.map.price}</li>
                <li className="sub-label-map">
                  {getLocalizeText(englishText.PRICE_SMALL)}
                </li>
              </p>
            </div>
          </div>
        </InfoBox>
      )}
    </>
  );
};

export default SingleMapWindow;
