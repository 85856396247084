
let refs: any = {};

/**
 * getRef - get html element reference
 */
const getRef = (key: string) => {
	if (refs[key]) {
		return refs[key].current;
	}
};

/**
 * setRef - set html element reference
 */
const setRef = (ref: any, key: string) => {
	refs = {
		...refs,
		[key]: ref
	};
};

export {
	getRef,
	setRef,
};
