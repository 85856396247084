import React from "react";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import InputRange from "react-input-range";
import CurrencyInput from "react-currency-input-field";
import { FormattedMessage } from "react-intl";
// utils and functions
import {
  citiesForCalculator,
  fixationPeriodFields,
  lang,
  getLocalizeText,
} from "utils";
import englishText from "languages/english/english";
// assets
import Commerzbank from "assets/images/Commerzbank.svg";
import Postbank from "assets/images/Postbank.svg";
import ING from "assets/images/ING.svg";
import Deutsche_Bank from "assets/images/Deutsche_Bank.svg";
import Sparkasse from "assets/images/Sparkasse.svg";
import { Arrow } from "components/commonComponents/icons";
import "react-input-range/lib/css/index.css";
// interface
import * as calFormInterface from "../interface/calculator";

const langSelected = localStorage.getItem("language") || "de";

const CalculatorForm: React.FC<calFormInterface.ICalFormProps> = ({
  ...props
}) => {
  const { state, locale, isMobileView } = props;
  const {
    property_price,
    annual_repayment_rate,
    savings,
    fixation_period,
    estate_agent_fee,
    location,
  } = state;

  const currLocLabel: any =
    Object.values(citiesForCalculator).find(
      (city: any) => city.value === location
    ) || {};

  return (
    <div className="calculator-form">
      <p className="calc-title">
        <FormattedMessage
          id={englishText.MORTGAGE_CALCULATOR}
          defaultMessage={englishText.MORTGAGE_CALCULATOR}
        />
      </p>
      {/* <p className="calc-subtitle">
        <FormattedMessage
          id={englishText.GET_FREE_REC}
          defaultMessage={englishText.GET_FREE_REC}
        />
      </p> */}
      <div className={`row ${!isMobileView ? "mb-3" : ""}`}>
        <Form.Group className="col-sm-6 mb-2 pb-2">
          <Form.Label className="mb-2 field-title">
            {getLocalizeText(englishText.PROPERTY_PRICE)}
          </Form.Label>
          <div className="option-input number-increment relative">
            <CurrencyInput
              id="input-example"
              name="property_price"
              placeholder={getLocalizeText(englishText.ENTER_PRICE)}
              defaultValue={property_price}
              value={property_price}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                props.handlePriceChange(value, name)
              }
              intlConfig={{ locale: locale, currency: "EUR" }}
              suffix={" "}
              prefix={" "}
            />
            <span className="euro-label">€</span>
          </div>
        </Form.Group>
        <Form.Group className="col-sm-6 mb-2 pb-2">
          <Form.Label className="mb-2 field-title">
            {getLocalizeText(englishText.LOCATION_1)}
          </Form.Label>

          <Dropdown drop={"down"} defaultValue={location}>
            <Dropdown.Toggle id="dropdown-basic">
              {currLocLabel.label}
              <span className="euro-label" style={{ right: "1px", top: 0 }}>
                <Arrow className={`arrow-icon`} />
              </span>
            </Dropdown.Toggle>

            <Dropdown.Menu
              style={{ width: "100%", maxHeight: "400px", overflow: "auto" }}
            >
              {Object.keys(citiesForCalculator).map((city) => (
                <Dropdown.Item
                  key={city}
                  onClick={() =>
                    props.handleDropdown(
                      citiesForCalculator[city].value,
                      "location"
                    )
                  }
                >
                  {citiesForCalculator[city].label}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </Form.Group>
      </div>
      <div className="row mb-3">
        <Form.Group className="col-sm-6 mb-2 ">
          <Form.Label className="mb-2 field-title">
            {getLocalizeText(englishText.SAVINGS)}
          </Form.Label>
          <div className="option-input number-increment relative">
            <CurrencyInput
              id="input-example"
              name="savings"
              placeholder={""}
              defaultValue={property_price}
              value={savings}
              decimalsLimit={2}
              onValueChange={(value, name) =>
                props.handlePriceChange(value, name)
              }
              intlConfig={{ locale: locale, currency: "EUR" }}
              suffix={" "}
              prefix={" "}
            />
            <span className="euro-label">€</span>
          </div>
        </Form.Group>
        {!isMobileView && (
          <Form.Group className="col-sm-6 mb-2 pb-2 form-group">
            <Form.Label
              className="mb-2 field-title"
              style={{ paddingBottom: "4px" }}
            >
              {getLocalizeText(englishText.REAL_ESTATE_COMM)}
            </Form.Label>
            <div className="radio-agent">
              {[
                { label: getLocalizeText(englishText.YES_1), value: "Yes" },
                { label: getLocalizeText(englishText.NO_1), value: "No" },
              ].map((field, index) => (
                <Form.Check
                  key={index}
                  type="radio"
                  custom
                  label={field.label}
                  checked={
                    field.value === "Yes" ? estate_agent_fee : !estate_agent_fee
                  }
                  style={{ marginRight: "30px" }}
                  onChange={() =>
                    props.handleRadio(field.value === "Yes" ? true : false)
                  }
                  id={field.value}
                />
              ))}
            </div>
          </Form.Group>
        )}
      </div>
      {!isMobileView && (
        <div className="row mb-3">
          <Form.Group className="col-sm-6" controlId="formBasicRange">
            <div className="annul-div mb-4">
              <Form.Label className="mb-2 field-title">
                {getLocalizeText(englishText.ANNUAL_REPAY_RATE)}
              </Form.Label>
              {/* <p className="annual-label">
              {annual_repayment_rate?.toLocaleString(locale) + " %"}
            </p> */}
            </div>
            <div className="annual-range">
              <InputRange
                minValue={1}
                maxValue={5}
                value={annual_repayment_rate || 0}
                name="annual_repayment_rate"
                onChange={props.handleRangechange}
                step={0.5}
                formatLabel={(value) =>
                  `${value.toLocaleString(locale) + " %"}`
                }
              />
            </div>
          </Form.Group>
          <Form.Group className="col-sm-6 mb-2 pb-2">
            <Form.Label className="mb-2 field-title">
              {getLocalizeText(englishText.FIXATION_PERIOD)}
            </Form.Label>
            <Dropdown drop={"down"} defaultValue={fixation_period}>
              <Dropdown.Toggle id="dropdown-basic">
                {`${fixation_period} ${getLocalizeText(englishText.YEARS)}`}
                <span className="euro-label" style={{ right: "1px", top: 0 }}>
                  <Arrow className={`arrow-icon`} />
                </span>
              </Dropdown.Toggle>

              <Dropdown.Menu
                style={{ width: "100%", maxHeight: "400px", overflow: "auto" }}
              >
                {Object.keys(fixationPeriodFields).map((yearData) => (
                  <Dropdown.Item
                    key={yearData}
                    onClick={() =>
                      props.handleDropdown(
                        fixationPeriodFields[yearData].value,
                        "fixation_period"
                      )
                    }
                  >
                    {fixationPeriodFields[yearData].label}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </Form.Group>
        </div>
      )}
      <div className="bank-group">
        {bankArray.map((bank, index) => (
          <img
            src={bank.image}
            alt="bank"
            key={index}
            style={{ height: isMobileView ? "10px" : "20px" }}
          />
        ))}
      </div>
      {langSelected === lang.EN ? (
        <p className="section-description">
          This German mortgage calculator uses the realtime interest rates from{" "}
          <b>700 different</b> German mortgage lenders.
        </p>
      ) : (
        <p className="section-description">
          Dieser Hypothekenrechner verwendet die Echtzeit-Zinssätze von über{" "}
          <b>700 verschiedenen</b> deutschen Hypothekengebern.
        </p>
      )}
    </div>
  );
};

export default CalculatorForm;

const bankArray = [
  { image: Postbank },
  { image: Deutsche_Bank },
  { image: Sparkasse },
  { image: ING },
  { image: Commerzbank },
];
