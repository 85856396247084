import React from "react";
import logo from "assets/images/Immozy-logo-web2.png";
import { lang } from "utils/constants";
import "../onboarding-new.scss";

const ThanksPage: React.FC = () => {
  const langSelected = localStorage.getItem("language") || "de";

  return (
    <div style={{ margin: "0 auto", color: "#fff" }}>
      <div className="header-onboarding thanks-page-wrapper">
        <div>
          <img
            className="mb-3"
            src={logo}
            alt="img"
            style={{ marginLeft: "0px" }}
          />
        </div>
        {langSelected === lang.EN ? (
          <>
            <p className="question-text thanks-page" style={{ width: "auto" }}>
              Finished! <br /> <br />
              Now we can look for the right property for you, the first results
              are already calculated.
              <br /> <br />
              Let the finding begin!
            </p>
            <p className="question-text thanks-page-mobile">
              Finished! <br /> <br />
              You have just secured full access to IMMOZY. <br />
              <br />
              In order to be able to use IMMOZY you have to log in on your
              computer, we have sent you the link by email. <br />
              <br />
              We are working on giving you mobile access in the future
            </p>
          </>
        ) : (
          <>
            <p className="question-text thanks-page" style={{ width: "auto" }}>
              Fertig! <br /> <br />
              Nun können wir für dich die passende Immobilie suchen, die ersten
              Ergebnisse werden jetzt gerade schon berechnet.
              <br /> <br />
              Lass uns das Finden beginnen!
            </p>
            <p className="question-text thanks-page-mobile">
              Fertig! <br /> <br />
              Du hast dir soeben den vollen Zugang zu IMMOZY gesichert.
              <br />
              <br />
              Um IMMOZY benutzen zu können musst du dich nun über deinen
              Computer einloggen, den Link haben wir dir per E-Mail geschickt.
              <br />
              <br />
              Wir arbeiten daran dir zukünftig auch einen mobilen Zugang zu
              ermöglichen."
            </p>
          </>
        )}
      </div>
    </div>
  );
};

export default ThanksPage;
