export const autosuggestionList = [

  "21037, Allermöhe, Hamburg",
  "21035, Allermöhe, Hamburg",
  "22335, Alsterdorf, Hamburg",
  "22337, Alsterdorf, Hamburg",
  "22297, Alsterdorf, Hamburg",
  "22767, Altona-Altstadt, Hamburg",
  "22765, Altona-Altstadt, Hamburg",
  "20359, Altona-Altstadt, Hamburg",
  "22765, Altona-Nord, Hamburg",
  "22769, Altona-Nord, Hamburg",
  "20357, Altona-Nord, Hamburg",
  "22549, Bahrenfeld, Hamburg",
  "22525, Bahrenfeld, Hamburg",
  "22761, Bahrenfeld, Hamburg",
  "22547, Bahrenfeld, Hamburg",
  "22607, Bahrenfeld, Hamburg",
  "22765, Bahrenfeld, Hamburg",
  "22309, Barmbek-Nord, Hamburg",
  "22305, Barmbek-Nord, Hamburg",
  "25764, Barmbek-Nord, Hamburg",
  "22303, Barmbek-Nord, Hamburg",
  "22307, Barmbek-Nord, Hamburg",
  "22083, Barmbek-Süd, Hamburg",
  "22305, Barmbek-Süd, Hamburg",
  "22085, Barmbek-Süd, Hamburg",
  "22081, Barmbek-Süd, Hamburg",
  "21035, Bergedorf, Hamburg",
  "21031, Bergedorf, Hamburg",
  "23552, Bergedorf, Hamburg",
  "21039, Bergedorf, Hamburg",
  "21033, Bergedorf, Hamburg",
  "21029, Bergedorf, Hamburg",
  "22395, Bergstedt, Hamburg",
  "22359, Bergstedt, Hamburg",
  "22117, Billstedt, Hamburg",
  "22111, Billstedt, Hamburg",
  "22115, Billstedt, Hamburg",
  "22119, Billstedt, Hamburg",
  "22159, Billstedt, Hamburg",
  "22113, Billwerder, Hamburg",
  "21033, Billwerder, Hamburg",
  "22587, Blankenese, Hamburg",
  "22589, Blankenese, Hamburg",
  "20537, Borgfelde, Hamburg",
  "20535, Borgfelde, Hamburg",
  "22179, Bramfeld, Hamburg",
  "22391, Bramfeld, Hamburg",
  "22309, Bramfeld, Hamburg",
  "22177, Bramfeld, Hamburg",
  "22175, Bramfeld, Hamburg",
  "22047, Bramfeld, Hamburg",
  "22159, Bramfeld, Hamburg",
  "21129, Cranz, Hamburg",
  "22049, Dulsberg, Hamburg",
  "22397, Duvenstedt, Hamburg",
  "22523, Eidelstedt, Hamburg",
  "22457, Eidelstedt, Hamburg",
  "22527, Eidelstedt, Hamburg",
  "22525, Eidelstedt, Hamburg",
  "22089, Eilbek, Hamburg",
  "22527, Eimsbüttel, Hamburg",
  "20253, Eimsbüttel, Hamburg",
  "22769, Eimsbüttel, Hamburg",
  "20257, Eimsbüttel, Hamburg",
  "20255, Eimsbüttel, Hamburg",
  "20144, Eimsbüttel, Hamburg",
  "20259, Eimsbüttel, Hamburg",
  "20357, Eimsbüttel, Hamburg",
  "22525, Eimsbüttel, Hamburg",
  "21077, Eißendorf, Hamburg",
  "21073, Eißendorf, Hamburg",
  "22119, Eißendorf, Hamburg",
  "21075, Eißendorf, Hamburg",
  "20249, Eppendorf, Hamburg",
  "20251, Eppendorf, Hamburg",
  "22159, Farmsen-Berne, Hamburg",
  "21129, Finkenwerder, Hamburg",
  "22335, Fuhlsbüttel, Hamburg",
  "22339, Fuhlsbüttel, Hamburg",
  "22453, Groß Borstel, Hamburg",
  "22607, Groß Flottbek, Hamburg",
  "20457, HafenCity, Hamburg",
  "22303, Hamburg, Hamburg",
  "22769, Hamburg, Hamburg",
  "22763, Hamburg, Hamburg",
  "22393, Hamburg, Hamburg",
  "22145, Hamburg, Hamburg",
  "22085, Hamburg, Hamburg",
  "21033, Hamburg, Hamburg",
  "21149, Hamburg, Hamburg",
  "20149, Hamburg, Hamburg",
  "22159, Hamburg, Hamburg",
  "22767, Hamburg, Hamburg",
  "22115, Hamburg, Hamburg",
  "22395, Hamburg, Hamburg",
  "22359, Hamburg, Hamburg",
  "22453, Hamburg, Hamburg",
  "20148, Hamburg, Hamburg",
  "20146, Hamburg, Hamburg",
  "22043, Hamburg, Hamburg",
  "22459, Hamburg, Hamburg",
  "22525, Hamburg, Hamburg",
  "22089, Hamburg, Hamburg",
  "20255, Hamburg, Hamburg",
  "22765, Hamburg, Hamburg",
  "22305, Hamburg, Hamburg",
  "22337, Hamburg, Hamburg",
  "20251, Hamburg, Hamburg",
  "20355, Hamburg, Hamburg",
  "21039, Hamburg, Hamburg",
  "22587, Hamburg, Hamburg",
  "21129, Hamburg, Hamburg",
  "21079, Hamburg, Hamburg",
  "20144, Hamburg, Hamburg",
  "22391, Hamburg, Hamburg",
  "22609, Hamburg, Hamburg",
  "20253, Hamburg, Hamburg",
  "20357, Hamburg, Hamburg",
  "21107, Hamburg, Hamburg",
  "20097, Hamburg, Hamburg",
  "22889, Hamburg, Hamburg",
  "22309, Hamburg, Hamburg",
  "21037, Hamburg, Hamburg",
  "22399, Hamburg, Hamburg",
  "21077, Hamburg, Hamburg",
  "22177, Hamburg, Hamburg",
  "20257, Hamburg, Hamburg",
  "21031, Hamburg, Hamburg",
  "21109, Hamburg, Hamburg",
  "22589, Hamburg, Hamburg",
  "21029, Hamburg, Hamburg",
  "21075, Hamburg, Hamburg",
  "20535, Hamburg, Hamburg",
  "22335, Hamburg, Hamburg",
  "22049, Hamburg, Hamburg",
  "20359, Hamburg, Hamburg",
  "22529, Hamburg, Hamburg",
  "22547, Hamburg, Hamburg",
  "22417, Hamburg, Hamburg",
  "21073, Hamburg, Hamburg",
  "22607, Hamburg, Hamburg",
  "22041, Hamburg, Hamburg",
  "23617, Hamburg, Hamburg",
  "22149, Hamburg, Hamburg",
  "22087, Hamburg, Hamburg",
  "21147, Hamburg, Hamburg",
  "22111, Hamburg, Hamburg",
  "22119, Hamburg, Hamburg",
  "22339, Hamburg, Hamburg",
  "22455, Hamburg, Hamburg",
  "22113, Hamburg, Hamburg",
  "20249, Hamburg, Hamburg",
  "22397, Hamburg, Hamburg",
  "22297, Hamburg, Hamburg",
  "20354, Hamburg, Hamburg",
  "22045, Hamburg, Hamburg",
  "22457, Hamburg, Hamburg",
  "22605, Hamburg, Hamburg",
  "22415, Hamburg, Hamburg",
  "21035, Hamburg, Hamburg",
  "20537, Hamburg, Hamburg",
  "22549, Hamburg, Hamburg",
  "22081, Hamburg, Hamburg",
  "22527, Hamburg, Hamburg",
  "20539, Hamburg, Hamburg",
  "22047, Hamburg, Hamburg",
  "20459, Hamburg-Altstadt, Hamburg",
  "20095, Hamburg-Altstadt, Hamburg",
  "20457, Hamburg-Altstadt, Hamburg",
  "20099, Hamburg-Altstadt, Hamburg",
  "20537, Hamm, Hamburg",
  "20535, Hamm, Hamburg",
  "22111, Hamm, Hamburg",
  "22089, Hamm, Hamburg",
  "20097, Hammerbrook, Hamburg",
  "21073, Harburg, Hamburg",
  "21075, Harburg, Hamburg",
  "21079, Harburg, Hamburg",
  "20149, Harvestehude, Hamburg",
  "20249, Harvestehude, Hamburg",
  "20144, Harvestehude, Hamburg",
  "21149, Hausbruch, Hamburg",
  "21147, Hausbruch, Hamburg",
  "21079, Heimfeld, Hamburg",
  "21075, Heimfeld, Hamburg",
  "20251, Hoheluft-Ost, Hamburg",
  "20255, Hoheluft-West, Hamburg",
  "20253, Hoheluft-West, Hamburg",
  "22087, Hohenfelde, Hamburg",
  "22111, Horn, Hamburg",
  "22119, Horn, Hamburg",
  "25355, Horn, Hamburg",
  "22457, Hummelsbüttel, Hamburg",
  "22339, Hummelsbüttel, Hamburg",
  "22399, Hummelsbüttel, Hamburg",
  "22391, Hummelsbüttel, Hamburg",
  "22417, Hummelsbüttel, Hamburg",
  "22589, Iserbrook, Hamburg",
  "22043, Jenfeld, Hamburg",
  "22045, Jenfeld, Hamburg",
  "21037, Kirchwerder, Hamburg",
  "21077, Langenbek, Hamburg",
  "21079, Langenbek, Hamburg",
  "22419, Langenhorn, Hamburg",
  "22417, Langenhorn, Hamburg",
  "22415, Langenhorn, Hamburg",
  "22397, Lemsahl-Mellingstedt, Hamburg",
  "22399, Lemsahl-Mellingstedt, Hamburg",
  "24253, Lemsahl-Mellingstedt, Hamburg",
  "21031, Lohbrügge, Hamburg",
  "21033, Lohbrügge, Hamburg",
  "22527, Lokstedt, Hamburg",
  "22529, Lokstedt, Hamburg",
  "22457, Lurup, Hamburg",
  "22549, Lurup, Hamburg",
  "22523, Lurup, Hamburg",
  "22547, Lurup, Hamburg",
  "22041, Marienthal, Hamburg",
  "22089, Marienthal, Hamburg",
  "22043, Marienthal, Hamburg",
  "21077, Marmstorf, Hamburg",
  "22113, Moorfleet, Hamburg",
  "21035, Neuallermöhe, Hamburg",
  "21129, Neuenfelde, Hamburg",
  "21039, Neuengamme, Hamburg",
  "21147, Neugraben-Fischbek, Hamburg",
  "21149, Neugraben-Fischbek, Hamburg",
  "21079, Neuland, Hamburg",
  "20354, Neustadt, Hamburg",
  "20355, Neustadt, Hamburg",
  "22453, Niendorf, Hamburg",
  "22455, Niendorf, Hamburg",
  "22459, Niendorf, Hamburg",
  "22609, Nienstedten, Hamburg",
  "22587, Nienstedten, Hamburg",
  "21037, Ochsenwerder, Hamburg",
  "22335, Ohlsdorf, Hamburg",
  "22337, Ohlsdorf, Hamburg",
  "22609, Osdorf, Hamburg",
  "22549, Osdorf, Hamburg",
  "22587, Osdorf, Hamburg",
  "22589, Osdorf, Hamburg",
  "22763, Othmarschen, Hamburg",
  "22605, Othmarschen, Hamburg",
  "22609, Othmarschen, Hamburg",
  "22763, Ottensen, Hamburg",
  "22765, Ottensen, Hamburg",
  "22391, Poppenbüttel, Hamburg",
  "22399, Poppenbüttel, Hamburg",
  "22393, Poppenbüttel, Hamburg",
  "22395, Poppenbüttel, Hamburg",
  "22149, Rahlstedt, Hamburg",
  "22143, Rahlstedt, Hamburg",
  "22147, Rahlstedt, Hamburg",
  "22145, Rahlstedt, Hamburg",
  "22559, Rissen, Hamburg",
  "21079, Rönneburg, Hamburg",
  "20539, Rothenburgsort, Hamburg",
  "20354, Rotherbaum, Hamburg",
  "20146, Rotherbaum, Hamburg",
  "20148, Rotherbaum, Hamburg",
  "20097, Sankt Georg, Hamburg",
  "20099, Sankt Georg, Hamburg",
  "20359, Sankt Pauli, Hamburg",
  "22767, Sankt Pauli, Hamburg",
  "22769, Sankt Pauli, Hamburg",
  "20357, Sankt Pauli, Hamburg",
  "22395, Sasel, Hamburg",
  "22081, Sasel, Hamburg",
  "22393, Sasel, Hamburg",
  "22457, Schnelsen, Hamburg",
  "22459, Schnelsen, Hamburg",
  "21079, Sinstorf, Hamburg",
  "22309, Steilshoop, Hamburg",
  "22525, Stellingen, Hamburg",
  "20255, Stellingen, Hamburg",
  "22527, Stellingen, Hamburg",
  "20357, Sternschanze, Hamburg",
  "22587, Sülldorf, Hamburg",
  "22589, Sülldorf, Hamburg",
  "22047, Tonndorf, Hamburg",
  "22045, Tonndorf, Hamburg",
  "22041, Tonndorf, Hamburg",
  "22087, Uhlenhorst, Hamburg",
  "22085, Uhlenhorst, Hamburg",
  "22081, Uhlenhorst, Hamburg",
  "22359, Volksdorf, Hamburg",
  "21129, Waltershof, Hamburg",
  "22089, Wandsbek, Hamburg",
  "22041, Wandsbek, Hamburg",
  "22049, Wandsbek, Hamburg",
  "22047, Wandsbek, Hamburg",
  "22393, Wellingsbüttel, Hamburg",
  "22391, Wellingsbüttel, Hamburg",
  "21107, Wilhelmsburg, Hamburg",
  "21109, Wilhelmsburg, Hamburg",
  "21077, Wilstorf, Hamburg",
  "21079, Wilstorf, Hamburg",
  "22303, Winterhude, Hamburg",
  "22301, Winterhude, Hamburg",
  "22297, Winterhude, Hamburg",
  "22299, Winterhude, Hamburg",
  "22397, Wohldorf-Ohlstedt, Hamburg",
  "12489, Adlershof, Berlin",
  "12524, Altglienicke, Berlin",
  "13053, Alt-Hohenschönhausen, Berlin",
  "13055, Alt-Hohenschönhausen, Berlin",
  "13051, Alt-Hohenschönhausen, Berlin",
  "12435, Alt-Treptow, Berlin",
  "12487, Baumschulenweg, Berlin",
  "12437, Baumschulenweg, Berlin",
  "13059, Berlin, Berlin",
  "12099, Berlin, Berlin",
  "13355, Berlin, Berlin",
  "13351, Berlin, Berlin",
  "13359, Berlin, Berlin",
  "13587, Berlin, Berlin",
  "12057, Berlin, Berlin",
  "12489, Berlin, Berlin",
  "14109, Berlin, Berlin",
  "10777, Berlin, Berlin",
  "10711, Berlin, Berlin",
  "12439, Berlin, Berlin",
  "10713, Berlin, Berlin",
  "10719, Berlin, Berlin",
  "10963, Berlin, Berlin",
  "12161, Berlin, Berlin",
  "10439, Berlin, Berlin",
  "14169, Berlin, Berlin",
  "12437, Berlin, Berlin",
  "13435, Berlin, Berlin",
  "12109, Berlin, Berlin",
  "13503, Berlin, Berlin",
  "13129, Berlin, Berlin",
  "10779, Berlin, Berlin",
  "10965, Berlin, Berlin",
  "13159, Berlin, Berlin",
  "13583, Berlin, Berlin",
  "12105, Berlin, Berlin",
  "13589, Berlin, Berlin",
  "13088, Berlin, Berlin",
  "14199, Berlin, Berlin",
  "10555, Berlin, Berlin",
  "13597, Berlin, Berlin",
  "13353, Berlin, Berlin",
  "10249, Berlin, Berlin",
  "12557, Berlin, Berlin",
  "12279, Berlin, Berlin",
  "12623, Berlin, Berlin",
  "13156, Berlin, Berlin",
  "10557, Berlin, Berlin",
  "12307, Berlin, Berlin",
  "13409, Berlin, Berlin",
  "13089, Berlin, Berlin",
  "10785, Berlin, Berlin",
  "12589, Berlin, Berlin",
  "12621, Berlin, Berlin",
  "12353, Berlin, Berlin",
  "10119, Berlin, Berlin",
  "13507, Berlin, Berlin",
  "10318, Berlin, Berlin",
  "10825, Berlin, Berlin",
  "10247, Berlin, Berlin",
  "10315, Berlin, Berlin",
  "13055, Berlin, Berlin",
  "12103, Berlin, Berlin",
  "12277, Berlin, Berlin",
  "13158, Berlin, Berlin",
  "13357, Berlin, Berlin",
  "10787, Berlin, Berlin",
  "12527, Berlin, Berlin",
  "14129, Berlin, Berlin",
  "12159, Berlin, Berlin",
  "13629, Berlin, Berlin",
  "13469, Berlin, Berlin",
  "13581, Berlin, Berlin",
  "12683, Berlin, Berlin",
  "10707, Berlin, Berlin",
  "14059, Berlin, Berlin",
  "14089, Berlin, Berlin",
  "12305, Berlin, Berlin",
  "12247, Berlin, Berlin",
  "12587, Berlin, Berlin",
  "13187, Berlin, Berlin",
  "12559, Berlin, Berlin",
  "13349, Berlin, Berlin",
  "12347, Berlin, Berlin",
  "13407, Berlin, Berlin",
  "12357, Berlin, Berlin",
  "13057, Berlin, Berlin",
  "12059, Berlin, Berlin",
  "13439, Berlin, Berlin",
  "10365, Berlin, Berlin",
  "14193, Berlin, Berlin",
  "13127, Berlin, Berlin",
  "12526, Berlin, Berlin",
  "14195, Berlin, Berlin",
  "12459, Berlin, Berlin",
  "14052, Berlin, Berlin",
  "10589, Berlin, Berlin",
  "10709, Berlin, Berlin",
  "10559, Berlin, Berlin",
  "13465, Berlin, Berlin",
  "14163, Berlin, Berlin",
  "12047, Berlin, Berlin",
  "13593, Berlin, Berlin",
  "12249, Berlin, Berlin",
  "13595, Berlin, Berlin",
  "12355, Berlin, Berlin",
  "12359, Berlin, Berlin",
  "13347, Berlin, Berlin",
  "13437, Berlin, Berlin",
  "13125, Berlin, Berlin",
  "14197, Berlin, Berlin",
  "13509, Berlin, Berlin",
  "10789, Berlin, Berlin",
  "12487, Berlin, Berlin",
  "14055, Berlin, Berlin",
  "12157, Berlin, Berlin",
  "13505, Berlin, Berlin",
  "10317, Berlin, Berlin",
  "12101, Berlin, Berlin",
  "12351, Berlin, Berlin",
  "12524, Berlin, Berlin",
  "12107, Berlin, Berlin",
  "10969, Berlin, Berlin",
  "14167, Berlin, Berlin",
  "13051, Berlin, Berlin",
  "12435, Berlin, Berlin",
  "13591, Berlin, Berlin",
  "13599, Berlin, Berlin",
  "10435, Berlin, Berlin",
  "14050, Berlin, Berlin",
  "14057, Berlin, Berlin",
  "12163, Berlin, Berlin",
  "13585, Berlin, Berlin",
  "10827, Berlin, Berlin",
  "10369, Berlin, Berlin",
  "10967, Berlin, Berlin",
  "12349, Berlin, Berlin",
  "13403, Berlin, Berlin",
  "12683, Biesdorf, Berlin",
  "13129, Blankenburg, Berlin",
  "12526, Bohnsdorf, Berlin",
  "13509, Borsigwalde, Berlin",
  "12349, Britz, Berlin",
  "12347, Britz, Berlin",
  "12359, Britz, Berlin",
  "13125, Buch, Berlin",
  "13127, Buchholz, Berlin",
  "12353, Buckow, Berlin",
  "12351, Buckow, Berlin",
  "12349, Buckow, Berlin",
  "12357, Buckow, Berlin",
  "10587, Charlottenburg, Berlin",
  "14055, Charlottenburg, Berlin",
  "10789, Charlottenburg, Berlin",
  "10623, Charlottenburg, Berlin",
  "13627, Charlottenburg, Berlin",
  "10629, Charlottenburg, Berlin",
  "10585, Charlottenburg, Berlin",
  "14059, Charlottenburg, Berlin",
  "10625, Charlottenburg, Berlin",
  "10707, Charlottenburg, Berlin",
  "10627, Charlottenburg, Berlin",
  "10711, Charlottenburg, Berlin",
  "10719, Charlottenburg, Berlin",
  "14057, Charlottenburg, Berlin",
  "10589, Charlottenburg, Berlin",
  "13629, Charlottenburg-Nord, Berlin",
  "13627, Charlottenburg-Nord, Berlin",
  "14195, Dahlem, Berlin",
  "14169, Dahlem, Berlin",
  "14193, Dahlem, Berlin",
  "13057, Falkenberg, Berlin",
  "13589, Falkenhagener Feld, Berlin",
  "13583, Falkenhagener Feld, Berlin",
  "13585, Falkenhagener Feld, Berlin",
  "13125, Französisch Buchholz, Berlin",
  "78333, Französisch Buchholz, Berlin",
  "13158, Französisch Buchholz, Berlin",
  "13127, Französisch Buchholz, Berlin",
  "12159, Friedenau, Berlin",
  "12161, Friedenau, Berlin",
  "14197, Friedenau, Berlin",
  "12157, Friedenau, Berlin",
  "10319, Friedrichsfelde, Berlin",
  "10315, Friedrichsfelde, Berlin",
  "12587, Friedrichshagen, Berlin",
  "10247, Friedrichshain, Berlin",
  "10249, Friedrichshain, Berlin",
  "10245, Friedrichshain, Berlin",
  "10243, Friedrichshain, Berlin",
  "13465, Frohnau, Berlin",
  "14089, Gatow, Berlin",
  "13357, Gesundbrunnen, Berlin",
  "13355, Gesundbrunnen, Berlin",
  "13347, Gesundbrunnen, Berlin",
  "13359, Gesundbrunnen, Berlin",
  "13409, Gesundbrunnen, Berlin",
  "12351, Gropiusstadt, Berlin",
  "12353, Gropiusstadt, Berlin",
  "12527, Grünau, Berlin",
  "10711, Grunewald, Berlin",
  "14055, Grunewald, Berlin",
  "14199, Grunewald, Berlin",
  "14193, Grunewald, Berlin",
  "13587, Hakenfelde, Berlin",
  "13585, Hakenfelde, Berlin",
  "13589, Hakenfelde, Berlin",
  "10709, Halensee, Berlin",
  "10711, Halensee, Berlin",
  "10555, Hansaviertel, Berlin",
  "10557, Hansaviertel, Berlin",
  "13599, Haselhorst, Berlin",
  "13503, Heiligensee, Berlin",
  "13505, Heiligensee, Berlin",
  "13089, Heinersdorf, Berlin",
  "12627, Hellersdorf, Berlin",
  "12619, Hellersdorf, Berlin",
  "12621, Hellersdorf, Berlin",
  "9471, Hermsdorf, Berlin",
  "13467, Hermsdorf, Berlin",
  "12589, Hessenwinkel, Berlin",
  "12487, Johannisthal, Berlin",
  "10318, Karlshorst, Berlin",
  "12527, Karolinenhof, Berlin",
  "13125, Karow, Berlin",
  "12621, Kaulsdorf, Berlin",
  "12619, Kaulsdorf, Berlin",
  "14089, Kladow, Berlin",
  "12557, Kleingartenanlage Köpenick West, Berlin",
  "12559, Kleingartenanlage Müggelheim II, Berlin",
  "10407, Kleingartenanlage Neu Berlin, Berlin",
  "10407, Kleingartenanlage Volkspark Prenzlauer Berg, Berlin",
  "10318, Kleingartenanlage Wuhlheide Lichtenberg, Berlin",
  "12057, Kolonie Alt-Ruhleben I, Berlin",
  "13591, Kolonie Gartenbauverein Staaken, Berlin",
  "13593, Kolonie Heerstr., Berlin",
  "14050, Kolonie Westend, Berlin",
  "13505, Konradshöhe, Berlin",
  "12555, Köpenick, Berlin",
  "12559, Köpenick, Berlin",
  "10963, Kreuzberg, Berlin",
  "10965, Kreuzberg, Berlin",
  "10969, Kreuzberg, Berlin",
  "10967, Kreuzberg, Berlin",
  "10785, Kreuzberg, Berlin",
  "10999, Kreuzberg, Berlin",
  "10961, Kreuzberg, Berlin",
  "10997, Kreuzberg, Berlin",
  "12247, Lankwitz, Berlin",
  "12249, Lankwitz, Berlin",
  "10369, Lichtenberg, Berlin",
  "10365, Lichtenberg, Berlin",
  "10317, Lichtenberg, Berlin",
  "10367, Lichtenberg, Berlin",
  "10318, Lichtenberg, Berlin",
  "12309, Lichtenrade, Berlin",
  "12307, Lichtenrade, Berlin",
  "12305, Lichtenrade, Berlin",
  "12203, Lichterfelde, Berlin",
  "12207, Lichterfelde, Berlin",
  "14169, Lichterfelde, Berlin",
  "12209, Lichterfelde, Berlin",
  "14167, Lichterfelde, Berlin",
  "12205, Lichterfelde, Berlin",
  "13469, Lübars, Berlin",
  "12623, Mahlsdorf, Berlin",
  "13051, Malchow, Berlin",
  "12105, Mariendorf, Berlin",
  "12107, Mariendorf, Berlin",
  "12277, Mariendorf, Berlin",
  "12109, Mariendorf, Berlin",
  "12307, Marienfelde, Berlin",
  "12277, Marienfelde, Berlin",
  "12279, Marienfelde, Berlin",
  "13439, Märkisches Viertel, Berlin",
  "13435, Märkisches Viertel, Berlin",
  "12679, Marzahn, Berlin",
  "12685, Marzahn, Berlin",
  "12681, Marzahn, Berlin",
  "12687, Marzahn, Berlin",
  "12689, Marzahn, Berlin",
  "10117, Mitte, Berlin",
  "10178, Mitte, Berlin",
  "10115, Mitte, Berlin",
  "10179, Mitte, Berlin",
  "17098, Mitte, Berlin",
  "10969, Mitte, Berlin",
  "13158, Mitte, Berlin",
  "10435, Mitte, Berlin",
  "10119, Mitte, Berlin",
  "10553, Moabit, Berlin",
  "16928, Moabit, Berlin",
  "10557, Moabit, Berlin",
  "10247, Moabit, Berlin",
  "10555, Moabit, Berlin",
  "10559, Moabit, Berlin",
  "10551, Moabit, Berlin",
  "13059, Neu-Hohenschönhausen, Berlin",
  "13057, Neu-Hohenschönhausen, Berlin",
  "13051, Neu-Hohenschönhausen, Berlin",
  "12045, Neukölln, Berlin",
  "10965, Neukölln, Berlin",
  "10967, Neukölln, Berlin",
  "12055, Neukölln, Berlin",
  "12057, Neukölln, Berlin",
  "12047, Neukölln, Berlin",
  "12049, Neukölln, Berlin",
  "12043, Neukölln, Berlin",
  "12053, Neukölln, Berlin",
  "12059, Neukölln, Berlin",
  "12051, Neukölln, Berlin",
  "12439, Niederschöneweide, Berlin",
  "13156, Niederschönhausen, Berlin",
  "13158, Niederschönhausen, Berlin",
  "14163, Nikolassee, Berlin",
  "14109, Nikolassee, Berlin",
  "14129, Nikolassee, Berlin",
  "12459, Oberschöneweide, Berlin",
  "13187, Pankow, Berlin",
  "13189, Pankow, Berlin",
  "10439, Pankow, Berlin",
  "13127, Pankow, Berlin",
  "13595, Pichelsdorf, Berlin",
  "12437, Plänterwald, Berlin",
  "12435, Plänterwald, Berlin",
  "10119, Prenzlauer Berg, Berlin",
  "10409, Prenzlauer Berg, Berlin",
  "10435, Prenzlauer Berg, Berlin",
  "10405, Prenzlauer Berg, Berlin",
  "10249, Prenzlauer Berg, Berlin",
  "10439, Prenzlauer Berg, Berlin",
  "10247, Prenzlauer Berg, Berlin",
  "10437, Prenzlauer Berg, Berlin",
  "12589, Rahnsdorf, Berlin",
  "13409, Reinickendorf, Berlin",
  "13403, Reinickendorf, Berlin",
  "13405, Reinickendorf, Berlin",
  "13437, Reinickendorf, Berlin",
  "13407, Reinickendorf, Berlin",
  "13509, Reinickendorf, Berlin",
  "13158, Rosenthal, Berlin",
  "12355, Rudow, Berlin",
  "12357, Rudow, Berlin",
  "10317, Rummelsburg, Berlin",
  "14193, Schmargendorf, Berlin",
  "14195, Schmargendorf, Berlin",
  "14199, Schmargendorf, Berlin",
  "12527, Schmöckwitz, Berlin",
  "10827, Schöneberg, Berlin",
  "10787, Schöneberg, Berlin",
  "10829, Schöneberg, Berlin",
  "12159, Schöneberg, Berlin",
  "10789, Schöneberg, Berlin",
  "10823, Schöneberg, Berlin",
  "12157, Schöneberg, Berlin",
  "10783, Schöneberg, Berlin",
  "10777, Schöneberg, Berlin",
  "10781, Schöneberg, Berlin",
  "10825, Schöneberg, Berlin",
  "10785, Schöneberg, Berlin",
  "14197, Schöneberg, Berlin",
  "10779, Schöneberg, Berlin",
  "12101, Schöneberg, Berlin",
  "12459, Siedl. Oberspree Köpenick, Berlin",
  "13629, Siedl. Siedlungsverein Siemensstadt, Berlin",
  "13627, Siemensstadt, Berlin",
  "13587, Spandau, Berlin",
  "13589, Spandau, Berlin",
  "13581, Spandau, Berlin",
  "40210, Spandau, Berlin",
  "13599, Spandau, Berlin",
  "13597, Spandau, Berlin",
  "13593, Spandau, Berlin",
  "13583, Spandau, Berlin",
  "13585, Spandau, Berlin",
  "13595, Spandau, Berlin",
  "13591, Spandau, Berlin",
  "13581, Staaken, Berlin",
  "13593, Staaken, Berlin",
  "13088, Stadtrandsiedlung Malchow, Berlin",
  "12167, Steglitz, Berlin",
  "12157, Steglitz, Berlin",
  "12247, Steglitz, Berlin",
  "12163, Steglitz, Berlin",
  "12165, Steglitz, Berlin",
  "12169, Steglitz, Berlin",
  "12161, Steglitz, Berlin",
  "14195, Steglitz, Berlin",
  "13507, Tegel, Berlin",
  "13405, Tegel, Berlin",
  "13509, Tegel, Berlin",
  "13599, Tegel, Berlin",
  "13629, Tegel, Berlin",
  "12105, Tempelhof, Berlin",
  "12107, Tempelhof, Berlin",
  "12099, Tempelhof, Berlin",
  "12101, Tempelhof, Berlin",
  "12109, Tempelhof, Berlin",
  "12103, Tempelhof, Berlin",
  "10965, Tempelhof, Berlin",
  "10963, Tiergarten, Berlin",
  "10785, Tiergarten, Berlin",
  "10559, Tiergarten, Berlin",
  "10555, Tiergarten, Berlin",
  "10557, Tiergarten, Berlin",
  "10787, Tiergarten, Berlin",
  "13469, Waidmannslust, Berlin",
  "14109, Wannsee, Berlin",
  "13051, Wartenberg, Berlin",
  "13059, Wartenberg, Berlin",
  "13351, Wedding, Berlin",
  "13359, Wedding, Berlin",
  "13407, Wedding, Berlin",
  "13353, Wedding, Berlin",
  "13349, Wedding, Berlin",
  "13347, Wedding, Berlin",
  "13088, Weißensee, Berlin",
  "13086, Weißensee, Berlin",
  "15711, Westend, Berlin",
  "14059, Westend, Berlin",
  "14052, Westend, Berlin",
  "14055, Westend, Berlin",
  "14057, Westend, Berlin",
  "12589, Wilhelmshagen, Berlin",
  "13158, Wilhelmsruh, Berlin",
  "13597, Wilhelmstadt, Berlin",
  "13581, Wilhelmstadt, Berlin",
  "13595, Wilhelmstadt, Berlin",
  "13593, Wilhelmstadt, Berlin",
  "14197, Wilmersdorf, Berlin",
  "14199, Wilmersdorf, Berlin",
  "10711, Wilmersdorf, Berlin",
  "10789, Wilmersdorf, Berlin",
  "10779, Wilmersdorf, Berlin",
  "10719, Wilmersdorf, Berlin",
  "10777, Wilmersdorf, Berlin",
  "10715, Wilmersdorf, Berlin",
  "10717, Wilmersdorf, Berlin",
  "10709, Wilmersdorf, Berlin",
  "14195, Wilmersdorf, Berlin",
  "10713, Wilmersdorf, Berlin",
  "10707, Wilmersdorf, Berlin",
  "13435, Wittenau, Berlin",
  "13437, Wittenau, Berlin",
  "13403, Wittenau, Berlin",
  "13469, Wittenau, Berlin",
  "14163, Zehlendorf, Berlin",
  "14167, Zehlendorf, Berlin",
  "14165, Zehlendorf, Berlin",
  "14169, Zehlendorf, Berlin",
  "14129, Zehlendorf, Berlin",
  "30453, Ahlem, Hannover",
  "30559, Anderten, Hannover",
  "30455, Badenstedt, Hannover",
  "30559, Bemerode, Hannover",
  "30539, Bemerode, Hannover",
  "30453, Bornum, Hannover",
  "30657, Bothfeld, Hannover",
  "30659, Bothfeld, Hannover",
  "30655, Bothfeld, Hannover",
  "30173, Bult, Hannover",
  "30169, Calenberger Neustadt, Hannover",
  "30167, Calenberger Neustadt, Hannover",
  "30455, Davenstedt, Hannover",
  "30519, Döhren, Hannover",
  "30627, Groß Buchholz, Hannover",
  "30625, Groß Buchholz, Hannover",
  "30655, Groß Buchholz, Hannover",
  "30165, Hainholz, Hannover",
  "30167, Hannover, Hannover",
  "30173, Hannover, Hannover",
  "30177, Hannover, Hannover",
  "30453, Hannover, Hannover",
  "30625, Hannover, Hannover",
  "30159, Hannover, Hannover",
  "30161, Hannover, Hannover",
  "30659, Hannover, Hannover",
  "30519, Hannover, Hannover",
  "30163, Hannover, Hannover",
  "30457, Hannover, Hannover",
  "30655, Hannover, Hannover",
  "30539, Hannover, Hannover",
  "30419, Hannover, Hannover",
  "30459, Hannover, Hannover",
  "30657, Hannover, Hannover",
  "30627, Hannover, Hannover",
  "30171, Hannover, Hannover",
  "30629, Hannover, Hannover",
  "30559, Hannover, Hannover",
  "30165, Hannover, Hannover",
  "30625, Heideviertel, Hannover",
  "30419, Herrenhausen, Hannover",
  "30657, Isernhagen-Süd, Hannover",
  "30559, Kirchrode, Hannover",
  "30625, Kleefeld, Hannover",
  "30419, Ledeburg, Hannover",
  "30419, Leinhausen, Hannover",
  "30453, Limmer, Hannover",
  "30449, Linden-Mitte, Hannover",
  "30451, Linden-Nord, Hannover",
  "30449, Linden-Süd, Hannover",
  "30559, Linden-Süd, Hannover",
  "30177, List, Hannover",
  "30655, List, Hannover",
  "30161, List, Hannover",
  "30163, List, Hannover",
  "30419, Marienwerder, Hannover",
  "30627, Misburg-Nord, Hannover",
  "30629, Misburg-Nord, Hannover",
  "31535, Mitte, Hannover",
  "30175, Mitte, Hannover",
  "30171, Mitte, Hannover",
  "30159, Mitte, Hannover",
  "30161, Mitte, Hannover",
  "30519, Mittelfeld, Hannover",
  "30539, Mittelfeld, Hannover",
  "30457, Mühlenberg, Hannover",
  "30167, Nordstadt, Hannover",
  "30459, Oberricklingen, Hannover",
  "30161, Oststadt, Hannover",
  "30453, Ricklingen, Hannover",
  "30459, Ricklingen, Hannover",
  "30179, Sahlkamp, Hannover",
  "30657, Sahlkamp, Hannover",
  "30519, Seelhorst, Hannover",
  "30419, Stöcken, Hannover",
  "30175, Südstadt, Hannover",
  "30169, Südstadt, Hannover",
  "30159, Südstadt, Hannover",
  "30173, Südstadt, Hannover",
  "30171, Südstadt, Hannover",
  "30179, Vahrenheide, Hannover",
  "30161, Vahrenwald, Hannover",
  "30165, Vahrenwald, Hannover",
  "30419, Vinnhorst, Hannover",
  "30519, Waldhausen, Hannover",
  "30519, Waldheim, Hannover",
  "30457, Wettbergen, Hannover",
  "30519, Wülfel, Hannover",
  "30539, Wülferode, Hannover",
  "30175, Zoo, Hannover"
];
