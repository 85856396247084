/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Suspense } from "react";
import { Route } from "react-router-dom";
import CustomSpinner from "components/commonComponents/customSpinner";
//@ts-ignore
import ReactDependentScript from "react-dependent-script";
import { getScripts } from "utils/functions";

interface Props {
  path: string | string[];
  component: any;
}

const PublicRoute: React.FC<Props> = ({ component: Component, ...rest }) => 
   (
    <Route
      exact
      {...rest}
      render={(props) =>
        window.location.pathname.includes("realtor") ? (
          <ReactDependentScript
            scripts={getScripts()}
            renderChildren={() => (
              <Suspense
                fallback={
                  <div>
                    <div style={{ padding: "20% 0" }}>
                      <CustomSpinner />
                    </div>
                  </div>
                }
              >
                <Component {...props} />
              </Suspense>
            )}
          />
        ) : (
          <Suspense
            fallback={
              <div>
                <div style={{ padding: "20% 0" }}>
                  <CustomSpinner />
                </div>
              </div>
            }
          >
            <Component {...props} />
          </Suspense>
        )
      }
    />
  )
;

export default PublicRoute;
