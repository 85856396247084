import React from "react";
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";

interface ISelectProps {
  toggleOptions: any;
  showDrop: string;
  name: string;
  options: any;
  selectedOption: string;
  emptyFields?: string[];
  field?: any;
  changeToggleOptions: (value: boolean) => void;
  changeShowDrop: (name: string) => void;
  changeSelectedOption: (event: any) => void;
}

// custom dropdown menu
const CustomSelect: React.FC<ISelectProps> = (props) => {
  const { changeToggleOptions, toggleOptions, showDrop, changeShowDrop } =
    props;

  // get default or selected value
  const getValue = () => {
    const { name, options, selectedOption } = props;
    let value = selectedOption || englishText.PLEASE_SELECT;
    if (name === "obj_telekomInternet") {
      const findvalue = options.find(
        (i: any) => i.value === props.selectedOption
      );
      if (findvalue) {
        value = findvalue.label;
      }
    }
    return value;
  };
  const showValue = getValue();

  return (
    <div
      className="custom-select-form"
      style={
        props.emptyFields && props.emptyFields.includes(props.name)
          ? { borderColor: "#d84747" }
          : {}
      }
    >
      <div
        className="selected-option"
        onClick={() => {
          changeShowDrop(!toggleOptions ? props.name : "");
          changeToggleOptions(!toggleOptions);
        }}
      >
        <span style={{ textTransform: "capitalize" }}>
          {getLocalizeText(showValue)}
        </span>
        <div className="arrow-div">
          <i className="fa fa-caret-down" aria-hidden="true" />
        </div>
      </div>
      <div
        className={`custom-options-wrapper ${
          toggleOptions && props.name === showDrop ? "show" : ""
        }`}
      >
        <ul className="custom-options">
          {props.options.map((option: any, index: number) => (
            <li
              key={index}
              onClick={() => {
                props.changeSelectedOption({
                  target: {
                    name: props.name,
                    value:
                      props.name === "obj_telekomInternet"
                        ? option.value
                        : option,
                    type: "dropdown",
                  },
                });
                changeToggleOptions(false);
              }}
              style={{ textTransform: "capitalize" }}
            >
              {option.label || getLocalizeText(option).replace("_", " ")}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

CustomSelect.defaultProps = {
  emptyFields: [],
  toggleOptions: false,
};

export default CustomSelect;
