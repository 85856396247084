import React, { useState } from 'react';
import PasswordInput from '../../auth/components/passwordMeter';
import getLocalizeText from 'utils/getLocalizeText';
import englishText from 'languages/english/english';
import { FormattedMessage } from 'react-intl';
import * as changePassInterface from '../interface/profile';
import 'components/auth/auth.scss';
import { Link } from "react-router-dom";

export const ChangePasswordTab: React.FC<
  changePassInterface.IChangePassProps
> = (props) => {
  const [showOldPassword, setOldPwd] = useState(false);
  const [showPassword, toggleShowPwd] = useState(false);
  return (
    <div className="profile-tab-wrapper">
      <div className="row">
        <div className="col-md-12">
          <div className="tab-content profile-tab" id="myTabContent">
            <div
              className="tab-pane fade show active"
              id="home"
              role="tabpanel"
              aria-labelledby="home-tab"
            >
              <div className="row">
                <div className="col-12">
                  <label style={{ marginTop: "5px" }}>
                    <FormattedMessage
                      id={englishText.NEW_PASSWORD}
                      defaultMessage={englishText.NEW_PASSWORD}
                    />
                  </label>
                </div>
                <div
                  className="form-group col-12 mb-0"
                  style={{ paddingRight: "5px" }}
                >
                  <PasswordInput
                    value={props.state.newPassword}
                    name={"newPassword"}
                    onKeyDown={props.onKeyDown}
                    handleChanges={props.handleChange}
                    showPassword={showPassword}
                    toggleShowPwd={() => toggleShowPwd(!showPassword)}
                    placeholder={getLocalizeText("New Password")}
                    fromReset={true}
                  />
                </div>
                <br />
              </div>
              <div className="row mb-3">
                <div className="col-12">
                  <label style={{ marginTop: "5px" }}>
                    {getLocalizeText("Old Password")}
                  </label>
                </div>
                <div className="col-12" style={{ paddingRight: "5px" }}>
                  <input
                    className="abc"
                    name={"password"}
                    onChange={props.handleChange}
                    onKeyDown={props.onKeyDown}
                    type={showOldPassword ? "text" : "password"}
                    value={props.state["password"]}
                    disabled={props.state.isLoading}
                    placeholder={getLocalizeText("Old Password")}
                  />
                  <i
                    className={
                      showOldPassword
                        ? "pwd-icon p-icon fa fa-eye-slash"
                        : "pwd-icon p-icon fa fa-eye"
                    }
                    onClick={() => setOldPwd(!showOldPassword)}
                  />
                </div>
              </div>
              {props.state.isError !== "" && props.state.changePassword && (
                <p className="profile-error">
                  {props.state.isError === "Invalid password"
                    ? getLocalizeText(englishText.INCORRECT_OLD_PWD)
                    : getLocalizeText(props.state.isError)}
                </p>
              )}
              {props.state.issuccess !== "" && props.state.changePassword && (
                <p className="profile-success">
                  {getLocalizeText(props.state.issuccess)}
                </p>
              )}
              <div className="text-center w-100">
                <button
                  className="button"
                  onClick={() => props.submitPassword()}
                  tabIndex={1}
                  style={{
                    textTransform: "uppercase",
                    fontSize: "12px",
                  }}
                >
                  <FormattedMessage
                    id={englishText.SAVE_PASSWORD}
                    defaultMessage={englishText.SAVE_PASSWORD}
                  />
                </button>
                <div>
                  <Link
                    className="w-100"
                    to="/forgot-password"
                    style={{ fontSize: "14px" }}
                  >
                    {getLocalizeText(englishText.FORGOT_PWD)}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePasswordTab;
