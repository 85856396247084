import React from 'react';
import { useHistory } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
// component
import ContactPopUpWrapper from 'components/objectDetails/components/popups/newPopUps/contactPopUpWrapper';
// functions
import englishText from 'languages/english/english';
// assets
import bg from "assets/images/onb-banner-bg.png";
import arrow from "assets/images/Nextarrow.png";

interface IReminderProps {
  closepopup: () => void;
}

const OnboardingReminder: React.FC<IReminderProps> = (props) => {
  const history = useHistory();

  const renderLeftPannel = () => (
    <div className="left-section d-flex flex-column justify-content-between">
      <div>
        <h1>
          <FormattedMessage
            id={englishText.BETTER_RESULTS}
            defaultMessage={englishText.BETTER_RESULTS}
          />
        </h1>
        <p>
          <FormattedMessage
            id={englishText.BETTER_RES_SUBTITLE}
            defaultMessage={englishText.BETTER_RES_SUBTITLE}
          />
        </p>
      </div>
      <button
        className="contact-btn fade-in"
        onClick={() => history.push("/onboarding")}
      >
        <FormattedMessage
          id={englishText.ACTIVATE_NOW}
          defaultMessage={englishText.ACTIVATE_NOW}
        />
        <img src={arrow} alt="img" />
      </button>
    </div>
  );

  const renderRightPannel = () => (
    <div className="right-section">
      <img src={bg} alt="img" />
    </div>
  );
  return (
    <div className="onb-warning-container d-flex justify-content-center align-items-center model-wrapper show fade-in">
      <ContactPopUpWrapper
        className="platform-contact-popup"
        onClose={props.closepopup}
        leftPannel={renderLeftPannel()}
        rightPannel={renderRightPannel()}
      />
    </div>
  );
};

export default OnboardingReminder;