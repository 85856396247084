/* eslint-disable no-extra-boolean-cast */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import ReactToPrint from "react-to-print";
import html2canvas from "html2canvas";
// Components
import RadialSeparators from "../component/radialSeperators";
import PdfFooter from "../component/pdfFooter";
// Functions
import { convertDateFormatToDdMm, getIcon, getLocalizeText } from "utils";
import { API_CONFIG, HttpService } from "services";
import englishText from "languages/english/english";
import { FormattedMessage } from "react-intl";
import {
  fiOnbMapper,
  onbMapper,
  translatedAND,
  transletedYesNo,
} from "../constants";
// Assets
import logo from "assets/images/logo-without-name.png";
import immozyLogo from "assets/images/immozyLogo.png";
import "../qualifyPDF.scss";

const currentLang = localStorage.getItem("language") || "de";

interface IQualifyProps {
  enabled: boolean;
  sendToMailButton?: boolean;
  uploadFile?: (file: File) => void;
}

let showPdfPreview = false;

class QualifyPDF extends Component<IQualifyProps> {
  componentRef: any = React.createRef();
  state: any = {
    isLoading: true,
    contactDetails: {},
    onboarding: {},
    financeOnboarding: {},
  };
  componentDidMount() {
    this.fetchData();
  }

  render() {
    const { isLoading } = this.state;
    let styles: React.CSSProperties = {};
    if (!showPdfPreview) {
      styles = {
        position: "absolute",
        transform: "translate(-106%, -100%)",
      };
    }
    return (
      <>
        {!this.props.sendToMailButton && !showPdfPreview && !isLoading && (
          <div className="d-flex justify-content-center align-item-center">
            <ReactToPrint
              content={() => this.componentRef}
              trigger={() => (
                <button
                  className="contact-btn fade-in"
                  disabled={!this.props.enabled || isLoading}
                  title={
                    this.props.enabled
                      ? ""
                      : getLocalizeText(englishText.COMPLETE_QUALIFY_WARNING)
                  }
                >
                  <FormattedMessage
                    id={englishText.DOWNLOAD_PDF}
                    defaultMessage={englishText.DOWNLOAD_PDF}
                  />
                </button>
              )}
            />
          </div>
        )}
        <button
          id="sendToMailButton"
          hidden
          onClick={() =>
            this.props.sendToMailButton && this.handleCreateCanvas()
          }
        >
          Create Cnvas
        </button>
        <div style={styles}>{!isLoading && this.renderPdfContent()}</div>
      </>
    );
  }

  dataURLtoFile = (dataurl: string, filename: string) => {
    const arr: any = dataurl.split(",");
    const mime = arr[0].match(/:(.*?);/)[1];
    const bstr = atob(arr[1]);
    let n = bstr.length;
    const u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  };

  handleCreateCanvas = () => {
    if (!!this.props.uploadFile) {
      html2canvas(this.componentRef, {
        imageTimeout: 5000,
        backgroundColor: "#ffffff",
        useCORS: true,
        logging: true,
        allowTaint: true,
      }).then((canvas) => {
        const blobData = canvas.toDataURL("image/png");
        const userId: string =
          window.location.pathname.replace("/user-profile/", "") ||
          localStorage.getItem("userId") ||
          "";
        const file: File = this.dataURLtoFile(blobData, `${userId}.png`);
        this.props.uploadFile && this.props.uploadFile(file);
      });
    }
  };

  getDiffTypeData = (data: any, key: string) => {
    if (!data) return "-";
    switch (key) {
      case "availableEquity":
      case "maxPurchasePrice":
      case "netIncome":
        return this.currencyformatter(data, "de-DE", "EUR");
      case "space":
        return `${data} m²`;
      case "hasGermanCitizenship":
        return data.includes(getLocalizeText(englishText.YES))
          ? getLocalizeText(englishText.YES)
          : getLocalizeText(englishText.NO);
      default:
        return data;
    }
  };

  renderPdfContent = () => {
    const { contactDetails, financeCheck, onboarding, financeOnboarding } =
      this.state;
    return (
      <div
        className="pdf-wrapper"
        ref={(el) => (this.componentRef = el)}
        style={!showPdfPreview ? { width: "1390px", maxHeight: "1457px" } : {}}
      >
        <div className="pdf-header">
          <a href="/find">
            <img src={immozyLogo} alt="immozy-logo" />
          </a>
          <h2>{getLocalizeText(englishText.HANDSHAKE_PDF_HEADING)}</h2>
        </div>
        <div className="pdf-content">
          <div className="contact-detail-wrapper">
            <div className="col">
              <h4 className="name">
                {contactDetails.firstName}
                {!!contactDetails.lastName ? (
                  <> {contactDetails.lastName}</>
                ) : (
                  ""
                )}
              </h4>
              <p className="born">
                {getLocalizeText(englishText.BORN)}.{" "}
                {!!contactDetails.birthdate
                  ? convertDateFormatToDdMm(
                      contactDetails.birthdate
                    ).replaceAll("/", ".")
                  : "-"}
              </p>
            </div>
            <div className="col address-detail">
              {!!contactDetails.street ? (
                <>
                  {contactDetails.street} <br />
                </>
              ) : (
                "-"
              )}
              {!!(contactDetails.postalcode || contactDetails.city)
                ? `${contactDetails.postalcode || ""} ${
                    contactDetails.city || ""
                  }`
                : "-"}
            </div>
            <div className="col" />
            <div className="col" />
            <div className="col">
              <div className="progress-wrapper">
                <CircularProgressbarWithChildren
                  value={Number(contactDetails.calculatePercentage)}
                  strokeWidth={10}
                  styles={buildStyles({
                    strokeLinecap: "butt",
                    pathColor: getIcon(
                      Number(contactDetails.calculatePercentage)
                    ),
                  })}
                >
                  <img
                    style={{ width: 18, marginTop: 0, marginLeft: "-2px" }}
                    src={logo}
                    alt="immozy-logo"
                  />
                  <RadialSeparators
                    count={12}
                    style={{
                      background: "#fff",
                      width: "2px",
                      height: `${10}%`,
                    }}
                  />
                </CircularProgressbarWithChildren>
              </div>
              <p className="progress-percentage">
                {contactDetails.calculatePercentage} %
              </p>
            </div>
            <div className="col">
              {contactDetails.email || contactDetails.contact_email || "-"}
              <br />
              {"+ " + contactDetails.phone || "-"}
            </div>
            <div className="col">
              <FormattedMessage
                id={englishText.BEST_ACHIEVABLE}
                defaultMessage={englishText.BEST_ACHIEVABLE}
              />
              <br />
              {!!contactDetails.reachablity
                ? `${getLocalizeText(
                    englishText.IN_BTWN
                  )} ${contactDetails.reachablity.replace(
                    "-",
                    `:00 ${translatedAND[currentLang]}`
                  )}:00 ${getLocalizeText(englishText.OCLOCK_BY_PHONE)}`
                : "-"}
            </div>
            <div className="col">
              <a
                href={`mailto:${
                  contactDetails.email || contactDetails.contact_email || "-"
                }`}
              >
                <button className="contact-btn">
                  <FormattedMessage
                    id={englishText.CONTACT}
                    defaultMessage={englishText.CONTACT}
                  />
                </button>
              </a>
            </div>
          </div>
          <div className="onboarding-detail-wrapper">
            <div className="onboarding-header">
              <p>
                <FormattedMessage
                  id={englishText.SEARCH_PREFERENCES}
                  defaultMessage={englishText.SEARCH_PREFERENCES}
                />
              </p>
            </div>
            <div className="onboarding-content-wrapper">
              <div className="onboarding-content">
                <table>
                  <tbody>
                    {Object.keys(onboarding).map(
                      (key: string, index: number) => (
                        <tr key={index}>
                          <th> {onboarding[key].label}</th>
                          <td colSpan={2}>
                            <ul>
                              {onboarding[key].ans.map(
                                (data: any, index: number) => (
                                  <li key={index}>
                                    {this.getDiffTypeData(data, key)}
                                  </li>
                                )
                              )}
                            </ul>
                          </td>
                        </tr>
                      )
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="finance-onboarding-detail-wrapper">
            <div className="finance-onboarding-header">
              <p>
                <FormattedMessage
                  id={englishText.FINANCIAL_CONDITIONS}
                  defaultMessage={englishText.FINANCIAL_CONDITIONS}
                />
              </p>
            </div>
            <div className="finance-onboarding-content-wrapper">
              <div className="finance-onboarding-content">
                <table>
                  <tbody>
                    {Object.keys(financeOnboarding).map(
                      (key: string, index: number) => (
                        <tr key={index}>
                          <th> {financeOnboarding[key].label}</th>
                          <td>
                            <ul>
                              {financeOnboarding[key].ans.map(
                                (data: any, index: number) => (
                                  <li key={index}>
                                    {this.getDiffTypeData(data, key)}
                                  </li>
                                )
                              )}
                            </ul>
                          </td>
                        </tr>
                      )
                    )}
                    <tr>
                      <th>
                        <FormattedMessage
                          id={englishText.FINANCING_CERTIFICATE_AVAILABLE}
                          defaultMessage={
                            englishText.FINANCING_CERTIFICATE_AVAILABLE
                          }
                        />
                      </th>
                      <td>
                        <ul>
                          <li>
                            {financeCheck
                              ? transletedYesNo.YES[currentLang]
                              : transletedYesNo.NO[currentLang]}
                          </li>
                        </ul>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {!this.props.sendToMailButton && (
          <PdfFooter showPdfPreview={showPdfPreview} />
        )}
      </div>
    );
  };

  fetchData = () => {
    let userId = localStorage.getItem("userId") || "";
    if (window.location.pathname.includes("user-profile")) {
      userId = window.location.pathname.replace("/user-profile/", "");
      showPdfPreview = true;
    }
    HttpService.get(
      `${API_CONFIG.path.getRealtorHandShakeDetailForPdf}/${userId}`
    )
      .then((res) => {
        const { contactDetails, onboarding, financeCheck, financeOnboarding } =
          res.data;
        this.setState({
          contactDetails,
          financeCheck,
        });
        this.extractAns(onboarding.questions, onbMapper, "onboarding");
        this.extractAns(
          financeOnboarding.questions,
          fiOnbMapper,
          "financeOnboarding"
        );

        setTimeout(() => {
          this.setState({ isLoading: false });
        }, 500);
      })
      .catch((err) => {
        console.log("  err", err);
        this.setState({ isLoading: false });
      });
  };

  currencyformatter = (digit: string, lang: string, currency: string) =>
    new Intl.NumberFormat(lang, {
      style: "currency",
      currency,
      minimumFractionDigits: 0,
    }).format(parseInt(digit));

  getOption = (option: any, customType: null | string = null) => {
    const key = customType || option.type;
    switch (key) {
      case "SINGLE_CHECKBOX":
        return option.isSelected
          ? transletedYesNo.YES[currentLang]
          : transletedYesNo.YES[currentLang];
      case "INPUT":
      case "RANGE":
        return option.value;
      case "DOUBLE_RANGE":
        return option.value.join("-");
      default:
        return option.option[currentLang];
    }
  };

  extractAns = (questions: any[], mapper: any, stateKey: string) => {
    const qIds = questions.map((que) => que.questionId);
    let city = "";
    const state: any = {};
    for (const key in mapper) {
      const value: any = mapper[key].stateKey;
      const index = qIds.findIndex((qId) => qId === key);
      const que = questions[index];
      if (key === "custom") {
        state[mapper[key].key] = {
          label: mapper[key].label || "",
          ans: [
            getLocalizeText(
              (this.state[mapper[key].stateKey] || {})[mapper[key].key] || "-"
            ),
          ],
        };
      } else {
        if (typeof value !== "object") {
          const ans: any = [];
          que.options.forEach((opt: any) => {
            if (opt.isSelected) {
              if (key === "8baf754e-c74f-4019-90ea-96e419d6ee71") {
                city = this.getOption(opt);
              } else {
                if (key === "a1d4da38-ef3e-4786-befa-db2e52031a67") {
                  ans.push(`${city} - ${this.getOption(opt)}`);
                } else {
                  ans.push(this.getOption(opt));
                }
              }
            }
          });
          state[value] = { label: mapper[key].label || "", ans };
        } else {
          value.forEach((element: any) => {
            que.options.forEach((opt: any) => {
              if (opt.isSelected && element.optionId === opt.optionId) {
                state[element.key] = {
                  label: element.label || "",
                  ans: [this.getOption(opt, element.type)],
                };
              }
            });
          });
        }
      }
    }
    this.setState({ [stateKey]: state }, () => {
      console.log("this.state", this.state);
    });
  };
}

export default QualifyPDF;
