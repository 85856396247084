import React from "react";
import Form from "react-bootstrap/Form";
import ButtonDiv from "./buttonDiv";
import { inputFieldsGeneral, checkFields } from "../utils/constants";
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import { IGeneralProps } from "../interface/myproperty.interface";

const GeneralTab: React.FC<IGeneralProps> = (props) => {
  const { generalTabObj, emptyFields } = props;

  const handleSaveClick = () => {
    const { generalTabObj, handleSaveAndContinue } = props;
    handleSaveAndContinue(generalTabObj, "location", "v1/objects", "POST");
  };

  return (
    <div className="general-main-div">
      <div className="general-main-div-wrapper">
        <p className="add-prop-desc">
          {getLocalizeText(englishText.ADD_PROP_TITLE)}
          <br /> {getLocalizeText(englishText.ADD_PROP_SUBTITLE)}
        </p>
        {/* Name and description */}
        {inputFieldsGeneral.map((field, i) => (
          <div className="row form-group" key={i}>
            <label className="col-sm-2 mb-3 form-check-label">
              {getLocalizeText(field.label)}
              {field.isRequired && "*"}
            </label>
            <input
              className="form-control col-sm-3"
              value={generalTabObj[field.name]}
              name={field.name}
              onChange={props.handleValueChange}
              style={
                emptyFields.includes(field.name)
                  ? { borderColor: "#d84747" }
                  : {}
              }
            />
          </div>
        ))}

        {checkFields.map((field, i) => (
          <div className="row form-group col-5 mb-3 right-side-check" key={i}>
            <Form.Check
              id={field.name}
              custom
              type="checkbox"
              label={getLocalizeText(field.label)}
              checked={generalTabObj[field.name]}
              name={field.name}
              onChange={props.handleValueChange}
            />
          </div>
        ))}
      </div>

      <ButtonDiv
        handleSaveClick={handleSaveClick}
        show={false}
        emptyFields={emptyFields}
      />
    </div>
  );
};

GeneralTab.defaultProps = {
  emptyFields: [],
};

export default GeneralTab;
