/* eslint-disable @typescript-eslint/ban-ts-comment */
import pin from "assets/images/pin.png";
import englishText from "languages/english/english";
import {
  getIcon,
  getIconImg,
  getIconImgHighlight,
  getLocalizeText,
} from "utils";
import budget from "assets/images/icon-budget.png";
import house from "assets/images/icon-House.png";
import location from "assets/images/icon-Location.png";
import greenHighlight from "assets/images/green-highlight.png";

export const setProperties = (propertyList: any, statemapData: any) => {
  const list: any = [];
  const mapData: any = [];
  const favoList: any = [];
  let mapResult = null;

  propertyList.forEach((prop: any) => {
    const imgList: any = [];

    prop.objectPictures.forEach((element: string) => {
      const images = {
        original: element,
      };
      imgList.push(images);
    });
    const a = {
      id: prop.id,
      title: prop.objectTitle,
      coverImg: prop.objectTitlePicture,
      adrPostalCode: prop.adrPostalCode,
      price: prop.formatedPrice,
      propertyType: prop.propertyType,
      priceCurrency: prop.priceCurrency,
      rooms: prop.propRoomsAmount,
      areaLiving: prop.areaLiving,
      isFavourite: prop.isFavourite,
      rankId: prop.score || 0,
      imgList,
      adrQuarter: prop.adrQuarter,
      adrStreet: prop.adrStreet,
      yearConstructed: prop.yearConstructed,
      noMap: !prop.location ? true : false,
      isActive: prop.isActive,
      isEnable: prop.isEnable,
      isNewProperty: prop.isNewProperty,
      iconColor: getIcon(prop.score || 0),
      objectPictures: prop.objectPictures,
      type_auction_forced: prop.type_auction_forced,
      is_realtor_active: prop.is_realtor_active,
    };

    if (prop.isFavourite) {
      favoList.push(prop.id);
      //@ts-ignore
      a.isPrivate = prop.isPrivate;
    }
    setMapData(prop, mapData, imgList);
    list.push(a);
  });

  if (mapData.length > 0 && statemapData) {
    mapResult = setMultiMapData(mapData, statemapData);
  }

  return { list, mapResult, favoList };
};

export const setMapData = (prop: any, mapData: any, imgList: any[]) => {
  if (prop.location && prop.isActive && prop.isEnable) {
    const mapdata = {
      id: prop.id,
      title: prop.objectTitle,
      name: prop.objectTitle && prop.objectTitle.substring(0, 25) + "...",
      label: prop.score || 0,
      lat: parseFloat(prop.location.lat).toFixed(7),
      lon: parseFloat(prop.location.lon).toFixed(7),
      coverImg: prop.objectTitlePicture,
      imgList,
      price: prop.formatedPrice,
      rooms: prop.propRoomsAmount,
      areaLiving: prop.areaLiving,
      propertyType: prop.propertyType,
      adr: prop.adrPostalCode
        ? prop.adrPostalCode + ", " + prop.adrQuarter
        : prop.adrQuarter,
      icon: prop.score ? getIconImg(prop.score) : pin,
      highlightIcon: prop.score
        ? getIconImgHighlight(prop.score)
        : greenHighlight,
    };
    mapData.push(mapdata);
  }
};

export const setMultiMapData = (mapData: any, statemapData: any) => {
  let singlePins: any = [];
  let multiPins = null;
  let group: any = null;
  const newArray: any = [];

  group = mapData.reduce((r: any, a: any) => {
    r[a.lat] = [...(r[a.lat] || []), a];
    if (a && r[a.lat] && r[a.lat].length === 1) {
      singlePins.push(a);
    }
    return r;
  }, {});

  // remove this later
  statemapData.forEach((element: any) => {
    singlePins = singlePins.filter(
      (i: any) => element.lat !== i.lat && element.lon !== i.lon
    );
  });

  multiPins = Object.keys(group)
    .filter((i) => group[i].length > 1)
    .reduce((obj, key) => {
      //@ts-ignore
      obj[key] = group[key];
      return obj;
    }, {});

  const mapResult = createMultipleMapObject(multiPins, newArray, singlePins);
  return mapResult;
};

export const createMultipleMapObject = (
  multiPins: any,
  newArray: any,
  singlePins: any
) => {
  const alreadyAdded: any = [];
  Object.entries(multiPins).forEach((pin: any) => {
    const id: string[] = [];
    const title: string[] = [];
    const label: string[] = [];
    const coverImg: string[] = [];
    const price: string[] = [];
    const rooms: number[] = [];
    const areaLiving: string[] = [];
    const adr: string[] = [];
    const propertyType: string[] = [];
    const imgList: string[] = [];

    pin[1].forEach((p: any) => {
      id.push(p.id);
      title.push(p.title);
      label.push(p.label);
      coverImg.push(p.coverImg);
      price.push(p.price);
      rooms.push(p.rooms);
      areaLiving.push(p.areaLiving);
      adr.push(p.adr);
      imgList.push(p.imgList);
      alreadyAdded.push(p.id);
      propertyType.push(p.propertyType);
    });

    const multiMapData = {
      id,
      title,
      label,
      coverImg,
      price,
      rooms,
      adr,
      imgList,
      areaLiving,
      propertyType,
      lat: pin[1][0].lat,
      lon: pin[1][0].lon,
    };

    newArray.push(multiMapData);
  });

  singlePins.forEach((pin: any, index: number) => {
    newArray.forEach((i: any) => {
      if (pin.lat === i.lat && pin.lon === i.lon) {
        singlePins.splice(index, 1);
      }
    });
  });

  alreadyAdded.forEach((element: any) => {
    const remaining = singlePins.filter((i: any) => i.id === element);
    if (remaining.length > 0) {
      const index = singlePins.findIndex((i: any) => i.id === remaining[0].id);
      singlePins.splice(index, 1);
    }
  });

  return {
    singlePins,
    newArray,
  };
};

export const createList = (summary: any) => {
  if (summary) {
    let arrayOfFeatures: any = [];
    let arrayofRequirements: any = [];

    // features section

    if (
      summary.Good_internet_connection &&
      summary.Good_internet_connection.data
    ) {
      arrayOfFeatures.push(
        getLocalizeText(englishText.GOOD_INTERNET_CONNECTION)
      );
    }

    if (summary.Good_neighborhood && summary.Good_neighborhood.data) {
      arrayOfFeatures.push(getLocalizeText(englishText.GOOD_NEIGHBOURHOOD));
    }

    if (
      summary.Good_traffic_connection &&
      summary.Good_traffic_connection.data
    ) {
      arrayOfFeatures.push(getLocalizeText(englishText.TRAFFIC_CONNECTION));
    }

    if (summary.School_and_childcare && summary.School_and_childcare.data) {
      arrayOfFeatures.push(getLocalizeText(englishText.SCHOOL_CHILDCARE));
    }

    if (
      summary.Sufficient_supermarkets_and_cultural_offers &&
      summary.Sufficient_supermarkets_and_cultural_offers.data
    ) {
      arrayOfFeatures.push(getLocalizeText(englishText.MARKET_CULTUTE));
    }

    if (summary.Parks_or_Green_spaces && summary.Parks_or_Green_spaces.data) {
      arrayOfFeatures.push(getLocalizeText(englishText.PARKS_GREEN_SPACES));
    }

    arrayOfFeatures = arrayOfFeatures.join(", ");

    // requirements
    if (summary.Family_friendly && summary.Family_friendly.data) {
      arrayofRequirements.push(getLocalizeText(englishText.FAMILY_FRIENDLY));
    }
    if (summary.Investment && summary.Investment.data) {
      arrayofRequirements.push(getLocalizeText(englishText.FOR_INVESTMENT));
    }
    if (summary.Freedom_and_luxury && summary.Freedom_and_luxury.data) {
      arrayofRequirements.push(getLocalizeText(englishText.FREEDOM_LUXURY));
    }
    if (summary.Retirement_provision && summary.Retirement_provision.data) {
      arrayofRequirements.push(getLocalizeText(englishText.PENSION_SECURITY));
    }

    if (arrayofRequirements.length === 0) {
      arrayofRequirements = getLocalizeText(englishText.OTHER);
    } else {
      arrayofRequirements = arrayofRequirements.join(", ");
    }

    return {
      arrayOfFeatures,
      arrayofRequirements,
    };
  }
};

export const getSummaryPointList = (summary: any, list: any) => {
  const summaryData = [
    {
      title: "Hamburg, Germany",
      img: location,
      detailArray: [
        {
          title: englishText.AREA_TYPE,
          value: `${getLocalizeText(summary.Residential_area_type.data)}`,
          action: summary.Residential_area_type.questionid,
        },
        {
          title: englishText.GOOD_CONNECTION_TO,
          value: list.arrayOfFeatures,
          action: summary.School_and_childcare.questionid,
        },
      ],
    },
    {
      title: summary.Property_type.data,
      img: house,
      action: summary.Property_type.questionid,
      detailArray: [
        {
          title: "Size",
          value: `${getLocalizeText(englishText.AT_LEAST)} ${
            summary.Living_space.data[0]
          } m²`,
          action: summary.Living_space.questionid,
        },
        {
          title: "Rooms",
          value: ` ${getLocalizeText(englishText.AT_LEAST)} ${
            summary.Rooms.data[0]
          }`,
          action: summary.Rooms.questionid,
        },
        {
          title: "Others",
          value: list.arrayofRequirements,
          action: summary.Family_friendly.questionid,
        },
      ],
    },
    {
      title: englishText.FINANCIAL_SCORE,
      img: budget,
      detailArray: [
        {
          title: englishText.MONTHLY_PAYMENT,
          value: ` EUR ${
            (summary.Monthly_costs && summary.Monthly_costs.data) || 0
          }`,
          action: summary.Monthly_costs.questionid,
        },
        {
          title: "Reserve assets/Equity",
          value: `EUR ${summary.Equity.data || 0}`,
          action: summary.Equity.questionid,
        },
      ],
    },
  ];
  return summaryData;
};

export const openObjectDetail = (event: any, id: string) => {
  const classes = [
    "fa fa-heart-o",
    "fa fa-close",
    "fa fa-heart",
    "like-icon text-center pointer",
  ];
  if (
    event.target.className.animVal ||
    event.target.className.animVal === "" ||
    classes.includes(event.target.className)
  ) {
    return false;
  }

  const win = window.open(`/object-detail/${id}`, "_blank");
  win && win.focus();
};
