import React from "react";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Modal from "react-awesome-modal";
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";

interface IModalProps{
  className?: string;
  width?: string;
  closeModal: (value?:boolean) => void;
  bodyMsg?: string;
  handleYes?:() => void;
  btnText?: string;
  title: string;
}

const ModalComponent: React.FC<IModalProps> = (props) =>(
    <div className={props.className ? props.className : ""}>
      <Modal
        visible={true}
        effect="fadeInUp"
        onClickAway={() => props.closeModal()}
        width={props.width ? props.width : "50%"}
      >
        <div
          className="bottom-btn"
          style={{ padding: "15px", textAlign: "center" }}
        >
          <p className={`modal-body-text ${!props.bodyMsg && "mb-4"}`}>
            {getLocalizeText(props.title)}
          </p>
          {props.bodyMsg && props.bodyMsg !== "" ? (
            <p style={{ padding: "20px", fontSize: "16px" }}>
              {getLocalizeText(props.bodyMsg)}
            </p>
          ) : (
            <p>{"           "} </p>
          )}

          {props.handleYes && (
            <button
              className="btn modal-btn"
              type="button"
              onClick={() => props.handleYes && props.handleYes()}
              style={{
                marginRight: "15px",
                width: "100px",
              }}
            >
              {getLocalizeText(englishText.YES)}
            </button>
          )}
          <button
            className="btn modal-btn"
            type="button"
            onClick={() => props.closeModal()}
            onKeyDown={() => props.closeModal()}
            tabIndex={1}
          >
            {props.btnText
              ? getLocalizeText(props.btnText)
              : getLocalizeText(englishText.CANCEL)}
          </button>
        </div>
      </Modal>
    </div>
  )

export default ModalComponent;
