import React from "react";

import gdprBanner from "assets/images/gdprBannerTransparent.png";
import englishText from "languages/english/english";
import { footerRows, socialLinks, getLocalizeText } from "utils";

interface IPdfProps {
  showPdfPreview: boolean;
}

const PdfFooter: React.FC<IPdfProps> = (props) => {
  const { showPdfPreview } = props;

  return (
    <footer style={showPdfPreview ? { position: "unset" } : {}}>
      <div className="pdf-footer">
        <div className="col">
          <table>
            <thead>
              <tr>
                <th>{getLocalizeText(englishText.LEGAL)}</th>
                <th>{getLocalizeText(englishText.PLATFORM)}</th>
              </tr>
            </thead>
            <tbody>
              {footerRows.map((row, i) => (
                <tr key={i}>
                  {row.data.map((field, index) => (
                    <td key={index}>
                      <a
                        href={field.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {getLocalizeText(field.label)}
                      </a>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
            <tfoot />
          </table>
        </div>
        <div className="col">
          <div className="right-part">
            <ul className="social-list">
              {socialLinks.map((data, i) => (
                <li key={i}>
                  <a href={data.link} target="_self" title="">
                    <img src={data.img} alt="" />
                  </a>
                </li>
              ))}
            </ul>
            <div className="gdpr-banner">
              <img src={gdprBanner} alt="" />
            </div>
          </div>
        </div>
      </div>
      <div className="pdf-footer-2">
        <a href="https://www.immozy.de">www.immozy.de</a>
        <p className="copyright-text">
          copyright {new Date().getFullYear()} immozy GmbH
        </p>
      </div>
    </footer>
  );
};

export default PdfFooter;