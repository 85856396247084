import { CSSProperties } from "react";
import englishText from "languages/english/english";
import getLocalizeText from "./getLocalizeText";
import xing from "assets/images/icon-xing-1.png";
import insta from "assets/images/icon-instagram-1.png";
import linkedIn from "assets/images/icon-linkedin-2.png";
import facebook from "assets/images/icon-fb-1.png";

const selectedLang = localStorage.getItem("language") || "de";

export const recordPerPage = 24;

export const lang = {
  EN: "en",
  GR: "de",
};

export const cities = {
  HAM: "Hamburg",
  BER: "Berlin",
  HANNOVER: "Hannover",
  LEIPZIG: "Leipzig",
};

export const siteMenuLinks = [
  {
    path: "/prepare",
    label: englishText.PREPARE_MENU,
    name: "prepare",
  },
  {
    path: "/calculator",
    label: englishText.FINANCING,
    name: "calculator",
  },
  {
    path: "/find",
    label: englishText.FIND,
    name: "find",
  },
];

export const msgTextArray = [
  englishText.LOAD_MSG_1,
  englishText.LOAD_MSG_2,
  englishText.LOAD_MSG_3,
  englishText.LOAD_MSG_4,
  englishText.LOAD_MSG_5,
  englishText.LOAD_MSG_6,
];

export const fixationPeriodFields: any = {
  YEAR_5: {
    label: `5 ${getLocalizeText(englishText.YEARS)}`,
    value: 5,
  },
  YEAR_10: {
    label: `10 ${getLocalizeText(englishText.YEARS)}`,
    value: 10,
  },
  YEAR_15: {
    label: `15 ${getLocalizeText(englishText.YEARS)}`,
    value: 15,
  },
  YEAR_20: {
    label: `20 ${getLocalizeText(englishText.YEARS)}`,
    value: 20,
  },
  YEAR_25: {
    label: `25 ${getLocalizeText(englishText.YEARS)}`,
    value: 25,
  },
  YEAR_30: {
    label: `30 ${getLocalizeText(englishText.YEARS)}`,
    value: 30,
  },
};

export const citiesForCalculator: any = {
  BA_WU: {
    label: getLocalizeText(englishText.BA_WU),
    value: englishText.BA_WU,
  },
  BAV: {
    label: getLocalizeText(englishText.BAV),
    value: englishText.BAV,
  },
  BER: {
    label: getLocalizeText(englishText.BER),
    value: englishText.BER,
  },
  BRND: {
    label: getLocalizeText(englishText.BRND),
    value: englishText.BRND,
  },
  BRE: {
    label: getLocalizeText(englishText.BRE),
    value: englishText.BRE,
  },
  HAM: {
    label: getLocalizeText(englishText.HAM),
    value: englishText.HAM,
  },
  HES: {
    label: getLocalizeText(englishText.HES),
    value: englishText.HES,
  },
  LIP: {
    label: cities.LEIPZIG,
    value: cities.LEIPZIG,
  },
  ME_VO: {
    label: getLocalizeText(englishText.ME_VO),
    value: englishText.ME_VO,
  },
  NIED: {
    label: getLocalizeText(englishText.NIED),
    value: englishText.NIED,
  },
  N_RH_WE: {
    label: getLocalizeText(englishText.N_RH_WE),
    value: englishText.N_RH_WE,
  },
  RH_PF: {
    label: getLocalizeText(englishText.RH_PF),
    value: englishText.RH_PF,
  },
  SL: {
    label: getLocalizeText(englishText.SL),
    value: englishText.SL,
  },
  SAXONY: {
    label: getLocalizeText(englishText.SAXONY),
    value: englishText.SAXONY,
  },
  SA_AN: {
    label: getLocalizeText(englishText.SA_AN),
    value: englishText.SA_AN,
  },
  SC_HO: {
    label: getLocalizeText(englishText.SC_HO),
    value: englishText.SC_HO,
  },
  THUR: {
    label: getLocalizeText(englishText.THUR),
    value: englishText.THUR,
  },
};

export const primaryColor = "rgba(147, 40, 73, 1)";
export const secondaryColor = "rgba(55, 75, 106, 1)";

export const modalNames = {
  SORT_MENU: "short_menu",
  PREF_COUNT_MODAL: "pref_count_modal",
  SEARCH_PROFILE_MODAL: "search_profile_modal",
  MY_SEARCH: "my_search",
  SAVED_SEARCH: "saved_search",
  RESET_CONFIRM: "reset_confirm",
  SMALL_FILTER: "small_filter",
  MORE_FILTER: "more_filter",
  NOTIFICATION: "notification_modal",
  ONBOARDING_WARNING: "onboarding_warning",
};

export const inputTypes = {
  CHECKBOX: "CHECKBOX",
  SELECTBOX: "SELECTBOX",
  RANGE: "SLIDER",
  DOUBLE_RANGE: "DOUBLE_RANGE",
  RADIO: "RADIO",
  INPUT: "INPUT",
  RADIO_IMAGE: "RADIO-IMAGE",
  ARRAY: "ARRAY",
  PICTURE: "One Choice Picture",
};

export const sortOrder = {
  DESC: "DESC",
  ASC: "ASC",
};

export const legendTypes = [
  "schools",
  "supermarkets",
  "transports",
  "medicals",
  "parks",
];

export const houseTypes: { [key: string]: string[] } = {
  detached_house: [
    "Single family house (detached)",
    "Bungalow",
    "Villa",
    "Residential property (other)",
  ],
  double_or_townhouse: [
    "Semi-detached house",
    "Bungalow",
    "Mid-terraced house",
    "Corner house",
    "Residential property (other)",
  ],
  apartment: [
    "Apartment",
    "Ground floor apartment",
    "Penthouse",
    "Terrace apartment",
    "Loft",
    "Attic",
    "Mezzanine",
    "Maisonette",
    "Residential property (other)",
    "Basement",
  ],
};

export const sortData = {
  sortList: [
    { label: englishText.STANDARD_SORTING, value: "created_at" },
    { label: englishText.MATCH_FILTER, value: "score" },
    { label: englishText.PRICE_FILTER, value: "price" },
    { label: englishText.PRICE_PER_METER, value: "pricePerSQM" },
    { label: englishText.LIVING_SPACE, value: "livingSpace" },
    { label: englishText.ROOMS, value: "rooms" },
    { label: englishText.YEAR_OF_CONSTRUCTION, value: "yearsOfConstruction" },
  ],
  selectedSortType: sortOrder.DESC,
};

export const sortDataTopResults = {
  sortList: [
    { label: englishText.TOTAL_SCORE, value: "score" },
    { label: englishText.LOCATION_SCORE, value: "locationsScore" },
    { label: englishText.PROPERTY_SCORE, value: "requirementsScore" },
    { label: englishText.FINANCIAL_SCORE, value: "financialsScore" },
  ],
  selectedSortType: sortOrder.DESC,
  selectedSortName: "score",
  selectedSortLabel: englishText.TOTAL_SCORE,
};

export const propertyType = {
  HOUSE: "Houses",
  APT: "Apartment",
};

export const houseTypesArray = [
  // For houses tab
  { label: englishText.VILLA, value: false, propTypeFor: propertyType.HOUSE },
  {
    label: englishText.FARMHOUSE,
    value: false,
    propTypeFor: propertyType.HOUSE,
  },
  {
    label: englishText.SPECIAL_PROPERTY,
    value: false,
    propTypeFor: propertyType.HOUSE,
  },
  { label: englishText.BUNGLOW, value: false, propTypeFor: propertyType.HOUSE },
  {
    label: englishText.SEMI_DETECHED_HOUSE,
    value: false,
    propTypeFor: propertyType.HOUSE,
  },
  {
    label: englishText.DETACHED_HOUSE,
    value: false,
    propTypeFor: propertyType.HOUSE,
  },
  {
    label: englishText.MID_TERRACE_HOUSE,
    value: false,
    propTypeFor: propertyType.HOUSE,
  },
  {
    label: englishText.END_HOUSE,
    value: false,
    propTypeFor: propertyType.HOUSE,
  },
  {
    label: englishText.RES_COMPLEX,
    value: false,
    propTypeFor: propertyType.HOUSE,
  },

  // For apartment tab
  {
    label: englishText.ATTIC_FLOOR,
    value: false,
    propTypeFor: propertyType.APT,
  },
  {
    label: englishText.GROUND_FLOOR,
    value: false,
    propTypeFor: propertyType.APT,
  },
  { label: englishText.FLAT, value: false, propTypeFor: propertyType.APT },
  {
    label: englishText.RAISED_FLOOR,
    value: false,
    propTypeFor: propertyType.APT,
  },
  { label: englishText.LOFT, value: false, propTypeFor: propertyType.APT },
  { label: englishText.MAISON, value: false, propTypeFor: propertyType.APT },
  { label: englishText.PENTHOUSE, value: false, propTypeFor: propertyType.APT },
  { label: englishText.OTHER, value: false, propTypeFor: propertyType.APT },
  {
    label: englishText.TERRACE_APT,
    value: false,
    propTypeFor: propertyType.APT,
  },
  { label: englishText.BASEMENT, value: false, propTypeFor: propertyType.APT },
  {
    label: englishText.APART_BLOCK,
    value: false,
    propTypeFor: propertyType.APT,
  },
];

export const extraFeatures = [
  {
    label: englishText.NEW_CONSTRUCTION,
    value: false,
    countName: "construction",
  },
  { label: englishText.CELLER, value: false, countName: "celler" },
  {
    label: englishText.PARKING,
    value: false,
    countName: "parking_garage",
  },
  {
    label: englishText.GARDEN_BALCONY,
    value: false,
    countName: "garden_balcony",
  },
  {
    label: englishText.NO_COMMISSION,
    value: false,
    countName: "without_comission",
  },
];

export const utmOrganic = {
  utm_campaign: "organic",
  utm_medium: "organic",
  utm_source: "organic",
  utm_term: "organic",
  initial_utm_campaign: "organic",
  initial_utm_medium: "organic",
  initial_utm_source: "organic",
  initial_utm_term: "organic",
};

export const importanceLevelConstant: any = {
  1: {
    label: englishText.VERY_IMPORTANT,
    color: "#932849",
  },
  2: {
    label: englishText.IMPORTANT,
    color: "#EDBE59",
  },
  3: {
    label: englishText.LESS_IMPORTANT,
    color: "#38CC6B",
  },
};

/**
 * reactSelectStyles - css style object for react-select
 */
export const reactSelectStyles = {
  option: (base: CSSProperties, state: any) => ({
    ...base,
    borderBottom: "1px solid #F5F5F5",
    color: "#000000a6",
    cursor: "pointer",
    padding: "5px 12px",
    fontSize: "16px",
    backgroundColor: state.isSelected
      ? "#d5d5d5"
      : !state.isSelected && state.isFocused
      ? "#F5F5F5"
      : "",
    ":active": {
      backgroundColor: "#F5F5F5",
    },
    ":hover": {
      backgroundColor: !state.isSelected ? "#F5F5F5" : "#d5d5d5",
    },
    ":focus": {
      backgroundColor: "#F5F5F5",
      outline: 0,
    },
  }),
  menu: (base: CSSProperties) => ({
    ...base,
    zIndex: 3,
    borderRadius: "2px",
    marginTop: "12px",
  }),
  menuList: (base: CSSProperties) => ({
    ...base,
    padding: "4px 0",
    backgroundColor: "#ffffff !important",
    fontSize: "16px",
  }),
  clearIndicator: (base: CSSProperties) => ({
    ...base,
    cursor: "pointer",
  }),
  dropdownIndicator: (base: CSSProperties, state: any) => ({
    ...base,
    cursor: "pointer",
    transition: "all 0.2s ease",
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "none",
  }),
  indicatorSeparator: () => ({
    width: "0",
  }),
  control: () => ({
    // none of react-selects styles are passed to <View />
    display: "flex",
    width: "100%",
    minHeight: "24px !important",
    border: "1px solid #e5e6e7",
    padding: "8px 4px !important",
  }),
  singleValue: (base: CSSProperties, state: any) => ({
    ...base,
    opacity: state.isDisabled ? 0.5 : 1,
    transition: "opacity 300ms",
    color: "#000000a6",
  }),
};

export const contactType = {
  SELLER: "seller",
  HELP: "help",
  FINANCE: "finance",
};

export const footerRows = [
  {
    data: [
      {
        link: `https://immozy.de/${selectedLang}/imprint/`,
        label: englishText.IMPRINT,
      },
      {
        link: `https://immozy.de/${selectedLang}/ueber-uns/`,
        label: englishText.ABOUT_US_2,
      },
    ],
  },
  {
    data: [
      {
        link: `https://immozy.de/${selectedLang}/privacy/`,
        label: englishText.PRIVACY_POLICY,
      },
      {
        link: `https://immozy.de/${selectedLang}/faq/`,
        label: "FAQ",
      },
    ],
  },
  {
    data: [
      {
        link: `https://immozy.de/${selectedLang}/termsandcondition/`,
        label: englishText.TERMS_OF_USE,
      },
      {
        link: `https://immozy.de/${selectedLang}/kontakt/`,
        label: englishText.CONTACT,
      },
    ],
  },
];

export const socialLinks = [
  {
    link: "https://de.linkedin.com/company/immozy/",
    imglink: "https://immozy.de/wp-content/uploads/2022/03/icon-linkedin-2.svg",
    img: linkedIn,
  },
  {
    link: "https://www.xing.com/pages/immozy-gmbh",
    imglink: "https://immozy.de/wp-content/uploads/2022/03/icon-xing-1.svg",
    img: xing,
  },
  {
    link: "https://www.facebook.com/immozy.de",
    imglink: "https://immozy.de/wp-content/uploads/2022/03/icon-fb-1.svg",
    img: facebook,
  },
  {
    link: "https://www.instagram.com/immozy_official/",
    imglink:
      "https://immozy.de/wp-content/uploads/2022/03/icon-instagram-1.svg",
    img: insta,
  },
];
