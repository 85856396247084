import React, { Dispatch } from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { RouteComponentProps } from "react-router";
// Components
import SearchResult from "components/searchResult/container/searchResult";
import SortComponent from "components/commonComponents/sortComponent";
import GoToDashboardBtn from "components/commonComponents/goToDashboardBtn";
// functions
import * as favouritesAction from "actions/favouritesAction";
import englishText from "languages/english/english";
import { API_CONFIG, HttpService } from "services";
import {
  disableScrolling,
  getLocalizeText,
  modalNames,
  sortData,
  sortOrder,
  getIconImg,
  getIconImgHighlight,
  getStatusData,
} from "utils";
import { setProperties } from "../utils/commonFunctions";
import { clearReducer } from "actions";
// assets
import pinHighlight from "assets/images/pin-highlight.png";
import pin from "assets/images/pin.png";
import { MapPinIcon } from "components/commonComponents/icons";
import "../dashboard.scss";

interface IFavoriteProps extends RouteComponentProps {
  removeFavoReducer: any;
  addToFavourites: (objId: string) => void;
  removeFromFavourites: (objId: string) => void;
  clearReducer: (name: string) => void;
  isFullMapScreen: boolean;
  favoUpdateReducer: any;
  getFavoCount: () => void;
  changeCityReducer: any;
  profileCountReducer: any;
}

class Favorites extends React.Component<IFavoriteProps> {
  state: any = {
    propertyList: [],
    favoList: [],
    mapData: [],
    multiMapData: [],
    totalRecords: 0,
    totalPages: 0,
    isFullMapScreen: false,
    currentView: "list",
    ...sortData,
    selectedSortName: "score",
    selectedSortLabel: "Match",
    visibleModal: "",
    showResultSpinner: true,
    onboarding: true,
    selectedCity: "",
    cities: [],
  };
  wrapperRef: any;

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
    disableScrolling(false);
    window.scrollTo(0, 0);

    const data = getStatusData();
    this.setState({ ...data });
    if (!data.onboarding) {
      this.setState({
        selectedSortLabel: englishText.STANDARD_SORTING,
        selectedSortName: "created_at",
      });
      this.getFavouritesListAPI("created_at");
    } else {
      this.getFavouritesListAPI();
    }
  }

  componentDidUpdate(prevProps: IFavoriteProps) {
    const {
      clearReducer,
      favoUpdateReducer,
      getFavoCount,
      changeCityReducer,
      profileCountReducer,
    } = this.props;
    const { fromObjDetail, type, selectedCity } = this.state;

    if (
      profileCountReducer !== prevProps.profileCountReducer &&
      profileCountReducer.status === "success" &&
      selectedCity === ""
    ) {
      this.setState({ selectedCity: profileCountReducer.data.city });
    }

    if (
      changeCityReducer !== prevProps.changeCityReducer &&
      changeCityReducer.status === "success" &&
      changeCityReducer.data &&
      changeCityReducer.data.city !== selectedCity
    ) {
      this.state.isFullMapScreen && this.toggleMapView("list");
      this.setState({
        selectedCity: changeCityReducer.data.city,
        pageChanged: true,
      });
    }

    if (
      prevProps.favoUpdateReducer !== favoUpdateReducer &&
      favoUpdateReducer.status === "success" &&
      fromObjDetail &&
      type === "add"
    ) {
      this.getFavouritesListAPI();
      clearReducer("CLEAR_FAVO_CHANGE");

      this.setState({ fromObjDetail: false });
      setTimeout(() => {
        getFavoCount();
      }, 500);
    }
  }

  componentWillUnmount() {
    const { clearReducer } = this.props;
    this.setState({ showResultSpinner: false });
    clearReducer("CLEAR_FAVO");
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  toggleMapView = (value: string) => {
    const { isFullMapScreen, currentView } = this.state;
    value === "map" && window.scrollTo(0, 170);
    if (currentView !== value) {
      this.setState({
        isFullMapScreen: !isFullMapScreen,
        currentView: value,
      });
    }
  };

  render() {
    const {
      propertyList,
      favoList,
      mapData,
      multiMapData,
      totalRecords,
      totalPages,
      pageChanged,
      showResultSpinner,
      currentView,
      isFullMapScreen,
      sortList,
      selectedSortType,
      selectedSortName,
      selectedSortLabel,
      visibleModal,
      onboarding,
      selectedCity,
    } = this.state;

    const windowWidth = {
      "--element-window-width": `${(window.innerWidth - 1920) / 2 - 8}px`,
    };

    return (
      <>
        <div className={`search-result-section`}>
          <div
            className="property-result-wrapper"
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            style={{ ...windowWidth }}
          >
            <div className={`curved-div favorites`}>
              <div
                className={`section-heading d-flex justify-content-between ${
                  propertyList.length > 0 ? "" : ""
                }`}
              >
                {/* {propertyList.length > 0 && ( */}
                <div
                  className={`sort-margin sort-div-wrapper mt-2 ${
                    propertyList.length > 0 ? "visible" : "hidden"
                  }`}
                >
                  <SortComponent
                    sortObj={{
                      sortList,
                      selectedSortType,
                      selectedSortName,
                      selectedSortLabel,
                      top: "78px",
                      left: "60px",
                    }}
                    changeSorting={this.changeSortingName}
                    changeSortingType={this.changeSortingType}
                    toggleSortMenu={() => {
                      this.setState({
                        visibleModal:
                          visibleModal === modalNames.SORT_MENU
                            ? ""
                            : modalNames.SORT_MENU,
                      });
                      visibleModal === modalNames.SORT_MENU &&
                        disableScrolling(false);
                    }}
                    visibleModal={visibleModal}
                    setWrapperRefForSortMenu={this.setWrapperRefForSortMenu}
                  />
                </div>
                {/* )} */}
                <div
                  className="title text-black"
                  style={{
                    marginLeft: window.innerWidth > 1366 ? "-90px" : "-40px",
                  }}
                >
                  <FormattedMessage
                    id={englishText.YOUR_FAVORITES}
                    defaultMessage={englishText.YOUR_FAVORITES}
                  />
                </div>
                {/* {propertyList.length > 0 && ( */}
                <div
                  className={`pointer map-wishlist mt-2 ${
                    propertyList.length > 0 ? "visible" : "hidden"
                  }`}
                  onClick={() =>
                    this.toggleMapView(currentView === "map" ? "list" : "map")
                  }
                >
                  <button
                    className={`btn button-apply btn-map d-flex justify-content-center ${
                      currentView === "map" ? "active" : ""
                    }`}
                  >
                    <MapPinIcon />
                    <p>{getLocalizeText(englishText.MAP)}</p>
                  </button>
                </div>
                {/* )} */}
              </div>
            </div>

            {/* {propertyList.length > 0 && ( */}
            <SearchResult
              searchList={propertyList}
              totalRecords={totalRecords}
              mapData={mapData}
              multiMapData={multiMapData}
              totalPages={totalPages}
              isfavorite={true}
              favouritesList={favoList}
              addFavourites={this.updateFavourites}
              isFullMapScreen={isFullMapScreen}
              pageChanged={pageChanged}
              resetPageChange={this.resetPageChange}
              onFavoChanged={this.updateFavourites}
              showResultSpinner={showResultSpinner}
              currentView={currentView}
              showBg={visibleModal === ""}
              topResult={true}
              onboarding={onboarding}
              selectedCity={selectedCity}
              cities={this.state.cities}
            />
            {/* )} */}
          </div>

          {!showResultSpinner && propertyList.length === 0 && (
            <div
              style={{
                textAlign: "center",
                paddingTop: "15%",
              }}
            >
              <h5>
                <FormattedMessage
                  id={englishText.NO_FAVO_MSG}
                  defaultMessage={englishText.NO_FAVO_MSG}
                />
              </h5>

              <GoToDashboardBtn />
            </div>
          )}
        </div>
      </>
    );
  }

  //remove from favorites
  updateFavourites = (id: string, type: string, fromObjDetail: boolean) => {
    const { favoList, propertyList, mapData, multiMapData } = this.state;
    const { addToFavourites, removeFromFavourites, clearReducer } = this.props;

    try {
      if (type === "remove") {
        const obj = propertyList.find((i: any) => i.id === id);
        if (obj.isPrivate) {
          this.removeCustomObject(id);
        } else {
          removeFromFavourites(id);
          clearReducer("CLEAR_FAVO");
        }
        this.updateMapData(favoList, id, propertyList, mapData, multiMapData);
      } else {
        favoList.push(id);
        addToFavourites(id);
        this.setState({ favoList });
        this.setState({
          showResultSpinner: true,
          mapData: [],
          multiMapData: [],
        });
      }
      this.forceUpdate();
    } catch (error) {
      const msg = getLocalizeText(englishText.ERROR_CONTACT_ADMIN);
      window.alert(msg);
    }
    this.setState({ fromObjDetail, type });
  };

  //used in map change detect
  resetPageChange = () => {
    this.setState({ pageChanged: false });
  };

  //update single and multiple map object as properties get removed from favorites
  //convert multi properties as single when one object remaining in multimap data
  updateMapData = (
    favoList: any[],
    id: string,
    propertyList: any[],
    mapData: any,
    multiMapData: any
  ) => {
    let mapData1 = [];
    let multiMapData1: any = [];

    const favourites = favoList.filter(function (item) {
      return item !== id;
    });

    const propList = propertyList.filter(function (item) {
      return item.id !== id;
    });

    mapData1 = mapData.filter(function (item: any) {
      return item.id !== id;
    });
    let singleIndex: any = "";

    multiMapData1 =
      multiMapData &&
      multiMapData.map((map: any, index: number) => {
        const indexToRemove = map.id.findIndex((i: string) => i === id);
        if (indexToRemove >= 0) {
          for (const property in map) {
            Array.isArray(map[property]) &&
              map[property].splice(indexToRemove, 1);
          }

          //create object to convert into single map object from multimap object
          if (map.id.length === 1) {
            const newProp = {
              adr: map.adr[0],
              areaLiving: map.areaLiving[0],
              id: map.id[0],
              label: map.label[0],
              coverImg: map.coverImg[0],
              price: map.price[0],
              rooms: map.rooms[0],
              icon: this.state.onboarding ? getIconImg(map.label[0]) : pin,
              lat: map.lat,
              lon: map.lon,
              imgList: map.imgList[0],
              propertyType: map.propertyType[0],
              highlightIcon: this.state.onboarding
                ? getIconImgHighlight(map.label[0])
                : pinHighlight,
            };
            mapData1.push(newProp);
            singleIndex = index;
          } else {
            const updatedLabel =
              map.label.length > 0 &&
              map.label.map((i: string) => Math.round(parseFloat(i)));
            map.label = updatedLabel;
          }
        }
        return map;
      });

    if (singleIndex !== "") {
      multiMapData1 = multiMapData1.filter(
        (i: any, index: number) => index !== singleIndex
      );
    }
    multiMapData1 &&
      multiMapData1.forEach((map: any, index: number) => {
        if (map.id && map.id.length === 0) {
          multiMapData1.splice(index, 1);
        }
      });

    this.setState({
      favoList: favourites,
      propertyList: propList,
      mapData: mapData1,
      multiMapData: multiMapData1,
      pageChanged: true,
    });
    setTimeout(() => {
      this.props.getFavoCount();
    }, 500);
  };

  removeCustomObject = (id: string) => {
    HttpService.post(`${API_CONFIG.path.removeCustomFavorite}${id}`, {}).then(
      () => {
        HttpService.get(API_CONFIG.path.objects, {})
          .then(() => {
            console.log("property removed from wishlist");
          })
          .catch((err) => {
            console.log(err);
          });
      }
    );
  };

  setWrapperRefForSortMenu = (node: any) => {
    this.wrapperRef = node;
  };

  getFavouritesListAPI = (
    sortBy = "score",
    sortType: string = sortOrder.DESC
  ) => {
    HttpService.get(API_CONFIG.path.favoritesList, { sortBy, sortType })
      .then((response) => {
        const combinedResult = setProperties(
          response.data.results,
          this.state.mapData
        );

        if (!this.state.sortApplied) {
          this.setState({
            pageChanged: true,
            mapData: combinedResult.mapResult
              ? combinedResult.mapResult.singlePins
              : [],
            multiMapData: combinedResult.mapResult
              ? combinedResult.mapResult.newArray
              : [],
          });
        }

        this.setState({
          propertyList: combinedResult.list,
          totalRecords: response.data.length,
          favoList: combinedResult.favoList,
          totalPages: 1,
          showResultSpinner: false,
          sortApplied: false,
          cities: response.data.cities,
        });
        window.scrollTo(0, 0);
        clearReducer("CLEAR_FAVO");
        setTimeout(() => {
          this.props.getFavoCount();
        }, 500);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // change sorting parameter
  changeSortingName = (sortObj: any) => {
    if (this.state.selectedSortName !== sortObj.value) {
      const { selectedSortType } = this.state;

      this.setState({
        selectedSortName: sortObj.value,
        selectedSortLabel: sortObj.label,
        visibleModal: "",
        showResultSpinner: true,
        sortApplied: true,
      });

      this.getFavouritesListAPI(sortObj.value, selectedSortType);
    } else {
      this.setState({ visibleModal: "" });
    }
    disableScrolling(false);
  };

  // change asc/desc
  changeSortingType = (type: string) => {
    if (this.state.selectedSortType !== type) {
      const { selectedSortName } = this.state;

      this.setState({
        selectedSortType: type,
        visibleModal: "",
        showResultSpinner: true,
        sortApplied: true,
      });
      this.getFavouritesListAPI(selectedSortName, type);
    } else {
      this.setState({ visibleModal: "" });
    }
    disableScrolling(false);
  };

  // To close sort menu whne clicked outside
  handleClickOutside = (event: MouseEvent) => {
    const { visibleModal } = this.state;
    if (
      visibleModal !== "" &&
      this.wrapperRef &&
      !this.wrapperRef.contains(event.target) &&
      (event.target as HTMLElement).className !== "sort-menu-div"
    ) {
      this.setState({ visibleModal: "" });
      disableScrolling(false);
    }
  };
}

const mapStateToProps = (state: any) => ({
  removeFavoReducer: state.favoReducer,
  favoUpdateReducer: state.favoUpdateReducer,
  changeCityReducer: state.changeCityReducer,
  profileCountReducer: state.profileCountReducer,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  addToFavourites: (objId: string) => {
    dispatch(favouritesAction.addToFavourites(objId));
  },

  removeFromFavourites: (objId: string) => {
    dispatch(favouritesAction.removeFromFavourites(objId));
  },

  clearReducer: (name: string) => {
    dispatch(clearReducer(name));
  },

  getFavoCount: () => {
    dispatch(favouritesAction.getFavoCount());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Favorites);
