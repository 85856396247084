import React from "react";
import { Link } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
// functions
import englishText from "languages/english/english";
import {
  sendAmplitudeDataEvent,
  setUserIdToLocal,
  detectIsMobile,
  addFbPixelEvent,
  setLanguageInLocalStorage,
} from "utils";
import { optionInputTypes } from "../interface/utils";
import {
  getAnswerObject,
  getAnswerValuesForApi,
} from "../interface/onboardingFunctions";
import { API_CONFIG, HttpService } from "services";
// components
import OptionsComponent from "../component/optionsComponent";
import CustomSpinner from "components/commonComponents/customSpinner";
import ModalComponent from "components/commonComponents/modalComponent";
// assets
import logo from "assets/images/immozy-logo.svg";
import ralf from "assets/images/ralf.png";
import close from "assets/images/close.png";
import { OnboardingPrev } from "components/commonComponents/icons";
import "../onboarding-new.scss";
// interface
import * as onboardingInterface from "../interface/onboarding";

let isEdit = false;
let mobileBrowser = false;
const lang = setLanguageInLocalStorage();
let changedCity = "";

class OnboardingContainer extends React.Component<onboardingInterface.IOnboardingProps> {
  state: any = {
    isLoading: true,
    questionsList: [],
    currentStep: 0,
    visitedSteps: [0],
    showModal: false,
    edited: false,
    errorMsg: "",
    isAnswerModified: false,
    completedSteps: 0,
  };

  componentDidMount() {
    setUserIdToLocal();
    if (
      window.location.pathname.includes("true") ||
      this.props.onboardingPending
    ) {
      isEdit = true;
      this.setState({ isAnswerModified: true });
    }
    mobileBrowser = detectIsMobile();

    if (mobileBrowser) {
      const meta = {
        Action: "Onboarding started on mobile",
      };
      sendAmplitudeDataEvent("mobile_start_onboarding", meta);
    }
    const meta = {
      Action: "Onboarding started",
    };
    sendAmplitudeDataEvent("onboarding_page_view", meta);
    this.state.isLoading && this.getQuestionsList();
  }

  componentWillUnmount() {
    isEdit = false;
  }

  render() {
    const { questionsList, currentStep, showModal, completedSteps } =
      this.state;
    const { fromPopup } = this.props;
    const totalQuestions = questionsList.length;
    const completedPercentage = (completedSteps * 100) / totalQuestions;

    return (
      <div className={`welcome-container ${fromPopup ? "from-popup" : ""}`}>
        <div className="mobile-header-bar d-flex justify-content-between align-items-center">
          <img src={logo} alt="logo" />
        </div>
        <div className="header-bar d-flex justify-content-between align-items-start">
          {!fromPopup && <img src={logo} alt="logo" />}
          {fromPopup ? (
            <img
              className="close-btn-fin pointer"
              onClick={() =>
                this.props.closeOnboarding && this.props.closeOnboarding()
              }
              style={{ width: "unset" }}
              src={close}
              alt="img"
            />
          ) : (
            <Link
              to="/find"
              onClick={(e: React.MouseEvent<HTMLElement>) => {
                fromPopup && this.handleSkipForPopup(e);
              }}
            >
              <button className="onboarding-button-white">
                <FormattedMessage
                  id={englishText.SKIP}
                  defaultMessage={englishText.SKIP}
                />
              </button>
            </Link>
          )}
        </div>

        {this.state.isLoading ? (
          <div className="onboarding-spinner-wrapper d-flex align-items-center justify-content-center">
            <CustomSpinner />
          </div>
        ) : (
          <>
            <div className="ralf-section d-flex justify-content-center align-items-center">
              {currentStep !== 0 && (
                <button
                  className="mobile-prev-button prev-button pointer"
                  onClick={() => this.handlePrev()}
                  disabled={currentStep === 0}
                >
                  <OnboardingPrev />
                </button>
              )}
              <ProgressBar
                className="onboarding-progress"
                min={0}
                max={100}
                now={completedPercentage}
              />
              <img className="ralf-photo" src={ralf} alt="img" />
            </div>
            {questionsList[currentStep] && (
              <div className="question-container mb-3">
                <p className="question-text mb-4">
                  {questionsList[currentStep].question[lang]}
                </p>
                <OptionsComponent
                  hanldeRangeChange={this.hanldeRangeChange}
                  currentStep={this.state.currentStep}
                  questionsList={questionsList}
                  selectOption={this.selectOption}
                  edited={this.state.edited}
                  handleInputchange={this.handleInputchange}
                />
                <div
                  className={`action-section-1   ${
                    questionsList[currentStep].options &&
                    questionsList[currentStep].options.length >= 4
                      ? "relative"
                      : "absolute"
                  } `}
                >
                  {currentStep !== 0 && (
                    <button
                      className="prev-button pointer"
                      onClick={() => this.handlePrev()}
                    >
                      <FormattedMessage
                        id={englishText.PREV_STEP}
                        defaultMessage={englishText.PREV_STEP}
                      />
                    </button>
                  )}
                  <Link to="/find" onClick={this.checkMobile}>
                    <p className="skip-button">Skip Questions</p>
                  </Link>
                  <button
                    className={`onboarding-button-sm ${
                      this.checkButtonActiveStatus() ? "active" : ""
                    }`}
                    autoFocus
                    onClick={() => this.handleNextCilck()}
                  >
                    <FormattedMessage
                      id={englishText.NEXT}
                      defaultMessage={englishText.NEXT}
                    />
                  </button>
                </div>
              </div>
            )}
          </>
        )}
        {showModal && (
          <ModalComponent
            title={englishText.FILL_ANS_ERROR}
            closeModal={() => this.toggleModal(false)}
            width={"370px"}
            className="error-popup"
            btnText={"OK"}
          />
        )}
      </div>
    );
  }

  handleSkipForPopup = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    this.props.closeOnboarding && this.props.closeOnboarding();
  };

  getQuestionsList = () => {
    HttpService.get(API_CONFIG.path.onboardingNew, {})
      .then((response) => {
        this.setState({
          questionsList: response.data.questions,
          isLoading: false,
        });
        if (!isEdit) {
          this.resumeOnboarding(response.data.questions);
        }
      })
      .catch((err) => {
        console.log(err);
        // window.location.pathname = "/login";
      });
  };

  // start from the question where user left off
  resumeOnboarding = (questionsList: onboardingInterface.IQuestionProps[]) => {
    let currentStep = 0;
    const visitedSteps = [0];
    const visitedQuestions = questionsList.filter((i) => i.isAnswerGiven);
    if (visitedQuestions.length > 0 && !questionsList[0].isAnswerGiven) {
      return;
    } else {
      if (visitedQuestions.length !== 0) {
        questionsList.forEach(
          (q: onboardingInterface.IQuestionProps, index: number) => {
            if (!q.isAnswerGiven && currentStep === 0) {
              currentStep = index;
            }
            if (q.isAnswerGiven && !visitedSteps.includes(index)) {
              visitedSteps.push(index);
            }
          }
        );
      }
      if (visitedSteps.length === 8) {
        visitedSteps.push(9);
      }

      this.setState({
        currentStep,
        visitedSteps,
        completedSteps: visitedQuestions
          ? visitedQuestions.length
          : visitedSteps.length,
      });
    }
  };

  selectOption = (index: number) => {
    const { questionsList, currentStep } = this.state;
    const question = questionsList[currentStep].options[index];
    const singleSelectTypes = [
      optionInputTypes.RADIO,
      optionInputTypes.PICTURE,
    ];
    if (!question.isSelected) {
      question.isSelected = true;

      if (singleSelectTypes.includes(questionsList[currentStep].type)) {
        questionsList[currentStep].options.map((op: any, i: number) => {
          if (i !== index) {
            questionsList[currentStep].options[i].isSelected = false;
            changedCity = question.option.en;
          }
        });
      }
    } else {
      question.isSelected = false;
    }

    this.setState({ questionsList, isAnswerModified: true });
  };

  hanldeRangeChange = (value: onboardingInterface.ISliderProps) => {
    const { questionsList, currentStep } = this.state;
    questionsList[currentStep].options[0].value = [value.min, value.max];
    this.setState({ questionsList, isAnswerModified: true });
  };

  handleInputchange = (value: any) => {
    const { questionsList, currentStep } = this.state;
    questionsList[currentStep].options[0].value = value;
    this.setState({
      questionsList,
      isAnswerModified: true,
      edited: true,
    });
  };

  handleNextCilck = (isSkip?: boolean) => {
    const { currentStep, questionsList } = this.state;
    this.state.edited && this.setState({ edited: false });
    this.handleNext(currentStep, questionsList, isSkip);
  };

  handlePrev = () => {
    const { currentStep, errorMsg } = this.state;
    if (errorMsg === "") {
      this.setState({ currentStep: currentStep - 1 });
    }
  };

  handleNext = (
    currentStep: number,
    questionsList: onboardingInterface.IQuestionProps[],
    isSkip?: boolean
  ) => {
    const { visitedSteps, errorMsg, completedSteps } = this.state;
    if (isSkip) {
      visitedSteps.push(currentStep + 1);
      this.setState({
        currentStep: currentStep + 1,
        visitedSteps,
      });
      return true;
    } else {
      const currentOptions = questionsList[currentStep].options;
      const questionId = questionsList[currentStep].questionId;

      // object of answers given and checked
      let optionIds = getAnswerObject(currentOptions);

      // Add FB pixel event
      // Action: User chooses city (about the middle of the questions)
      if (
        currentOptions[0].optionId === "2.1.1" &&
        currentOptions[0].isSelected
      ) {
        addFbPixelEvent("track", "AddToCart");
      }

      let isValueAddedInSelect = true;
      const selectboxValue = optionIds.find(
        (i: onboardingInterface.IOptionProps) => i.type === "INPUT"
      );

      if (
        selectboxValue &&
        optionIds.length === 1 &&
        (!selectboxValue.value || selectboxValue.value === "")
      ) {
        isValueAddedInSelect = false;
      }

      const checkEmptyEquaity = optionIds.filter(
        (i: any) => i.optionId === "4.1.1" && (!i.value || i.value === "")
      );
      if (checkEmptyEquaity && checkEmptyEquaity.length > 0) {
        isValueAddedInSelect = false;
      }

      if (optionIds.length === 0 || !isValueAddedInSelect) {
        // if user has not selected any answers, show error
        this.toggleModal(true);
        return false;
      } else if (errorMsg !== "") {
        return false;
      } else {
        currentStep === 0 && localStorage.removeItem("showWelcome");
        if (currentStep !== questionsList.length - 1) {
          if (!visitedSteps.includes(currentStep + 1)) {
            visitedSteps.push(currentStep + 1);

            if (!visitedSteps.includes(currentStep)) {
              //only for the case of resume onboarding
              visitedSteps.push(currentStep);
            }
          }
          // update the index to next question

          this.setState({
            currentStep: currentStep + 1,
            visitedSteps,
            completedSteps:
              currentStep + 1 > completedSteps
                ? Number(this.state.completedSteps) + 1
                : Number(this.state.completedSteps),
          });
          this.forceUpdate();
        } else {
          if (!isEdit) {
            this.setState({
              currentStep: currentStep + 1,
            });
          }
          this.setState({ isLoading: true });
        }

        optionIds = getAnswerValuesForApi(optionIds);
        if (
          changedCity !== "" &&
          this.props.fromPopup &&
          !localStorage.getItem("refreshPageOnb") &&
          this.state.isAnswerModified
        ) {
          localStorage.setItem("refreshPageOnb", "true");
        }
        // call API on next button click to save answers
        if (this.state.isAnswerModified && isEdit) {
          HttpService.post(API_CONFIG.path.onboardingNew, {
            questionId,
            answers: optionIds,
          })
            .then((response) => {
              response.data.orderid === 9 && this.checkforLastStep();
            })
            .catch((err) => {
              this.setState({ currentStep: currentStep - 1 });
              console.log(err);
            });
          this.setState({ isAnswerModified: false });
        } else if (!isEdit) {
          HttpService.post(API_CONFIG.path.onboardingNew, {
            questionId,
            answers: optionIds,
          }).then(() => {
            const { currentStep } = this.state;
            if (currentStep === 9) {
              if (this.props.fromPopup) {
                localStorage.setItem("onboarding", "true");
                localStorage.setItem("turnTopResult", "true");
                this.props.closeOnboarding && this.props.closeOnboarding();
              } else {
                this.setState({ isLoading: false });
                this.moveToDashboard();
              }
            }
          });
        } else {
          this.checkforLastStepWithoutEdit();
        }
        this.setState({ isAnswerModified: false });
      }
    }
  };

  checkforLastStepWithoutEdit = () => {
    const { currentStep, questionsList } = this.state;
    if (currentStep === questionsList.length - 1) {
      if (this.props.fromPopup) {
        localStorage.setItem("onboarding", "true");
        localStorage.setItem("turnTopResult", "true");
        this.props.closeOnboarding && this.props.closeOnboarding();
      } else {
        this.moveToDashboard();
      }
    }
  };

  checkforLastStep = () => {
    const { currentStep, questionsList } = this.state;
    if (currentStep === questionsList.length - 1) {
      if (this.props.fromPopup) {
        localStorage.setItem("onboarding", "true");
        localStorage.setItem("turnTopResult", "true");
        this.props.closeOnboarding && this.props.closeOnboarding();
      } else {
        this.moveToDashboard();
      }
    }
  };

  checkButtonActiveStatus = () => {
    try {
      if (!this.state.isLoading && this.state.questionsList.length > 0) {
        const { currentStep, questionsList } = this.state;

        const options = questionsList[currentStep].options;
        const optionIds = getAnswerObject(options);

        if (
          optionIds &&
          optionIds.length === 1 &&
          optionIds[0].type === "INPUT" &&
          !optionIds[0].value
        ) {
          return false;
        }
        return optionIds.length > 0;
      }
    } catch (error) {
      return false;
    }
  };

  toggleModal = (value: boolean) => {
    this.setState({ showModal: value });
  };

  moveToDashboard = () => {
    const { history } = this.props;
    const { visitedSteps } = this.state;

    if (visitedSteps.length === 9) {
      localStorage.setItem("turnTopResult", "true");
      localStorage.setItem("onboarding", "true");
      visitedSteps.push(10);
      // Add FB pixel event
      // Action: User selects maximum purchase price => form submitted
      addFbPixelEvent("track", "SubmitApplication");
      if (mobileBrowser) {
        const meta = {
          Action: "Onboarding completed on mobile",
        };
        sendAmplitudeDataEvent("mobile_onboarding_complete", meta);
      }
    }
    if (visitedSteps.length >= 4) {
      localStorage.setItem("onboardingHalfDone", "true");
    }
    const pathname = mobileBrowser ? "/thank-you" : "/find";
    history.push(pathname);
  };

  checkMobile = (e: React.MouseEvent<HTMLElement>) => {
    const mobileBrowser = detectIsMobile();
    const { history } = this.props;

    if (mobileBrowser) {
      e.preventDefault();
      history.push("/thank-you");
    }
  };
}

export default OnboardingContainer;
