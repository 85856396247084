/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Suspense } from "react";
import { Route, Redirect } from "react-router-dom";
import MainLayout from "components/layout/container/layout";
import Footer from "../components/layout/components/footer";
//@ts-ignore
import ReactDependentScript from "react-dependent-script";
import { getScripts } from "utils/functions";
import { LogoLoader } from "components/commonComponents/SkeletonLogo/logoLoader";

interface Props {
  path: string | string[];
  component: any;
}

const PrivateRoute: React.FC<Props> = ({ component: Component, ...rest }) => (
  // Show the component only when the user is logged in
  // Otherwise, redirect the user to /login page
  <Route
    exact
    {...rest}
    render={(props) =>
      localStorage.getItem("token") ? (
        <ReactDependentScript
          scripts={getScripts()}
          renderChildren={() => (
            <>
              <MainLayout />
              <Suspense
                fallback={
                  <div
                    style={{
                      padding: "20% 0",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <LogoLoader />
                  </div>
                }
              >
                <main className="main-section fade-in">
                  <Component {...props} />
                </main>
              </Suspense>
              <Footer />
            </>
          )}
        />
      ) : (
        <Redirect to="/login" />
      )
    }
  />
);

export default PrivateRoute;
