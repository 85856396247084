import React, { useEffect } from "react";
import { IntlProvider } from "react-intl";
import jwt_decode from "jwt-decode";
import { Provider } from "react-redux";
// component
import { ToastContainer } from "react-toastify";
// functions
import { store } from "store/store";
import { useClearCache } from "react-clear-cache";
import {
  clearStorage,
  loginUsingUrl,
  setLanguageInLocalStorage,
} from "utils";
import Text from "./languages/text";
import Routers from "./router";
import "rheostat/initialize";

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();
  const langValue = setLanguageInLocalStorage();

  useEffect(
    () => {
      if (!isLatestVersion &&  process.env.REACT_APP_API_URL &&
        process.env.REACT_APP_API_URL.includes("staging")) {
         emptyCacheStorage();
      }
      decodeTokenAndSetLocation();
    },
    // eslint-disable-next-line
    []
  );

  const decodeTokenAndSetLocation = () => {
    const token = localStorage.getItem("token");
    if (token) {
      const decoded: any = token ? jwt_decode(token) : null;
      if (decoded && decoded.rolename === "GUEST") {
        const path = window.location.pathname;
        clearStorage();
        if (path.includes("landing") || path.includes("contact")) {
          window.location.reload();
        } else {
          window.location.pathname = "/login";
        }
      }
    } else if (!token && window.location.pathname.includes("finder")) {
      loginUsingUrl();
    }
  };

  return (
    <div className="App">
      <ToastContainer
        theme="colored"
        position="bottom-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Provider store={store}>
        <IntlProvider
          locale={langValue}
          messages={Text[langValue]}
          key={langValue}
          textComponent={React.Fragment}
        >
          <Routers />
        </IntlProvider>
      </Provider>
    </div>
  );
}

export default App;
