/* eslint-disable no-undef */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { compose, lifecycle } from "recompose";
import { withGoogleMap, GoogleMap, Marker } from "react-google-maps";
import markerIcon from "assets/images/pin.png";
import * as mapForObInterface from "../interface/objectDetails";

const MapWithAMarker = compose<any, mapForObInterface.IMapForObProps>(
  lifecycle<mapForObInterface.IMapForObLifeCycle, any>({
    componentWillMount() {
      const { fromPopup } = this.props;
      if (fromPopup) {
        const refs: any = {};
        this.setState({
          onMapMounted: (ref: any) => {
            refs.map = ref;
            maprefs = refs.map;
          },
        });
      }
    },

    componentDidUpdate() {
      const {
        showType,
        resultArray,
        clearRemoveMarker,
        removeMarker,
        iconFile,
        fromPopup,
      } = this.props;

      //show checkboxes data : schools, hospitals etc. on checked
      if (
        fromPopup &&
        maprefs &&
        showType &&
        markerArray[showType].length === 0 &&
        !removeMarker
      ) {
        if (resultArray[showType].length > 0) {
          resultArray[showType].forEach((place: any, index: number) => {
            if (index < 300) {
              const marker = new google.maps.Marker({
                position: new google.maps.LatLng(
                  parseFloat(place.latitude),
                  parseFloat(place.longitude)
                ),
                title: place.name,
                icon: iconFile,
              });
              marker.setMap(
                maprefs.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
              );
              markerArray[showType].push(marker);
            }
          });
        }
      }

      //remove checkboxes data : schools, hospitals etc.
      if (removeMarker) {
        markerArray[showType].length > 0 &&
          markerArray[showType].forEach((marker: any) => {
            marker.setMap(null);
          });
        clearRemoveMarker();
        markerArray[showType] = [];
      }
    },

    componentWillUnmount() {
      const { fromPopup } = this.props;

      if (fromPopup) {
        markerArray = {
          schools: [],
          supermarkets: [],
          transports: [],
          medicals: [],
          parks: [],
        };
        this.props.clearCheckedArray();
      }
    },
  }),
  withGoogleMap
)((props: any) => (
  <GoogleMap
    defaultZoom={10}
    defaultCenter={{ lat: props.lat, lng: props.lon }}
    ref={props.onMapMounted}
    options={{
      fullscreenControl: false,
      scrollwheel: false,
    }}
    // zoomControl={true}
    onClick={() => !props.fromPopup && props.onMarkerClick()}
  >
    <Marker
      position={{ lat: props.lat, lng: props.lon }}
      title={props.title}
      icon={markerIcon}
      onClick={() => {
        !props.fromPopup && props.onMarkerClick();
      }}
    />
  </GoogleMap>
));

let markerArray: any = {
  schools: [],
  supermarkets: [],
  transports: [],
  medicals: [],
  parks: [],
};
let maprefs: any = "";

export default MapWithAMarker;
