import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
import englishText from "languages/english/english";
import logo from "assets/images/immozy-logo.svg";
import ralf from "assets/images/ralf.png";
import "../onboarding-new.scss";
import { addMicrosoftUetEvent } from "utils/globalFunctions";

const WelcomeContainer: React.FC = () => {
  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("addUet")) {
        addMicrosoftUetEvent();
        localStorage.removeItem("addUet");
      }
    }, 1000);
  });

  return (
    <div className="welcome-container">
      <div className="mobile-header-bar d-flex justify-content-between align-items-center">
        <img src={logo} alt="logo" />
      </div>
      <div className="header-bar d-flex justify-content-between align-items-start">
        <img src={logo} alt="logo" />
        <Link
          to="/find"
          onClick={() => localStorage.setItem("showWelcome", "true")}
        >
          <button className="onboarding-button-white">
            <FormattedMessage
              id={englishText.SKIP}
              defaultMessage={englishText.SKIP}
            />
          </button>
        </Link>
      </div>
      <div className="ralf-section d-flex justify-content-center align-items-center">
        <img src={ralf} alt="img" />
        <p className="ralf-title">
          <FormattedMessage
            id={englishText.WELCOME_TITLE}
            defaultMessage={englishText.WELCOME_TITLE}
          />
        </p>
        <p className="ralf-subtitle">
          <FormattedMessage
            id={englishText.WELCOME_LINE_1}
            defaultMessage={englishText.WELCOME_LINE_1}
          />
        </p>
      </div>
      <div className="welcome-info">
        <p className="info-1">
          <FormattedMessage
            id={englishText.WELCOME_LINE_2}
            defaultMessage={englishText.WELCOME_LINE_2}
          />
        </p>
        <p className="ralf-subtitle info-2">
          <FormattedMessage
            id={englishText.WELCOME_LINE_3}
            defaultMessage={englishText.WELCOME_LINE_3}
          />
          <br />
          <br />
          <FormattedMessage
            id={englishText.WELCOME_LINE_4}
            defaultMessage={englishText.WELCOME_LINE_4}
          />
        </p>
      </div>
      <div className="mt-5 mb-5 d-flex justify-content-center">
        <Link to="/onboarding">
          <button className="onboarding-button-primary">
            <FormattedMessage
              id={englishText.LETS_GO}
              defaultMessage={englishText.LETS_GO}
            />
            !
          </button>
        </Link>
      </div>
    </div>
  );
};

export default WelcomeContainer;
