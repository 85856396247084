import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import OtpInput from "react-otp-input";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Modal from "react-awesome-modal";
import HttpService from "services/http.service";
import englishText from "languages/english/english";
import getLocalizeText from "utils/getLocalizeText";
import { CloseIcon } from "components/commonComponents/icons";
import { userContactProps } from "components/objectDetails/interface/objectDetails";

interface IOtpModalProps {
  contactDetails: userContactProps;
  setshowOtpMpdal: (value: boolean) => void;
  sendContactRequest: (verified: boolean) => void;
  sendOtpToMobile: () => void;
}

let isOtpVerified = false;

export const OtpVerifyPopup: React.FC<IOtpModalProps> = (props) => {
  const {
    contactDetails,
    setshowOtpMpdal,
    sendOtpToMobile,
    sendContactRequest,
  } = props;

  const [otp, setotp] = useState("");
  const [otpError, setotpError] = useState("");

  const verifyOtp = () => {
    const payload = {
      to: "+" + contactDetails.phone,
      otp: otp,
    };

    HttpService.post("v1/profile/verifyOtp", payload)
      .then(() => {
        isOtpVerified = true;
        sendContactRequest(true);
      })
      .catch((err) => {
        setotpError(
          err.response &&
            err.response.data &&
            err.response.data.message === "Enter valid OTP"
            ? getLocalizeText(englishText.ENTER_VALID_OTP)
            : getLocalizeText(englishText.TRY_AGIN_LATER)
        );

        setTimeout(() => {
          setotpError("");
        }, 4000);
      });
  };

  return (
    <div>
      <Modal visible={true} effect="fadeInUp" width={"590px"}>
        <div className="modal-otp-verify">
          <div
            className="close-btn-div"
            onClick={() => {
              setshowOtpMpdal(false);
            }}
          >
            <CloseIcon />
          </div>
          <h5 className="mb-2">
            <FormattedMessage
              id={englishText.OTP_VERIFICATION}
              defaultMessage={englishText.OTP_VERIFICATION}
            />
          </h5>
          <p className="mb-4">
            <FormattedMessage
              id={englishText.CODE_SENT_TO_MOBILE}
              defaultMessage={englishText.CODE_SENT_TO_MOBILE}
            />
            <br />
            <FormattedMessage
              id={englishText.CODE_SENT_TO_MOBILE_1}
              defaultMessage={englishText.CODE_SENT_TO_MOBILE_1}
            />
            <span className="mobile">+{contactDetails.phone}</span>
          </p>
          <div className="d-flex justify-content-center">
            <OtpInput
              value={otp}
              onChange={(otp: any) => {
                otpError !== "" && setotpError("");
                setotp(otp);
              }}
              numInputs={4}
              className="otp-input"
              shouldAutoFocus={true}
            />
          </div>

          <div className="d-flex align-items-center justify-content-center mt-4">
            <button
              className="btn primary-btn"
              disabled={otp.length !== 4}
              onClick={verifyOtp}
            >
              <FormattedMessage
                id={englishText.VERIFY}
                defaultMessage={englishText.VERIFY}
              />
            </button>
          </div>
          <div className="d-flex align-items-center justify-content-center">
            <p className="skip-link mr-3" onClick={() => sendOtpToMobile()}>
              <FormattedMessage
                id={englishText.RESEND_OTP}
                defaultMessage={englishText.RESEND_OTP}
              />
            </p>
            <p
              className="skip-link"
              onClick={() => sendContactRequest(isOtpVerified)}
            >
              <FormattedMessage
                id={englishText.SKIP_OTP}
                defaultMessage={englishText.SKIP_OTP}
              />
            </p>
          </div>
          {otpError !== "" && <p className="otp-error">{otpError}</p>}
        </div>
      </Modal>
    </div>
  );
};
