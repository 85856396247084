const englishText = {
  //Authentication pages
  WELCOME_TO: "Welcome to Immozy",
  LOGIN: "Log In",
  LOGOUT: "Logout",
  PASSWORD: "Password",
  SIGN_UP: "Sign up",
  FORGOT_PWD: "Forgot password?",
  I_AGREE: `I agree to Immozy’s `,
  TERMS_OF_USE: "Terms of Use",
  PRIVACY_POLICY: "Privacy Policy",
  WEBSITE: "Website",
  FORGOT_PWD_SUBTITLE: "Enter your email address to reset the password",
  SEND_EMAIL: "Send an email",
  BACK_TO_LOGIN: "Back to Login",
  UPDATE_PWD: "Update password",
  RESET_YOUR_PWD: "Reset your password",
  TOO_WEAK: "Too weak",
  WEAK: "Weak",
  AVERAGE: "OK",
  GOOD: "Good",
  STRONG: "Safe",
  ACTIVATE_SUCCESS_MSG: "Your account has been verified - great!",
  ACTIVATE_SUBTITLE: `To help you find the best properties with Immozy and our innovative matching algorithm, we've prepared a short onboarding for you.`,
  FIND_PROPS_NOW: "Find properties now!",
  TERMS_ERROR: "Please accept terms & conditions",
  EXISTING_ACC_ERROR:
    "There already exists a user account with this email address, please use another one.",
  EXISTING_USER_ERROR:
    "Username already exists, Please try with diffrent username",
  EMAIL_SENT_SUCCESS: "An email has been sent.",
  EMAIL_ERROR: "Please provide your email address",

  //dashboard
  WISHLIST: "Wishlist",
  SEARCH: "Search",
  PREPARE_MENU: "Qualify",
  ONBAORDING_MENU: "Onboarding ",
  FIND: "Find",
  BUY: "BUY",
  LIVE: "LIVE",
  LIST: "List",
  MAP: "Map",
  INSTRUCTIONS: "Instructions",

  //search results - top results and search
  YOUR_FAVORITES: "Your Favorites",
  RESET_FILTER: "Reset filter",
  PRICE: "Price",
  SPACE: "Space",
  SPACE_SMALL: "space",
  ROOMS: "Rooms",
  ROOMS_OP: "rooms",
  ROOM: "Room",
  MORE_FILTERS: "More Filters",
  AREA: "Area",
  YEAR_BUILT: "Year built",
  LOCATION: "Location",
  LOCATION_1: "City / State",
  FINANCIAL: "Financial",
  PROPERTY: "Property",
  RESULT_FOUND: "result found",
  RESULTS_FOUND: "results found",
  WITH_TOTAL_RATING_OF: "with a total rating of",
  OR_MORE: "or more",
  PROPERTIES: "properties",
  RESULT_FOUND_IN_HAMBURG: "result in",
  RESULTS_FOUND_IN_HAMBURG: "results in",
  TOTAL_SCORE: "Total score",
  LOCATION_SCORE: "Location score",
  PROPERTY_SCORE: "Property score",
  FINANCIAL_SCORE: "Price score",
  DETACHED_HOUSE: "Detached house",
  SEMI_DETECHED_HOUSE: "Semi-detached house",
  BUNGLOW: "Bungalow",
  MID_TERRACE_HOUSE: "Mid-terrace house",
  VILLA: "Villa",
  PENTHOUSE: "Penthouse",
  RES_COMPLEX: "Residential complex",
  NEW_CONSTRUCTION: "New, planned or under construction",
  PARKING: "Parking Space / Garage",
  PARKING_SPACE: "Parking space",
  GARDEN_BALCONY: "Garden / Balcony",
  NO_COMMISSION: "Commission free",
  STANDARD_SORTING: "Topicality",
  PRICE_PER_METER: "Price per m2",
  YEAR_OF_CONSTRUCTION: "Year of construction",
  INTERNET_SPEED: "Internet Speed",
  NO_MATTER: "No matter",
  INTERNET_ACCESS: "Internet access",
  MB_MIN: "50 MBit/s at minimum",
  APPLY: "Apply",
  YYYY: "YYYY", //As in placaholder for year,
  PLOT: "Plot",
  ALL: "All",
  OTHER_FEATURES: "Other features",
  SEARCH_BY_RADIUS: "Search by Radius",
  SEARCH_BY_ZIP: "Search by ZIP-Code, name or district",
  SAERCH_LOCATION: "Search location",
  HIDE_FORECLOSURE: "Hide Foreclosure Sale Properties",

  // MAP
  MULTIPLE_PROPERTIES: "Multiple properties",
  SCHOOLS: "Schools",
  TRANSPORTS: "Public transportation",
  MEDICLES: "Medical facilities",
  PARKS: "Parks",
  SUPERMARKETS: "Supermarkets",
  NOT_AVAILABLE: "Cannot be found on the map",

  //On-boarding
  HEY: "Dear",
  SAVE_AND_PROCEED: "Save and proceed",
  BACK: "Return",
  THANKS_MSG:
    "Thanks! Now we have a lot better picture of what you are looking for.",
  YOUR_SEARCH_PROFILE: "Your search profile",
  GO_TO_SEARCH: "Go to search options",
  COME_ON: "COME ON!",
  TO_THE_TOP: "To the top",
  FILL_ANS_ERROR: "Please answer the question to continue.",
  EQUITY_LIMIT_ERROR: "Please enter a number between 0 and 1,000,000",
  EMI_LIMIT_ERROR: "Please enter a number between 0 and 10,000",
  OTHER_PLACAHOLDER:
    "Add your thoughts here if none of options above should be suitable.",

  //On-boarding Summary
  PROPERTY_SPECIFIACTION: "Specification",
  AREA_TYPE: "Residential area type",
  PROPERTY_TYPE: "Property type",
  LIVING_SPACE: "Living space",
  REQUIREMENTS: "Requirements",
  EQUITY: "Equity",
  PRICE_BUDGET: "Recommended purchase price budget",
  MONTHLY_COST: "Monthly costs",
  GOOD_INTERNET_CONNECTION: "Good Internet connection",
  GOOD_NEIGHBOURHOOD: "Good neighbourhood",
  TRAFFIC_CONNECTION: "Traffic connection",
  SCHOOL_CHILDCARE: "School & childcare",
  MARKET_CULTUTE: "Supermarkets & cultural attractions",
  PARKS_GREEN_SPACES: "Parks/Green spaces",
  FAMILY_FRIENDLY: "Family friendly",
  FOR_INVESTMENT: "For investment",
  FREEDOM_LUXURY: "Freedom and luxury",
  PENSION_SECURITY: "Pension security",
  OTHER: "Other",

  //Profile
  ENGLISH: "English",
  GERMAN: "German",
  USER_NAME: "User Name",
  EMAIL: "E-Mail address",
  EMAIL_1: "E-Mail address*",
  NAME: "Name",
  CHANGE_PASSWORD: "Change password",
  SAVE_PASSWORD: "SAVE PASSWORD",
  NEW_PASSWORD: "New Password",
  CONFIRM_PASSWORD: "Confirm Password",
  OLD_PASSWORD: "Old Password",
  SAVE: "Save",
  EDIT_PROFILE: "Edit Profile",
  ABOUT: "ABOUT",
  ONBOARDING_SUMMARY: "ONBOARDING SUMMARY",
  LANG_SETTINGS: "LANGUAGE SETTINGS",
  PROFILE_SUCCESS_MSG: "Profile updated successfully.",
  PWD_CHANGED_SUCCESS_ALERT:
    "Your password has been changed successfully. Please login again with your new password.",
  FIELD_FILL_ERROR: "Please provide all of the required information",
  FIRST_LAST_ERROR: "Please provide your first and last name",
  FIRST_ERROR: "Please provide your first name",
  LAST_ERROR: "Please provide your last name",
  MAIL_ERROR: "Please provide your EMail-address",
  IMAGE_SIZE_ERROR: "Please select an attachment which is less than 1 MB",
  IMAGE_TYPE_ERROR: "Only JPEG, JPG and PNG formats are supported",
  NOT_ADDED_YET: "Not added yet",
  BIRTH_DATE: "Birth Date",
  POSTAL_CODE: "Postal Code",
  EMPLOYMENT: "Employment",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  SELECT_PHOTO: "Select Photo",
  CHANGE_PHOTO: "Change Photo",

  // Object Details
  PROPERTY_DETAILS: "Property Details",
  FINANCIAL_DETAILS: "Financial Details",
  TOTAL_MATCH_SCORE: "Overall match score",
  TOAL_SCORE_SUBTITLE:
    "The match score shows how well the property details fit for your search criteria.",
  TOTAL_PRICE: "Total Price",
  MONTHLY_COST_EST: "Monthly (estimated) cost",
  PLOT_AREA: "Plot area",
  FLOORS: "Number of floors",
  CONDITION: "Condition of the house", //as in condition of house
  PRICE_TREND: "Price trend 10 yrs",
  VACANT_FROM: "Vacant from",
  ENERGY_EFF: "Energy effieciency",
  LOCATION_SCORE_SUBTITLE:
    "Residental area, connection, micro- and macro location analysis",
  PROPERTY_SCORE_SUBTITLE:
    "Quality of living, space, condition, and type. Based on individual requirements",
  FINANCIAL_SCORE_SUBTITLE:
    "Budget fit, price evalution and development, market indicators",
  LOCATION_INFO: "Location/Map",
  NOTICES: "Notices",
  SELLER_INFO: "Seller Information",
  SAVE_NOTICE: "Save Notice",
  COURTAGE_FOR_BUYER: "Courtage for buyer",
  YES: "Yes",
  NO: "No",
  YES_1: "Yes",
  NO_1: "No",
  PLATFORM: "Platform",
  CALL_UP_AD: "To our offer",
  ONLINE_SINCE: "Online since",
  REMOVE_FAVO: "Remove from favourites",
  REMOVE_FAVO_CONFIRM:
    "Are you sure that you want to remove this property from favourites?",
  FAVORITES: "Favourites",
  OBJ_DESC: "Object description",
  ENERGY_CERTIFICATE: "Energy Certificate",
  ENERGY_CLASS: "Energy efficiency class",
  UNDER_CONSTRUCTION: "Building under construction",
  OPEN_LINK_IN_NEW: "Open link in new tab",
  EMPTY_NOTICE_ERROR: "Please add something to save.",
  NOTICE_SUCCESS_MSG: "Notice added successfully.",
  FLOORPLAN: "Floorplan",

  //Misc
  CALC_FINISH_MSG: "Please wait till calculation is finished",
  CALCULATING: "calculating",
  YOUR_MAIL_ADR: "Your e-mail",
  MIN_VALUES: "Minimum Values",
  SET_LIMITS_TITLE: "Set your minimum limits to improve the results",
  PROPERTY_MEET_CRITERIA: "Properties that meet your criteria",
  SEND_NOTIF_FOR: "Send me an email notification for",
  NOTIF_NEW_TOP_MATCHES: "Newly added top matches",
  NOTIF_SUBTITLE__NEW_TOP_MATCHES:
    "Get notified immediately for new top properties",
  NOTIF_DAILY_SUMMARY: "Daily Summaries",
  NOTIF_SUBTITLE_DAILY_SUMMARY: "New objects added and status updates",
  NOTIF_WEEKLY_REPORTS: "Weekly reports",
  NOTIF_SUBTITLE_WEEKLY_REPORTS: "Your weekly personalized market upadates",
  MY_SEARCH_PROFILE: "My Search Profile",
  GOOD_CONNECTION_TO: "Good connection to",
  AT_LEAST: "Starting at",
  MAX_PROPERTY_VALUE: "Maximum property value",
  MONTHLY_PAYMENT: "Monthly payment",
  SAVING_EQUITY: "Reserve assets/Equity",
  LOADING_MSG: "Loading the top results for you",
  PWD_NOT_MATCH: "The passwords do not match. Please check both passwords.",
  INCORRECT_OLD_PWD: "You've entered an incorrect old password",
  NOTIFICATION: "Notification",
  JUST_NOW: "Just now",
  SOMETHING_WENT_WRONG: "Something went wrong",
  CANCEL: "Cancel",
  NO_SEARCH_RESULT_FOUND: `No results found for your desired criteria. You can search with other filters in the "Search" tab or you can change the requirements listed in your profile.`,
  NO_RESULT_FOUND_TRY_OTHER: "No results found. Please try another filter.",
  NEXT: "Next",
  PREV: "Previous",
  PREV_STEP: "Previous Step",
  SUB_SCORE: "Sub score",
  MIN_SCORE: "Min Score",
  NOTIF_COMING_SOON: "Notifications coming soon",
  PROPERTIES_DISPLAYED: "The properties displayed and the scoring",
  IS_BASED_ON: "is based on your",
  PERSONAL_SEARCH_PROFILE: "personal search profile.",
  PROFILE: "Profile",
  NO_FAVO_MSG: "No properties are added as favourites. ",
  ERROR_CONTACT_ADMIN:
    "An unknonw error has occured. Please try again or contact the administrator.",

  // house data
  WELL_KEPT: "well_kept",
  MODERNIZED: "modernized",
  MINT: "mint_condition",
  FULLY_RENOVATED: "fully_renovated",
  FIRST_TIME_USE: "first_time_use",
  REFURBISHED: "refurbished",
  FIRST_AFTER_REFURB: "first_time_use_after_refurbishment",
  NAGOTIABLE: "negotiable",
  NO_INFO: "no_information",
  NEED_OF_RENOVATION: "need_of_renovation",
  DEMOLITION: "ripe_for_demolition",
  DOWNTOWN: "Downtown",
  SUBURBAN: "Suburban",
  DOWNTOWN_1: "downtown",
  SUBURBAN_1: "suburban",

  FLAT: "Flat",
  ATTIC_FLOOR: "Attic floor",
  GROUND_FLOOR: "Ground floor",
  MAISON: "Maisonette",
  RAISED_FLOOR: "Raised ground floor",
  TERRACE_APT: "Terrace apartment",
  BASEMENT: "Basement",
  LOFT: "Loft",
  OTHER_1: "other",
  SEMI_DETECTHED: "semi-detached-house",
  DETACHED_HOUSE_2: "detached-house",
  END_HOUSE: "End-terrace house",
  BUNGLOW_1: "bungalow",
  SPCL_PROPERTY: "special-property",
  APT_BLOCK: "apartment-block",
  MID_TERRACE_HOUSE_1: "Mid-terrace house",
  FREE_HOLD_FLAT: "Free hold flat",
  RES_AND_OFFICE: "residential-and-office-building",
  HALL_STORAGE: "hallstorage",
  MISC: "miscellaneous",
  COM_UNIT: "commercial-unit",
  RESIDEN_COMPLEX: "residential-complex",
  SHOPSALE: "shopsales-area",
  COM_PROPERTY: "commercial-property",
  SPECIAL_PROPERTY: "Special property",
  APART_BLOCK: "Apartment block",

  //new
  LOGIN_AGAIN_ERROR: "Your login session has expired. Please login again",
  DOUBLE_TOWNHOUSE: "double_or_townhouse",
  APAARTMENT: "apartment",
  DETACHED_HOUSE_1: "detached_house",
  OBJECT_DISABLED_ERROR: "This property has already been sold.",

  // custom object
  MY_PROPERTIES: "My Property List",
  ADD_NEW_PROPERTY: "Add new property",
  NEW_PROP_HEADER:
    "Everyone can add properties for checking and purchasing on the platform, because not every property gets to the online market. This property is only visible to you.",

  TITLE: "Name",
  ACTION: "Action",
  SEE_DETAILS: "See details",
  EDIT_PROPERTY: "Edit property",
  DELETE_PROPERTY: "Delete property",
  QUARTER: "Quarter",
  CITY: "Town",
  CITY_2: "City",
  EXPOSE: "Exposé",
  PROP_SUB_TYPE: "Detailed property type",
  FLOOR_OF_BUILDING: "Floors of the building",
  FLOOR_S: "Floors",
  FEATURES: "Features",
  INT_SPEED: "Internet speed (Mbps)",
  HEATING: "Heating",
  PICTURES: "Pictures",
  CLEAR: "Clear",
  REMOVE_ALL: "Remove All",
  IMG_ERROR_10: "Only 10 images allowed",
  IMAGE_SIZE_ERR: "Please select an attachment which is less than 512 KB",
  AGENT_COM: "Agent commission",
  ADD_FEES: "Other payment costs",
  PURCHASE_STATUS: "State of negotiations",
  GENERAL_INFO: "General Information",
  FINANCE: "Finance",
  NAME_OF_OBJECT: "Property name",
  DESC_OF_PROPERTY: "Description",
  IS_FOR_SALE: "Is this property for sale?",
  IS_RES: "Is it a residential property?",
  COUNTRY: "Country",
  ZIP_CODE: "Zip code",
  STREET: "Street",
  STREET_HOUSE: "Street and house number",
  STREET_NO: "House number",
  LIVING_AREA: "Living area (m2)",
  PLOT_AREA_m2: "Plot area (m2)",
  AMOUNT_OF_ROOMS: "Amount of rooms",
  AMOUNT_OF_SLEEPROOMS: "Amount of sleeping rooms",
  AMOUNT_OF_BATHROOMS: "Amount of bath rooms",
  FURNISHED: "Furnished",
  NEW_WIN: "New windows",
  AC: "Air condition",
  HEATING_SYSTEM: "Central Heating",
  FIERPLACE: "Oven",
  GARAGE: "Garage",
  DOUBLE_GARAGE: "Double garage",
  PARKING_LOT: "Parking lot",
  UND_GARAGE: "Underground garage",
  GAS: "Gas",
  GEO_THERMAL: "Geothermal energy",
  PHOTOVOLIC: "Photovoltaic",
  NOT_STARTED: "Not started",
  ONGOING: "Ongoing",
  COMPLETED: "Completed",
  ADDRESS: "Address",
  PHONE: "Phone",
  PHONE_2: "Phone",
  BALCONY: "Balcony / Terrace",
  GARDEN: "Garden",
  GUEST_TOILET: "Guest Toilet",
  SAVE_AND_CONT: "Save And Next",
  ABORT: "Abort",
  CANCEL_CONFIRM: "Are you sure you want to cancel?",
  FILL_ERROR_OBJECT:
    "Please fill required fields and save the changes to move forward.",
  OK: "OK",
  PROPERTY_UPDATED: "Your property has been updated successfully.",
  PROPERTY_SUCCESSS: "Success! The property has been added to the system.",
  HOUSE: "House",
  ADD_TO_FAVO: "Add to favorites",
  APAART: "Apartment",
  IMG_INFO_UPLOAD:
    "You can upload up to 10 files (512 kb each). Format:.png, .jpg, .jpeg",
  SUBMIT_IMG: "Submit pictures",
  IMG_SUCCESS: "Uploaded !",
  IMG_SUBMIT_ERR: "Please click on submit to save your images.",
  PDF_ERR: "Only PDF files allowed",
  PLEASE_SELECT: "Please select...",
  NO_INTERNET: "No internet",
  EMPTY_ERR: "*Please fill out all required fields!",
  NO_DATA: "No results found",
  SEARCH_LOCATION: "Search location",
  LOCATION_TOOLTIP: "With this search you can fill in the place automatically",
  LOCATION_PLCAEHOLDER: "Search by quarter, zip code, or district",
  SEND_MAIL_CONFIRM:
    "For using the notifications you have to verify your email address. Send out verification email now?",
  CANT_SEND: "Verification message cannot be sent",

  CHAR_LIMIT: "Name must be shorter than or equal to 24 characters.",

  //New onboarding
  WHY_LINK: "Why is IMMOZY asking you this question?",
  PROGRESS: "PROGRESS",
  GENERAL: "General",
  RES_AREA: "Residential area",
  PROP: "Property",
  FINAN: "Finances",
  IMMOZY_EXP: "IMMOZY-Matches",
  CONTINUE: "Continue",
  SKIP_1: "SKIP",
  PARA_1: "Your most important",
  PARA_2: "Decision is also the right one!",
  PARA_3:
    "Answering the questions fully will bring you the benefits you need to quickly and safely find the property on best suits you.",
  PARA_4:
    "You will also find tons of useful tips to help you to be able to make a good decision.",
  PARA_5: "You can also skip the questions now and ask them later answer.",
  SKIP: "Skip",

  //NEW SIGNUP
  GREAT: "Great! You did it!",
  HERE_WE_GO: "HERE WE GO!",
  FIN: "FINANCE",
  SPEC: "SPECIFICATIONS",
  BASED_ON_INFO: "Based on your information, we have found",
  WE_HAVE_PROPS: "suitable properties. Register now and see your top matches",

  REQUEST_SHOW: "REQUEST A SHOWING",
  REQUEST_SHOW_MSG:
    "Thank you for your interest! We will contact you promptly by e-mail to make an appointment.",
  REQUEST_DOCUMENTS: "REQUEST DOCUMENTS",
  ASK_QUESTION: "ASK QUESTION",
  OFFER_PUBLISHED: "Offer published on:",
  SIZE: "size",
  FIND_PROP: "Find property",
  CHECK_PROP: "Check property",
  BUY_PROP: "Buy property",

  //today
  EXAMPLE_EMAIL: "example@email.com",
  SAVE_SEARCH: "Save Search",
  SAVED_SEARCH: "Search Saved",
  EMAIL_NOTIF_FOROBJ: "E-Mail Notifications if new objects are availabe.",
  MY_SEARCHLIST: "My search list",
  VERIFY_YOUR_EMAIL: "VERIFY YOUR E-MAIL",
  VERIFY_MAIL_MSG:
    "Please check your inbox and verify your E-Mail address through the activation E-Mail we’ve send to you to unlock all our cool features, including:",
  RESEND_MAIL: "Resend Mail",
  OP_1: "Save searches",
  OP_2: "Notifications about new properties",
  OP_3: "“Contact” options if interested in a property",
  MATCH_FILTER: "Match",
  PRICE_FILTER: "Price ",
  RESET_FILTERS: "RESET FILTERS",
  RESET_FILTER_TEXT: "Resetting filters changes your prior search results.",
  RESET_ALL: "Reset All",
  TOP_MATCHES: "Top Matches",
  MATCHING_SCORE: "Matching Score",
  SCORE_MIN: "Score Min.",
  SCORE_MAX: "Score Max.",
  RESULTS: "results",
  SWITCH_OFF_FILTER: "Switch off the filter",
  ADVANCE_OPTION: "Advanced Options",
  MATCH_FINDER: "Match Finder",
  MIN_LEFT_5: "3-5 minutes left",
  MIN_LEFT_3: "2-3 minutes left",
  MIN_LEFT_2: "1-2 minutes left",
  MIN_LEFT_1: "<1 minutes left",

  OUT_OF: "out of more than",
  PAGE_RESULTS: "results",
  ABOUT_US: "ABOUT US",
  ABOUT_US_2: "About us",
  WHO: "Who we are",
  HOW: "How to use IMMOZY",
  FAQ: "FAQs",
  CAREERS: "Careers",
  DESTINATIONS: "DESTINATIONS",
  LEGAL: "LEGAL",
  TERMS: "Terms and Conditions",
  IMPRINT: "Imprint",
  NEW: "New! ",
  SHOW_ALL: "Show All",
  SHOW_ON_MAP: "Show on map",
  I_AM_INTERESTED: "I am interested!",
  SCORE_BASED: "The scores are based on your personal ",
  FACTS: "Facts",
  FINANCES: "Finances",
  DETAILS: "Details",
  FURNISHING: "Furnishing",
  BEDROOMS: "Bedrooms",
  BATHROOMS: "Bathrooms",
  CONSTRUCTION_PHASE: "Construction Phase",
  CELLER: "Celler",
  PURCHASE_PRICE: "Purchase Price",
  PURCHASE_PRICE_M: "Purchase price per m2",
  ADDITIONAL_COST: "Additional purchase costs",
  TOTAL_COSTS: "Total costs",
  KITCHEN_FITTED: "Kitchen fitted",
  LOCATION_INFORMATION: "Location Information",
  POPULATION: "Population",
  LOCAL_SUPPLY: "Local Supply",
  SHOPPING: "Shopping",
  PRIMARY_SCHOOL: "Primary School",
  UNIVERSITY: "University",
  PUBLIC_TRANSPORT: "Public transport",
  HELP_TEXT_1:
    "Are you interested in this property? We would like to help you and be at your side with words and deeds.",
  HELP_TEXT_2: "Let us support you now free of charge and with no obligation!",
  NO_EXTRA: "No extras",
  CONTINUE_1: "CONTINUE ",
  WELCOME: "WELCOME!",
  MY_PROFILE: "My Profile",

  EMAIL_NOT_VERIFIED: "Your email has not been verified!",
  RESEND_MAIL_MSG: "Should we resend this verifiation E-Mail?",
  VERIFY_MAIL_WARNING:
    "Please check your inbox and verify this email address in order to use it as credential and in other mail communication.",
  SAVE_PROFILE_ERROR:
    "An error has occured while saving profile details. Please try after some time.",
  SAVE_MAIL_ERROR:
    "An error has occured while changing the email address. Please try after some time.",
  UNVERIFIED_USER: "Unverified user",
  EMAIL_VERIFICATION: "Email verification",
  REMOVE_IMG: "remove picture",
  LANGUAGE: "Language",
  ADD_PROPERTY: "Add Property",
  ADD_PROP_TITLE:
    "You can add more property objects to sell, buy or check them through IMMOZY. Not every property is online.",
  ADD_PROP_SUBTITLE: "This properties will only be visible for you.",
  SELECT_FILE: "Select File",
  MY_SEARCHES: "My searches",
  ONBOARDING: "Onboarding",
  MY_PROP_LIST: "My property list",
  IN_EUROS: "in Euros",
  IN_PRECENTAGE: "in percentage",
  REQUEST_AND_ACCESS: "REQUEST AND GET ACCESS TO ALL DOCUMENTS",
  REQ_MSG_BODY:
    "Immozy supports you with expertise and a guided process for a smooth purchase. The procurement of the documents required for the evaluation is an important part of this. We are happy to do this for you and make sure that the documents are complete.",
  REQ_MSG_BODY_2:
    "As soon as new documents are available, you will be notified by e-mail.",
  CAN_WE_HELP: "Can we help you?",
  CONTACT_US: "Contact us ",
  TAKES_1_2_DAYS:
    "Usually it takes 1-2 days to get the documents, rarely longer than 1 week.",
  REQUEST_DOCUMENTS_NOW: "Request Documents now",
  WAITING_FOR_DOC: "Waiting for documents",
  SELLER_NO_AN: "We're sorry, the seller side does not answer",
  NO_AN_10_DAYS:
    "No answer for 10 days, we have tried several times. Nevertheless, we will keep going and inform you when we get and answer.",
  DOWNLOAD_AREA: "Download area",
  DOC_AVAILABLE: "Request finished. Documents available for donwload.",
  DOC_REQ_ERR:
    "Some error has occured while trying to request doucment. Please try again later.",
  DOCUMENT_REQUESTED: "Documents requested",
  NO_SAVED_SEARCH: "No Saved Searches",
  NO_SAVED_SEARCH_WARNING: "There are no saved searches in your list.",
  CHANGE_NAME: "Change Name",
  MATCH_SCORE: "Match score",
  TYPE: "Type",
  SETTINGS: "Settings",
  SHOW_RESULTS: "Show Results",
  DELETE: "Delete",
  DELETE_SEARCH_CONFIRM: "Do you really want to delete the search",
  DELETE_SEARCH: "DELETE SEARCH",
  NOT_GIVEN: "Not given",
  EMAIL_NOTIF: "E-Mail Notifications",
  EMAIL_OFFERS_CHOOSE: "Choose how often you want to get new offers by E-Mail.",
  EVERY_HOUR: "Every hour",
  EVERY_3_HOURS: "Every 3 hour",
  ONE_TIME_DAY: "1 time a day",
  NEVER: "Never",
  FULL_SCREEN: "Full screen",
  EXIT_FULL_SCREEN: "Exit full screen",
  YOUR_OLD_MAIL: "Your old email ",
  WILL_BE_REMOVE: " will be removed once you verify this new email address:",
  SHOW_LESS: "Show less",
  READ_MORE: "Read more...",
  TYPES_OF_BUILDING: "Types of building",
  HOUSES: "Houses",
  PRICE_1: "price",
  ADVERT_AGE: "Advertising age in days",
  YEAR_MIN: "Year min.",
  YEAR_MAX: "Year max.",
  AGE_MIN: "Age min.",
  AGE_MAX: "Age max.",
  MY_SEARCH: "My Search",
  MUNICH: "Munich",
  COLOGNE: "Cologne",
  COMING_SOON: "coming soon",
  WITH_COMMISSION: "With commission",
  FINISH_THE: "Fill out the ",
  TO_SEE_PROPERTY:
    "to see the how good this property matches your requirements.",
  WHY_MSG:
    "Immozy uses a revolutionary algorithm that can give you tailor-made recommendations and thus turns your search into a find! But of course we have to understand exactly what your needs and requirements are. We therefore recommend that you answer our questionnaire to ensure the full performance of our technology.",
  WHY_MSG_1:
    "It goes without saying that we treat all your data as strictly confidential and we do not share them with third-party providers or the like. All data that we collect serves the sole purpose of making you suitable offers, we guarantee that!",
  SHOW_ERROR: "Your request is already being processed.",
  REQUEST_COMPLETED: "Your request has been completed.",
  BUILDING_TYPE: "Type of building",
  DEACTIVATED: "Already Sold",
  FARMHOUSE: "Farmhouse",
  REGISTRATION: "Registration",
  LOG_IN: "Log-in",
  PRICE_SMALL: "Price",
  FIRST_SELECT_CITY: "First select the city",
  LOADING_DREAM_HOMES: "LOADING YOUR DREAM HOMES IN ",
  LOAD_MSG_1: "Looking for nice homes",
  LOAD_MSG_2: "Searching for villas",
  LOAD_MSG_3: "Loading apartments",
  LOAD_MSG_4: "Unfolding maps",
  LOAD_MSG_5: "Sorting pins",
  LOAD_MSG_6: "Checking prizes",
  MEETING_YOUR: "meeting",

  // calculator
  FINANCING: "Financing",
  MORTGAGE_CALCULATOR: "Mortgage Calculator",
  GET_FREE_RECOM: "Get a free personalized mortgage recommendation?",
  GET_STARTED: "Get Started",
  GET_FRWW_FINANCING_OFFER: "Get a free financing offer now",
  CALCULATE_NOW: "Calculate now",
  ESTATE_AGENT_FEE: "Estate agent fee",
  PURCHASE_TAX: "Purchase Tax",
  LAND_RESGISTER_ENTRY: "Land register entry",
  NOTARY_FEES: "Notary Fees",
  PURCHASE_COST_COMPOSITION: "What is the composition of the purchase cost ?",
  FIXATION_PERIOD: "Fixation Period",
  YEARS: "Years ",
  YEARS_2: "Years",
  MONTHS: "Months",
  REPAY_MORGAGE: "When will I be done repaying my mortgage?",
  MONTHLY_RATE: "Monthly Rate",
  INT_RATE: "Interest Rate",
  REMAINING_DEBT: "Remaining Dept",
  FIXED_UNTIL: "Fixed Until",
  LOAN_AMOUNT: "Loan amount",
  PROPERTY_PRICE: "Property Price",
  REAL_ESTATE_COMM: "Real Estate Commission",
  ANNUAL_REPAY_RATE: "Annual repayment rate (amortization)",
  SAVINGS: "Savings",
  TOTAL_PURCHASE_COSTS: "Total Purchase Costs",
  GET_STARTED_SUCESS:
    "Your request has been successfully submitted. Our financing experts will contact you and help you understand your mortgage options.",
  GET_STARTED_WARNING:
    "We help you understand your mortgage options and basic alternatives for using your savings. Please login first into your Immozy Account to proceed.",
  FREE_FINANCE_OFFER: "Free financing offer",
  GET_FREE_REC: "Receive your free, personal mortgage loan recommendation",
  COMPOSITION_OF_COST: "Composition of the additional purchase costs",
  VIEW_HISTROY: "Show Repayment History",
  AMORTISATION_HISTORY: "Repayment history",
  SHOW_REPAYMENT_PROGRESSION: "Repayment Progression",
  COMPARE_THE_TERMS: "Compare the terms here and choose a different bond:",
  SELECT: "Select",
  GET_FREE_RECOMMENDATIONS:
    "Receive your free, personal mortgage recommendation",

  //signup popup
  EMAIL_ADDRESS_1: "Email Address",
  ALREADY_MEMBER: "Are you already at IMMOZY?",
  ALREADY_HAVE_AN_ACCOUNT: "Already have an account?",
  LOGIN_HERE: "Log in here",
  COMP_MARKET: "COMPREHENSIVE MARKET RANGE.",
  ALL_OFFER_GERMANY: "All offers from the most important portals",
  IN_GERMANY: "in Germany",
  FIND_1: "Find",
  REAL_ESTATE: "REAL ESTATE.",
  SIMPLE_FAST: "Simple. Fast. Personalized for you.",
  IMMOZY_MATCH: "Thanks to the IMMOZY matching algorithm.",
  COMP_MATERIAL: "Comprehensive auxiliary material.",
  OVER: "Over",
  OFFER_PER_YEAR: "offers per year including extended data sets.",

  //login popup
  REMEMBER_ME: "Remember my login details",
  DONT_HAVE_AN_ACCOUNT: "Don't have an account?",
  WELCOME_BACK: "Welcome back!",
  REG_WITH_EXISTING_ACC:
    "Register here with your existing IMMOZY account data.",

  ACCOUNT_CREATE: "CREATE ACCOUNT",
  LATER: "Later",
  LETS_GO: "Here we go",
  QUESTION_TOOLTIP_1: "Take the questionnaire to activate our ",
  QUESTION_TOOLTIP_2: "Matching System. ",
  QUESTION_TOOLTIP_3: "Click here to start!",
  PREPARE_TOOLTIP:
    "Prepare better to increase your chances of buying property. Click here to start now!",
  REGISTER_NOW: "Register Now!",

  // User contact feature screens
  FINANCING_DESC:
    "Verified financing increases your chances of purchasing this property many times over!",
  FINANCE_CHECK: "Financing check",
  CONTACT_SELLER: "Contact seller",
  SELLER_DESC: "Here you can contact the seller of this property.",
  IMMOZY_HELP: "IMMOZY Help",
  HELP_DESC:
    "Speak directly to an IMMOZY representative for assistance with this property.",
  CONTACT_NOW: "Contact Now",
  HELP_TO_REAL: "HELP TO REAL ESTATE",
  BROKER_MANDATE:
    "IMMOZY does not have a broker mandate, but supports the independent purchase with service and know-how.",
  DO_YOU_HAVE_QUESTION: "Do you have any questions?",
  HAPPY_TO_HELP: "We are happy to help you - competently,",
  FREE_OF_CHARGE: "Free of charge and without obligation.",
  BEST_WAY_TO_REACH: "What's the best way to reach you?",
  GENDER: "Salutation*",
  GENDER_1: "Salutation",
  FNAME_AND_LNAME: "First name / last name*",
  ENTER_YOUR_PHONE: "Enter your phone number",
  REACHABILTY: "Reachability",
  REQUIRED: "*Required",
  PLEASE_FILL_REQUIRED: "Please fill in the required fields",
  SEND: "Send",
  BACK_SMALL: "back",
  SECURELY_ENCRYPTED: "Securely encrypted via SSL",
  SECURELY_ENCRYPTED_2: "Free of charge. Secure. Without Schufa.",
  ABOUT_TO_LEAVE: "YOU ARE ABOUT TO LEAVE US!",
  ABOUT_LEAVE_WARNING_MSG:
    "You are about to leave IMMOZY, are you sure you don’t want us to help you?",
  DONT_WANT_TO_LEAVE: `I don’t want to leave`,
  THANK_YOU: "THANK YOU!",
  REQUEST_RCVD_INFORM:
    "We have received your request and will get back to you as soon as possible.",
  MEETING_ALREADY_SET:
    "You have already scheduled a meeting for this property with Immozy team. You can check you email for meeting details.",
  BOOK_DIRECT_APOINTMENT:
    "If you don't want to wait, you can book an appointment directly here.",
  BOOK: "Book",
  FIND_SELLER_INFO_BELOW: "Please find below the contact of your seller.",
  BEAWARE_FIRST_CONTACT_SELLER:
    "Be aware that a first contact with a seller might hold the possibility for misunderstandings.",
  SELLER_CONTACT_DETAILS: "SELLER CONTACT DETAILS",
  SELLER_DETAILS_DISABLED:
    "The seller has not enabled his contact information for public access.",
  CONTACT_ASAP: "We will contact you asap with the information required.",
  SELLER: "SELLER",
  NO_DETAILS_AVAILABLE: "No contact information available.",
  OFFER_FOUND_AT: "Offer found at:",
  BOOK_APPOINTMENT_WITH_US:
    "Book an appointment with your IMMOZY -Help before and let us assist you.",
  CALENDLY_TITLE: "Schedule your appointment with Immozy team",
  MR: "Mr.",
  MS: "Mrs.",
  MISCELLANEOUS: "Mx.",
  EARLY_MORNING: "8 - 10 am",
  MORNING: "10 am - 1 pm",
  AFTERNOON: "1 - 4 pm",
  EVENING: "4 - 7 pm",
  LOGING_IN_ADMIN: "Logging you in. Please wait....!",
  ENTER_PRICE: "Please enter a price.",
  CONDITION_BROKER:
    "Immozy has no broker mandate with regard to the offers linked here and is not connected in any way to the advertisers or the external platforms or their operators.",
  ONE_MORE_CLICK: "Just one click to the property",
  WE_FOUND_THIS_ON: "We found this property for you on * ",
  FOLLOW_THE_LINK_BELOW:
    "to take a closer look at the property and to contact the seller, follow the link below",
  ANY_QUESTIONS_CONTACT_US:
    "Do you have any questions or would you like support? Then please contact us",
  WE_WILL_GET_BACK:
    "We have received your request and you will receive prompt feedback so that you get the best possible support with your request.",
  BASED_ON_INFORMATION: "Based on your information, we have found",
  SUITABLE_PROPS: "suitable properties",
  ANSWER_MORE_QUESTION:
    "Answer more questions to refine your individual matching results",
  WE_CALCULATE: "We calculate your top matches based on your information",
  PROPERTY_DISABLED: "The property is no longer available",
  REG_NOW_FOR_FREE: "Register for the full service.",
  IMMOZY: "Non-binding, secure and free of charge.",
  DIVERSE_RANGE: "Diverse range of advertisements from",
  REGIONAL_SPECIALIST:
    "regional specialists and the most important real estate platforms.",
  MATCHING_ALGO_FINDS: "Our matching algorithm finds exactly",
  SUIT_NEEDS: "the properties that best suit your individual needs.",
  IF_YOU_HAVE_QUEST: "If you do have a question,",
  IMMOZY_EXPRTS_ADVICE:
    "our experienced Immozy experts will give you quick, well-founded advice.",
  WANT_PRECIE_RESULT: "Want precise results?",
  TO_THE_ONB: "To The Onboarding",
  OPEN_ONBOARDING: "Open Onboarding",
  OR: "or",
  FILL_ONBORADING:
    "First, answer a few onboarding questions so that we can recommend your top properties based on your answers.",
  EMAIL_STAR: "Email*",
  CONTACT_TITLE: "Your contact request to:",
  OPT_IN:
    "I would like a free expert check by Immozy to increase my chances of buying a property (opt.)",
  FOR_FREE: "FOR FREE",
  FREE_REUQEST_1: "Your request is free of charge,",
  NO_FEES_CHARGED: "no fees will be charged.",
  SECURE: "SECURE",
  DATA_SAFE: "Your data is safe and",
  PROCESSED_CONF: "processed confidentially",
  AFTER_YOUR_CONTACT: "After your contact request, we will contact you",
  HELP_YOU: "personally and help you immediately.",
  LONG_TEXT:
    "By sending the request you make use of the Immozy service. You will receive information tailored to your request based on the data you have entered, the services you have used and our business purpose. You can object to this service at any time at datenschutz@immozy.de. You can find more information in the data protection declaration.",
  YOUR_CONTACT_REQUEST: "Your Contact Request",
  ALL_OFFERS: "All Offers",
  ACTIVE_OFFERS: "Active Offers",
  ALL_OFFER_TITLE: "All offers, including expired and deactivated ones",
  ACTIVE_OFFER_TITLE: "All offers that are active",
  NO_OFFER_AVAILABLE: "No active offers available for this realtor",
  UNSUBSCRIBE_MESSAGE:
    "You have successfully unsubscribed from our newsletter email list.",
  SMART_FINDER: "Find smart real estate",
  PERSONAL_AND_PROFESSIONAL: "Personal & professional",
  COMPREHENSIVE_OFFER: "Comprehensive offer",
  FIRST_STEP: "Your step into your own home",

  // Immozy Home

  VERY_IMPORTANT: "Very important",
  IMPORTANT: "Important",
  LESS_IMPORTANT: "Less important",
  WHY_QUESTION_HEADING: "Why is this important?",
  UPLOAD_DOCUMENT_HEADING: "Upload your ID card for verification",
  UPLOAD_FILE_LABEL: "Select a file or drag and drop it here",
  UPLOAD_FILE_DESCRIPTION: "JPG, PNG, or PDF, file size not more than 10MB",

  // Immozy Home - new contact pop-up

  PROFILE_COMPLETION_POPUP_HEADING: "Are you well prepared?",
  PROFILE_COMPLETION_POPUP_SUBTEXT:
    "A seller of a property often gets a lot of purchase inquiries, especially for a good property in a popular location. To increase your chance of getting the property, we at Immozy want to help you prepare yourself in the best possible way to make the best pitch to the seller.",
  PROFILE_COMPLETION_POPUP_SUBTEXT_2:
    "We advise you to clarify the following issues:",
  STEPS_COMPLETED: "steps completed",
  PROVIDE_PERSONAL_INFORMATION: "Provide personal data (self-disclosure)",
  CLARIFY_PERSONAL_NEEDS: "Complete your search profile",
  CHECK_AND_SECURE_YOUR_FINANCING: "Complete financing check",
  PREPARE_PROPERTY_INSPECTION: "Prepare Real-estate inspection",
  // CONTACT_SELLER_INSTRUCTION_LABEL:
  //   "You are well prepared! Contact the provider now.",
  DONT_WANT_TO_SEE_THIS_CHECK_AGAIN: "Don’t show this page again.",
  FILL_ALL: "Please fill in all fields",
  SECURE_FINANCING_LABEL: "Secure the best financing",
  REQUEST_FREE_ADVICE: "Request free advice",
  FINANCE_ADVICE_FOOTER_TEXT:
    "By sending the request, you accept the Immozy service. You can object to this service at any time at datenschutz@immozy.de.",
  FINANCIAL_ADVICE_DESCRIPTION:
    "We find your best financing with the most suitable financing partner free of charge.",

  CONTACT_SLIDER_TITLE_1: "Comprehensive comparison of offers",
  CONTACT_SLIDER_SUBTITLE_1:
    "Compare with us more than 700 providers for real estate financing.",
  CONTACT_SLIDER_SUBTITLE_2:
    "Benefit from the best interest rates and competent advice.",
  CONTACT_SLIDER_TITLE_2: "Personal and fast",
  CONTACT_SLIDER_SUBTITLE_2_1:
    "We advise and support you with experienced experts in a personal",
  CONTACT_SLIDER_SUBTITLE_2_2:
    "conversation - which usually starts within 24 hours.",
  CONTACT_SLIDER_TITLE_3: "Free of charge and serious",
  CONTACT_SLIDER_SUBTITLE_3_1:
    "Your request and the following consultation is free of charge.",
  CONTACT_SLIDER_SUBTITLE_3_2:
    "We work together with a careful selection of the most reputable German intermediaries and financial institutions.",
  CONTACT_SLIDER_TITLE_4: "Not possible? No way!",
  CONTACT_SLIDER_SUBTITLE_4_1:
    "We have selected and verified partners for self-employed persons,",
  CONTACT_SLIDER_SUBTITLE_4_2:
    "persons without German citizenship and credit applicants with Schufa entry in our network. Not possible, does not exist with us.",
  WELCOME_TITLE: "Hello, I’m Ralf Baumann. ",
  WELCOME_LINE_1: "We have prepared a short onboarding for you.",
  WELCOME_LINE_2: "Onboarding for what?",
  WELCOME_LINE_3:
    "In order to present you with the properties that best match your wishes, the new and unique Immozy matching algorithm needs a few, but very important pieces of information from you.",
  WELCOME_LINE_4: "Confidential, secure and always DSGVO compliant.",

  //-----------------realtor info pop up------------------

  REALTOR_INFO_OPTION_1: "I would like to visit the property.",
  REALTOR_INFO_OPTION_1_TEXT:
    " I would like to visit the property and would like you to suggest an appointment.",
  REALTOR_INFO_OPTION_2: "I want to receive further documents of the property.",
  REALTOR_INFO_OPTION_2_TEXT:
    " I would be very happy if you could send me any relevant documents relating to the property.",
  REALTOR_INFO_OPTION_3: "I would like Immozy to help me.",
  I_AM_INT_TEXT: "Dear Sir or Madam, I am interested in your property.",

  // cities name
  HAM: "Hamburg",
  BER: "Berlin",
  BAV: "Bavaria",
  BA_WU: "Baden-Württemberg",
  SAXONY: "Saxony",
  BRE: "Bremen",
  NIED: "Niedersachsen",
  RH_PF: "Rheinland-Pfalz",
  SA_AN: "Saxony-Anhalt",
  HES: "Hessen",
  ME_VO: "Mecklenburg-Vorpommern",
  BRND: "Brandenburg",
  N_RH_WE: "North Rhine-Westphalia",
  SL: "Saarland",
  SC_HO: "Schleswig-Holstein",
  THUR: "Thuringia",

  THANK_YOU_FOR_REG: "Thank you for registering!",
  MAIL_SENT_MSG: `We've just sent you an email with a confirmation link that you can use to confirm your email address and account. Please check your e-mail inbox (incl. spam folder) to complete your registration.`,
  DIDNT_GET_MAIL: `Didn't receive email with confirmation link?`,
  YOUR_CONTACT_REQUEST_TO: "Your contact request to:",
  IMMOZY_CHECK: "Immozy-Check",
  COMPLETE_PROFILE: "Complete profile",
  INCREASE_CHANCES: "Increase chances",
  SEND_AGAIN: "Send again",
  SELECT_PLACEHOLDER: "Please choose an option",
  UHR: "Hr",
  UHR_AM: "am",
  UHR_PM: "pm",
  REACHABILTY_TEXT: "When is the best time to reach you?",

  PROFILE_SECTION_LABEL: "Self-disclosure",
  ONBOARDING_SECTION_LABEL: "Complete onboarding",
  FINANCING_SECTION_LABEL: "Financing",
  FIN_START: "Start financing check",

  EMPLOYED: "Employed",
  SELF_EMPLOYED: "Self-employed",
  PUBLIC_SERVICE: "Public service",
  FREELANCE: "Freelance",
  Student: "Student",
  PRIVATE_INDIVIDUAL: "Private individual",
  PENSIONER: "Pensioner",

  WE_WILL_HELP_YOU: "Any questions? We help you.",

  SCHUFA_CREDIT_CHECK_LABEL: "Upload Schufa credit check",
  SCHUFA_FIN_CHECK_LABEL: "Upload Financing confirmation",
  ALREADY_HAVE_SCHUFA_CREDIT_CHECK_LABEL:
    "Do you already have a Schufa credit check?",
  ALREADY_HAVE_FINANCING_CONFIRMATION_LABEL:
    "Do you already have a financing certificate?",

  JA: "Yes",
  NEIN: "No",
  DONE: "Done",
  THANK_YOU_HEADING: "Thank you for your request!",
  THANK_YOU_TEXT:
    "We have received your request and will get back to you as soon as possible so that we can help you with your request as soon as possible.",

  //immozy qualify
  SECURE_FREE_CONSULT: "Secure a free consultation",
  HI: "Hi",
  PROFILE_TOOLTIP:
    "In order for the provider to be able to contact you in the best possible way, they need some information from you",
  ONB_TOOLTIP:
    "In order for our matching algorithm to be able to identify tailor-made offers for you, we need some information about your requirements.",
  FINANCE_TOOLTIP:
    "Answer a few finance-related questions so we can find you the best possible deals and partners.",

  LOCATION_30:
    "The residential area does not correspond very much to your wishes.",
  LOCATION_60: "The residential area partly corresponds to your wishes",
  LOCATION_100: "The residential area corresponds very well to your wishes.",
  FINANCE_30:
    "The purchase costs could be significantly too high for your budget",
  FINANCE_60: "The purchase costs might be a bit too high for your budget.",
  FINANCE_100: "The purchase costs fit your budget well.",
  REQUIRE_30: "The equipment does not fit your needs as much.",
  REQUIRE_60: "Some of the equipment fits your needs.",
  REQUIRE_100: "The equipment suits your requirements very well.",

  PROFILE_TRUE: "You've prepared all the helpful data.",
  PROFILE_FALSE: "Your personal information is still missing.",
  ONB_TRUE: "We checked the suitability of the property.",
  ONB_FALSE:
    "Complete <a href='/onboarding' target='_blank' >onboarding</a> to verify property fit.",
  FIN_ONB_TRUE: "You have checked your financing.",
  FIN_ONB_FALSE:
    "Complete the financing check to have all financing options checked.",
  FIN_UPLOAD_TRUE: "You can present a financing certificate.",
  FIN_UPLOAD_FALSE:
    "You may be missing another confirmation of financing certificate.",
  CONTACT_PROVIDER: "Contact the Realtor",
  USE_LINK_BELOW:
    "We found this property for you on an external platform. To take a closer look at the property and to contact the property owner, use a link below:",
  UPLOAD_SCHUFA: "Upload your Schufa-CreditReport now.",
  UPLOAD_FINANCE: "Upload your financing confirmation now",
  PREPARE: "Prepare",
  WE_HELP_PREPARE:
    "We help you to prepare yourself in the best possible way for the first contact with realtors. ",
  WE_HELP_PREPARE_1:
    "Click through the steps to complete them and increase your level of preparation.",
  OKAY: "Okay",
  START_ONBOARDING: "Start onboarding",
  INVALID_INPUT_FIELDS: "Please enter valid details.",
  DATE_ERROR: `For using Immozy, please ensure that you‘re at least 18 years old.`,
  CITY_ERROR: "Please enter in which city you live.",
  STREET_ERROR: "Please enter the street name and number where you live.",
  POSTAL_CODE_ERROR: "Please enter a valid postal code.",
  REQUEST_FREE_CONSULATION: "Request free consultation",
  BETTER_PREPARED: "Better prepared with Immozy",
  HEADER_30: "You should prepare better",
  HEDAER_60: "Prepare better",
  HEADER_80: "Well prepared",
  SUB_HEADER_30:
    "Increase your chances of winning this property by completing the items on the left.",
  SUB_HEADER_60:
    "Increase your chances for buying this property by providing more details.",
  SUB_HEADER_80:
    "Through your preparation you have significantly increased your chances of buying this property - great!",
  COMPLETE: "Complete",
  ENCRYPTED_SSL: "Encrypted with SSL",
  PERSONAL_DATA: "Personal data",
  CONTACT_DATA: "Contact data",
  DATA_SAVED: "Data has been saved.",
  FIN_CHECK_COMPLETED: "Financing check completed - great!",
  WE_WILL_HELP:
    "If you need further assistance, feel free to contact us at any time and we will help you immediately.",
  GO_BACK_TO_OBJ: "Go back to your last viewed property",
  YOUR_PWD_MUST_HAVE: "Your password should include the following:",
  NOT_ALLOWED: "not allowed",
  TO_THE_OBJECT: "To the object",
  LAST_VIEWED_PROPERTY: "Your last viewed property",
  YOUR_PREPARATION_STATUS: "Your preparation status",

  //------------- Password Assistant ---------------------------------------
  LOWER_CASE_CONTRAINT: "Lowercase letters",
  UPPER_CASE_CONTRAINT: "Uppercase letters",
  DIGIT_CONTRAINT: "At least one number",
  SPECIAL_CHARACTER_CONTRAINT: "At least one special character",
  EIGHT_OR_MORE_CHARACTER_CONTRAINT: "8 or more characters",

  //----------------------------------------------------

  AND: "and",
  CALENDER_PLACEHOLDER: "DD/MM/YYYY",
  SOCIAL_LOGIN_ERROR:
    "Not able to sign in using this platform. Please try with some other way.",
  SOCIAL_LOGIN_ERROR_EMAIL:
    "Email is mandatory for the social account login. Please try with some other way.",
  ENTER_NEW_PWD: "Enter new password",
  NO_ENTRY_AVAIALBLE: "No entries available",
  PERSONAL_DATA_TOOLTIP:
    "In order for sellers to know who you are, they need some information about you.",
  CONTACT_TOOLTIP:
    "In order for sellers to know when and how to contact you, they need your contact information.",
  CONTINUE_WITH: "or continue with",
  REGISTER_NOW_LINK: "Register now",

  SEND_REQUEST: "Send request",
  CHARACTERS: "characters",
  WRITE_PERSONAL_MESSAGE: "Write personal message *",
  REALTOR_HANDSHAKE_TERMS_TEXT: `<p>By submitting the request, you are using the Immozy service. You will receive information based on the data you have entered, the services you have used and our business purpose. You may opt out of this service at any time at <a href="mailto:datenschutz@immozy.de">datenschutz@immozy.de</a>. Further information can be found in the privacy policy.</p>`,
  SAVE_DATA: "Save Data",
  BACK_TO_REQUEST: "Back to Request",
  NEED_INFO: "For your request we need some necessary information.",
  COMPLETE_MISSING_INFO: "Complete missing information",
  CHECK_DATA_AGAIN: "Check your previously entered data again before sending.",
  CHECK_DETAILS: "Check details",

  //----------------------- Realtor Handshake PDF -----------------------------------------

  HANDSHAKE_PDF_HEADING: "INFORMATION FOR INTERESTED PARTIES",

  SEARCH_PREFERENCES: "Search preferences",
  FINANCIAL_CONDITIONS: "Financial conditions",

  REASON_FOR_BUYING_PROPERTY: "Reason for buying a property",
  DESIRED_TYPE_OF_PROPERTY: "Desired type of property",
  DESIRED_RESIDENTAL_LOCATION: "Desired residential location",
  PARTICULARLY_IMPORTANT_FOR_THE_RESIDENTIAL_LOCATION:
    "Particularly important for the residential location",
  DESIRED_LIVING_SPACE: "Desired living space",
  DESIRED_NUMBER_OF_ROOMS: "Desired number of rooms",

  AVAILABLE_EQUITY: "Available equity",
  OWNER_OF_PROPERTY: "Owner of property",
  NOT_CLEAR_YET: "Not clear yet?",
  MAXIMUM_PURCHASE_PRICE: "Maximum purchase price",
  GERMAN_CITIZENSHIP: "German citizenship",
  PURCHASE_WITH_PARTNER: "Purchase with partner",
  MONTHLY_NET_INCOME: "Monthly net income",

  FINANCING_CERTIFICATE_AVAILABLE: "Financing certificate available?",

  BEST_ACHIEVABLE: "Best achievable",
  IN_BTWN: "In between ",
  OCLOCK_BY_PHONE: "o'clock by phone",
  CONTACT: "Contact",
  BORN: "Born",
  COMPLETE_QUALIFY_WARNING:
    "Please complete Immozy Qualify in order to view or download pdf.",
  DOWNLOAD_PDF: "Download self-assessment",
  INCREASE_CHANCES_VISIT: "Increase your chances of a visit!",
  SHARE_AS_PDF:
    "Share your self-assessment with sellers via a link or as a PDF:",
  COPY: "Copy",
  PREVIEW_LINK: "Preview link in new tab",
  URL_COPIED: "URL copied",
  PREMIUM_PROPERTY: "Premium property",
  FORCLOSURE_SALE: "Foreclosure sale",
  DIRECT_INVENTORY_TOOLTIP:
    "Here the trustful and fast processing of your inquiry is guaranteed: The object is offered by an Immozy verified partner.",
  FORCLOSURE_TOOLTIP:
    "This property is a foreclosure auction sale. The purchase price of the property is probably a starting price of the auction and will most likely increase, which could distort the matching score.",
  QUALIFY_PDF_WARNING:
    "To be able to download your self-disclosure, you need a preparation status of 100 %.",
  GO_TO_QUALIFY: "» Increase preparation status now",

  OTP_VERIFICATION: "Just One More Thing",
  CODE_SENT_TO_MOBILE: "Confirm your phone number to complete you request.",
  CODE_SENT_TO_MOBILE_1: "We sent a verification code to ",
  VERIFY: "Verify",
  RESEND_OTP: "Resend",
  SKIP_OTP: "Skip",
  OTP_VALID: "OTP is valid for ",
  SECONDS: " seconds",
  ENTER_VALID_OTP: "Enter valid OTP",
  TRY_AGIN_LATER: "Please try again later.",

  ENERGY_DEMAND: "Energy Demand",
  EN_CONST_YEAR: "Construction Year",
  HEATING_TYPE: "Heating Type",
  PRIMARY_EN_SOURCE: "Primary Energy Source",
  BETTER_RESULTS: "Better results",
  BETTER_RES_SUBTITLE:
    "Our matching algorithm + finds suitable properties individually for you. Fast and uncomplicated.",
  ACTIVATE_NOW: " Activate now",
  NO_ENERGY_CERT: "No energy certificate available.",
  ENERGY_HEATING: "Energy & Heating",
};

export default englishText;
