import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from "react-router-dom";
// component
import { toast } from "react-toastify";
// functions
import englishText from "languages/english/english";
import getLocalizeText from "utils/getLocalizeText";
import HttpService from "services/http.service";
import { API_CONFIG } from "services/api";
// assets
import { Logo } from "components/commonComponents/icons";
import "../auth.scss";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");

  const handleSubmit = () => {
    sendMail(email);
  };

  const sendMail = async (email: string) => {
    HttpService.post(API_CONFIG.path.forget, { email })
      .then((response) => {
        // Email sent successfully
        const msg = response.message
          ? response.message
          : englishText.EMAIL_SENT_SUCCESS;
        toast.success(getLocalizeText(msg));
      })
      .catch((err) => {
        //Email sent failed, show error message
        const errorMessage = err.response
          ? err.response.data.message
          : getLocalizeText(englishText.SOMETHING_WENT_WRONG);
        toast.error(errorMessage);
      });
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  };

  return (
    <div className="forgot-password-container">
      <div className="logo-imz">
        <Logo />
      </div>
      <div className="email-verify-container">
        <p className="verify-title mt-2 mb-2">
          <FormattedMessage
            id={englishText.FORGOT_PWD}
            defaultMessage={englishText.FORGOT_PWD}
          />
        </p>
        <p className="verify-subtitle mt-2 mb-2">
          <FormattedMessage
            id={englishText.FORGOT_PWD_SUBTITLE}
            defaultMessage={englishText.FORGOT_PWD_SUBTITLE}
          />
        </p>
        <div className="form-group form-primary mt-5">
          <div className="d-flex align-items-center" style={{ height: "45px" }}>
            <label className="input-label">E-mail</label>
          </div>
          <input
            id="inputID"
            type="text"
            name="email"
            placeholder={getLocalizeText(englishText.EXAMPLE_EMAIL)}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setEmail(event.target.value)
            }
            value={email}
            autoComplete="none"
            onKeyDown={onKeyDown}
            className="login-input-box"
          />
          <span className="form-bar" />
        </div>
        <button
          className="btn mail-send-btn mt-5"
          onClick={handleSubmit}
          disabled={email === ""}
        >
          <FormattedMessage
            id={englishText.SEND_EMAIL}
            defaultMessage={englishText.SEND_EMAIL}
          />
        </button>
        <div className="d-flex align-items-center justify-content-between link-section">
          <Link to="/login">
            <FormattedMessage
              id={englishText.LOGIN}
              defaultMessage={englishText.LOGIN}
            />
          </Link>
          <Link to="/signup">
            <FormattedMessage
              id={englishText.REGISTRATION}
              defaultMessage={englishText.REGISTRATION}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
