import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
// Components
import { Form } from "react-bootstrap";
import CustomSpinner from "components/commonComponents/customSpinner";
import PhoneInput from "react-phone-input-2";
import Select, { StylesConfig } from "react-select";
import Slider from "react-slick";
import { OtpVerifyPopup } from "./otpVerifyPopup";
// functions
import englishText from "languages/english/english";
import { API_CONFIG, HttpService } from "services";
import {
  addGoogleAdsPixelEvent,
  lang,
  reactSelectStyles,
  sendAmplitudeDataEvent,
  checkValidation,
  getLocalizeText,
} from "utils";
import { reachabilityOptions } from "components/immozyHome/utils/constantsAndFunctions";
import { OptionsInterface } from "components/immozyHome/interface/immozyhome";
import { userContactProps } from "components/objectDetails/interface/objectDetails";
import de from "react-phone-input-2/lang/de.json";
// assets
import maillogo from "assets/images/mail-logo.svg";
import sliderImage1 from "assets/images/image4.png";
import sliderImage2 from "assets/images/Group16.png";
import sliderImage3 from "assets/images/Kostenlos.png";
import sliderImage4 from "assets/images/image5.png";
import { Logo } from "components/commonComponents/icons";
import calendly from "assets/images/calendly-icon.png";
import "react-phone-input-2/lib/material.css";

let counrtrycode = "de";

const selectedLang = localStorage.getItem("language");

const ContactScreen: React.FC<any> = (props) => {
  const [showFullLoader, setshowFullLoader] = useState(false);
  const [errorFields, seterrorFields] = useState<any[]>([]);
  const [checkProfile, setcheckProfile] = useState(true);
  const [contactDetails, setContactDetails] = useState<userContactProps>({
    firstName: "",
    lastName: "",
    phone: "",
    gender: "",
    contact_email: "",
    reachability: "",
    agreed: false,
  });
  const [apiError, setapiError] = useState("");
  const [showThanks, setshowThanks] = useState(false);
  const [showOtpMpdal, setshowOtpMpdal] = useState(false);

  useEffect(() => {
    props.fromCalc && checkProfile && getProfileInfo();
  });

  const getProfileInfo = () => {
    HttpService.get(API_CONFIG.path.profile)
      .then((response) => {
        const details = response.data;
        if (details) {
          const contactDetails = {
            firstName: details.firstName || "",
            lastName: details.lastName || "",
            phone: details.phone || "",
            gender: details.gender || "",
            contact_email: details.email || details.contact_email || "",
            reachability: details.reachability || "",
            agreed: details.agreed || false,
          };
          localStorage.setItem(
            "name",
            details.firstName + " " + details.lastName
          );
          localStorage.setItem("email", details.email || "");
          setContactDetails(contactDetails);
        }
        setcheckProfile(false);
      })
      .catch(() => ({ message: "something went wrong" }));
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.type === "radio" ? "gender" : e.target.name;
    const value = e.target.value;
    setContactDetails((contactDetails) => ({
      ...contactDetails,
      [name]: value,
    }));
    errorFields.length > 0 && seterrorFields([]);
  };

  const hanldeSubmit = () => {
    if (props.fromCalc) {
      delete contactDetails.reachability;
    }
    const errorFields: any = checkValidation(contactDetails);
    apiError !== "" && setapiError("");

    if (errorFields && errorFields.length === 0) {
      // For  German numbers proceed for OTP verification
      if (["de", "in"].includes(counrtrycode) && !props.fromCalc) {
        sendOtpToMobile();
      } else {
        // In other case directly send contact request
        sendContactRequest(false);
      }
    } else {
      seterrorFields(errorFields);
    }
  };

  const sendContactRequest = (verified: boolean) => {
    setshowFullLoader(true);
    setshowOtpMpdal(false);
    let payload: any = contactDetails;

    if (props.fromCalc) {
      payload = {
        ...payload,
        isVerified: verified,
        context: "mortgage-calculator",
      };
    } else {
      payload = {
        ...payload,
        isVerified: verified,
        context: "wordpress-mortgage-calculator",
      };
    }
    const url = props.fromCalc
      ? API_CONFIG.path.updateContact
      : API_CONFIG.path.updateGuestContact;

    HttpService.put(`${url}`, payload)
      .then(() => {
        if (!props.fromCalc) {
          localStorage.setItem(
            "name",
            contactDetails.firstName + " " + contactDetails.lastName
          );

          addGoogleAdsPixelEvent();
          const meta = {
            Action: "Contact Requested form wordpress page",
          };
          sendAmplitudeDataEvent(
            "send_contact_from_finance",
            meta,
            "",
            "GUEST"
          );
        }
        setshowFullLoader(false);
        setshowThanks(true);
      })
      .catch((err) => {
        setapiError(
          err.response.data
            ? err.response.data.message
            : getLocalizeText(englishText.SOMETHING_WENT_WRONG)
        );
        setshowFullLoader(false);
        console.log(err);
      });
  };

  const setChecked = (value: any) => {
    console.log(value);
  };

  const sendOtpToMobile = () => {
    setshowFullLoader(true);
    const payload = {
      phone: "+" + contactDetails.phone,
    };

    HttpService.post("v1/profile/sendSms", payload)
      .then(() => {
        setshowOtpMpdal(true);
        setshowFullLoader(false);
      })
      .catch(() => {
        sendContactRequest(false);
      });
  };

  const nameFields = [
    {
      label: englishText.FIRST_NAME,
      name: "firstName",
      placeholder: englishText.FIRST_NAME,
      value: contactDetails.firstName,
    },
    {
      label: englishText.LAST_NAME,
      name: "lastName",
      placeholder: englishText.LAST_NAME,
      value: contactDetails.lastName,
    },
  ];

  const contactFields = [
    {
      label: englishText.EMAIL,
      name: "contact_email",
      placeholder: englishText.EMAIL,
      value: contactDetails.contact_email,
    },
    {
      label: englishText.PHONE,
      name: "phone",
      placeholder: "+49 | 0000000000",
      value: contactDetails.phone,
    },
  ];

  return (
    <div
      className={
        window.location.pathname.includes("contact")
          ? "contact-iframe-container "
          : ""
      }
    >
      {showFullLoader && (
        <div className="onboarding-spinner-wrapper d-flex align-items-center justify-content-center">
          <CustomSpinner />
        </div>
      )}
      <div
        className="new-contact-page new-interested-popup d-flex fade-in interested-main-container"
        style={showFullLoader ? { opacity: "0.5" } : {}}
      >
        <div className="first-part d-flex align-items-start justify-content-center flex-column">
          <div
            style={{ width: "80px", marginBottom: "20px" }}
            className="logo-wrapper"
          >
            <Logo />
          </div>
          {!showThanks ? (
            <div className="login-form-popup fade-in">
              <p className="guest-headline" style={{ fontSize: "32px" }}>
                <FormattedMessage
                  id={englishText.SECURE_FINANCING_LABEL}
                  defaultMessage={englishText.SECURE_FINANCING_LABEL}
                />
              </p>
              <p className="logo-text-popup">
                <FormattedMessage
                  id={englishText.FINANCIAL_ADVICE_DESCRIPTION}
                  defaultMessage={englishText.FINANCIAL_ADVICE_DESCRIPTION}
                />
              </p>
              <div>
                <div className="radio-container d-flex align-items-center justify-content-start mb-3 mt-3">
                  <p className="contact-form-label mr-3 login-label-popup">
                    <FormattedMessage
                      id={englishText.GENDER}
                      defaultMessage={englishText.GENDER}
                    />
                  </p>
                  <div className="d-flex ">
                    {genderObj.map((radioOptions, i) => (
                      <div
                        className={`option-text mr-4 ${
                          errorFields.length > 0 &&
                          errorFields.includes("gender") &&
                          "error-border-round"
                        }`}
                        key={i}
                        onClick={() => setChecked(radioOptions)}
                      >
                        <Form.Check
                          type="radio"
                          custom
                          label={getLocalizeText(radioOptions.title)}
                          name="formHorizontalRadios"
                          id={`formHorizontalRadios1${i}`}
                          checked={contactDetails.gender === radioOptions.value}
                          onChange={handleInputChange}
                          value={radioOptions.value}
                        />
                      </div>
                    ))}
                  </div>
                </div>

                <div className="d-flex align-items-center mb-3">
                  {nameFields.map((field: any, index: number) => (
                    <div
                      className={`form-group form-primary w-50 mt-2 mb-2 ${
                        index === 0 ? "mr-2" : ""
                      }`}
                      key={index}
                    >
                      <p className="contact-form-label mb-2 login-label-popup">
                        {getLocalizeText(field.label) + "*"}
                      </p>
                      <input
                        className={`login-input-box ${
                          errorFields.length > 0 &&
                          errorFields.includes(field.name) &&
                          "error-border"
                        }`}
                        key={index}
                        id="inputID"
                        autoFocus={field.name === "firstName"}
                        type={"text"}
                        name={field.name}
                        value={field.value}
                        placeholder={getLocalizeText(field.placeholder)}
                        onChange={handleInputChange}
                      />
                    </div>
                  ))}
                </div>

                <div className="mb-2">
                  {contactFields.map((field: any, index: number) => (
                    <div
                      key={index}
                      className="form-group form-primary full-width mt-2 mb-4"
                    >
                      <p className="contact-form-label mb-2 login-label-popup">
                        {getLocalizeText(field.label)}*
                      </p>
                      {field.name === "phone" ? (
                        <PhoneInput
                          country={"de"}
                          localization={
                            selectedLang === lang.GR ? de : undefined
                          }
                          inputClass={`login-input-box ${
                            errorFields.length > 0 &&
                            errorFields.includes(field.name) &&
                            "error-border"
                          }`}
                          inputStyle={{ paddingLeft: "65px", height: "54px" }}
                          autoFormat={true}
                          inputProps={{
                            name: field.name,
                          }}
                          // buttonStyle={{ borderWidth: "2px" }}
                          enableSearch
                          placeholder={""}
                          value={field.value}
                          onChange={(phone, data: any) => {
                            handleInputChange({
                              target: { value: phone, name: field.name },
                            } as React.ChangeEvent<HTMLInputElement>);
                            if (counrtrycode !== data && data.countryCode) {
                              counrtrycode = data.countryCode;
                            }
                          }}
                          searchPlaceholder={getLocalizeText(
                            englishText.SEARCH
                          )}
                          searchNotFound={getLocalizeText(
                            englishText.NO_ENTRY_AVAIALBLE
                          )}
                        />
                      ) : (
                        <input
                          className={`login-input-box ${
                            errorFields.length > 0 &&
                            errorFields.includes(field.name) &&
                            "error-border"
                          }`}
                          key={index}
                          id="inputID"
                          type={"text"}
                          name={field.name}
                          value={field.value}
                          placeholder={getLocalizeText(field.placeholder)}
                          onChange={handleInputChange}
                        />
                      )}
                    </div>
                  ))}
                  {!props.fromCalc && (
                    <div className="form-group form-primary reach-select-container">
                      <p className="contact-form-label mb-2 login-label-popup">
                        {getLocalizeText(englishText.REACHABILTY)}*
                      </p>
                      <div>
                        <Select
                          className={` ${
                            errorFields.length > 0 &&
                            errorFields.includes("reachability") &&
                            "error-border"
                          }`}
                          placeholder={getLocalizeText(
                            englishText.REACHABILTY_TEXT
                          )}
                          name="reachability"
                          options={reachabilityOptions}
                          value={
                            (reachabilityOptions as OptionsInterface[]).find(
                              (option: OptionsInterface) =>
                                option.value === contactDetails.reachability
                            ) || ""
                          }
                          styles={reactSelectStyles as StylesConfig}
                          onChange={({ value }: any, { name }: any) => {
                            setContactDetails({
                              ...contactDetails,
                              [name]: value,
                            });
                            errorFields.length > 0 && seterrorFields([]);
                          }}
                        />
                      </div>
                      <span className="form-bar" />
                    </div>
                  )}
                </div>

                <div
                  className={`custom-checkbox checkbox-fade fade-in-primary mt-2 w-100 ${
                    contactDetails.agreed ? "checked" : ""
                  }`}
                >
                  <div className="btn-new-int d-flex justify-content-center mb-3 mt-3">
                    <button
                      className="btn primary-btn submit-btn"
                      onClick={() => hanldeSubmit()}
                    >
                      <FormattedMessage
                        id={englishText.REQUEST_FREE_ADVICE}
                        defaultMessage={englishText.REQUEST_FREE_ADVICE}
                      />
                    </button>
                  </div>
                  <p className="secure-label text-center  mb-2 mt-2">
                    <i className="fa fa-lock mr-2" aria-hidden="true" />
                    <FormattedMessage
                      id={englishText.SECURELY_ENCRYPTED_2}
                      defaultMessage={englishText.SECURELY_ENCRYPTED_2}
                    />
                  </p>
                  {errorFields.length > 0 && (
                    <p className="error-contact">
                      <FormattedMessage
                        id={englishText.PLEASE_FILL_REQUIRED}
                        defaultMessage={englishText.PLEASE_FILL_REQUIRED}
                      />
                    </p>
                  )}
                  {apiError !== "" && (
                    <p className="error-contact">{apiError}</p>
                  )}
                </div>
                <p className="long-desc-text text-center mt-3">
                  {getLocalizeText(englishText.FINANCE_ADVICE_FOOTER_TEXT)}
                </p>
              </div>
            </div>
          ) : (
            <div className="success-info fade-in">
              <p className="line-1" style={{ fontSize: "32px" }}>
                <FormattedMessage
                  id={englishText.THANK_YOU}
                  defaultMessage={englishText.THANK_YOU}
                />
              </p>
              <p className="line-2">
                <FormattedMessage
                  id={englishText.WE_WILL_GET_BACK}
                  defaultMessage={englishText.WE_WILL_GET_BACK}
                />
              </p>
              <div className="text-center">
                <img src={maillogo} alt="img" style={{ width: "100px" }} />
              </div>
              {props.fromCalc && (
                <>
                  <p className="line-3 mt-3">
                    <FormattedMessage
                      id={englishText.BOOK_DIRECT_APOINTMENT}
                      defaultMessage={englishText.BOOK_DIRECT_APOINTMENT}
                    />
                  </p>
                  <div className="d-flex justify-content-center mt-3 align-items-center">
                    <button
                      className="btn primary-btn"
                      style={{ margin: "unset", height: "46px" }}
                      onClick={props.openCalendly}
                      // disabled={isMeetAlreadySet}
                    >
                      <FormattedMessage
                        id={englishText.BOOK}
                        defaultMessage={englishText.BOOK}
                      />
                    </button>
                    <img className="calendly" src={calendly} alt="img" />
                  </div>
                </>
              )}
            </div>
          )}
        </div>
        <div className="second-part d-flex position-relative">
          <div className="slider-wrapper position-relative">
            <Slider {...settings}>
              {detailsData.map((detail, index) => (
                <div
                  className="slide d-flex justify-content-center align-items-center flex-column"
                  key={index}
                >
                  <img
                    src={detail.img}
                    alt="img"
                    style={{
                      maxWidth: "80%",
                      width: "500px",
                      minHeight: props.fromPopup ? "425px" : "250px",
                      objectFit: "contain",
                    }}
                  />
                  <p className="big-text mt-3">
                    {getLocalizeText(detail.title)}
                  </p>
                  <p className="small-text d-flex justify-content-center align-items-center flex-column">
                    <span>
                      {getLocalizeText(detail.subtitle1)}{" "}
                      {getLocalizeText(detail.subtitle2)}
                    </span>
                  </p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      {showOtpMpdal && (
        <OtpVerifyPopup
          contactDetails={contactDetails}
          setshowOtpMpdal={setshowOtpMpdal}
          sendContactRequest={sendContactRequest}
          sendOtpToMobile={sendOtpToMobile}
        />
      )}
    </div>
  );
};

export default ContactScreen;

const detailsData = [
  {
    title: englishText.CONTACT_SLIDER_TITLE_1,
    subtitle1: englishText.CONTACT_SLIDER_SUBTITLE_1,
    subtitle2: englishText.CONTACT_SLIDER_SUBTITLE_2,
    img: sliderImage1,
  },
  {
    title: englishText.CONTACT_SLIDER_TITLE_2,
    subtitle1: englishText.CONTACT_SLIDER_SUBTITLE_2_1,
    subtitle2: englishText.CONTACT_SLIDER_SUBTITLE_2_2,
    img: sliderImage2,
  },
  {
    title: englishText.CONTACT_SLIDER_TITLE_3,
    subtitle1: englishText.CONTACT_SLIDER_SUBTITLE_3_1,
    subtitle2: englishText.CONTACT_SLIDER_SUBTITLE_3_2,
    img: sliderImage3,
  },
  {
    title: englishText.CONTACT_SLIDER_TITLE_4,
    subtitle1: englishText.CONTACT_SLIDER_SUBTITLE_4_1,
    subtitle2: englishText.CONTACT_SLIDER_SUBTITLE_4_2,
    img: sliderImage4,
  },
];

const genderObj = [
  {
    title: englishText.MR,
    value: "MALE",
  },
  { title: englishText.MS, value: "FEMALE" },
];

const settings = {
  draggable: true,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 6000,
  speed: 900,
  infinite: true,
  pauseOnHover: true,
};
