import React, { lazy } from "react";
import { Switch, BrowserRouter } from "react-router-dom";
import PrivateRoute from "./privateRouter";
import PublicRoute from "./publicRoute";
import QualifyPDF from "components/qualifyPDF/container/qualifyPDF";
import VerifyUser from "components/auth/components/verifyUser";
import LandingPage from "components/landingPage/landing";
import ObjectDetails from "components/objectDetails/container/objectDetails";
import AuthScreenSocial from "components/landingPage/authScreenWithSocial";
import MailLogin from "components/commonComponents/mailLogin";
import OnboardingContainer from "components/onboarding-new/container/onboarding";
import WelcomeContainer from "components/onboarding-new/component/welcome";
import RealtorListing from "components/realtor/realtorListing";
import ThanksPage from "components/onboarding-new/component/thanksPage";
import ContactScreen from "components/objectDetails/components/popups/contactScreenForWordpress";
import Unsubscribe from "components/commonComponents/unsubscribe";
import ImmozyHome from "components/immozyHome/container/immozyHome";
import Calculator from "components/calculator/container/calculator";
import Favorites from "components/dashboard/container/favorites";
import VerifyEmail from "components/auth/components/verifyEmailAuth";
import ForgotPassword from "components/auth/components/forgotPasword";
import ResetPassword from "components/auth/components/resetPassword";

const AdminLogin = lazy(() => import("components/commonComponents/adminLogin"));
const Dashboard = lazy(
  () => import("components/dashboard/container/dashboard")
);

const router: React.FC = () => (
  <BrowserRouter>
    <Switch>
      {/* Public routes */}
      <PublicRoute path="/admin-login/:token/:userId" component={AdminLogin} />
      <PublicRoute path="/mail/:token" component={MailLogin} />
      <PublicRoute path="/forgot-password" component={ForgotPassword} />
      <PublicRoute path="/reset-password/:token" component={ResetPassword} />
      <PublicRoute path="/welcome" component={WelcomeContainer} />
      <PublicRoute
        path={["/landing-page", "/landing-page/:wp", "/landing-page-first"]}
        component={LandingPage}
      />
      <PublicRoute
        path={["/signup", "signup/:city", "/login", "/dev-login"]}
        component={AuthScreenSocial}
      />
      <PublicRoute path="/activation/:token" component={() => <VerifyUser />} />
      <PublicRoute path="/mortgage-calculator" component={Calculator} />
      <PublicRoute path="/unsubscribe/:id" component={Unsubscribe} />
      <PublicRoute path="/baufinanzierungsrechner" component={Calculator} />
      <PublicRoute path="/realtor/:id" component={RealtorListing} />
      <PublicRoute path="/finder-redirect" component={Dashboard} />
      <PublicRoute path="/contact" component={ContactScreen} />
      <PublicRoute path="/verify" component={VerifyEmail} />
      <PublicRoute
        path={"/realtor-object-detail/:id"}
        component={ObjectDetails}
      />
      <PublicRoute
        path={["/onboarding", "/onboarding/:edit"]}
        component={OnboardingContainer}
      />
      <PublicRoute path="/user-profile/:userid" component={QualifyPDF} />
      {/* Private routes */}
      <PrivateRoute
        path={["/object-detail/:id", "/object-detail/:id/:mail"]}
        component={ObjectDetails}
      />
      <PrivateRoute path="/favourites" component={Favorites} />
      <PublicRoute path="/thank-you" component={ThanksPage} />
      <PrivateRoute path="/calculator" component={Calculator} />
      <PrivateRoute path={["/prepare"]} component={ImmozyHome} />
      <PrivateRoute
        path={["/", "/find", "/find/:pageName"]}
        component={Dashboard}
      />
    </Switch>
  </BrowserRouter>
);

export default router;
