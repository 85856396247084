import React from "react";

const getRange = (count: number | string) => {
  const array = [];
  for (let index = 0; index < count; index++) {
    array.push(index);
  }
  return array;
};

function Separator(props: any) {
  return (
    <React.Fragment>
      <div
        style={{
          position: "absolute",
          height: "100%",
          transform: `rotate(${props.turns}turn)`,
        }}
      >
        <div style={props.style} />
      </div>
    </React.Fragment>
  );
}

const RadialSeparators: any = (props: any) => {
  const turns = 1 / props.count;
  return getRange(props.count).map((index) => (
    <Separator turns={index * turns} key={index} style={props.style} />
  ));
};

export default RadialSeparators;
