import axios, { AxiosResponse, Method } from 'axios';
import { ResponseObj } from 'utils/globalInterface';
import { getUrl } from './api';

const axiosInstance = axios.create();
const CancelToken = axios.CancelToken;

const lang = localStorage.getItem('language') || 'de';
const ampSessionId = localStorage.getItem('ampSessionId');
const ampDeviceId = localStorage.getItem('ampDeviceId') || undefined;

let cancel_req: any;

export { cancel_req };

export interface AxiosParams {
    method: Method | undefined;
    url: string;
    data?: any;
    contentType?: string;
    isLogin?: boolean;
}

export interface MiscellaneousRequestParams {
    contentType?: string;
}

/**
 * get method
 * @param request object containing axios params
 */
const get = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
    commonAxios({ method: 'GET', url: getUrl(url, params), ...otherData });

/**
 * post method
 * @param request object containing axios params
 */
const post = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
    commonAxios({ method: 'POST', url: getUrl(url), data: params, ...otherData });

/**
 * put method
 * @param request object containing axios params
 */
const put = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
    commonAxios({ method: 'PUT', url: getUrl(url), data: params, ...otherData });

/**
 * deleteRequest method
 * @param request object containing axios params
 */
const deleteRequest = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
    commonAxios({
        method: 'DELETE',
        url: getUrl(url),
        data: params,
        ...otherData,
    });

/**
 * patch method
 * @param request object containing axios params
 */
const patch = (url: string, params: any = {}, otherData: MiscellaneousRequestParams = {}) =>
    commonAxios({
        method: 'PATCH',
        url: getUrl(url),
        data: params,
        ...otherData,
    });

/**
 * commonAxios
 * @param object containing method, url, data, access token, content-type, isLogin
 */
const commonAxios = ({ method, url, data, contentType = 'application/json' }: AxiosParams): Promise<any> => {
    const headers: any = {
        'Content-Type': contentType,
        language: lang,
        ampSessionId,
        ampDeviceId,
    };

    const token = localStorage.getItem('token') || sessionStorage.getItem('token');

    if (token) {
        headers['x-access-token'] = `${token}`;
    }

    let body: any = null;

    if (contentType === 'application/json') {
        body = JSON.stringify(data);
    } else {
        body = data;
    }

    return new Promise((resolve, reject) => {
        axiosInstance({
            method: method,
            url: url,
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel_req = c;
            }),
            headers: headers,
            data: body,
        })
            .then((response: AxiosResponse<ResponseObj<any>>) => {
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            });
    });
};

export { axiosInstance };

export const HttpService = {
  get: get,
  post: post,
  put: put,
  deleteRequest: deleteRequest,
  patch: patch,
};

export default HttpService;
