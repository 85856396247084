import callAPI from "services/callApi";

export const getTopResultsCount = (
  locationsScoreFrom = 0,
  requirementsScoreFrom = 0,
  financialsScoreFrom = 0,
  totalScoreFrom = 0,
  count = true
) => ({
  type: "FETCH_TOP_RESULTS_COUNT",
  payload: callAPI(`v4/objects`, "POST", {
    locationsScoreFrom,
    requirementsScoreFrom,
    financialsScoreFrom,
    totalScoreFrom,
    count,
  }),
});


export const changeCity = (city: string) => ({
  type: "CHNAGE_CITY",
  payload: callAPI(`v4/objects/searchcity/${city}`, "PUT", {}),
});
