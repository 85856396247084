import React from "react";
import { Link } from "react-router-dom";
import { Logo } from "components/commonComponents/icons";

interface ILogoProps {
  showFullLoader: boolean;
}

const LogoComponent: React.FC<ILogoProps> = (props) => {
  const { showFullLoader } = props;
  return (
    <div style={{ width: "159px" }}>
      <Link to={!showFullLoader ? "/find" : window.location.pathname}>
        <div className="logo-img">
          <Logo />
        </div>
      </Link>
    </div>
  );
};

export default LogoComponent;
