import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
// components
import LogoComponent from "../components/logoComponent";
import LayoutPicker from "../components/layoutPicker";
import MenuLinks from "../components/menuLinks";
import LayoutDropMenu from "../components/layoutDropMenu";
import CityChangeLoader from "../components/cityChangeLoader";
import OnboardingReminder from "../components/onboardingReminder";
// functions
import { setRef } from "services/referance.service";
import { HttpService } from "services";
import {
  getProfileStatusDetails,
  getFavoCount,
  clearReducer,
  changeCity,
  getSummary,
} from "actions";
import {
  checkIsGuestUser,
  setUserIdToLocal,
  disableScrolling,
  getCoordinates,
  getLocalizeText,
  msgTextArray,
  siteMenuLinks,
} from "utils";
// images
import englishText from "languages/english/english";
// interface
import * as layoutInterface from "../interface/layout";
//css
import "../layout.scss";

let intervalId: any = null;
let isGuestUser = false;

class Layout extends React.PureComponent<layoutInterface.ILayoutProps> {
  favoriteEndRef: any;
  wrapperRef: any;

  state: layoutInterface.ILayoutState = {
    selectedCity: "",
    isFavoActive: false,
    favoCount: 0,
    isEmailVerified: true,
    onboarding: true,
    showFullLoader: false,
    cityTemp: "",
    msgText: msgTextArray[0],
    showOnboardingBanner: false,
  };

  componentDidMount() {
    setUserIdToLocal();
    const { getFavoCount, getProfileStatusDetails } = this.props;
    isGuestUser = checkIsGuestUser();

    if (!isGuestUser) {
      getFavoCount();
    }

    getProfileStatusDetails();
    this.props.getSummary();
    this.setActiveIndicator();

    if (window.location.pathname === "/favourites") {
      this.setState({ isFavoActive: true });
    }
    setRef(this.favoriteEndRef, "favoriteEndRef");
  }

  componentDidUpdate(prevProps: any) {
    const {
      location,
      favoCountReducer,
      changeCityReducer,
      profileCountReducer,
    } = this.props;
    const { selectedCity, favoCount } = this.state;

    if (prevProps.location !== location) {
      this.setActiveIndicator();
    }

    if (
      favoCountReducer !== prevProps.favoCountReducer &&
      favoCountReducer.status === "success" &&
      favoCountReducer.data &&
      favoCount !== parseInt(favoCountReducer.data)
    ) {
      this.setState({ favoCount: parseInt(favoCountReducer.data) });
    }
    if (
      changeCityReducer.data &&
      changeCityReducer.data.city &&
      changeCityReducer.data.city !== this.state.selectedCity &&
      changeCityReducer.status === "success"
    ) {
      this.setState({
        selectedCity: changeCityReducer.data.city,
        showFullLoader: false,
        msgText: msgTextArray[0],
      });
      clearInterval(intervalId);
      disableScrolling(false);
      clearReducer("CLEAR_FETCH_COUNT");
      this.props.getProfileStatusDetails();
      localStorage.setItem("selectedCity", changeCityReducer.data.city);
    }

    if (
      changeCityReducer !== prevProps.changeCityReducer &&
      changeCityReducer.status === "fail"
    ) {
      if (changeCityReducer.data.status === 401) {
        return;
      } else {
        this.setState({
          showFullLoader: false,
        });
        const message = getLocalizeText(englishText.ERROR_CONTACT_ADMIN);
        window.alert(message);
      }
    }

    if (
      profileCountReducer !== prevProps.profileCountReducer &&
      profileCountReducer.status === "success" &&
      profileCountReducer.data
    ) {
      if (selectedCity === "") {
        this.getMailVerificationStatus(profileCountReducer.data);
        getCoordinates(profileCountReducer.data.city);
        localStorage.setItem("selectedCity", profileCountReducer.data.city);
      }
    }
  }

  componentWillUnmount() {
    localStorage.removeItem("userVerified");
  }

  render() {
    const {
      selectedCity,
      isFavoActive,
      favoCount,
      isEmailVerified,
      onboarding,
      showFullLoader,
      currentTab,
      cityTemp,
      showOnboardingBanner,
    } = this.state;

    return (
      <>
        <header className="header buying-header" id="header">
          <div className="container-fluid d-flex align-items-center justify-content-between">
            <LogoComponent showFullLoader={showFullLoader} />
            <MenuLinks
              links={siteMenuLinks}
              currentTab={currentTab}
              linkClass="site-menu"
            />
            <LayoutPicker
              selectedCity={selectedCity}
              cityTemp={cityTemp}
              handleSelect={this.handleSelect}
            />

            <div className="buying-menu">
              <h2>.</h2>
            </div>

            <LayoutDropMenu
              favoCount={favoCount}
              isFavoActive={isFavoActive}
              favoriteEndRef={this.favoriteEndRef}
              isEmailVerified={isEmailVerified}
              onboarding={onboarding}
              isGuestUser={isGuestUser}
              updateFavoActive={() =>
                this.setState({
                  isFavoActive: !isFavoActive,
                })
              }
              getFavoCount={this.props.getFavoCount}
            />
          </div>
        </header>

        {showFullLoader && (
          <CityChangeLoader msgText={this.state.msgText} cityTemp={cityTemp} />
        )}
        {showOnboardingBanner && (
          <OnboardingReminder
            closepopup={() => this.setState({ showOnboardingBanner: false })}
          />
        )}
      </>
    );
  }

  callFirstTimeUserEvent = (onboarding: boolean) => {
    HttpService.post("v3/savesearch/uniqueSerch").then((res) => {
      if (res && res.message === "Successfull" && !onboarding) {
        this.setState({ showOnboardingBanner: true });
      }
      if (res && res.message === "Alredy added") {
        this.state.showOnboardingBanner &&
          this.setState({ showOnboardingBanner: false });
      }
    });
  };

  setActiveIndicator = () => {
    this.setState({ currentTab: window.location.pathname });
  };

  handleSelect = (event: any) => {
    const city = event.target.innerText;
    if (this.state.selectedCity !== city) {
      const { changeCity } = this.props;
      changeCity(city);
      getCoordinates(city);
      this.setState({ cityTemp: city, showFullLoader: true });
      let a = 1;
      intervalId = setInterval(() => {
        this.setState({ msgText: msgTextArray[a] });
        if (a === msgTextArray.length - 1) {
          a = 0;
        } else {
          a = a + 1;
        }
      }, 2000);
      disableScrolling(true);
    }
  };

  getMailVerificationStatus = (data: any) => {
    const isEmailVerified = data.isEmailVerified;
    const onboarding = data.onboarding;
    const city = data.city;

    this.setState({
      isEmailVerified,
      onboarding,
      selectedCity: city,
    });
    localStorage.setItem("userVerified", isEmailVerified.toString());
    localStorage.setItem("onboarding", onboarding.toString());
    this.callFirstTimeUserEvent(onboarding);
  };
}

const mapStateToProps = (state: layoutInterface.ILayoutProps) => ({
  favoCountReducer: state.favoCountReducer,
  changeCityReducer: state.changeCityReducer,
  profileCountReducer: state.profileCountReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  getFavoCount: () => {
    dispatch(getFavoCount());
  },

  clearReducer: (name: string) => {
    dispatch(clearReducer(name));
  },

  changeCity: (city: string) => {
    dispatch(changeCity(city));
  },
  getProfileStatusDetails: () => {
    dispatch(getProfileStatusDetails());
  },
  getSummary: () => {
    dispatch(getSummary());
  },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));
