import React from "react";
import englishText from "languages/english/english";
import { FormattedMessage } from "react-intl";
import { ChatIcon } from "components/commonComponents/icons";
import close from "assets/images/close.png";

const ThanksPageOnboarding: React.FC<any> = (props) => {
  const { noSupportText } = props;
  return (
    <>
      <div className="thank-you-wrapper">
        {props.fromObjDetails && (
          <div className="close-btn" onClick={props.closepopup}>
            <img className="close-btn-fin pointer" src={close} alt="img" />
          </div>
        )}
        <div className="chat-icon">
          <ChatIcon className={props.fromObjDetails ? "chat-icon-lg" : ""} />
        </div>
        <div className="thank-you-heading">
          <FormattedMessage
            id={
              noSupportText
                ? englishText.FIN_CHECK_COMPLETED
                : englishText.THANK_YOU_HEADING
            }
            defaultMessage={
              noSupportText
                ? englishText.FIN_CHECK_COMPLETED
                : englishText.THANK_YOU_HEADING
            }
          />
        </div>
        <div className="thank-you-sub-text">
          <FormattedMessage
            id={
              noSupportText
                ? englishText.WE_WILL_HELP
                : englishText.THANK_YOU_TEXT
            }
            defaultMessage={
              noSupportText
                ? englishText.WE_WILL_HELP
                : englishText.THANK_YOU_TEXT
            }
          />
        </div>
        <div className="form-group form-primary d-flex justify-content-center mt-3">
          <button
            type="button"
            className="btn immozy-home-action-btn"
            onClick={props.closepopup}
          >
            <FormattedMessage
              id={englishText.OKAY}
              defaultMessage={englishText.OKAY}
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default ThanksPageOnboarding;
