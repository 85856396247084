import hamburg from "assets/images/hamburg.jpg";
import Berlin from "assets/images/Berlin.jpg";
import hanover from "assets/images/hanover.jpg";
import liepzig from "assets/images/liepzig.png";
import { cities } from "utils/constants";

export const imageOptions = [
  {
    img: hamburg,
    title: cities.HAM,
    disabled: false,
  },
  {
    img: Berlin,
    title: cities.BER,
    disabled: false,
  },
  {
    img: hanover,
    title: cities.HANNOVER,
    disabled: false,
  },
  {
    img: liepzig,
    title: cities.LEIPZIG,
    disabled: false,
  },
];

export const optionInputTypes = {
    PICTURE: "One Choice Picture",
    RADIO: "One Choice",
    CHECKBOX: "Multiple Choice",
    RANGE: "Range",
    INPUTFIELD: "Inputfield",
};