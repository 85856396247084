import React from "react";
import Accordion from "react-bootstrap/Accordion";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Spinner } from "react-bootstrap";
import isEmpty from "lodash/isEmpty";
import { RouteComponentProps } from "react-router-dom";
import { FormattedMessage } from "react-intl";
// components
import AccordionContent from "../components/accordionContent";
import UploadFile from "components/immozyHome/components/uploadFile";
import RenderPersonalInfoForm from "../components/profileComponent";
import OnboardingComponent from "../components/onboardingComponent";
import FinanceOnboarding from "../components/financeOnboarding";
import SomethingWentWrong from "components/commonComponents/somethingWentWrong";
import OnboardingContainer from "components/onboarding-new/container/onboarding";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Modal from "react-awesome-modal";
// functions
import { API_CONFIG, HttpService } from "services";
import { convertBool, getIcon } from "utils/functions";
import englishText from "languages/english/english";
import { sectionTitleEnum } from "../utils/constantsAndFunctions";
// interface
import { IImmozyHomeStats } from "../interface/immozyhome";
// assets
import {
  // ExclamationMark,
  Icon1,
  Icon2,
  Icon3,
  Icon4,
} from "components/commonComponents/icons";
import ssl1 from "assets/images/ssl1.png";
import "react-circular-progressbar/dist/styles.css";
import "../immozy-home.scss";
import { FadeInSection } from "components/commonComponents/fadeInSection/fadeInSection";

const modalNames = {
  ONBOARDING_POPUP: "ONBOARDING_POPUP",
  FINANCE_POPUP: "FINANCE_POPUP",
};

const selectedLang = localStorage.getItem("language");

class ImmozyHome extends React.Component<
  RouteComponentProps<
    any,
    any,
    { object: string; activeTab: string; image: string; score: number | string }
  >
> {
  state: IImmozyHomeStats = {
    isLoading: true,
    graphData: {},
    sectionData: [],
    // isUploading: false,
    // isUploaded: false,
    // progress: "",
    activeTab: (this.props.location.state || {}).activeTab || "0",
    showObjLinkId: "",
    object: {},
    hidePreparation: false,
  };

  handleScroll = () => {
    const currentScrollPos = document.documentElement.scrollTop;
    if (currentScrollPos < 235 && !this.state.hidePreparation) {
      this.setState({ hidePreparation: true });
    } else if (currentScrollPos >= 235 && this.state.hidePreparation) {
      this.setState({ hidePreparation: false });
    }
  };
  componentDidMount() {
    this.getQualifyData();
    // const params: any = getUrlParameters();
    const params: any = this.props.location.state;
    const lastobjId = localStorage.getItem("last_open_object");

    if (params) {
      if (Object.keys(params || {}).length !== 0) {
        this.setState({ object: params });
      }

      if (params && params.object) {
        this.setState({
          showObjLinkId: params.object,
        });
      }
    } else if (lastobjId) {
      const objectData = JSON.parse(lastobjId);
      this.setState({ object: objectData, showObjLinkId: objectData.object });
    }

    //  window.addEventListener("scroll", this.handleScroll)
    //eslint-disable-next-line no-extra-boolean-cast
    // if (!!this.props.location.hash) {
    //   const scrollToTag = document.querySelector(this.props.location.hash);
    //   console.log("  scrollToTag", scrollToTag);
    //   !!scrollToTag && scrollToTag.scrollIntoView({ behavior: "smooth" });
    // }
  }

  componentDidUpdate(prevProps: any) {
    //eslint-disable-next-line no-extra-boolean-cast

    if (
      !!this.props.location.hash &&
      prevProps.location.hash &&
      prevProps.location.hash !== this.props.location.hash
    ) {
      const scrollToTag = document.querySelector(this.props.location.hash);
      !!scrollToTag && scrollToTag.scrollIntoView({ behavior: "smooth" });
    }
  }

  // componentWillUnmount = () => {
  //   window.removeEventListener("scroll", this.handleScroll);
  // }

  render() {
    const {
      graphData,
      sectionData,
      isLoading,
      activePopup,
      activeTab,
      showObjLinkId,
      object,
      hidePreparation,
    } = this.state;
    const {
      color,
      chance_percentage,
      // message_de, message_en
    } = graphData;

    return (
      <div style={{ backgroundColor: "#fafbff" }}>
        <div className="immozy-home-container">
          {isLoading ? (
            <div className="d-flex justify-content-center align-items-center">
              {[1, 2, 3].map((count) => (
                <Spinner
                  key={count}
                  className="spin-img"
                  animation="grow"
                  variant="danger"
                />
              ))}
            </div>
          ) : (
            <>
              {!isEmpty(graphData) && sectionData.length > 0 ? (
                <>
                  <div className="intro-section d-flex">
                    <div
                    //className="profile-details"
                    >
                      <p className="name">
                        <FormattedMessage
                          id={englishText.BETTER_PREPARED}
                          defaultMessage={englishText.BETTER_PREPARED}
                        />
                      </p>
                      <div className="description d-flex justify-content-center align-items-center">
                        <div>
                          <FormattedMessage
                            id={englishText.WE_HELP_PREPARE}
                            defaultMessage={englishText.WE_HELP_PREPARE}
                          />{" "}
                          <br />
                          <FormattedMessage
                            id={englishText.WE_HELP_PREPARE_1}
                            defaultMessage={englishText.WE_HELP_PREPARE_1}
                          />
                        </div>
                        <img src={ssl1} alt="img" className="ml-5" />
                      </div>
                    </div>
                    {/* <div className="progress-details d-flex justify-content-between align-items-center">
                    <FadeInSection>
                      <CircularProgressbar
                        value={chance_percentage || 0}
                        text={`${chance_percentage}%`}
                        strokeWidth={15}
                        styles={buildStyles({
                          textSize: "16px",
                          pathTransitionDuration: 0.5,
                          pathColor: color,
                          textColor: color,
                          trailColor: color + "26",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </FadeInSection>
                    <div>
                      <p
                        style={{ backgroundColor: color + "26", color }}
                        id="profile-completion"
                        className="score-chances-label d-flex justify-content-center align-items-center"
                      >
                        <ExclamationMark
                          width="20"
                          height="20"
                          className="mr-2"
                          fill={color}
                        />{" "}
                        {selectedLang === lang.EN ? message_en : message_de}
                      </p>
                    </div>
                  </div> */}
                  </div>
                  <div className="d-flex justify-content-between align-items-start collapse-container">
                    <Accordion
                      key={0}
                      id={"0"}
                      className="profile-accordian"
                      // style={{ maxWidth: showObjLinkId ? "calc(100% - 300px)" : "100%" }}
                      defaultActiveKey={activeTab}
                      activeKey={activeTab}
                    >
                      {sectionData.map((entry: any, index: number) => {
                        const {
                          section_name,
                          calculatePer,
                          priority,
                          // order_number,
                        } = entry;
                        return (
                          <AccordionContent
                            key={index}
                            id={section_name}
                            icon={sectionIcons[section_name]}
                            eventKey={index.toString()}
                            activeTab={activeTab}
                            title={sectionTitleEnum[section_name]}
                            progressPercentage={calculatePer || 0}
                            handleTabChange={this.handleTabChange}
                            importanceLevel={this.getImportanceLevel(priority)}
                          >
                            {this.getAccordionContent(section_name)}
                          </AccordionContent>
                        );
                      })}
                    </Accordion>
                    {/* <CircularProgressbar
                    value={chance_percentage || 0}
                    text={`${chance_percentage}%`}
                    strokeWidth={15}
                    styles={buildStyles({
                      textSize: "16px",
                      pathTransitionDuration: 0.5,
                      pathColor: color,
                      textColor: color,
                      trailColor: color + "26",
                      backgroundColor: "#3e98c7",
                    })}
                  /> */}
                    <div className="floating-card">
                      <div
                        className={`total-progress-wrapper ${
                          hidePreparation ? "hide-total-progress" : ""
                        }`}
                      >
                        <FadeInSection className="full-height d-flex justify-content-center align-items-center flex-column">
                          <p className="label">
                            <FormattedMessage
                              id={englishText.YOUR_PREPARATION_STATUS}
                              defaultMessage={
                                englishText.YOUR_PREPARATION_STATUS
                              }
                            />
                          </p>
                          <CircularProgressbar
                            value={chance_percentage || 0}
                            text={`${chance_percentage || 0}%`}
                            strokeWidth={15}
                            styles={buildStyles({
                              textSize: "16px",
                              pathTransitionDuration: 0.5,
                              pathColor: color,
                              textColor: color,
                              trailColor: color + "26",
                              backgroundColor: "#3e98c7",
                            })}
                          />
                        </FadeInSection>
                        {/* <div
                            style={{ backgroundColor: color + "26", color }}
                            id="profile-completion"
                            className="score-chances-label d-flex justify-content-center align-items-center"
                          >
                            <ExclamationMark
                              width="20"
                              height="20"
                              className="mr-2"
                              fill={color}
                            />{" "}
                            {selectedLang === lang.EN ? message_en : message_de}
                          </div> */}
                      </div>
                      {showObjLinkId !== "" && (
                        <div className="object-card">
                          <p className="label">
                            <FormattedMessage
                              id={englishText.LAST_VIEWED_PROPERTY}
                              defaultMessage={englishText.LAST_VIEWED_PROPERTY}
                            />
                          </p>
                          <div className="image-wrapper">
                            <img src={object.image} alt="img" />

                            <div
                              className="score-badge"
                              style={{
                                backgroundColor:
                                  object.score === 0
                                    ? "#cecece"
                                    : getIcon(Number(object.score)),
                              }}
                            >
                              {Number(object.score).toFixed(0)}
                            </div>
                          </div>
                          <button
                            className="go-to-object-btn"
                            onClick={() => {
                              window.scrollTo(0, 0);
                              this.props.history.push(
                                `/object-detail/${object.object}`
                              );
                            }}
                          >
                            <FormattedMessage
                              id={englishText.TO_THE_OBJECT}
                              defaultMessage={englishText.TO_THE_OBJECT}
                            />
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <SomethingWentWrong />
              )}
            </>
          )}
          {activePopup === modalNames.ONBOARDING_POPUP && (
            <div>
              <Modal visible={true} effect="fadeInUp" width={"1100px"}>
                <OnboardingContainer
                  fromPopup={true}
                  closeOnboarding={this.closeModal}
                  onboardingPending={convertBool("onboarding")}
                />
              </Modal>
            </div>
          )}
          {activePopup === modalNames.FINANCE_POPUP && (
            <div>
              <Modal visible={true} effect="fadeInUp" width={"1100px"}>
                <FinanceOnboarding
                  closepopup={this.closeModal}
                  data={this.state.sectionData.find(
                    ({ section_name }: any) => section_name === "profile"
                  )}
                  finData={this.state.sectionData.find(
                    ({ section_name }: any) => section_name === "finance"
                  )}
                />
              </Modal>
            </div>
          )}
        </div>
      </div>
    );
  }

  getImportanceLevel = (priority: string): number => {
    switch (priority) {
      case "Weniger wichtig":
        return 3;

      case "Sehr wichtig":
        return 1;

      case "Wichtig":
        return 2;

      case "No Badge (Not visible on screen)":
        return 4;

      default:
        return 4;
    }
  };

  handleTabChange = (activeTab: string) => {
    this.setState({
      activeTab: this.state.activeTab === activeTab ? "" : activeTab,
    });
  };

  closeModal = () => {
    this.setState({ activePopup: "" });
    this.updatePage();
    if (localStorage.getItem("refreshPageOnb")) {
      localStorage.removeItem("refreshPageOnb");
      window.location.reload();
    }
  };

  updatePage = () => {
    this.getQualifyData(true);
  };

  getAccordionContent = (section: string) => {
    switch (section) {
      case "profile":
        return (
          <RenderPersonalInfoForm
            data={this.state.sectionData.find(
              ({ section_name }: any) => section_name === section
            )}
            updatePage={this.updatePage}
            selectedLang={selectedLang}
          />
        );
      case "onboarding":
        return (
          <OnboardingComponent
            data={this.state.sectionData.find(
              ({ section_name }: any) => section_name === section
            )}
            showOnb={() =>
              this.setState({ activePopup: modalNames.ONBOARDING_POPUP })
            }
            updatePage={this.updatePage}
            selectedLang={selectedLang}
          />
        );
      case "document":
      case "finance":
        return (
          <UploadFile
            data={this.state.sectionData.find(
              ({ section_name }: any) => section_name === section
            )}
            openFinance={() => this.openFinance()}
            updatePage={this.updatePage}
            selectedLang={selectedLang}
          />
        );

      default:
        return "abcs";
    }
  };

  openFinance = () => {
    this.setState({ activePopup: modalNames.FINANCE_POPUP });
  };

  getQualifyData = (alreadyOnPage = false) => {
    //this.setState({ isLoading: true });
    HttpService.get(API_CONFIG.path.qualifyData)
      .then((response) => {
        if (response.data) {
          const data = response.data;
          if (alreadyOnPage) {
            const { sectionData } = data;

            const activeTabData =
              sectionData[0].calculatePer < 100
                ? { sectoinName: sectionData[0].section_name, activeTab: "0" }
                : sectionData[1].calculatePer < 100
                ? { sectoinName: sectionData[1].section_name, activeTab: "1" }
                : { sectoinName: sectionData[2].section_name, activeTab: "2" };
            const sectionCompleted = sectionData.filter(
              (i: any) => i.calculatePer === 100
            );

            const aciveTabNumber = parseInt(this.state.activeTab);

            const isNextSectionComplete =
              aciveTabNumber < 2 &&
              sectionData[aciveTabNumber + 1].calculatePer === 100;

            if (
              activeTabData.activeTab !== this.state.activeTab &&
              sectionCompleted.length < 3 &&
              !isNextSectionComplete &&
              aciveTabNumber < 2
            ) {
              this.setState(
                {
                  ...data,
                  isLoading: false,
                  activeTab: (aciveTabNumber + 1).toString(),
                },
                () => {
                  this.props.history.push(
                    `/prepare#${activeTabData.sectoinName}`
                  );
                }
              );
            } else {
              this.setState({ ...data, isLoading: false });
            }
          } else {
            this.setState({ ...data, isLoading: false });
          }
        }
      })
      .catch((err: Error) => {
        console.log(err);
        this.setState({ isLoading: false });
      });
  };
}

const sectionIcons: any = {
  profile: <Icon1 />,
  onboarding: <Icon2 style={{ marginLeft: "-7px", marginTop: "5px" }} />,
  document: <Icon3 />,
  finance: <Icon4 />,
};

export default ImmozyHome;
