import React from "react";
import CurrencyInput from "react-currency-input-field";
import { optionInputTypes } from "components/onboarding-new/interface/utils";
import { CheckMark } from "components/commonComponents/icons";
import { primaryColor } from "utils/constants";
import { Form } from "react-bootstrap";
import { IFinOptionsProps } from "../interface/immozyhome";
import { IOptionProps } from "components/onboarding-new/interface/onboarding";
import { checkDisable } from "components/onboarding-new/interface/onboardingFunctions";

const FinanceOptions: React.FC<IFinOptionsProps> = (props) => {
  const { questionsList, type, lang, currentStep } = props;

  const locale = lang.includes("en") ? "en-US" : "de-DE";
  const options = questionsList[currentStep].options;

  return (
    <>
      {(type === optionInputTypes.RADIO ||
        type === optionInputTypes.INPUTFIELD) && (
        <div className="finance-options-container">
          <div
            className="d-flex justify-content-center align-items-start"
            style={{ flexWrap: "wrap" }}
          >
            {options.map((op: IOptionProps, i: number) => (
              <>
                {op.type === "RADIO" && (
                  <div
                    className={`check-option ${op.isSelected ? "active" : ""}`}
                    key={op.optionid}
                    onClick={() => props.selectOption(i)}
                  >
                    <CheckMark className="checkmark" fill={primaryColor} />
                    {op.option[lang]}
                  </div>
                )}
                {op.type === "INPUT" && (
                  <div
                    className={`option-input mb-2 relative currency-input-style-wrapper ${
                      checkDisable(options) ? "disabled" : ""
                    }`}
                    style={
                      // op.optionid === "1.3.1"?
                      { justifyContent: "center" }
                      // : {}
                    }
                  >
                    {/* {op.optionid !== "1.3.1" && (
                      <label
                        htmlFor="input-example"
                        className="option-input-label"
                      >
                        {getLocalizeText(englishText.PRICE)}
                      </label>
                    )} */}
                    <CurrencyInput
                      key={`${i} ${op ? op.slug : ""}`}
                      id={"input-example" + op.optionid}
                      defaultValue={op.value}
                      value={op.value}
                      decimalsLimit={2}
                      onValueChange={(value) => props.handleInputchange(value)}
                      intlConfig={{
                        locale: locale,
                        currency: "EUR",
                      }}
                      suffix={" €"}
                      prefix={" "}
                      disabled={checkDisable(options)}
                    />
                  </div>
                )}
                {op.type === "CHECKBOX" && (
                  <>
                    <Form.Group
                      className="d-flex align-items-center justify-content-center form-group mt-3 w-100"
                      controlId="formBasicCheckbox"
                      key={i}
                    >
                      <Form.Check
                        id={op.optionId}
                        custom
                        type="checkbox"
                        label={op.option[lang]}
                        checked={op.isSelected}
                        name={op.option[lang]}
                        onChange={() => props.selectOption(i)}
                        style={{ width: "391px" }}
                      />
                    </Form.Group>
                  </>
                )}
              </>
            ))}
          </div>
        </div>
      )}
      {type === "Multiple Choice" && (
        <div className="finance-options-container w-50">
          <ul className="completion-steps-checkmark-wrapper">
            {options.map((op: IOptionProps, i: number) => (
              <>
                <li
                  key={i}
                  className="checkbox-op-text step d-flex justify-content-start align-items-center pointer"
                  onClick={() => props.selectOption(i)}
                >
                  <div>
                    {op.isSelected ? (
                      <CheckMark fill={primaryColor} />
                    ) : (
                      <div className="empty-check" />
                    )}
                  </div>
                  <div
                    className={`step-text `}
                    key={`${op.slug}`}
                    style={{ animationDelay: `${i * 0.2}s` }}
                  >
                    {op.option[lang]}
                  </div>
                </li>
                {i === 3 && (
                  <Form.Control
                    as="textarea"
                    className="textarea-input"
                    rows={3}
                    value={options[2].value}
                    onChange={props.handleTextareachnage}
                  />
                )}
              </>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

export default FinanceOptions;