import React, { useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
// functions
import {
  getIcon,
  getPercentageRangeForScore,
  getProgressColor,
  matchingMapper,
  profileCompletionMapper,
} from "components/objectDetails/shared/objectFunctions";
import englishText from "languages/english/english";
import HttpService from "services/http.service";
import getLocalizeText from "utils/getLocalizeText";

interface IDynamicTextProps {
  propertyDetail: any;
  selectedLang?: string;
  id?: string;
  setqualifyComplete?: (value: boolean) => void;
}

export const DynmicTextComponent: React.FC<IDynamicTextProps> = (props) => {
  const { propertyDetail } = props;

  const { locationScore, requirementsScore, financialScore } = propertyDetail;

  const [financeCheck, setFinanceCheck] = useState<null | boolean>(null);
  const [profileCompletionPercentage, setProfileCompletionPercentage] =
    useState(0);

  const [matchScoring, setMatchScoring] = useState<{ [key: string]: number }>({
    financialScore,
    locationScore,
    requirementsScore,
  });

  const [graphColor, setGraphColor] = useState(getProgressColor(100));

  const [profileScoring, setProfileScoring] = useState<{
    [key: string]: number | null | boolean;
  }>({
    profilePercentage: 0,
    onboardingPercentage: 0,
    financePercentagefirstOnboarding: 0,
    financePercentagesecondDocument: 0,
  });

  useEffect(() => {
    HttpService.get("v1/qualify/optOut", {})
      .then((response) => {
        const {
          financePercentagefirstOnboarding,
          financePercentagesecondDocument,
          onboardingPercentage,
          profileCompletionPercentage,
          profilePercentage,
          financecheck,
          color,
        } = response.data;

        setProfileCompletionPercentage(profileCompletionPercentage);
        if (
          // eslint-disable-next-line no-prototype-builtins
          props.hasOwnProperty("setqualifyComplete") &&
          profileCompletionPercentage === 100
        ) {
          props.setqualifyComplete && props.setqualifyComplete(true);
        }

        setProfileScoring({
          profilePercentage,
          onboardingPercentage,
          financePercentagefirstOnboarding,
          financePercentagesecondDocument,
        });
        setFinanceCheck(financecheck);
        setGraphColor(color);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  useEffect(() => {
    setMatchScoring({
      locationScore,
      requirementsScore,
      financialScore,
    });
  }, [locationScore, requirementsScore, financialScore]);

  return (
    <div className="full-width">
      <div className="d-flex justify-content-center align-items-center flex-column full-width">
        <p className="immozy-check-heading">
          <FormattedMessage
            id={englishText.IMMOZY_CHECK}
            defaultMessage={englishText.IMMOZY_CHECK}
          />
        </p>
        <div className="immozy-check-card">
          <div className="section-check">
            <div
              className="score"
              style={{
                backgroundColor: !propertyDetail.score
                  ? "#cecece"
                  : getIcon(Math.round(propertyDetail.score)),
              }}
            >
              {Math.round(propertyDetail.score) || "?"}
            </div>
            <div className="score-text">
              <p>Immo-Matching:</p>
              {!propertyDetail.score ? (
                <ul className="scoring-list">
                  <li>
                    {`${getLocalizeText(englishText.FINISH_THE)} `}
                    <Link to="/onboarding">{englishText.ONBOARDING}</Link>{" "}
                    {` ${getLocalizeText(englishText.TO_SEE_PROPERTY)}`}
                  </li>
                </ul>
              ) : (
                <ul className="scoring-list" style={{ paddingLeft: "25px" }}>
                  {Object.keys(matchScoring).map(
                    (key: string, index: number) => (
                      <li key={index} style={{ listStyleType: "disc" }}>
                        {getLocalizeText(
                          matchingMapper[key][
                            getPercentageRangeForScore(matchScoring[key])
                          ]
                        )}
                        <br />
                      </li>
                    )
                  )}
                </ul>
              )}
            </div>
          </div>
          <div className="section-check mt-3">
            <div className="graph">
              <CircularProgressbar
                value={profileCompletionPercentage || 0}
                text={`${profileCompletionPercentage}%`}
                strokeWidth={10}
                styles={buildStyles({
                  textSize: "16px",
                  pathTransitionDuration: 0.5,
                  pathColor: graphColor,
                  textColor: graphColor,
                  trailColor: graphColor + "35",
                  backgroundColor: "#3e98c7",
                })}
              />
            </div>
            <div className="score-text">
              <p>
                <FormattedMessage
                  id={englishText.PREPARE}
                  defaultMessage={englishText.PREPARE}
                />
                :
              </p>
              <ul className="scoring-list">
                {Object.keys(profileScoring).map(
                  (key: string, index: number) => {
                    const profileScoreKey = [
                      "financePercentagefirstOnboarding",
                      "financePercentagesecondDocument",
                    ].includes(key)
                      ? (key === "financePercentagefirstOnboarding" &&
                          profileScoring[key] === 75) ||
                        (key === "financePercentagesecondDocument" &&
                          !!financeCheck)
                      : profileScoring[key] === 100;
                    return (
                      <li key={index} className="d-flex">
                        <div
                          className="d-flex justify-content-center align-items-center"
                          style={{
                            minWidth: "20px",
                            height: "20px",
                            marginRight: "5px",
                          }}
                        >
                          <i
                            className={`fa fa-${
                              profileScoreKey
                                ? "check success-color"
                                : "times danger-color"
                            }`}
                            aria-hidden="true"
                          />
                        </div>
                        <span
                          dangerouslySetInnerHTML={{
                            __html: `<span>${getLocalizeText(
                              profileCompletionMapper[key][
                                profileScoreKey.toString()
                              ]
                            )}</span>`,
                          }}
                        />
                        <br />
                      </li>
                    );
                  }
                )}
              </ul>
            </div>
          </div>
          {profileCompletionPercentage < 100 && (
            <div className="d-flex justify-content-center align-items-center mt-4">
              <Link
                to={{
                  pathname: "/prepare",
                  state: {
                    object: props.id || props.propertyDetail.id,
                    score: props.propertyDetail.score || 0,
                    image: props.propertyDetail.objectPictures[0] || "",
                  },
                }}
              >
                <button
                  type="submit"
                  className={`forward-btn welcome-btn text-center mb-0`}
                  style={
                    props.selectedLang === "de"
                      ? {
                          padding: "20px",
                          textTransform: "none",
                          fontSize: "16px",
                        }
                      : { textTransform: "none", fontSize: "16px" }
                  }
                  onClick={() => window.scrollTo(0, 0)}
                >
                  <FormattedMessage
                    id={englishText.INCREASE_CHANCES}
                    defaultMessage={englishText.INCREASE_CHANCES}
                  />
                </button>
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
