/* axios with abort */
import axios from "axios";
import englishText from "languages/english/english";
import getLocalizeText from "utils/getLocalizeText";
import { BASE_URL } from "./baseUrl";

const callAPI = (
  apiURL: string,
  apiMethod: any,
  apiData: any = {},
  resolveDataAsReponse = false,
  contentType = "application/json"
) => {
  const headers = {
    Authorization:
      localStorage.getItem("token") || sessionStorage.getItem("token"),
    "Content-Type": contentType,
    ampSessionId,
    ampDeviceId,
  };

  return new Promise((resolve, reject) => {
    axios({
      url: `${BASE_URL}${apiURL}`,
      method: apiMethod,
      data: apiData,
      headers,
    })
      .then((response) => {
        if (resolveDataAsReponse) {
          resolve(apiData);
        } else {
          resolve(response);
        }
      })
      .catch((err) => {
        if (err.response && err.response.data) {
          if (err.response.status === 401) {
            // if (
            //   localStorage.getItem("role") &&
            //   localStorage.getItem("role") === "GUEST" &&
            //   !localStorage.getItem("refreshPage")
            // ) {
            //   window.location.pathname = "/refresh-login";
            //   //   const a = localStorage.getItem("failed");
            //   //   if (!a) {
            //   //     localStorage.setItem("failed", "true");
            //   //     refreshTokenCall();
            //   //   }
            // } else {
            const a = localStorage.getItem("alertShowed");
            if (!a) {
              const msg = getLocalizeText(englishText.LOGIN_AGAIN_ERROR);
              localStorage.setItem("alertShowed", "true");
              if (window.confirm(msg)) {
                localStorage.removeItem("token");
                localStorage.removeItem("userId");
                window.location.pathname = "/login";
              }
            }
            // }
          }

          if (err.response.status === 403) {
            const a = localStorage.getItem("alertShowed");
            if (!a) {
              const msg =
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : getLocalizeText(englishText.LOGIN_AGAIN_ERROR);
              window.alert(msg);
              localStorage.setItem("alertShowed", "true");
              localStorage.removeItem("token");
              localStorage.removeItem("userId");
              window.location.pathname = "/login";
            }
          }

          if (!axios.isCancel(err) && err.response) {
            reject(err.response);
          }
        }
      });
  });
};

export default callAPI;

const ampSessionId = localStorage.getItem("ampSessionId");
const ampDeviceId = localStorage.getItem("ampDeviceId") || undefined;

export const getToken = () => {
  localStorage.getItem("token");
};
