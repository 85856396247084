import React from "react";
import englishText from "languages/english/english";
import { FormattedMessage } from "react-intl";

const SomethingWentWrong: React.FC = () => (
  <div className="something-went-wrong-element">
    <FormattedMessage
      id={englishText.SOMETHING_WENT_WRONG}
      defaultMessage={englishText.SOMETHING_WENT_WRONG}
    />{" "}
    !
  </div>
);

export default SomethingWentWrong;
