import React, { useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { Form } from "react-bootstrap";
// Components
import ContactPopUpWrapper from "./contactPopUpWrapper";
import {
  CheckMark,
  ExclamationMarkFilled,
  UncheckCircle,
} from "components/commonComponents/icons";
// functions
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import HttpService from "services/http.service";
import {
  getColor,
  getPercentageRange,
  matchingMapperHeader,
} from "components/objectDetails/shared/objectFunctions";

interface IContactFormProps extends RouteComponentProps {
  closeActivePopup: () => void;
  links?: any[];
  propertyDetail?: any;
  id?: string;
  fromCalculator?: boolean;
  openContactUs: (refreshPage?: boolean) => void;
  selectedLang: string;
}

const ProfileCompletionPopUp: React.FC<IContactFormProps> = (props) => {
  const [isLoading, setisLoading] = useState(true);
  const [check, setCheck] = useState(false);
  const [offsetWidth, setOffsetWidth] = useState(0);
  const [progressData, setprogressData] = useState<any>({});

  const { closeActivePopup } = props;

  const toolTipRef: any = useRef();
  const modalRef = useRef<any>(null);

  useEffect(() => {
    isLoading && getProfilePercentage();
  }, []);

  useEffect(() => {
    toolTipRef.current &&
      setOffsetWidth(-(toolTipRef.current.offsetWidth / 2 - 8));
  }, [setOffsetWidth]);

  const getProfilePercentage = () => {
    HttpService.get("v1/qualify/optOut", {})
      .then((response) => {
        setprogressData(response.data);
        setisLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
      });
  };

  const handleOptOut = () => {
    setisLoading(true);
    HttpService.put("v1/profile/showprofilepopup/false")
      .then(() => {
        setisLoading(true);
        props.openContactUs(true);
      })
      .catch((err) => {
        console.log(err);
        setisLoading(false);
      });
  };

  const stepsProgress: any = {
    steps1: {
      label: getLocalizeText(englishText.PROVIDE_PERSONAL_INFORMATION),
      isCompleted: progressData && progressData.profilePercentage === 100,
      tooltip: englishText.PROFILE_TOOLTIP,
    },
    steps2: {
      label: getLocalizeText(englishText.CLARIFY_PERSONAL_NEEDS),
      isCompleted: progressData && progressData.onboardingPercentage === 100,
      tooltip: englishText.ONB_TOOLTIP,
    },
    steps3: {
      label: getLocalizeText(englishText.CHECK_AND_SECURE_YOUR_FINANCING),
      isCompleted:
        progressData &&
        progressData.financePercentagefirstOnboarding +
          progressData.financePercentagesecondDocument ===
          100,
      tooltip: englishText.FINANCE_TOOLTIP,
    },
  };

  const renderLeftPannel = () => (
    <div className="offer-container">
      <>
        <p className="offer-title">
          <FormattedMessage
            id={englishText.PROFILE_COMPLETION_POPUP_HEADING}
            defaultMessage={englishText.PROFILE_COMPLETION_POPUP_HEADING}
          />
        </p>
        {/* <p className="progress-in-steps">
            {progressData.StepComplete + " / " + 3} &nbsp;
            <FormattedMessage
              id={englishText.STEPS_COMPLETED}
              defaultMessage={englishText.STEPS_COMPLETED}
            />
          </p> */}
        <p className="offer-desc">
          <FormattedMessage
            id={englishText.PROFILE_COMPLETION_POPUP_SUBTEXT}
            defaultMessage={englishText.PROFILE_COMPLETION_POPUP_SUBTEXT}
          />
        </p>
        <p className="offer-desc">
          <FormattedMessage
            id={englishText.PROFILE_COMPLETION_POPUP_SUBTEXT_2}
            defaultMessage={englishText.PROFILE_COMPLETION_POPUP_SUBTEXT_2}
          />
        </p>

        <ul className="completion-steps-checkmark-wrapper">
          {Object.keys(stepsProgress).map((key, index) => (
            <li
              key={index}
              className="step d-flex justify-content-start align-items-stretch position-relative"
            >
              <div className="d-flex justify-content-start align-items-stretch">
                {stepsProgress[key].isCompleted ? (
                  <CheckMark fill={"#38CC6B"} />
                ) : (
                  <UncheckCircle fill={"#C4C4C4"} />
                )}
              </div>
              <div
                className={`step-text 
                    ${stepsProgress[key].isCompleted ? "over-line" : ""}`}
              >
                {stepsProgress[key].label}
              </div>
              <div className="info-btn cursor-pointer">
                <div
                  className="tool-tip"
                  ref={toolTipRef}
                  style={{ right: offsetWidth + "px" }}
                >
                  {getLocalizeText(stepsProgress[key].tooltip)}
                </div>
                <ExclamationMarkFilled />
              </div>
              {/* <div className="tool-tip-wrapper" style={{ position: "unset", height: "unset" }}>
                  <OverlayTrigger
                    placement="top"
                    // delay={{ show: 50, hide: 50 }}
                    overlay={(props: any) => {
                      return <Tooltip id="button-tooltip" {...props}>
                        <p style={{ textAlign: "left", lineHeight: "1.5", fontFamily: "BrandonText" }}>{getLocalizeText(stepsProgress[key].tooltip)}</p>
                      </Tooltip>
                    }}
                  >
                    <Button variant="light" className="tool-tip-btn"><ExclamationMarkFilled /></Button>
                  </OverlayTrigger>
                </div> */}
            </li>
          ))}
        </ul>
        {/* <div
            className="contact-seller-instruction-label"
            style={{
              backgroundColor:
                getColor(profileCompletionPercentage) + "26",
              borderColor: getColor(profileCompletionPercentage),
            }}
          >
            <CheckMark fill={getColor(profileCompletionPercentage)} />
            <FormattedMessage
              id={englishText.CONTACT_SELLER_INSTRUCTION_LABEL}
              defaultMessage={englishText.CONTACT_SELLER_INSTRUCTION_LABEL}
            />
          </div> */}
        {progressData.profileCompletionPercentage === 100 && (
          <div
            className={`checkbox-wrapper check-option-for-profile-completion mb-4 mr-4 d-flex justify-content-center`}
          >
            <Form.Check
              type="checkbox"
              custom
              label={getLocalizeText(
                englishText.DONT_WANT_TO_SEE_THIS_CHECK_AGAIN
              )}
              name="formHorizontalCheck"
              id={`formHorizontalCheck`}
              checked={check}
              onChange={() => setCheck(!check)}
              value={""}
            />
          </div>
        )}
        {progressData.profileCompletionPercentage < 100 ? (
          <div className="contact-seller-btn d-flex align-items-center justify-content-between">
            <Link
              to={{
                pathname: "/prepare",
                state: {
                  object: props.id || props.propertyDetail.id,
                  score: props.propertyDetail.score || 0,
                  image: props.propertyDetail.objectPictures[0] || "",
                  activeTab: getSectionActiveTab(),
                },
              }}
            >
              <button
                type="submit"
                className={`forward-btn welcome-btn text-center mb-0`}
                // style={props.selectedLang === "de" ? { padding: "20px" } : {}}
                style={{ padding: "20px 35px", fontSize: "14px" }}
                onClick={() => window.scrollTo(0, 0)}
              >
                <FormattedMessage
                  id={englishText.INCREASE_CHANCES}
                  defaultMessage={englishText.INCREASE_CHANCES}
                />
              </button>
            </Link>
            <button
              type="submit"
              className="white-btn welcome-btn text-center"
              onClick={() => props.openContactUs()}
            >
              <FormattedMessage
                id={englishText.CONTACT_SELLER}
                defaultMessage={englishText.CONTACT_SELLER}
              />
            </button>
          </div>
        ) : (
          <div className="contact-seller-btn d-flex align-items-center">
            <button
              type="submit"
              className={`forward-btn welcome-btn text-center full-width`}
              onClick={() => (check ? handleOptOut() : props.openContactUs())}
            >
              <FormattedMessage
                id={englishText.NEXT}
                defaultMessage={englishText.NEXT}
              />
            </button>
          </div>
        )}
      </>
    </div>
  );

  const getSectionActiveTab = () =>
    !stepsProgress.steps1.isCompleted
      ? "0"
      : !stepsProgress.steps2.isCompleted
      ? "1"
      : "2";
  const renderRightPannel = () => (
    <div>
      <div className="progress-details d-flex justify-content-center align-items-center flex-column">
        <CircularProgressbar
          value={progressData.profileCompletionPercentage || 0}
          text={`${progressData.profileCompletionPercentage || 0}%`}
          strokeWidth={14}
          styles={buildStyles({
            textSize: "16px",
            pathTransitionDuration: 0.5,
            pathColor:
              progressData.color ||
              getColor(progressData.profileCompletionPercentage),
            textColor: "white",
            trailColor: progressData.color
              ? progressData.color + "35"
              : getColor(progressData.profileCompletionPercentage) + "4f",
            backgroundColor: "#3e98c7",
          })}
        />
      </div>
      <p className="profile-progress-heading">
        {getLocalizeText(
          matchingMapperHeader["header"][
            getPercentageRange(progressData.profileCompletionPercentage)
          ]
        )}
      </p>
      <p className="profile-progress-advice">
        {getLocalizeText(
          matchingMapperHeader["profileCompletionPercentage"][
            getPercentageRange(progressData.profileCompletionPercentage)
          ]
        )}
      </p>
    </div>
  );

  const handleClosePopUp = () => {
    // modalRef.current.classList.add("fade-out");
    // setTimeout(() => {
    //   modalRef.current.classList.remove("fade-out");
    closeActivePopup();
    // }, 1000);
  };

  return (
    <div
      className="d-flex justify-content-center align-items-center model-wrapper show fade-in"
      ref={modalRef}
    >
      <ContactPopUpWrapper
        onClose={handleClosePopUp}
        isLoading={isLoading}
        leftPannel={renderLeftPannel()}
        rightPannel={renderRightPannel()}
      />
    </div>
  );
};

export default withRouter(ProfileCompletionPopUp);
