import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import de from "react-phone-input-2/lang/de.json";
// Components
import { toast } from "react-toastify";
import Select, { StylesConfig } from "react-select";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import InputMask from "react-input-mask";
// functions
import englishText from "languages/english/english";
import { API_CONFIG, HttpService } from "services";
import {
  convertDateFormatToDdMm,
  getStatusData,
  lang,
  reactSelectStyles,
  getLocalizeText,
  sendAmplitudeDataEvent,
} from "utils";
import {
  employmentOptions,
  genderField,
  leftSection,
  reachabilityOptions,
  rightSection,
} from "../utils/constantsAndFunctions";
// Interface
import {
  InputFieldInterface,
  IPersonalInfoFormProps,
  OptionsInterface,
  userContactProps,
} from "../interface/immozyhome";
// Assets
import {
  ExclamationMark,
  ExclamationMarkFilled,
} from "components/commonComponents/icons";
import "react-toastify/dist/ReactToastify.css";
import "react-phone-input-2/lib/material.css";

const selectedLang = localStorage.getItem("language");

const RenderPersonalInfoForm: React.FC<IPersonalInfoFormProps> = (props) => {
  const { data, updatePage } = props;

  const {
    hintDe,
    hintEn,
    firstname,
    lastname,
    phone,
    gender,
    email,
    postalcode,
    street,
    city,
    birthdate,
    employment,
    reachability,
  } = data;

  const [errorFields, seterrorFields] = useState<any[]>([]);
  const [userStatus, setUserStatus] = useState<any>({
    userVerified: false,
    onboarding: false,
    profileLoading: true,
    apiData: data,
    ...data,
  });
  const [changed, setChanged] = useState(false);
  const [actionLoading, setActionLoading] = useState<boolean>(false);
  const [contactDetails, setContactDetails] = useState<userContactProps | any>({
    firstName: firstname || "",
    lastName: lastname || "",
    phone: phone || "",
    gender: gender || null,
    email: email || "",
    postalcode: postalcode || null,
    street: street || "",
    city: city || "",
    birthdate:
      birthdate && birthdate !== "" ? convertDateFormatToDdMm(birthdate) : "",
    employment:
      (
        employmentOptions.find(
          (emp: OptionsInterface) => emp.value === employment
        ) || {}
      ).value || "",
    reachability: !reachability
      ? ""
      : (
          reachabilityOptions.find(
            (emp: OptionsInterface) => emp.value === reachability
          ) || {}
        ).value || "",
  });

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.type === "radio" ? "gender" : e.target.name;
    const value = e.target.value;

    if (name === "postalcode" && e.target.value && e.target.value.length > 5) {
      return;
    }

    setContactDetails((contactDetails: any) => ({
      ...contactDetails,
      [name]: value,
    }));
    errorFields.length > 0 && seterrorFields([]);
    !changed && setChanged(true);
  };

  const fieldsValidated = () => {
    const errorFieldsList = [...errorFields];
    const letters = /^([äöüÄÖÜßA-z]+)(?:[\s|-]+[äöüÄÖÜßA-z]+){0,1}$/;

    ["firstName", "lastName", "city"].forEach((field) => {
      if (
        contactDetails[field] &&
        (contactDetails[field].length > 24 ||
          !contactDetails[field].match(letters))
      ) {
        errorFieldsList.push(field);
      }
    });

    if (!contactDetails.email || contactDetails.email === "") {
      errorFieldsList.push("email");
    }

    if (errorFieldsList.length > 0) {
      if (errorFieldsList.includes("city") && errorFieldsList.length === 1) {
        showError(englishText.CITY_ERROR);
      } else {
        showError(englishText.INVALID_INPUT_FIELDS);
      }
    }

    if (
      contactDetails.birthdate &&
      contactDetails.birthdate !==
        getLocalizeText(englishText.CALENDER_PLACEHOLDER)
    ) {
      const age = calculateAge(contactDetails.birthdate);

      if (age < 18 || age === 0) {
        errorFieldsList.push("birthdate");

        if (errorFieldsList.length > 0) {
          showError(englishText.DATE_ERROR);
        }
      } else if (age > 110 || age === null || age === undefined || isNaN(age)) {
        errorFieldsList.push("birthdate");
        errorFieldsList.length === 1 &&
          showError(englishText.INVALID_INPUT_FIELDS);
      }
    }

    const streetName = /^[ÆæßäöüÄÖÜß\w]+[,| |-]+[ÆæäöüÄÖÜß\w]+/;

    if (
      contactDetails.street &&
      (!contactDetails.street.match(streetName) ||
        !/\d/.test(contactDetails.street))
    ) {
      errorFieldsList.push("street");
      showError(englishText.STREET_ERROR);
    }

    if (contactDetails.postalcode && contactDetails.postalcode.length < 4) {
      errorFieldsList.push("postalcode");
      showError(englishText.POSTAL_CODE_ERROR);
    }
    seterrorFields(errorFieldsList);
    return errorFieldsList.length === 0;
  };

  const showError = (msg: string) => {
    toast.error(getLocalizeText(msg), {
      autoClose: 5000,
    });
  };

  const calculateAge = (birthday: any) => {
    const dateFormatted = convertDateFormatToMmDd(birthday);
    const b = new Date(dateFormatted);

    const ageDifMs = Date.now() - b.getTime();
    const ageDate = new Date(ageDifMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
  };

  const convertDateFormatToMmDd = (dateString: string) => {
    const from = dateString.split("/");
    const dateFormatted = `${from[1]}/${from[0]}/${from[2]}`;
    return dateFormatted;
  };

  // call update profile API
  const updateProfile = async () => {
    if (fieldsValidated()) {
      const payload = { ...contactDetails };
      // eslint-disable-next-line array-callback-return
      Object.keys(payload).map((field) => {
        if (field === "employment" && contactDetails[field] === "") {
          delete payload[field];
        }
        if (field === "postalcode") {
          if (payload[field] === "" || !payload[field]) {
            payload[field] = null;
          } else {
            payload[field] = parseInt(payload[field]);
          }
        }
        if (field === "birthdate") {
          if (
            payload[field] === getLocalizeText(englishText.CALENDER_PLACEHOLDER)
          ) {
            payload[field] = "";
          } else {
            payload[field] = convertDateFormatToMmDd(payload[field]);
          }
        }
      });

      setActionLoading(true);

      HttpService.put(API_CONFIG.path.profile, payload)
        .then((response) => {
          if (response.data) {
            const meta = {
              Action: "Profile section updated",
            };
            sendAmplitudeDataEvent(
              "profile_section_updated",
              meta,
              localStorage.getItem("userId"),
              "USER"
            );
            toast.success(getLocalizeText(englishText.DATA_SAVED));
            setActionLoading(false);
            setChanged(false);
            updatePage();
          }
        })
        .catch(() => {
          setActionLoading(false);
          setChanged(false);
        });
    }
  };

  useEffect(
    () => {
      const data = getStatusData();
      setUserStatus({ ...data, ...userStatus });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const renderInputs = (field: InputFieldInterface) => {
    const { type, options, label, name, defaultValue, placeholder } = field;

    switch (type) {
      case "radio":
        return (
          <div
            className="form-group form-primary radio-container d-flex"
            style={{ marginBottom: "10px" }}
          >
            <label className="mr-3">
              <FormattedMessage
                id={englishText.GENDER_1}
                defaultMessage={englishText.GENDER_1}
              />
            </label>
            <div className="d-flex">
              {(options || []).map((radioOptions: any, i) => (
                <div
                  className={`check-option-for-contact-seller option-text mr-4 ${
                    errorFields.length > 0 &&
                    errorFields.includes("gender") &&
                    "error-border-round"
                  }`}
                  key={i}
                >
                  <Form.Check
                    type="radio"
                    custom
                    disabled={actionLoading}
                    label={getLocalizeText(radioOptions.label)}
                    name="formHorizontalRadios"
                    id={`formHorizontalRadios1${i}`}
                    checked={contactDetails.gender === radioOptions.value}
                    onChange={handleInputChange}
                    value={radioOptions.value}
                  />
                </div>
              ))}
            </div>
          </div>
        );
      case "select":
        return (
          <div className="form-group form-primary">
            <label>{getLocalizeText(label)}</label>
            <div className="position-relative">
              <>
                {/* eslint-disable-next-line no-prototype-builtins */}
                {field.hasOwnProperty("options") ? (
                  <Select
                    id={label}
                    isDisabled={actionLoading}
                    styles={reactSelectStyles as StylesConfig}
                    placeholder={getLocalizeText(
                      englishText.SELECT_PLACEHOLDER
                    )}
                    isSearchable={false}
                    name={name}
                    className="actions-select"
                    onChange={({ value }: any, { name }: any) => {
                      setContactDetails({
                        ...contactDetails,
                        [name as any]: value,
                      });
                      !changed && setChanged(true);
                    }}
                    options={options || []}
                    value={
                      (options as OptionsInterface[]).find(
                        (option: OptionsInterface) =>
                          option.value === contactDetails[name]
                      ) || defaultValue
                    }
                  />
                ) : (
                  <></>
                )}
              </>
            </div>
            <span className="form-bar" />
          </div>
        );
      case "date":
        return (
          <div className="form-group form-primary">
            <label>{getLocalizeText(label)}</label>
            <div className="position-relative">
              <InputMask
                className={`form-control full-width transparent-bg ${
                  name === "birthdate" && "uppercase"
                } ${
                  errorFields.length > 0 &&
                  errorFields.includes(name) &&
                  "error-border"
                }`}
                value={contactDetails[name] || ""}
                mask={"99/99/9999"}
                maskPlaceholder={getLocalizeText(
                  englishText.CALENDER_PLACEHOLDER
                )}
                name={name}
                onChange={handleInputChange}
                alwaysShowMask={true}
                placeholder={getLocalizeText(englishText.CALENDER_PLACEHOLDER)}
              />
            </div>
            <span className="form-bar" />
          </div>
        );
      default:
        return (
          <div className="form-group form-primary">
            <label>{getLocalizeText(label)}</label>
            <div className="position-relative">
              {name === "phone" ? (
                <PhoneInput
                  country={"de"}
                  localization={selectedLang === lang.GR ? de : undefined}
                  disabled={actionLoading}
                  buttonClass={actionLoading ? "react-tel-input-disabled" : ""}
                  disableDropdown={actionLoading}
                  disableSearchIcon={actionLoading}
                  inputClass={`form-control ${
                    errorFields.length > 0 &&
                    errorFields.includes(name) &&
                    "error-border"
                  }`}
                  inputStyle={{ paddingLeft: "65px", height: "54px" }}
                  autoFormat={true}
                  inputProps={{
                    name,
                    disabled: actionLoading,
                  }}
                  placeholder={""}
                  enableSearch
                  value={contactDetails[name]}
                  onChange={(phone) =>
                    handleInputChange({
                      target: { value: phone, name },
                    } as React.ChangeEvent<HTMLInputElement>)
                  }
                  searchPlaceholder={getLocalizeText(englishText.SEARCH)}
                  searchNotFound={getLocalizeText(
                    englishText.NO_ENTRY_AVAIALBLE
                  )}
                />
              ) : (
                <input
                  id={name}
                  type={type}
                  disabled={actionLoading}
                  value={contactDetails[name] || ""}
                  name={name}
                  className={`form-control ${
                    name === "birthdate" && "uppercase"
                  } ${
                    errorFields.length > 0 &&
                    errorFields.includes(name) &&
                    "error-border"
                  }`}
                  placeholder={getLocalizeText(placeholder as string)}
                  onChange={handleInputChange}
                />
              )}
            </div>
            <span className="form-bar" />
          </div>
        );
    }
  };

  const tooltip = (msg: string, className?: string) => (
    <div
      className={`tool-tip-wrapper ${className} ${
        selectedLang === lang.GR && "de"
      }`}
    >
      <OverlayTrigger
        placement="top"
        overlay={(props: any) => (
          <Tooltip id="button-tooltip" {...props}>
            {getLocalizeText(msg)}
          </Tooltip>
        )}
      >
        <Button variant="light" className="tool-tip-btn">
          <ExclamationMarkFilled width={"20px"} height={"20px"} />
        </Button>
      </OverlayTrigger>
    </div>
  );

  return (
    <div className="personal-info-form-wrapper">
      <div className="row">
        <div className="col-md-12">
          <div className="hint-note-wrapper full-width">
            <div className="note-heading d-flex align-items-center justify-content-start">
              <ExclamationMark className="mr-2" />
              <FormattedMessage
                id={englishText.WHY_QUESTION_HEADING}
                defaultMessage={englishText.WHY_QUESTION_HEADING}
              />
            </div>
            <p> {props.selectedLang === lang.EN ? hintEn : hintDe}</p>
          </div>
        </div>
      </div>
      <form className="md-float-material form-material">
        <div className="row">
          <div className="col-md-6">
            <div className="col-sm-12 form-title p-0">
              <FormattedMessage
                id={englishText.PERSONAL_DATA}
                defaultMessage={englishText.PERSONAL_DATA}
              />
              {tooltip(englishText.PERSONAL_DATA_TOOLTIP, "personal-data")}
            </div>

            <div className="row" style={{ paddingRight: "20px" }}>
              <div className="col-md-12">{renderInputs(genderField)}</div>
              {leftSection.map((field: InputFieldInterface, index) => (
                <div className={`col-md-${field.partition}`} key={index}>
                  {renderInputs(field)}
                </div>
              ))}
            </div>
          </div>

          <div className="col-md-6">
            <div className="row" style={{ paddingLeft: "20px" }}>
              <div
                className="col-sm-12 form-title"
                // style={{ marginBottom: "57px" }}
              >
                <FormattedMessage
                  id={englishText.CONTACT_DATA}
                  defaultMessage={englishText.CONTACT_DATA}
                />
                {tooltip(englishText.CONTACT_TOOLTIP, "contact")}
              </div>

              {rightSection.map((field: InputFieldInterface, index) => (
                <div className={`col-md-${field.partition}`} key={index}>
                  {renderInputs(field)}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group form-primary d-flex justify-content-center mt-3">
              <button
                type="button"
                className="btn immozy-home-action-btn"
                onClick={updateProfile}
                disabled={actionLoading || !changed}
              >
                <FormattedMessage
                  id={englishText.SAVE}
                  defaultMessage={englishText.SAVE}
                />
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default RenderPersonalInfoForm;
