import React, { SyntheticEvent } from "react";
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import { setPlaceholderImg } from "utils/functions";
import * as objectGalleryInterface from "../../interface/objectDetails";
import realtorImg from "assets/images/special_replace_realtor.jpg";

const ObjectGallery: React.FC<objectGalleryInterface.IObjectGalleryProps> = (
  props
) => {
  const { objectPictures, isReplaceImageRealtor } = props;

  const setRealtorImage = (e: SyntheticEvent) => {
    (e.target as HTMLImageElement).src = realtorImg;
  };

  return (
    <div className="object-image-gallery">
      <div className="row pointer">
        <div
          className={`column-wrapper ${
            objectPictures.length === 1 ? "col-12" : "col-6"
          }`}
        >
          <div
            className="img-wrapper lg"
            onClick={() => {
              props.openGallary(0);
            }}
          >
            <img
              src={objectPictures[0]}
              alt=""
              className="w-100"
              onError={
                isReplaceImageRealtor ? setRealtorImage : setPlaceholderImg
              }
            />
          </div>
        </div>
        {objectPictures && objectPictures.length > 1 && (
          <div className="column-wrapper col-6 ">
            <div className={`d-flex flex-wrap`}>
              <div
                className={`img-wrapper ${
                  objectPictures.length === 2 ? "lg w-100" : ""
                } ${
                  objectPictures.length > 2 && objectPictures.length < 5
                    ? "w-100"
                    : "w-50"
                }`}
                onClick={() => props.openGallary(1)}
              >
                <img
                  src={objectPictures[1]}
                  alt=""
                  className="w-100"
                  onError={
                    isReplaceImageRealtor ? setRealtorImage : setPlaceholderImg
                  }
                />
              </div>
              {objectPictures.length > 4 && (
                <div
                  className={`img-wrapper w-50`}
                  onClick={() => props.openGallary(4)}
                >
                  <img
                    src={objectPictures[4]}
                    alt=""
                    className="w-100"
                    onError={
                      isReplaceImageRealtor
                        ? setRealtorImage
                        : setPlaceholderImg
                    }
                  />
                </div>
              )}
              {objectPictures.length > 2 && (
                <>
                  <div
                    className={`img-wrapper ${
                      objectPictures.length === 3 ? "w-100" : "w-50"
                    }`}
                    onClick={() => props.openGallary(2)}
                  >
                    <img
                      src={objectPictures[2]}
                      alt=""
                      className="w-100"
                      onError={
                        isReplaceImageRealtor
                          ? setRealtorImage
                          : setPlaceholderImg
                      }
                    />
                  </div>
                  {objectPictures.length > 3 && (
                    <div
                      className={`img-wrapper w-50`}
                      onClick={() => props.openGallary(3)}
                    >
                      <img
                        src={objectPictures[3]}
                        alt=""
                        className="w-100"
                        onError={
                          isReplaceImageRealtor
                            ? setRealtorImage
                            : setPlaceholderImg
                        }
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
        {objectPictures.length > 2 && (
          <button className="button" onClick={() => props.openGallary()}>
            {`${getLocalizeText(englishText.SHOW_ALL)} (${
              objectPictures.length
            })`}
          </button>
        )}
      </div>
    </div>
  );
};

export default ObjectGallery;
