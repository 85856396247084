import callAPI from "services/callApi";

// Map actions
export const getMapFilterResult = (type: string, cities?: string) => {
  const url = cities
    ? `v1/geolocation/${type}?cities=${cities}`
    : `v1/geolocation/${type}`;
  return {
    type: "FETCH_MAP_SEARCH",
    payload: callAPI(url, "GET"),
  };
};

export const getInterestedObjList = () => ({
  type: "INT_OBJ_LIST",
  payload: callAPI("v4/objects/interestedPropertyList", "POST"),
});
