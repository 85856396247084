import React, { useState, useEffect } from "react";
import ButtonDiv from "./buttonDiv";
import { purchaseStatus, commisionType, sellerInfo } from "../utils/constants";
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import { FormattedMessage } from "react-intl";
import CustomSelect from "./customselect";
import { IFinanceProps } from "../interface/myproperty.interface";

const FinanceTab: React.FC<IFinanceProps> = (props) => {
  const {
    financeObj,
    handleValueChange,
    setType,
    handleChangeForSeller,
    emptyFields,
  } = props;

  const [isScrolledUp, setScroll] = useState(false);
  const [toggleOptions, changeToggleOptions] = useState(false);
  const [showDrop, changeShowDrop] = useState("");

  useEffect(
    () => {
      if (!isScrolledUp) {
        props.scrollUp();
        setScroll(true);
      }
    },
    // eslint-disable-next-line
    []
  );

  const handleSaveClick = () => {
    const { financeObj, handleSaveAndContinue } = props;
    handleSaveAndContinue(financeObj, "", "v1/objects/financial", "PUT");
  };

  return (
    <div className="general-main-div row mb-4">
      <div className="col-sm-6 mb-4 mt-4">
        {/* Price */}
        <div className="row form-group mb-3">
          <label className="col-sm-5 form-check-label">
            <FormattedMessage
              id={englishText.PRICE}
              defaultMessage={englishText.PRICE}
            />
            {" (€)*"}
          </label>
          <input
            className="form-control col-sm-3"
            value={financeObj.price}
            type={"number"}
            min={0}
            name={"price"}
            onChange={handleValueChange}
            style={
              emptyFields.includes("price") ? { borderColor: "#d84747" } : {}
            }
          />
        </div>
        {/* Agent comission */}
        <div className="row form-group mb-3 com-div">
          <label className="col-sm-5 form-check-label">
            <FormattedMessage
              id={englishText.AGENT_COM}
              defaultMessage={englishText.AGENT_COM}
            />
            *
          </label>
          <input
            className="form-control col-sm-3"
            value={financeObj.agentCommission}
            type={"number"}
            name={"agentCommission"}
            min={0}
            onChange={handleValueChange}
            style={
              emptyFields.includes("agentCommission")
                ? { borderColor: "#d84747" }
                : {}
            }
          />
          <i
            className={`fa fa-eur ${
              financeObj.commisionType === commisionType.VALUE && "active"
            } mr-1 ml-2`}
            aria-hidden="true"
            onClick={() => setType(commisionType.VALUE)}
            title={getLocalizeText(englishText.IN_EUROS)}
          />
          <i
            className={`fa fa-percent ${
              financeObj.commisionType === commisionType.PERCENTAGE && "active"
            }`}
            aria-hidden="true"
            onClick={() => setType(commisionType.PERCENTAGE)}
            title={getLocalizeText(englishText.IN_PRECENTAGE)}
          />
        </div>
        {/* additional fees */}
        <div className="row form-group mb-3">
          <label className="col-sm-5 form-check-label">
            <FormattedMessage
              id={englishText.ADD_FEES}
              defaultMessage={englishText.ADD_FEES}
            />
            {" (€)"}
          </label>
          <input
            className="form-control col-sm-3"
            value={financeObj.additionalFees}
            type={"number"}
            min={0}
            name={"additionalFees"}
            onChange={handleValueChange}
          />
        </div>
        {/* Purchase status */}
        <div className="row form-group mb-3">
          <label className="col-sm-4 form-check-label">
            <FormattedMessage
              id={englishText.PURCHASE_STATUS}
              defaultMessage={englishText.PURCHASE_STATUS}
            />
          </label>

          <div
            className="col-sm-4"
            style={{
              padding: "0",
              marginLeft: "25px",
            }}
          >
            <CustomSelect
              name={"purchaseNegotiationStatus"}
              options={purchaseStatus}
              selectedOption={financeObj.purchaseNegotiationStatus}
              changeSelectedOption={handleValueChange}
              toggleOptions={toggleOptions}
              showDrop={showDrop}
              changeToggleOptions={changeToggleOptions}
              changeShowDrop={changeShowDrop}
            />
          </div>
          {/* <select
            className="form-control col-sm-4"
            onChange={handleValueChange}
            name={"purchaseNegotiationStatus"}
          >
            <option value="" key={0} disabled selected>
              {getLocalizeText(englishText.PLEASE_SELECT)}
            </option>
            {purchaseStatus.map((type, i) => (
              <option
                selected={type === financeObj.purchaseNegotiationStatus}
                key={i + 1}
                value={type}
              >
                {getLocalizeText(type)}
              </option>
            ))}
          </select> */}
        </div>
      </div>
      {/* Seller information section */}
      <div className="col-sm-6" style={{ marginTop: "-20px" }}>
        <h5 className="ml-3 mb-4">
          <FormattedMessage
            id={englishText.SELLER_INFO}
            defaultMessage={englishText.SELLER_INFO}
          />
        </h5>
        {sellerInfo.map((seller, i) => (
          <div className="row form-group mb-3" key={i}>
            <label className="col-sm-2 form-check-label">
              {getLocalizeText(seller.label)}
            </label>
            <input
              className="form-control col-sm-5"
              name={seller.name}
              value={financeObj.sellerOwner[seller.name]}
              type={seller.name === "phone" ? "tel" : "text"}
              onChange={handleChangeForSeller}
            />
          </div>
        ))}
      </div>
      <ButtonDiv
        handleSaveClick={handleSaveClick}
        emptyFields={emptyFields}
        goBack={props.goBack}
      />
    </div>
  );
};

FinanceTab.defaultProps = {
  emptyFields: [],
};

export default FinanceTab;
