import React from "react";
import { FormattedMessage } from "react-intl";
import englishText from "languages/english/english";
import Backbutton from "components/commonComponents/backbutton";
import getLocalizeText from "utils/getLocalizeText";

interface IButtonProps {
  emptyFields: string[];
  show?: boolean;
  handleSaveClick: () => void;
  goBack?: () => void;
}

const ButtonDiv: React.FC<IButtonProps> = (props) => (
  <div className="add-prop-next back-btn">
    {props.emptyFields && props.emptyFields.length > 0 && (
      <p className="field-err">*{getLocalizeText(englishText.EMPTY_ERR)}</p>
    )}
    {props.show && props.goBack && (
      <Backbutton onclick={props.goBack} emptyFields={props.emptyFields} />
    )}
    <button
      className={`modal-btn ${
        props.emptyFields && props.emptyFields.length > 0 ? "mt-2" : "mt-4"
      } mb-3`}
      onClick={() => props.handleSaveClick()}
      style={{ textTransform: "capitalize" }}
    >
      <FormattedMessage
        id={englishText.SAVE_AND_CONT}
        defaultMessage={englishText.SAVE_AND_CONT}
      />
    </button>
  </div>
);

ButtonDiv.defaultProps = {
  show: true,
  emptyFields: [],
};

export default ButtonDiv;
