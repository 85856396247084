import englishText from "languages/english/english";
import { lang, getLocalizeText } from "utils";
import { InputFieldInterface, OptionsInterface } from "../interface/immozyhome";

const selectedLang = localStorage.getItem("language");

export const sectionTitleEnum: any = {
  onboarding: getLocalizeText(englishText.ONBOARDING_SECTION_LABEL),
  profile: getLocalizeText(englishText.PROFILE_SECTION_LABEL),
  finance: getLocalizeText(englishText.FINANCING_SECTION_LABEL),
};

export const genderOptions: OptionsInterface[] = [
  { label: englishText.MR, value: "MALE" },
  { label: englishText.MS, value: "FEMALE" },
  { label: englishText.MISCELLANEOUS, value: "OTHER" },
];

export const genderField = {
  label: englishText.GENDER,
  name: "gender",
  type: "radio",
  partition: 12,
  options: genderOptions,
};

export const reachabilityOptions: OptionsInterface[] = [
  {
    label:
      selectedLang === lang.GR
        ? `08:00 - 10:00 ${getLocalizeText(englishText.UHR_AM)}`
        : "8 - 10 am",
    value: "08-10",
  },
  {
    label:
      selectedLang === lang.GR
        ? `10:00 - 12:00 ${getLocalizeText(englishText.UHR_AM)}`
        : "10 - 12 am",
    value: "10-12",
  },
  {
    label:
      selectedLang === lang.GR
        ? `12:00 - 14:00 ${getLocalizeText(englishText.UHR_PM)}`
        : "12 am - 2 pm",
    value: "12-14",
  },
  {
    label:
      selectedLang === lang.GR
        ? `14:00 - 16:00 ${getLocalizeText(englishText.UHR_PM)}`
        : "2 pm - 4 pm",
    value: "14-16",
  },
  {
    label:
      selectedLang === lang.GR
        ? `16:00 - 18:00 ${getLocalizeText(englishText.UHR_PM)}`
        : "4 pm - 6 pm",
    value: "16-18",
  },
  {
    label:
      selectedLang === lang.GR
        ? `18:00 - 20:00 ${getLocalizeText(englishText.UHR_PM)}`
        : "6 pm - 8 pm",
    value: "18-20",
  },
];

export const employmentOptions: OptionsInterface[] = [
  { label: getLocalizeText(englishText.EMPLOYED), value: "Employed" },
  { label: getLocalizeText(englishText.SELF_EMPLOYED), value: "Self_employed" },
  {
    label: getLocalizeText(englishText.PUBLIC_SERVICE),
    value: "Public_service",
  },
  { label: getLocalizeText(englishText.FREELANCE), value: "Freelancer" },
  { label: getLocalizeText(englishText.Student), value: "Student" },
  { label: getLocalizeText(englishText.PRIVATE_INDIVIDUAL), value: "Private" },
  { label: getLocalizeText(englishText.PENSIONER), value: "Pensioner" },
];

export const leftSection: InputFieldInterface[] = [
  {
    label: englishText.FIRST_NAME,
    name: "firstName",
    type: "text",
    partition: 6,
  },
  {
    label: englishText.LAST_NAME,
    name: "lastName",
    type: "text",
    partition: 6,
  },
  {
    label: englishText.BIRTH_DATE,
    name: "birthdate",
    placeholder: englishText.BIRTH_DATE,
    type: "date",
    partition: 12,
  },
  {
    label: englishText.POSTAL_CODE,
    name: "postalcode",
    type: "number",
    partition: 5,
  },
  {
    label: englishText.CITY_2,
    name: "city",
    type: "text",
    partition: 7,
  },
  {
    label: englishText.STREET_HOUSE,
    name: "street",
    type: "text",
    partition: 12,
  },
  {
    label: englishText.EMPLOYMENT,
    name: "employment",
    placeholder: englishText.EMPLOYMENT,
    type: "select",
    partition: 12,
    options: employmentOptions,
  },
];

export const rightSection: InputFieldInterface[] = [
  {
    label: englishText.EMAIL_1,
    name: "email",
    type: "email",
    partition: 12,
  },
  {
    label: englishText.PHONE,
    name: "phone",
    type: "number",
    partition: 12,
  },
  {
    label: englishText.REACHABILTY_TEXT,
    name: "reachability",
    placeholder: englishText.REACHABILTY_TEXT,
    type: "select",
    partition: 12,
    options: reachabilityOptions,
    // defaultValue: reachabilityOptions[0],
  },
];

export const schufaOptions = [
  { label: englishText.JA, value: true },
  { label: englishText.NEIN, value: false },
];
export const financeOptions = [
  { label: englishText.JA, value: true },
  { label: englishText.NEIN, value: false },
];
export const needFinancingOptions = [
  { label: englishText.JA, value: true },
  { label: englishText.NEIN, value: false },
];

export const fileTypes = ["JPG", "JPEG", "PNG", "PDF"];