import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { primaryColor } from "utils";
import englishText from "languages/english/english";

const GoToDashboardBtn: React.FC = () => (
  <Link to="/find">
    <button
      className="button button-filter mt-3"
      style={{
        width: "200px",
        height: "45px",
        borderRadius: "25px",
        color: primaryColor,
      }}
    >
      <FormattedMessage
        id={englishText.GO_TO_SEARCH}
        defaultMessage={englishText.GO_TO_SEARCH}
      />
    </button>
  </Link>
);

export default GoToDashboardBtn;
