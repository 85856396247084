import React from 'react';
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { ExclamationMarkFilled } from "./icons";
interface BadgeProps {
  icon?: JSX.Element;
  text: string;
  currentLanguage?: string;
  toolTip?: string;
}
export const Badge: React.FC<BadgeProps> = ({ text, toolTip }: BadgeProps) => (
  <div className="badge-wrapper">
    {/* {icon} */}
    <p>{text}</p>
    {toolTip && (
      <div className={`tool-tip-wrapper`} style={{ right: "-30px" }}>
        <OverlayTrigger
          placement="top"
          overlay={(props: any) => (
            <Tooltip id="button-tooltip" {...props}>
              {toolTip}
            </Tooltip>
          )}
        >
          <Button variant="light" className="tool-tip-btn">
            <ExclamationMarkFilled width={"20px"} height={"20px"} />
          </Button>
        </OverlayTrigger>
      </div>
    )}
  </div>
);