import React, { useState } from "react";
import ButtonDiv from "./buttonDiv";
import { inputFields } from "../utils/constants";
import englishText from "languages/english/english";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import SuggestionInputSearch from "suggestion-react-input-search";
import { autosuggestionList } from "../utils/autosuggestionList";
import CustomSelect from "./customselect";
import { ILocationProps } from "../interface/myproperty.interface";
import { cities, getLocalizeText } from "utils";

const LocationTab: React.FC<ILocationProps> = (props) => {
  const { locationObj, qtrList, emptyFields, handleAutosuggest } = props;

  const [toggleOptions, changeToggleOptions] = useState(false);
  const [showDrop, changeShowDrop] = useState("");

  const handleSaveClick = () => {
    const { locationObj, handleSaveAndContinue } = props;

    handleSaveAndContinue(
      locationObj,
      "property",
      "v1/objects/location",
      "PUT"
    );
  };

  const dropdownFields = [
    {
      label: englishText.CITY,
      options: Object.values(cities),
      name: "city",
      isRequired: true,
    },
    {
      label: englishText.QUARTER,
      options: qtrList,
      name: "quarter",
      isRequired: true,
    },
  ];

  return (
    <>
      <div className="row general-main-div">
        <div className="col-sm-5">
          <div className="row form-group  mb-3">
            {/* Country */}
            <label title="" className="col-sm-4 form-check-label">
              {getLocalizeText(englishText.COUNTRY)}
            </label>
            <input
              className="form-control col-sm-7"
              value={props.locationObj.countryId}
              disabled={true}
            />
          </div>
          {/* City and Quarter */}
          {dropdownFields.map((field, index) => (
            <div className="row form-group  mb-3" key={index}>
              <label title="" className="col-sm-4 form-check-label">
                {getLocalizeText(field.label)} {field.isRequired && "*"}
              </label>
              <div
                className="col-sm-7"
                style={{
                  padding: "0",
                  marginLeft: "25px",
                }}
              >
                <CustomSelect
                  field={field}
                  name={field.name}
                  options={field.options}
                  selectedOption={locationObj[field.name]}
                  changeSelectedOption={props.handleValueChange}
                  emptyFields={emptyFields}
                  toggleOptions={toggleOptions}
                  showDrop={showDrop}
                  changeToggleOptions={changeToggleOptions}
                  changeShowDrop={changeShowDrop}
                />
              </div>
            </div>
          ))}
        </div>
        {/* Auti suggestion field */}
        <div className="col-sm-5">
          <div className="row form-group  mb-3">
            <label title="" className="col-sm-4 form-check-label">
              {getLocalizeText(englishText.SEARCH_LOCATION)}
              <i
                className="fa fa-info-circle info-icon1 mt-2"
                aria-hidden="true"
                title={getLocalizeText(englishText.LOCATION_TOOLTIP)}
               />
            </label>
            <div className="form-control col-sm-7">
              {autosuggestionList.length > 0 && (
                <SuggestionInputSearch
                  onSubmitFunction={handleAutosuggest}
                  recentSearches={autosuggestionList}
                  placeholder={
                    props.locationObj.city === ""
                      ? getLocalizeText(englishText.FIRST_SELECT_CITY)
                      : getLocalizeText(englishText.LOCATION_PLCAEHOLDER)
                  }
                  inputPosition={"center"}
                  autocompleteOnMatch={true}
                />
              )}
            </div>
          </div>
          {/* Zip code | street | House number */}
          {inputFields.map((field, index) => (
            <div className="row form-group  mb-3" key={index}>
              <label title="" className="col-sm-4 form-check-label">
                {getLocalizeText(field.label)} {field.isRequired && "*"}
              </label>
              <input
                className="form-control col-sm-7"
                type={field.name === "street" ? "text" : "number"}
                value={locationObj[field.name]}
                name={field.name}
                onChange={props.handleValueChange}
                style={
                  emptyFields.includes(field.name)
                    ? { borderColor: "#d84747" }
                    : {}
                }
              />
            </div>
          ))}
        </div>
      </div>
      <ButtonDiv
        handleSaveClick={handleSaveClick}
        goBack={props.goBack}
        emptyFields={emptyFields}
      />
    </>
  );
};

LocationTab.defaultProps = {
  emptyFields: [],
};

export default LocationTab;
