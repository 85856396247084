import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import jwt_decode from "jwt-decode";
// component
import { Spinner } from "react-bootstrap";
// functions
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import { API_CONFIG, HttpService } from "services";
// assets
import emaillogo from "assets/images/mailcheck.png";
import { Logo } from "components/commonComponents/icons";

export default function VerifyEmail() {
  const [email, setemail] = useState("");
  const [successMsg, setSuccess] = useState("");
  const [showSpinner, setshowSpinner] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const decoded: any = token ? jwt_decode(token) : null;
    if (email === "" && decoded && decoded.email) {
      setemail(decoded.email);
    }
  }, []);

  const hanldeVerifyClick = async () => {
    setshowSpinner(true);
    HttpService.post(API_CONFIG.path.senderVerificationMail)
      .then(() => {
        setSuccess(englishText.EMAIL_SENT_SUCCESS);
        setshowSpinner(false);
      })
      .catch((err) => {
        const msg = err.message ? err.message : englishText.CANT_SEND;
        setSuccess(msg);
        setshowSpinner(false);
      });
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      hanldeVerifyClick();
    }
  };

  return (
    <React.Fragment>
      <div className="logo-imz">
        <Logo />
      </div>
      <div className="text-center email-verify-container">
        <img className="email-logo mt-2 mb-2" src={emaillogo} alt="logo" />
        <p className="verify-title mt-2 mb-2">
          <FormattedMessage
            id={englishText.THANK_YOU_FOR_REG}
            defaultMessage={englishText.THANK_YOU_FOR_REG}
          />
        </p>
        <p className="verify-subtitle mt-2 mb-2">
          <FormattedMessage
            id={englishText.MAIL_SENT_MSG}
            defaultMessage={englishText.MAIL_SENT_MSG}
          />
        </p>
        <p className="resend-title mt-4 mb-3">
          <FormattedMessage
            id={englishText.DIDNT_GET_MAIL}
            defaultMessage={englishText.DIDNT_GET_MAIL}
          />
        </p>

        <div className="mt-2 mb-2">
          <div className="form-group form-primary">
            <div
              className="d-flex align-items-center"
              style={{ height: "45px" }}
            >
              <label className="input-label">E-mail</label>
              {showSpinner && (
                <div className="spinner-wrapper ml-2">
                  <Spinner
                    animation="border"
                    variant="info"
                    style={{ marginTop: "-3px" }}
                  />
                </div>
              )}
            </div>
            <input
              id="inputID"
              type="text"
              name="email"
              placeholder={getLocalizeText(englishText.EXAMPLE_EMAIL)}
              className="login-input-box"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                setemail(event.target.value)
              }
              value={email}
              autoComplete="none"
              onKeyDown={onKeyDown}
            />
            <span className="form-bar" />
          </div>
          {successMsg !== "" && (
            <p className="verify-mail-success">{getLocalizeText(successMsg)}</p>
          )}
          <button
            className="btn mail-send-btn mt-5"
            onClick={hanldeVerifyClick}
            disabled={email === ""}
          >
            <FormattedMessage
              id={englishText.SEND_AGAIN}
              defaultMessage={englishText.SEND_AGAIN}
            />
          </button>
        </div>
      </div>
    </React.Fragment>
  );
}
