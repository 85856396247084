/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, useRef, useState } from "react";
import englishText from "languages/english/english";
import { FormattedMessage } from "react-intl";
import { ExclamationMarkFilled } from "components/commonComponents/icons";
import { getRealtorInfo } from "components/objectDetails/shared/objectFunctions";
import { primaryColor, getLocalizeText } from "utils";

interface IRealtorHeaderProps {
  realtor: any;
}

const RealtorHeader: React.FC<IRealtorHeaderProps> = (props) => {
  const { realtor } = props;

  const toolTipRef: any = useRef();

  const [offsetWidth, setOffsetWidth] = useState(0);

  useEffect(() => {
    toolTipRef &&
      toolTipRef.current &&
      setOffsetWidth(-(toolTipRef.current.offsetWidth / 2 - 8));
  }, [toolTipRef && toolTipRef.current]);

  const linkArray: { text: string; link: string }[] = [
    {
      text: englishText.WEBSITE,
      link: realtor.website_url,
    },
    {
      text: englishText.PRIVACY_POLICY,
      link: realtor.privacy_link,
    },
    {
      text: englishText.IMPRINT,
      link: realtor.imprint,
    },
  ];

  return (
    <>
      <p className="offer-title">
        <FormattedMessage
          id={englishText.YOUR_CONTACT_REQUEST_TO}
          defaultMessage={englishText.YOUR_CONTACT_REQUEST_TO}
        />
      </p>
      <div className="offer-desc">
        {realtor && realtor.active && (
          <div className="mb-4 realtor-name d-flex justify-content-start align-items-stretch mt-2">
            <span style={{ fontSize: "24px", color: primaryColor }}>
              {realtor.provider_company_name || "-"}
            </span>
            <div className="info-btn cursor-pointer ml-2">
              <div
                className="tool-tip"
                ref={toolTipRef}
                style={{ right: offsetWidth, width: "max-content" }}
              >
                {getRealtorInfo(realtor)}
              </div>
              <ExclamationMarkFilled />
            </div>
          </div>
        )}
        {linkArray.map(
          ({ link, text }: { link: string; text: string }, index: number) => (
            <Fragment key={index}>
              <a
                href={link}
                target="_blank"
                rel="noopener noreferrer"
                style={index === 0 ? { marginLeft: "0" } : {}}
              >
                {getLocalizeText(text)}
              </a>{" "}
              {linkArray.length - 1 !== index && <span>|</span>}
            </Fragment>
          )
        )}
      </div>{" "}
    </>
  );
};

export default RealtorHeader;
