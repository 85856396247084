import { Action } from "utils/globalInterface";

export const INITIAL_STATE = {
  response: [],
};

export const mapFilterReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case "FETCH_MAP_SEARCH_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "FETCH_MAP_SEARCH_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_FETCH_MAP_SEARCH":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};
