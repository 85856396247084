import React from "react";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
// actions
import {
  addToFavourites,
  removeFromFavourites,
  clearReducer,
  getSummary,
  getFavoCount,
  getProfileStatusDetails,
} from "actions";
import * as objectFunctions from "../shared/objectFunctions";
import englishText from "languages/english/english";
import {
  setUserIdToLocal,
  disableScrolling,
  getStatusData,
  getLocalizeText,
  sendAmplitudeDataEvent,
} from "utils";
import { API_CONFIG, HttpService } from "services";
// components
import ObjectGallery from "../components/popups/objectGallery";
import ModalComponent from "../../commonComponents/modalComponent";
import ImageGallary from "../components/popups/imageGallary";
import MapPopup from "../components/popups/mapPopup";
import VerifyEmail from "components/layout/components/verifyEmail";
import Calculator from "components/calculator/container/calculator";
import ObjectDescription from "../components/objectDescription";
import ObjectLocation from "../components/objectLocation";
import ContactRealtor from "../components/popups/newPopUps/contactSeller";
import ProfileCompletionPopUp from "../components/popups/newPopUps/profileCompletionPopup";
import ThanksPageOnboarding from "components/immozyHome/components/thanksPageOnboarding";
//interface
import * as objectDetailsInterface from "../interface/objectDetails";
import PlatformcontactPopup from "../components/popups/newPopUps/platformcontactPopup";
import RealtorInfoPopUp from "../components/popups/newPopUps/realtorInfoPopUp";
// style files
import "../../dashboard/dashboard.scss";
import "../objectDetail.scss";
import "react-image-gallery/styles/css/image-gallery.css";
import "../contact.scss";

let id = "";
const selectedLang = localStorage.getItem("language") || "de";
let realtorPage = false;

export const popupName = {
  INTERESTED_POPUP: "interested_popup",
  CONTACT_FORM: "contact_form",
  SUCCESS_POPUP: "success_popup",
};

class ObjectDetails extends React.Component<objectDetailsInterface.IObjectDetailsProps> {
  state: objectDetailsInterface.IObjectDetailsStates = {
    propertyDetail: {},
    imgList: [],
    objectDetails: {},
    initialColorState: objectFunctions.ColorState,
    startIndex: 0,
    isFav: false,
    showModal: false,
    showSpinner: true,
    showMapPopup: false,
    onboarding: true,
    activePopup: "",
    propertyInfoLoading: false,
  };

  scrollRef: any;

  componentDidMount() {
    setUserIdToLocal();
    this.getID();
    if (window.location.pathname.includes("realtor")) {
      realtorPage = true;
    }

    setTimeout(() => {
      const { summaryReducer, getSummary } = this.props;
      if (summaryReducer && !summaryReducer.data) {
        !realtorPage && getSummary();
        setTimeout(() => {
          this.getPropertyInfo(id);
        }, 200);
      } else {
        this.getPropertyInfo(id);
      }
      if (window.location && window.location.pathname.includes("mail")) {
        const meta = {
          propertyId: id,
          Action:
            "User clicked on the link in the notification mail and returned to the product",
        };
        sendAmplitudeDataEvent("notification_mail_reactivation", meta);
      }

      this.getStatus();
    }, 500);
  }

  componentWillUnmount() {
    const { propertyDetail } = this.state;

    const objState = {
      object: id || propertyDetail.id,
      score: propertyDetail.score || 0,
      image: propertyDetail.objectPictures[0] || "",
    };
    localStorage.setItem("last_open_object", JSON.stringify(objState));
  }

  getID = () => {
    const { match, objId } = this.props;
    id = match ? match.params.id : objId;
  };

  setStateForObject = (name: string, value: string | boolean) => {
    this.setState({ [name]: value });
  };

  platformsLinks = (uniquePlatforms: any) =>
    uniquePlatforms &&
    uniquePlatforms.map((i: any, ind: number) => (
      <a key={ind} href={i.link} target="_blank" rel="noopener noreferrer">
        {" " + i.platform}
        {uniquePlatforms.length - 1 === ind ? " " : ", "}
      </a>
    ));

  render() {
    const { propertyDetail, showModal, activePopup } = this.state;
    const { summaryReducer } = this.props;
    return (
      <>
        <div className="object-detail">
          <div className="container">
            {propertyDetail.objectPictures ? (
              <ObjectGallery
                objectPictures={propertyDetail.objectPictures}
                openGallary={(startIndex) => {
                  this.setState({ startIndex }, () =>
                    this.toggleImageGallary(true)
                  );
                }}
                startIndex={this.state.startIndex}
                isReplaceImageRealtor={this.checkRealtor()}
              />
            ) : this.state.showSpinner ? (
              <div className="object-image-gallery">
                <div className="text-center">
                  {[1, 2, 3].map((count) => (
                    <Spinner
                      key={count}
                      className="spin-img"
                      animation="grow"
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div className="disable-object-container">
                {getLocalizeText(englishText.PROPERTY_DISABLED)}
              </div>
            )}

            {this.state.showGallary && (
              <div className={`model-wrapper show`}>
                <ImageGallary
                  imgList={this.state.imgList}
                  closeGallery={() => this.toggleImageGallary(false)}
                  startIndex={this.state.startIndex}
                  isReplaceImageRealtor={this.checkRealtor()}
                />
              </div>
            )}
            {this.state.showMapPopup &&
              propertyDetail &&
              propertyDetail.location && (
                <div
                  className={`model-wrapper ${
                    this.state.showMapPopup ? "show" : ""
                  }`}
                >
                  <MapPopup
                    propertyDetail={propertyDetail}
                    closeMap={() =>
                      this.setState({
                        showMapPopup: false,
                      })
                    }
                  />
                </div>
              )}
            {this.state.showCalculator && (
              <div className={`model-wrapper show`}>
                <Calculator
                  closeCalc={() => this.setState({ showCalculator: false })}
                  price={propertyDetail.price}
                  hasCourtage={propertyDetail.hasCourtage}
                  savings={
                    summaryReducer.data &&
                    summaryReducer.data.Equity &&
                    summaryReducer.data.Equity.data
                  }
                  openContactForm={() => {
                    this.setState({
                      showCalculator: false,
                    });
                    this.openContactUs();
                  }}
                />
              </div>
            )}

            {/* Object description sections */}
            <ObjectDescription
              propertyDetail={this.state.propertyDetail}
              objectDetails={this.state.objectDetails}
              initialColorState={this.state.initialColorState}
              onboarding={this.state.onboarding}
              setStateForObject={this.setStateForObject}
              handleFavoClick={this.handleFavoClick}
              toggleModal={this.toggleModal}
              interestedClickHandle={this.interestedClickHandle}
              isFav={this.state.isFav}
              openCalc={() => this.setState({ showCalculator: true })}
              propertyInfoLoading={
                this.state.propertyInfoLoading || this.state.showSpinner
              }
              selectedLang={selectedLang}
            />

            {/* Object map | location info | contact info */}
            <ObjectLocation
              state={this.state}
              setStateForObject={this.setStateForObject}
              openContactUs={this.openContactUs}
              sendAmplitudeData={this.sendAmplitudeData}
              setWrapperRefScroll={this.setWrapperRefScroll}
            />
          </div>

          {/* Verify Mail popup */}
          {this.state.showMailPopup && (
            <div className="model-wrapper show">
              <VerifyEmail toggleMailPopup={this.toggleMailPopup} />
            </div>
          )}

          {/* Remove favorite popup */}
          {showModal && (
            <ModalComponent
              title={englishText.REMOVE_FAVO_CONFIRM}
              closeModal={() => this.toggleModal(false)}
              handleYes={this.removeFromFav}
              btnText={getLocalizeText(englishText.NO)}
              width={"446px"}
            />
          )}
        </div>
        {activePopup === "profile_prepare" && (
          <ProfileCompletionPopUp
            closeActivePopup={this.closeActivePopup}
            links={propertyDetail.platforms}
            propertyDetail={propertyDetail}
            openContactUs={(refreshPage?: boolean) => {
              if (refreshPage) {
                this.getPropertyInfo(id);
              }
              this.openContactUs();
            }}
            selectedLang={selectedLang}
          />
        )}
        {activePopup === "realtor_info" && (
          <RealtorInfoPopUp
            propertyDetail={propertyDetail}
            closeActivePopup={this.closeActivePopup}
            id={id}
            openRealtorContact={() =>
              this.setState({ activePopup: "contact_realtor" })
            }
            showThanksPage={() => this.setState({ activePopup: "thanks_page" })}
          />
        )}
        {activePopup === "contact_realtor" && (
          <ContactRealtor
            id={id}
            closeActivePopup={this.closeActivePopup}
            propertyDetail={propertyDetail}
            selectedLang={selectedLang}
            openRealtorInfo={() =>
              this.setState({ activePopup: "realtor_info" })
            }
          />
        )}
        {activePopup === "platform_contact" && (
          <PlatformcontactPopup
            propertyDetail={propertyDetail}
            links={propertyDetail.platforms}
            closepopup={() => this.setState({ activePopup: "" })}
          />
        )}
        {activePopup === "thanks_page" && (
          <div
            style={{ padding: "50px" }}
            className="d-flex justify-content-center align-items-center model-wrapper show fade-in"
          >
            <div className="thanks-container">
              <ThanksPageOnboarding
                fromObjDetails={true}
                closepopup={() => this.setState({ activePopup: "" })}
              />
            </div>
          </div>
        )}
      </>
    );
  }

  interestedClickHandle = () => {
    this.state.propertyDetail.is_showprofilepopup
      ? this.setState({ activePopup: "profile_prepare" })
      : this.openContactUs();
  };

  closeActivePopup = () => {
    disableScrolling(false);
    this.setState({ activePopup: "" });
  };

  getPropertyInfo = (id: string) => {
    this.setState({ propertyInfoLoading: true });
    const { summaryReducer } = this.props;
    let objectDetails = null;
    const url = !realtorPage
      ? `v4/objects/details/${id}`
      : `v4/objects/realtor/details/${id}`;
    HttpService.get(url, {})
      .then((response) => {
        this.setState({
          propertyDetail: response.data,
          isFav: response.data.isFavourite,
          propertyInfoLoading: false,
        });
        objectDetails = this.setImagesAndDetails(response.data);

        // only when opened in new window
        if (
          response.data &&
          summaryReducer.data &&
          summaryReducer.status === "success" &&
          objectDetails &&
          this.state.initialColorState.totalPriceColor === ""
        ) {
          if (this.state.onboarding) {
            this.setColors(response.data, summaryReducer.data, objectDetails);
          }
        }
      })
      .catch((err) => {
        this.setState({
          showSpinner: false,
          propertyInfoLoading: false,
        });
        console.log(err);
      });
  };

  // get user varificationa nd onboarding status
  getStatus = () => {
    const data = getStatusData();
    this.setState({ ...data });
  };

  checkRealtor = () => {
    const { propertyDetail } = this.state;

    if (
      (propertyDetail.realtor &&
        propertyDetail.realtor.id === "591b851a-f929-4bc0-b83c-882b00b1b203") ||
      propertyDetail.realtor_id === "591b851a-f929-4bc0-b83c-882b00b1b203"
    ) {
      return true;
    } else {
      return false;
    }
  };

  // Open contact us popup
  openContactUs = () => {
    const realtor = this.state.propertyDetail.realtor;
    const partnerLevel = realtor && realtor.partner_level;
    let show = false;
    //added comment

    if (
      partnerLevel &&
      (partnerLevel === "NEW" || partnerLevel === "NO_PARTNER")
    ) {
      show = false;
    } else if (
      partnerLevel &&
      (partnerLevel === "PERMISSION" || partnerLevel === "CONTRACT")
    ) {
      show = true;
    }

    if (!realtor || !realtor.active || !show) {
      this.setState({ activePopup: "platform_contact" });
    } else if (realtor && realtor.active && show) {
      this.setState({ activePopup: "realtor_info" });
    }
    this.sendAmplitudeData(
      "I'm Interested button clicked",
      "property_interaction"
    );
    HttpService.post(API_CONFIG.path.potentialUser, {});
  };

  sendAmplitudeData = (action: string, eventName: string) => {
    const meta = {
      propertyId: id,
      Action: action,
    };
    sendAmplitudeDataEvent(eventName, meta);
  };

  // toggle verify mail popup
  toggleMailPopup = (value: boolean) => {
    const { showMailPopup } = this.state;
    const status = value || !showMailPopup;
    if (!status) {
      disableScrolling(false);
    }
    this.setState({ showMailPopup: status });
  };

  setWrapperRefScroll = (node: any) => {
    this.scrollRef = node;
  };

  //set images for slider and floorplan and other object details
  setImagesAndDetails = (list: { [key: string]: string[] }) => {
    const imgList: any[] = [];
    const objectDetails: { [key: string]: string[] } = {};
    list.objectPictures &&
      list.objectPictures.forEach((element: any) => {
        const images = {
          original: element,
        };
        imgList.push(images);
      });

    list.objectDetails &&
      list.objectDetails.forEach((ob: any) => {
        let title = "";
        if (ob.title === "Garage/Parking space") {
          title = "Garage";
        } else {
          title = ob.title.replace(/\s+/g, "_");
        }
        objectDetails[title] = ob.value;
      });

    this.setState({
      imgList,
      objectDetails,
      showSpinner: false,
    });
    return objectDetails;
  };

  // add to favorites
  handleFavoClick = () => {
    const { addToFavourites, onFavoChanged } = this.props;
    const { propertyDetail } = this.state;

    if (propertyDetail.isPrivate) {
      HttpService.post(`${API_CONFIG.path.addCustomFavorite}${id}`, {});
    } else {
      addToFavourites(id);
      onFavoChanged && onFavoChanged(id, "add", true);
    }

    setTimeout(() => {
      this.setState({ isFav: true });
      this.props.getFavoCount();
    }, 700);
  };

  // remove from favorites
  removeFromFav = () => {
    const { removeFromFavourites, onFavoChanged } = this.props;
    const { propertyDetail } = this.state;
    if (propertyDetail.isPrivate) {
      HttpService.post(`${API_CONFIG.path.removeCustomFavorite}${id}`, {});
    } else {
      removeFromFavourites(id);
      onFavoChanged && onFavoChanged(id, "remove", true);
    }
    setTimeout(() => {
      this.setState({
        isFav: false,
        showModal: false,
      });
      this.props.getFavoCount();
    }, 700);
  };

  // favorite remove modal
  toggleModal = (value: boolean) => {
    this.setState({ showModal: value });
  };

  // set color bars acoording to property relavance
  setColors = (propertyDetail: any, summaryData: any, objectDetails: any) => {
    const Equity =
      summaryData.Equity && summaryData.Equity.data
        ? parseInt(summaryData.Equity.data)
        : 0;
    const Monthly_costs =
      summaryData.Monthly_costs && summaryData.Monthly_costs.data
        ? parseInt(summaryData.Monthly_costs.data)
        : 0;
    const Rooms = (summaryData.Rooms && summaryData.Rooms.data) || 0;
    const Living_space =
      (summaryData.Living_space && summaryData.Living_space.data) || 0;

    try {
      const totalPriceColor = objectFunctions.getColorFromValue(
        parseInt(propertyDetail.price),
        Equity,
        25
      );

      const equityColor = objectFunctions.getColorFromValue(
        parseInt(propertyDetail.monthlyCost),
        Monthly_costs,
        15
      );

      const plotColor = objectFunctions.getPlotColor(
        parseInt(propertyDetail.areaLiving),
        Living_space[1],
        350
      );

      const propertyColor =
        propertyDetail.objectMatchPercentage > 80
          ? "#65c239"
          : propertyDetail.objectMatchPercentage > 50
          ? "#ffbb00"
          : "#be3131";

      const spaceColor = objectFunctions.getColorForMinMax(
        parseInt(propertyDetail.areaLiving),
        Living_space[0],
        Living_space[1],
        25
      );

      const roomColor = objectFunctions.getColorForRooms(
        parseInt(propertyDetail.propRoomsAmount),
        Rooms[0],
        Rooms[1]
      );

      const energyColor = objectFunctions.getColorForEnergy(
        propertyDetail.energyEffenciencyClass
      );
      let vacantColor = "#fff";

      if (objectDetails.Vacant) {
        vacantColor =
          objectDetails.Vacant.toLowerCase() === "nach vereinbarung" ||
          objectDetails.Vacant.toLowerCase().includes("sofort")
            ? "#65c239"
            : "#ffbb00";
      }

      const trendColor = objectFunctions.getTrendColor(
        propertyDetail.priceTrend
      );

      const conditionColor = objectFunctions.getConditionColor(
        propertyDetail.objectCondition
      );

      this.setState((prevState: any) => ({
        initialColorState: {
          ...prevState.initialColorState,
          totalPriceColor,
          roomColor,
          spaceColor,
          propertyColor,
          equityColor,
          energyColor,
          vacantColor,
          plotColor,
          trendColor,
          conditionColor,
        },
      }));
      this.setState({
        showSpinner: false,
      });
    } catch (error) {
      console.log("Error occured in coloring");
      this.setState({
        showSpinner: false,
      });
    }
  };

  // toggle image gallary
  toggleImageGallary = (value: boolean) => {
    this.setState({ showGallary: value });
    if (!value) {
      this.setState({ startIndex: "" });
    }
    disableScrolling(value);
  };

  closeContactPopup = () => {
    this.setState({ activePopup: "" });
    disableScrolling(false);
  };
}

const mapStateToProps = (state: any) => ({
  summaryReducer: state.summaryReducer,
  profileCountReducer: state.profileCountReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  addToFavourites: (objId: string) => {
    dispatch(addToFavourites(objId));
  },
  removeFromFavourites: (objId: string) => {
    dispatch(removeFromFavourites(objId));
  },
  clearReducer: (type: string) => {
    dispatch(clearReducer(type));
  },
  getSummary: () => {
    dispatch(getSummary());
  },
  getFavoCount: () => {
    dispatch(getFavoCount());
  },
  getProfileStatusDetails: () => {
    dispatch(getProfileStatusDetails());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ObjectDetails);
