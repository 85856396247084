// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import englishText from "languages/english/english";
import {
  houseTypes,
  lang,
  sendAmplitudeDataEvent,
  getLocalizeText,
  convertToLocaleFormat,
} from "utils";
import {
  MapPinIcon,
  HouseIcon,
  MoneyIcon,
} from "components/commonComponents/icons";
import * as colorStateInterface from "../interface/objectDetails";
import { IKeyValue } from "utils/globalInterface";

const language = localStorage.getItem("language");

export const getCategoryForProperty = (type: string) => {
  const categories = [];
  for (const property in houseTypes) {
    if (houseTypes[property].includes(type)) {
      categories.push(property);
    }
  }
  return categories;
};

export const getColorFromValue = (
  value: number,
  summaryValue: number,
  percentage: number
) => {
  const difference = summaryValue * (percentage / 100);
  const borderValue = summaryValue + difference;

  if (value <= summaryValue) {
    return "#65c239";
  } else if (value <= borderValue) {
    return "#ffbb00";
  } else {
    return "#be3131";
  }
};

export const getPlotColor = (
  value: number,
  summaryValue: number,
  percentage: number
) => {
  const difference = summaryValue * (percentage / 100);
  const borderValue = summaryValue + difference;

  if (value > borderValue) {
    return "#65c239";
  } else {
    return "#ffbb00";
  }
};

export const getColorForEnergy = (value: string) => {
  if (["A", "B", "C", "A_PLUS"].includes(value)) {
    return "badge badge-green color-black mr-2";
  } else if (["D", "E"].includes(value)) {
    return "badge badge-yellow color-black mr-2";
  } else {
    return "badge badge-light-red color-black mr-2";
  }
};

export const getColorForMinMax = (
  value: number,
  min: number,
  max: number,
  percentage: number
) => {
  const difference = max * (percentage / 100);
  const borderValue = max + difference;

  if (value >= min && value <= max) {
    return "#65c239";
  } else if (value <= borderValue) {
    return "#ffbb00";
  } else {
    return "#be3131";
  }
};

export const getColorForRooms = (value: number, min: number, max: number) => {
  if (value >= min && value <= max + 1) {
    return "#65c239";
  } else if (value <= max + 2) {
    return "#ffbb00";
  } else {
    return "#be3131";
  }
};

export const getTrendColor = (value: number) => {
  if (value >= 4) {
    return "#65c239";
  } else if (value >= 1) {
    return "#ffbb00";
  } else {
    return "#be3131";
  }
};

export const getConditionColor = (value: string) => {
  const greenCondition = [
    "well_kept",
    "modernized",
    "mint_condition",
    "fully_renovated",
    "first_time_use",
  ];
  const yellowCondition = [
    "refurbished",
    "first_time_use_after_refurbishment",
    "negotiable",
  ];
  const redCondition = [
    "no_information",
    "need_of_renovation",
    "ripe_for_demolition",
  ];

  if (greenCondition.includes(value)) {
    return "#65c239";
  } else if (yellowCondition.includes(value)) {
    return "#ffbb00";
  } else if (redCondition.includes(value)) {
    return "#be3131";
  } else {
    return "#ffbb00";
  }
};

export const getIcon = (value: number) => {
  if (value <= 33) {
    return "#be3131";
  } else if (value > 33 && Math.round(value) <= 67) {
    return "#ffbb00";
  } else {
    return "#65c239";
  }
};

export const getProgress = (value: number) => {
  if (value <= 33) {
    return "danger";
  } else if (value > 33 && value <= 67) {
    return "warning";
  } else {
    return "success";
  }
};

export const getFacts = (
  propertyDetail: any,
  objectDetails: any,
  initialColorState: colorStateInterface.ColorState
) => [
  {
    label: englishText.PROP_SUB_TYPE,
    value: propertyDetail.propertyType || "-",
    color: initialColorState.propertyColor,
  },
  {
    label: englishText.YEAR_BUILT,
    value: propertyDetail.yearConstructed || "-",
    color: "#fff",
  },
  {
    label: englishText.PLOT_AREA,
    value: objectDetails.Plot_area_approx || "-",
    color: initialColorState.plotColor,
  },
  {
    label: englishText.LIVING_SPACE,
    value: propertyDetail.areaLiving
      ? propertyDetail.areaLiving.toString().replace(",", ".") + " m²"
      : "-",
    color: initialColorState.spaceColor,
  },
  {
    label: englishText.CONDITION,
    value:
      (propertyDetail.objectCondition &&
        getLocalizeText(propertyDetail.objectCondition).replace(/[_]/g, " ")) ||
      "-",
    color: initialColorState.conditionColor,
  },
  {
    label: englishText.FURNISHING,
    value: "-",
    color: "#fff",
  },
  {
    label: englishText.BEDROOMS,
    value: objectDetails.Sleeping_rooms || "-",
    color: initialColorState.roomColor,
  },
  {
    label: englishText.ROOMS,
    value: propertyDetail.propRoomsAmount
      ? propertyDetail.propRoomsAmount
      : "-",
    color: initialColorState.roomColor,
  },
  {
    label: englishText.BATHROOMS,
    value: objectDetails.Bathrooms || "-",
    color: "#fff",
  },

  {
    label: englishText.FLOORS,
    value: objectDetails.Total_floor || "-",
    color: "#fff",
  },
  {
    label: englishText.CONSTRUCTION_PHASE,
    value: objectDetails.constructionPhase || "-",
    color: "#fff",
  },
  {
    label: englishText.VACANT_FROM,
    value: objectDetails.Vacant || "-",
    color: initialColorState.vacantColor,
  },
];

export const getFinance = (
  propertyDetail: any
  // initialColorState: colorStateInterface.ColorState
) => [
  {
    label: englishText.PURCHASE_PRICE,
    value: propertyDetail.formatedPrice
      ? `${propertyDetail.formatedPrice}`
      : "-",
    // color: initialColorState.equityColor,
    color: getIcon(propertyDetail.financialScore),
  },

  {
    label: englishText.PURCHASE_PRICE_M,
    value: propertyDetail.pricePerSqm || "-",
    color: getIcon(propertyDetail.financialScore),
  },
  {
    label: englishText.AGENT_COM,
    value: propertyDetail.brokerCommissionPercentage || "-",
  },
  {
    label: englishText.ADDITIONAL_COST,
    value: propertyDetail.additionalCost || "-",
  },
  {
    label: englishText.TOTAL_COSTS,
    value: propertyDetail.totalCost || "-",
  },
  {
    label: englishText.MONTHLY_COST_EST,
    value: propertyDetail.monthlyCost || "-",
    // color: initialColorState.equityColor,
    color: getIcon(propertyDetail.financialScore),
  },
  {
    label: englishText.PRICE_TREND,
    value: propertyDetail.priceTrend || "-",
    //color: initialColorState.trendColor,
    color: getIcon(propertyDetail.financialScore),
  },
];

export const getDetail = (propertyDetail: any, objectDetails: any) => [
  {
    label: englishText.GARDEN,
    value: propertyDetail.equipGarden ? englishText.YES : englishText.NO,
  },
  {
    label: englishText.BALCONY,
    value: propertyDetail.equipBalcony ? englishText.YES : englishText.NO,
  },
  {
    label: englishText.PARKING,
    value: objectDetails.Garage ? englishText.YES : englishText.NO,
  },
  {
    label: englishText.KITCHEN_FITTED,
    value: objectDetails.equipKitchenFitted ? englishText.YES : englishText.NO,
  },
  {
    label: englishText.ENERGY_CLASS,
    value: propertyDetail.energyEffenciencyClass || "-",
  },
  {
    label: englishText.GUEST_TOILET,
    value: propertyDetail.equipBathGuestToilet
      ? englishText.YES
      : englishText.NO,
  },
];
export const getEnergyData = (energy_data: any) => {
  const energyDataArray: IKeyValue[] = [];

  const details = [
    { label: englishText.EN_CONST_YEAR, name: "construction_year" },
    { label: englishText.HEATING_TYPE, name: "heating_type" },
    { label: englishText.ENERGY_DEMAND, name: "energy_demand" },
    { label: englishText.ENERGY_CLASS, name: "value_class" },
    { label: englishText.PRIMARY_EN_SOURCE, name: "primary_energy_source" },
  ];

  details.forEach((data) => {
    const value = energy_data[data.name];
    if (value && value !== "" && value !== "NO_INFORMATION") {
      energyDataArray.push({
        label: data.label,
        value:
          data.label === englishText.ENERGY_DEMAND
            ? convertToLocaleFormat(value) + " kWh/(m²·a)"
            : value,
      });
    }
  });

  return energyDataArray;
};

export const ColorState = {
  totalPriceColor: "",
  roomColor: "",
  spaceColor: "",
  propertyColor: "",
  equityColor: "",
  plotColor: "",
  energyColor: "",
  vacantColor: "",
  trendColor: "",
  conditionColor: "",
};

export const getLocalSupplyArray = (propertyDetail: any) => [
  {
    label: englishText.SHOPPING,
    value: propertyDetail.closestDistance.shopping || "-",
  },
  {
    label: englishText.PRIMARY_SCHOOL,
    value: propertyDetail.closestDistance.primary_schools || "-",
  },
  {
    label: englishText.UNIVERSITY,
    value: propertyDetail.closestDistance.university || "-",
  },
  {
    label: englishText.PARKS,
    value: propertyDetail.closestDistance.parks || "-",
  },
  {
    label: englishText.PUBLIC_TRANSPORT,
    value: propertyDetail.closestDistance.transports || "-",
  },
];

export const getLocalInfoArray = (propertyDetail: any) => [
  { label: englishText.QUARTER, value: propertyDetail.adrQuarter || "-" },
  { label: englishText.POPULATION, value: propertyDetail.population || "-" },
  {
    label: englishText.AREA,
    value: propertyDetail.areakm ? propertyDetail.areakm + " km² " : "-",
  },
];

export const getScoreArray = (propertyDetail: any) => [
  {
    icon: <HouseIcon />,
    label: propertyDetail.requirementsScore,
    tooltip: "property",
    subtitle: englishText.PROPERTY_SCORE_SUBTITLE,
    title: englishText.PROPERTY_SCORE,
  },
  {
    icon: <MapPinIcon />,
    label: propertyDetail.locationScore,
    tooltip: "location",
    subtitle: englishText.LOCATION_SCORE_SUBTITLE,
    title: englishText.LOCATION_SCORE,
  },
  {
    icon: <MoneyIcon />,
    label: propertyDetail.financialScore,
    tooltip: "price",
    subtitle: englishText.FINANCIAL_SCORE_SUBTITLE,
    title: englishText.FINANCIAL_SCORE,
  },
];

export const charLimit = 600;

export const getDescription = (propertyDetail: any, showReadMore: boolean) => {
  if (propertyDetail.objectDescription.length > charLimit && !showReadMore) {
    const a = propertyDetail.objectDescription.substring(0, charLimit);
    return a.split("<br />");
  }
  return propertyDetail.objectDescription.split("<br />");
};

export const getEmailString = (adr: string, id: string) => {
  const recipient =
    language === lang.GR ? "kontakt@immozy.de" : "contact@immozy.de";
  const emailSub =
    language === lang.GR ? "Anfrage Immobilie" : "Property Request";
  const emailBody =
    language === lang.GR
      ? `Objekt ID: ${id} (Bitte nicht entfernen)`
      : `Object ID: ${id} (Please don't remove)`;

  const email = adr
    ? `mailto:${recipient}?subject=${emailSub} "${adr}"&body=${emailBody}`
    : `mailto:${recipient}?subject=${emailSub}&body=${emailBody}`;

  const meta = {
    Action: "User clicked on contact us",
  };
  sendAmplitudeDataEvent("contact_us", meta);
  const msg = `mailto:${recipient}`;
  return id ? email : msg;
};

export const getPlatformsList = (platformData: any[]) => {
  const list: any = [];
  platformData &&
    platformData.length > 0 &&
    platformData.forEach((i: any) => {
      i.active && list.push(i.platform);
    });
  return list;
};

export const getProgressColor = (percentage: number, fromPannel?: string) =>
  percentage < 38
    ? fromPannel === "right"
      ? "#ffffff"
      : "#932849"
    : percentage < 80
    ? "#EDBE59"
    : "#38CC6B";

export const getRealtorInfo = (realtor: any) => {
  let infoString = "";

  if (realtor) {
    if (realtor.street && realtor.street !== "") {
      infoString = realtor.street;
    }

    if (realtor.phone && realtor.phone !== "") {
      infoString =
        `${infoString !== "" ? infoString + " • " : ""}` + realtor.phone;
    }
    if (realtor.postal_code && realtor.postal_code !== "") {
      infoString =
        `${infoString !== "" ? infoString + " • " : ""}` + realtor.postal_code;
    }
    if (realtor.realtor_city && realtor.realtor_city !== "") {
      infoString =
        `${infoString !== "" ? infoString + " • " : ""}` + realtor.realtor_city;
    }
  }

  return infoString === "" ? "-" : infoString;
};

export const matchingMapper: any = {
  locationScore: {
    "0-30": englishText.LOCATION_30,
    "30-60": englishText.LOCATION_60,
    "60-100": englishText.LOCATION_100,
  },
  financialScore: {
    "0-30": englishText.FINANCE_30,
    "30-60": englishText.FINANCE_60,
    "60-100": englishText.FINANCE_100,
  },
  requirementsScore: {
    "0-30": englishText.REQUIRE_30,
    "30-60": englishText.REQUIRE_60,
    "60-100": englishText.REQUIRE_100,
  },
};

export const getPercentageRangeForScore = (percentage: number) =>
  percentage <= 30
    ? "0-30"
    : percentage >= 31 && percentage <= 60
    ? "30-60"
    : "60-100";

export const profileCompletionMapper: any = {
  profilePercentage: {
    true: englishText.PROFILE_TRUE,
    false: englishText.PROFILE_FALSE,
  },
  onboardingPercentage: {
    true: englishText.ONB_TRUE,
    false: englishText.ONB_FALSE,
  },
  financePercentagefirstOnboarding: {
    true: englishText.FIN_ONB_TRUE,
    false: englishText.FIN_ONB_FALSE,
  },
  financePercentagesecondDocument: {
    true: englishText.FIN_UPLOAD_TRUE,
    false: englishText.FIN_UPLOAD_FALSE,
  },
};

export const getColor = (percentage: number) =>
  percentage < 80 ? "#EDBE59" : "#38CC6B";

export const getPercentageRange = (percentage: number) =>
  percentage <= 30
    ? "0-30"
    : percentage >= 31 && percentage <= 80
    ? "30-79"
    : "80-100";

export const matchingMapperHeader: any = {
  header: {
    "0-30": englishText.HEADER_30,
    "30-79": englishText.HEDAER_60,
    "80-100": englishText.HEADER_80,
  },
  profileCompletionPercentage: {
    "0-30": englishText.SUB_HEADER_30,
    "30-79": englishText.SUB_HEADER_60,
    "80-100": englishText.SUB_HEADER_80,
  },
};

export const EnergyBarRange = [
  "0",
  "50",
  "100",
  "150",
  "200",
  "250",
  "300",
  "350",
  "400",
  "+",
];


export const getPrivacyLinkArray = (realtorData: any) => {
  const data = [];

  realtorData.imprint &&
    data.push({ label: "Impressum", link: realtorData.imprint || "" });

  realtorData.privacy_link &&
    realtorData.privacy_link !== "" &&
    data.push({
      label: "Datenschutzerklärung",
      link: realtorData.privacy_link || "",
    });

  return data;
};