import callAPI from "services/callApi";

export const addToFavourites = (objectId: string) => ({
  type: "ADD_FAVO",
  payload: callAPI(`v4/favourites/add/${objectId}`, "POST"),
});

export const removeFromFavourites = (objectId: string) => ({
  type: "REMOVE_FAVO",
  payload: callAPI(`v4/favourites/remove/${objectId}`, "POST"),
});

export const getFavoCount = () => ({
  type: "GET_FAVO_COUNT",
  payload: callAPI(`v4/objects/favourites/count`, "GET"),
});
