import { inputTypes } from "utils/constants";
import { IOptionProps } from "./onboarding";

export const getDefautValue = (op: IOptionProps, edited?: boolean) => {
  if (op && !edited) {
    if (op.optionId === "4.1.1") {
      return 50000;
    } else if (op.optionId === "4.2") {
      return 1000;
    } else if (op.optionId === "4.3.1") {
      return 500000;
    }
  }
};

const setDefaultValues = (currentOptions: IOptionProps[]) => {
  currentOptions.forEach((op: IOptionProps) => {
    if (op.type === inputTypes.RANGE) {
      op.value = 1;
    } else if (op.type === inputTypes.DOUBLE_RANGE) {
      //for livign area : [80,150] as min-max
      //for rooms : [3,5] as min-max
      op.value = op.optionId === "3.2.1" ? [80, 150] : [3, 5];
    }
  });
};

export const getAnswerValuesForApi = (optionIds: IOptionProps[]) => {
  const ids = optionIds.map((i) => {
    if (i.optionid === "1.6.4") {
      return { optionId: i.optionId || i.optionid, value: i.value };
    } else if (i.type === inputTypes.CHECKBOX || i.type === inputTypes.RADIO) {
      return { optionId: i.optionId || i.optionid };
    } else {
      return { optionId: i.optionId || i.optionid, value: i.value };
    }
  });
  return ids;
};

export const getAnswerObject = (currentOptions: any) => {
  let optionIds = [];
  // for single text type answers and selectbox
  // temp change
  // if (currentOptions.length === 1 && currentOptions[0].value !== "") {

  if (
    currentOptions.length <= 2 &&
    currentOptions[0].value !== "" &&
    currentOptions[0].type !== "RADIO"
  ) {
    if (
      currentOptions.length === 2 &&
      (currentOptions[1].optionId === "4.1.2" ||
        currentOptions[1].optionId === "4.3.2" ||
        currentOptions[1].optionid === "4.1.2" ||
        currentOptions[1].optionid === "4.3.2") &&
      currentOptions[1].isSelected
    ) {
      optionIds.push(currentOptions[1]);
    } else if (
      currentOptions[0].type === inputTypes.INPUT &&
      !currentOptions[0].value
    ) {
      return [];
    }
    if (
      currentOptions[0].type === inputTypes.INPUT &&
      currentOptions[0].value === 0
    ) {
      const value = getDefautValue(currentOptions[0]);
      currentOptions[0].value = value;
    }
    if (
      currentOptions[0].optionId === "2.1.1" &&
      !currentOptions[0].isSelected
    ) {
      return [];
    }
    optionIds.push(currentOptions[0]);
  } else if (
    // temp change
    // currentOptions.length === 1 &&
    currentOptions.length <= 2 &&
    currentOptions[0].type === inputTypes.INPUT &&
    currentOptions[0].value === ""
  ) {
    const op = currentOptions[0];
    op.value = getDefautValue(op);
    optionIds.push(op);
  }
  // for Range type answers
  else if (
    currentOptions[0].type === inputTypes.RANGE ||
    currentOptions[0].type === inputTypes.DOUBLE_RANGE
  ) {
    optionIds = currentOptions.filter((i: IOptionProps) => i.value !== "");
    if (optionIds.length === 0) {
      setDefaultValues(currentOptions);
      optionIds = currentOptions.filter((i: IOptionProps) => i.value !== "");
    }
  }
  // for checkbox
  else {
    optionIds = currentOptions.filter((i: IOptionProps) => i.isSelected);
  }
  return optionIds;
};

export const checkDisable = (optionArray: IOptionProps[]) => {
  const a = optionArray.find(
    (op) => (op.optionId || op.optionid) === "4.3.2" && op.isSelected
  );
  if (a) {
    return true;
  } else {
    return false;
  }
};