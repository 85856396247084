import React, { useState, useEffect } from "react";
import SwiperGallery from "components/searchResult/components/swiperGallery";
import englishText from "languages/english/english";
import { detectIsMobile, getParam, utmParamRetieve } from "utils/functions";
import {
  sendAmplitudeDataEvent,
  setAmplitudeUserProperties,
  setAmplitudeUserId,
  lang,
  utmOrganic,
  getLocalizeText,
} from "utils";
import { API_CONFIG, HttpService } from "services";
import "./landingPage.scss";

let searchObj: any = null;

const LandingPage: React.FC = () => {
  const [propertyList, setPropertyList] = useState([]);
  const [isLoading, setLoding] = useState(true);
  const [hoveredId, setHoverId] = useState("");

  useEffect(
    () => {
      if (window !== window.parent) {
        localStorage.setItem("language", lang.GR);
      }
      if (window.location.search && !searchObj) {
        checkforUrlParams();
      }
      if (window.location.pathname === "/landing-page-first") {
        getData();
      } else {
        if (isLoading) {
          generateUserId();
        }
      }
    },
    // eslint-disable-next-line
    []
  );

  const checkforUrlParams = () => {
    const searchObjPayload: any = {};
    const params = [
      "city",
      "object_count",
      "price_min",
      "price_max",
      "living_space_min",
      "living_space_max",
      "picture_count_min",
    ];
    params.forEach((param: string) => {
      const value = getParam(param);
      if (value) {
        searchObjPayload[param] = param === "city" ? value : parseInt(value);
      }
    });
    searchObj = searchObjPayload;
  };

  const getData = async () => {
    const payload = searchObj || {};
    HttpService.get(API_CONFIG.path.getLandingPageData, payload)
      .then((response) => {
        if (response.data) {
          setPropertyList(response.data);
          setLoding(false);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoding(false);
      });
  };

  const generateUserId = async () => {
    const userId = localStorage.getItem("userId");

    if (!userId) {
      HttpService.get(API_CONFIG.path.getUserID)
        .then((response) => {
          if (response.data) {
            const userId = response.data;
            localStorage.setItem("userId", userId);
            setAmplitudeUserId();
            handleUtmAttributes();
            getLandingPageData();
          }
        })
        .catch(() => {
          if (userId) {
            setAmplitudeUserId();
            handleUtmAttributes();
          }
          getLandingPageData();
        });
    } else {
      getLandingPageData();
      setAmplitudeUserId();
      handleUtmAttributes();
      // let meta: Object = {
      //   Action: "Landing page visited",
      // };

      // sendAmplitudeDataEvent(
      //   "view_landing_page",
      //   meta,
      //   localStorage.getItem("userId"),
      //   "GUEST"
      // );
    }
  };

  const sendAmplitudeData = async (utm?: any) => {
    let meta: any = {
      Action: "Landing page visited",
    };
    if (utm) {
      meta = {
        ...utm,
      };
    }

    sendAmplitudeDataEvent(
      "view_landing_page",
      meta,
      localStorage.getItem("userId"),
      "GUEST"
    );
    if (!localStorage.getItem("event_added_welcome")) {
      sendAmplitudeDataEvent(
        "view_landing_first",
        meta,
        localStorage.getItem("userId"),
        "GUEST"
      );

      // Add event for mobile
      const mobileBrowser = detectIsMobile();
      if (mobileBrowser) {
        const meta = {
          Action: "Landing page visited on Mobile",
        };
        sendAmplitudeDataEvent("mobile_view_landing_page", meta);
      }
      if (
        window.location.pathname &&
        window.location.pathname.includes("/landing-page/")
      ) {
        const eventName = window.location.pathname.replace(
          "/landing-page/",
          ""
        );
        if (eventName !== "") {
          let meta: any = {
            Action: "Wordpress page visited",
          };
          if (utm) {
            meta = {
              ...utm,
            };
          }
          sendAmplitudeDataEvent(
            `wp_page_${eventName}`,
            meta,
            localStorage.getItem("userId"),
            "GUEST"
          );
        }
      }
      localStorage.setItem("event_added_welcome", "true");
    }
  };

  const handleUtmAttributes = () => {
    try {
      if (window.location.search && window.location.search.includes("utm")) {
        const utm = utmParamRetieve();
        localStorage.setItem("utm", JSON.stringify(utm));
        setAmplitudeUserProperties(utm);
        sendAmplitudeData(utm);
      } else {
        localStorage.setItem("utm", JSON.stringify(utmOrganic));
        setAmplitudeUserProperties(utmOrganic);
        sendAmplitudeData(utmOrganic);
      }
    } catch (error) {
      console.log("Some error has occured in landing page", error);
      return;
    }
  };

  const getLandingPageData = () => {
    getData();
  };

  return (
    <div className="landing-page-wrapper property-card-wrapper d-flex flex-wrap justify-content-start ">
      {propertyList.length > 0 ? (
        !isLoading &&
        propertyList.map((listItem: any, index) => (
          <div
            className="column-wrapper"
            key={index}
            onMouseEnter={() => setHoverId(listItem.id)}
            onMouseLeave={() => setHoverId("")}
          >
            {index < 16 && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`https://app.immozy.de/object-detail/${listItem.id}`}
                // href={"https://app.immozy.de/onboarding"}
                // onClick={openSignup}
              >
                <div className="property-card d-flex pointer">
                  <div className="carousel-wrapper">
                    <div className={"search-img1"}>
                      <SwiperGallery
                        objectPictures={listItem.objectPictures}
                        id={listItem.id}
                        hoveredId={hoveredId}
                        isFullMapScreen={false}
                        fromLandingPage={true}
                        isdisable={false}
                      />
                      <div className="like-icon text-center pointer">
                        <i className={"fa fa-heart-o"} aria-hidden="true" />
                      </div>
                      <div className="justify-content-between">
                        <p className="ml-3 adr-label text-truncate">
                          {listItem.propertyType
                            ? `${getLocalizeText(listItem.propertyType)}  
                                ${
                                  listItem.adrPostalCode &&
                                  " in " + listItem.adrPostalCode
                                }${
                                listItem.adrQuarter && " " + listItem.adrQuarter
                              }${
                                listItem.adrQuarter !== listItem.adr_city
                                  ? ", " + listItem.adr_city
                                  : ""
                              }`
                            : `${listItem.adrStreet || "-"}`}
                        </p>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <p className="location font-bold mb-0">
                              <li>{`${
                                listItem.areaLiving
                                  ? Math.round(listItem.areaLiving) + " m²"
                                  : "-"
                              } `}</li>
                              {/* <li>{getLocalizeText(englishText.SIZE)}</li> */}
                            </p>
                            <p className="location font-bold mb-0">
                              <li>
                                {listItem.propRoomsAmount || "-"}{" "}
                                {getLocalizeText(englishText.ROOM)}
                              </li>
                              {/* <li>{getLocalizeText(englishText.ROOM)}</li> */}
                            </p>
                          </div>
                          <div>
                            <p className="price-label pt-0">
                              {listItem.formatedPrice || "-"}
                              {/* <li>{getLocalizeText(englishText.PRICE)}</li> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            )}
          </div>
        ))
      ) : (
        <h1>{}</h1>
      )}
    </div>
  );
};

export default LandingPage;
