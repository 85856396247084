import React, { useEffect, useState } from "react";
// functions
import { cities } from "utils";
// assets
import { CloseIcon } from "components/commonComponents/icons";
import pin from "assets/images/Icon-Location-Pin.png";

interface ILayoutPicker {
  cityTemp: string;
  selectedCity: string;
  handleSelect: (e: React.MouseEvent<HTMLElement>) => void;
}

let wrapperRef: any;

export const LayoutPicker: React.FC<ILayoutPicker> = (props) => {
  const { cityTemp, selectedCity } = props;
  const [showCityDropdown, setshowCityDropdown] = useState(false);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showCityDropdown]);

  const handleClickOutside = (event: MouseEvent) => {
    if (showCityDropdown && wrapperRef && !wrapperRef.contains(event.target)) {
      setshowCityDropdown(false);
    }
  };

  const setWrapperRef = (node: any) => {
    wrapperRef = node;
  };

  return (
    <>
      <div
        onClick={() => setshowCityDropdown(!showCityDropdown)}
        className="pointer"
      >
        <div
          className={`city-label pointer ${showCityDropdown ? "active" : ""}`}
        >
          {cityTemp !== "" ? cityTemp : selectedCity}
        </div>

        {showCityDropdown && (
          <div className="city-dropdown-menu" ref={setWrapperRef}>
            <div
              className="close-btn-div"
              onClick={() => setshowCityDropdown(false)}
            >
              <CloseIcon />
            </div>
            {Object.values(cities).map((city: string, ind: number) => (
              <li
                key={`${ind}_${city}`}
                className="city-list pointer"
                onClick={props.handleSelect}
              >
                <img className="mr-3 img-pin" src={pin} alt="pin" />
                {city}
              </li>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default LayoutPicker;
