import React from "react";

interface IButtonProps {
  zIndex?: number;
  disabled?: boolean;
  onclick: () => void;
  emptyFields: string[];
}

const Backbutton: React.FC<IButtonProps> = (props) => (
  <button
    className={`back-btn prev-question-btn image-gallery-icon image-gallery-left-nav ${
      props.emptyFields && props.emptyFields.length > 0 ? "mt-1" : "mt-0"
    }`}
    onClick={() => props.onclick()}
    disabled={props.disabled}
  >
    <svg
      className="image-gallery-svg"
      viewBox="6 0 12 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="1"
      strokeLinecap="round"
      tabIndex={2}
      strokeLinejoin="round"
      style={{
        color: "#A4A4A4",
      }}
    >
      <polyline points="15 18 9 12 15 6" />
    </svg>
  </button>
);

export default Backbutton;
