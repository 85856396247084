/* eslint-disable react/prop-types */
import React, { FormEvent, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Form } from "react-bootstrap";
// Components
import ContactPopUpWrapper from "./contactPopUpWrapper";
import QualifyPDF from "components/qualifyPDF/container/qualifyPDF";
import RealtorHeader from "./realtorHeader";
import { DynmicTextComponent } from "./dynmicTextComponent";
import { CustomCheckBox } from "components/commonComponents/form/checkBox/checkbox";
// functions
import * as awsServices from "services/aws";
import englishText from "languages/english/english";
import { API_CONFIG, HttpService } from "services";
import { contactType, getLocalizeText } from "utils";
// Assets
import exclaimation from "assets/images/icon.svg";
import check from "assets/images/Check-thick.svg";

interface userContactProps {
  firstName: string;
  lastName: string;
  phone: string;
  gender: string;
  contact_email: string;
  street: string;
  postalcode: string;
  city: string;
}

const RealtorInfoPopUp: React.FC<any> = (props) => {
  const modalRef = useRef<any>(null);
  const textarea = useRef<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [qualifyComplete, setqualifyComplete] = useState(false);

  const [contentArea, setContentArea] = useState("");
  const [isCharLimitReached, setIsCharLimitReached] = useState(false);
  const [isDetailFilled, setIsDetailFilled] = useState(false);

  const [checkOptions, setCheckOptions] = useState<any>({
    option1: {
      label: getLocalizeText(englishText.REALTOR_INFO_OPTION_1),
      isChecked: false,
      canAddToContentBox: true,
      addtext: getLocalizeText(englishText.REALTOR_INFO_OPTION_1_TEXT),
    },
    option2: {
      label: getLocalizeText(englishText.REALTOR_INFO_OPTION_2),
      isChecked: false,
      canAddToContentBox: true,
      addtext: getLocalizeText(englishText.REALTOR_INFO_OPTION_2_TEXT),
    },
    option3: {
      label: getLocalizeText(englishText.REALTOR_INFO_OPTION_3),
      isChecked: false,
      canAddToContentBox: false,
    },
  });

  const [contactDetails, setContactDetails] = useState<userContactProps>({
    firstName: "",
    lastName: "",
    phone: "",
    gender: "MALE",
    contact_email: "",
    street: "",
    postalcode: "",
    city: "",
  });

  useEffect(() => {
    getRealtorHandShakeDetail();
  }, []);

  const { closeActivePopup } = props;

  const getRealtorHandShakeDetail = () => {
    HttpService.get(API_CONFIG.path.getRealtorHandShakeDetail)
      .then(({ data }) => {
        const {
          realtor_handshake_option,
          realtor_handshake_description,
          is_contact_details_filled,
        } = data;
        const contactDetails = {
          firstName: data.firstName || "",
          lastName: data.lastName || "",
          phone: data.phone || "",
          gender: data.gender || "",
          contact_email: data.contact_email || data.email,
          postalcode: data.postalcode,
          city: data.city,
          street: data.street,
        };

        setContactDetails(contactDetails);
        setIsDetailFilled(is_contact_details_filled);
        if (
          ![null, undefined].includes(realtor_handshake_option) &&
          Object.keys(realtor_handshake_option).length === 3
        ) {
          setCheckOptions(realtor_handshake_option);
        }
        setContentArea(
          realtor_handshake_description ||
            getLocalizeText(englishText.I_AM_INT_TEXT)
        );
        setIsLoading(false);
      })
      .catch((error) => {
        console.log("  error", error);
        setIsLoading(false);
      });
  };

  const handleSubmit = (pdfUrl?: string) => {
    setIsLoading(true);
    const params = {
      realtor_handshake_option: checkOptions,
      realtor_handshake_description: contentArea,
    };

    HttpService.put(API_CONFIG.path.saveRealtorHandShakeDetail, params).catch(
      (error) => {
        console.log("error", error);
        // setIsLoading(false);
      }
    );

    // Realtor mail send API
    let payload: any = {
      ...contactDetails,
      objectId: props.id,
      context: contactType.SELLER,
      agreed: false,
      realtor_handshake_description: contentArea,
      is_visit_property: checkOptions.option1.isChecked ? "Yes" : "No",
      is_receive_documents: checkOptions.option2.isChecked ? "Yes" : "No",
      is_help_immozy: checkOptions.option3.isChecked ? "Yes" : "No",
    };

    if (pdfUrl) {
      payload = {
        ...payload,
        pdfData: pdfUrl,
      };
    }
    HttpService.put(`${API_CONFIG.path.updateContact}`, payload)
      .then(() => {
        props.showThanksPage();
        setIsLoading(false);
      })
      .catch((err) => {
        props.showThanksPage();
        setIsLoading(false);
        console.log(err);
      });
  };

  const handleCheckBoxChange = (key: string, isChecked: boolean) => {
    setCheckOptions({
      ...checkOptions,
      [key]: { ...checkOptions[key], isChecked },
    });
    const currentContent = checkOptions[key].addtext;

    if (isChecked && checkOptions[key].canAddToContentBox) {
      if (!contentArea.includes(currentContent)) {
        setContentArea(contentArea + currentContent);
      }
    } else {
      if (checkOptions[key].canAddToContentBox) {
        setContentArea(contentArea.replace(currentContent, ""));
      }
    }
  };

  const handleChangeContentArea = (value: string) => {
    setContentArea(value);
    setIsCharLimitReached(value.length > 2500);
  };

  const handleClosePopUp = () => {
    closeActivePopup && closeActivePopup();
  };

  // upload images to s3
  const uploadFile = async (file: any) => {
    // setIsLoading(true);
    const userId = localStorage.getItem("userId");
    HttpService.get(API_CONFIG.path.profiles3, {})
      .then((response) => {
        awsServices.updateAwsConfig(response.data);
        const fileName = `user-profile-pdf/${userId}/${userId}.png`;
        const params = awsServices.getS3UploadParams(
          file,
          fileName,
          "immozy-images"
        );
        const uploadToS3 = awsServices.manageUpload(params);
        const upload = awsServices.uploadImage(uploadToS3);
        upload
          .then((url) => {
            handleSubmit(url as string);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const triggerPdfUpload = () => {
    setIsLoading(true);
    const sendButton = document.getElementById("sendToMailButton");
    if (sendButton) {
      sendButton.click();
    }
  };
  const onChangeHandler = function (e: FormEvent<HTMLTextAreaElement>) {
    const target = e.target as HTMLTextAreaElement;

    target.style.height = "inherit";
    target.style.height = `${target.scrollHeight}px`;
  };

  const renderLeftPannel = () => (
    <div className="contact-form offer-container">
      <>
        {props.propertyDetail && (
          <RealtorHeader realtor={props.propertyDetail.realtor} />
        )}
        <div
          className={`contact-warning d-flex align-items-center ${
            isDetailFilled && "contact-success"
          }`}
        >
          <div>
            <p className="warning-contact-text">
              <img src={isDetailFilled ? check : exclaimation} alt="img" />

              <FormattedMessage
                id={
                  isDetailFilled
                    ? englishText.CHECK_DATA_AGAIN
                    : englishText.NEED_INFO
                }
                defaultMessage={
                  isDetailFilled
                    ? englishText.CHECK_DATA_AGAIN
                    : englishText.NEED_INFO
                }
              />
            </p>
            <p
              className="warning-contact-link"
              onClick={props.openRealtorContact}
            >
              <FormattedMessage
                id={
                  isDetailFilled
                    ? englishText.CHECK_DETAILS
                    : englishText.COMPLETE_MISSING_INFO
                }
                defaultMessage={
                  isDetailFilled
                    ? englishText.CHECK_DETAILS
                    : englishText.COMPLETE_MISSING_INFO
                }
              />
            </p>
          </div>
        </div>
        <CustomCheckBox
          options={checkOptions}
          handleChange={handleCheckBoxChange}
        />

        <Form.Group className="personal-message">
          <p className="personal-message-label">
            <FormattedMessage
              id={englishText.WRITE_PERSONAL_MESSAGE}
              defaultMessage={englishText.WRITE_PERSONAL_MESSAGE}
            />
          </p>
          <Form.Control
            isInvalid={isCharLimitReached}
            as="textarea"
            rows={3}
            onChange={({ target }) => handleChangeContentArea(target.value)}
            value={contentArea}
            ref={textarea}
            onInput={onChangeHandler}
          />
          {/* {!!appendedText && <p className="regards-message">
              Regards,<br />
              {appendedText}
            </p>} */}
          <p className="character-limit-indicator float-right">
            {contentArea.length}/2500{" "}
            <FormattedMessage
              id={englishText.CHARACTERS}
              defaultMessage={englishText.CHARACTERS}
            />
          </p>
        </Form.Group>

        <div className="contact-seller-btn d-flex justify-content-center mb-3">
          <button
            type="submit"
            className={`contact-realtor-btn ${isDetailFilled && "active"}`}
            disabled={isCharLimitReached || !isDetailFilled}
            onClick={() => {
              if (qualifyComplete) {
                triggerPdfUpload();
              } else {
                handleSubmit();
              }
            }}
          >
            <FormattedMessage
              id={englishText.SEND_REQUEST}
              defaultMessage={englishText.SEND_REQUEST}
            />
          </button>
        </div>
        <QualifyPDF enabled={false} sendToMailButton uploadFile={uploadFile} />
        <div
          className="terms-info"
          dangerouslySetInnerHTML={{
            __html: getLocalizeText(englishText.REALTOR_HANDSHAKE_TERMS_TEXT),
          }}
        />
      </>
    </div>
  );

  return (
    <div
      style={{ padding: "50px" }}
      className="d-flex justify-content-center align-items-center model-wrapper show fade-in"
      ref={modalRef}
    >
      <ContactPopUpWrapper
        className="contact-seller-popup"
        onClose={handleClosePopUp}
        isLoading={isLoading}
        leftPannel={renderLeftPannel()}
        rightPannel={
          <DynmicTextComponent
            propertyDetail={props.propertyDetail}
            setqualifyComplete={setqualifyComplete}
          />
        }
      />
    </div>
  );
};

export default RealtorInfoPopUp;
