import React from "react";
import { Link } from "react-router-dom";
import { getLocalizeText } from "utils";

interface ILinkType {
  path: string;
  label: string;
  name: string;
  apiName?: string;
}

interface IMenuLinkProps {
  currentTab: string | undefined;
  linkClass: string;
  links: ILinkType[];
  showArrow?: boolean;
}

const MenuLinks: React.FC<IMenuLinkProps> = (props) => {
  const { links, currentTab, linkClass } = props;

  return (
    <div className={`${linkClass} d-flex align-items-center`}>
      {links.map((menulink, i) => (
        <React.Fragment key={i}>
          <Link
            key={i}
            to={menulink.path}
            className={`${
              currentTab && currentTab.includes(menulink.name) && "active"
            }`}
          >
            {getLocalizeText(menulink.label)}
          </Link>
        </React.Fragment>
      ))}
    </div>
  );
};

export default MenuLinks;
