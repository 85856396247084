/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-undef */
import cluster from "assets/images/cluster-icon.png";
import multiIcon from "assets/images/purple.png";
import englishText from "languages/english/english";
import { cities, getLocalizeText } from "utils";
import MarkerClusterer from "@googlemaps/markerclustererplus";
import { IMapMulti } from "../interface/map.interface";

export const clusterStyles = [
  {
    textColor: "white",
    url: cluster,
    height: 45,
    width: 45,
    lineHeight: 45,
    margintop: 15,
  },
  {
    textColor: "white",
    url: cluster,
    height: 45,
    lineHeight: 45,
    width: 45,
  },
  {
    textColor: "white",
    url: cluster,
    height: 45,
    lineHeight: 45,
    width: 45,
  },
];

export const getPathCoordinates = (): any => {
  const data = localStorage.getItem("mapCoordinates");
  if (data) {
    const coordinates = JSON.parse(data);
    const coordinateObj = coordinates.map((ll: any) => ({
      lat: ll.lng,
      lng: ll.lat,
    }));
    return coordinateObj;
  }
};

export const createSingleMarker = (
  maprefs: any,
  onToggleOpen: (event: any, lat: string, lon: string, map: IMapMulti) => void,
  addHighLightClass: (id: string) => void,
  removeHighlight: (id: string) => void,
  mapArray: any,
  map: any,
  index: number
) => {
  const marker: any = new google.maps.Marker({
    position: new google.maps.LatLng(parseFloat(map.lat), parseFloat(map.lon)),
    icon: map.icon,
    // @ts-ignore
    key: index,
  });
  marker.metadata = {
    id: map.id,
    icon: map.icon,
    highlightIcon: map.highlightIcon,
  };

  marker.setMap(maprefs.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);
  mapArray.push(marker);
  google.maps.event.addDomListener(marker, "click", function (event) {
    onToggleOpen(event, map.lat, map.lon, map);
  });
  google.maps.event.addDomListener(marker, "mouseover", function () {
    addHighLightClass(map.id);
  });
  google.maps.event.addDomListener(marker, "mouseout", function () {
    removeHighlight(map.id);
  });
};

export const createMultiMarker = (
  map: any,
  onToggleMultiOpen: (
    event: any,
    lat: string,
    lon: string,
    mapMulti: IMapMulti
  ) => void,
  addHighLightClass: (id: string) => void,
  removeHighlight: (id: string) => void,
  maprefs: any,
  multiMapArray: any,
  index: number
) => {
  const markerIcon = {
    url: multiIcon,
    labelOrigin: new google.maps.Point(20, 45),
  };

  const marker = new google.maps.Marker({
    position: new google.maps.LatLng(parseFloat(map.lat), parseFloat(map.lon)),
    label: {
      text: map.id.length ? map.id.length.toString() : "",
      color: "white",
      fontWeight: "600",
      fontSize: "12px",
    },
    title: getLocalizeText(englishText.MULTIPLE_PROPERTIES),
    icon: markerIcon,
    // @ts-ignore
    key: index,
  });
  // @ts-ignore
  marker.metadata = { id: map.id, score: map.label };

  marker.setMap(maprefs.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED);

  multiMapArray.push(marker);
  google.maps.event.addDomListener(marker, "click", function (event) {
    onToggleMultiOpen(event, map.lat, map.lon, map);
  });

  google.maps.event.addDomListener(marker, "mouseover", function () {
    addHighLightClass(map.id[0]);
  });
  google.maps.event.addDomListener(marker, "mouseout", function () {
    removeHighlight(map.id[0]);
  });
};

export const createCluster = (array: any[], maprefs: any) =>
  new MarkerClusterer(
    maprefs.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED,
    array,
    {
      maxZoom: 10,
      styles: clusterStyles,
    }
  );

export const getCenterCoordinates = (selectedCity: string) => {
  switch (selectedCity) {
    case cities.HAM:
      return {
        lat: 53.5510846,
        lng: 9.9936819,
      };

    case cities.BER:
      return {
        lat: 52.520008,
        lng: 13.404954,
      };

    case cities.HANNOVER:
      return {
        lat: 52.379515,
        lng: 9.772897195110005,
      };

    case cities.LEIPZIG:
      return {
        lat: 51.340199,
        lng: 12.360103,
      };

    case "fav":
      return {
        lat: 53.1355,
        lng: 12.2018,
      };

    default:
      return {
        lat: 53.5510846,
        lng: 9.9936819,
      };
  }
};
