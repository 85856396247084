import React from "react";
import englishText from "languages/english/english";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Modal from "react-awesome-modal";
import getLocalizeText from "utils/getLocalizeText";
import GraphComponent from "./graphComponent";
import { CloseIcon } from "components/commonComponents/icons";
import { FormattedMessage } from "react-intl";

const PaymentDetailsPopup: React.FC<any> = (props) => {
  const {
    years,
    fixation_period,
    done_repaying_in,
    fixedUntilValue,
    locale,
    graph,
    getTimeLineData,
    fixedIndex,
  } = props;

  return (
    <div>
      <Modal
        visible={true}
        effect="fadeInUp"
        onClickAway={props.closeGraph}
        width={"80%"}
      >
        <div className="calc-interest-container">
          <div className="close-btn" onClick={props.closeGraph}>
            <CloseIcon fill="white" />
          </div>
          <div className="accordion-body">
            <p className="table-title-graph">
              <FormattedMessage
                id={englishText.SHOW_REPAYMENT_PROGRESSION}
                defaultMessage={englishText.SHOW_REPAYMENT_PROGRESSION}
              />
            </p>
            <p className="text-center">
              <FormattedMessage
                id={englishText.COMPARE_THE_TERMS}
                defaultMessage={englishText.COMPARE_THE_TERMS}
              />
            </p>

            <table className="fixation-div">
              <tbody>
                {years.length > 0 ? (
                  <>
                    <tr style={{ backgroundColor: "#FAFAFA" }}>
                      <th style={{ width: "15%" }}>
                        <FormattedMessage
                          id={englishText.FIXATION_PERIOD}
                          defaultMessage={englishText.FIXATION_PERIOD}
                        />
                      </th>
                      {years.map((item: any, index: number) => {
                        const yearValue = item.title.replace("YEAR_", "");
                        const activeTab =
                          parseInt(yearValue) === fixation_period;

                        return (
                          <th
                            key={index}
                            className={`${activeTab ? "active-tablehead" : ""}`}
                          >
                            {`${yearValue} ${getLocalizeText(
                              englishText.YEARS
                            )}`}
                          </th>
                        );
                      })}
                    </tr>

                    {fixationPeriodFields.map((item, index) => (
                      <tr key={index}>
                        <td className="row-title">
                          {getLocalizeText(item.label)}
                        </td>
                        {years.map((yearObj: any, i: number) => {
                          const yearValue = yearObj.title.replace("YEAR_", "");
                          const rounded = yearObj[item.values]
                            ? Math.round(yearObj[item.values])
                            : 0;
                          const actaulValue =
                            item.values !== "interest_rate_percent" &&
                            yearObj[item.values]
                              ? `${rounded.toLocaleString(locale)} €`
                              : `${yearObj[item.values].toLocaleString(
                                  locale
                                )} %`;
                          const activeTab =
                            parseInt(yearValue) === fixation_period;

                          return (
                            <td
                              key={i}
                              className={`${
                                activeTab ? "active-tablerow" : ""
                              } ${
                                activeTab && index === 2 ? "border-bottom" : ""
                              }`}
                            >
                              {actaulValue}
                            </td>
                          );
                        })}
                      </tr>
                    ))}
                    <tr className="button-section">
                      <td> </td>
                      {[5, 10, 15, 20, 25, 30].map(
                        (data: any, index: number) => (
                          <>
                            {data === fixation_period ? (
                              <td key={index} />
                            ) : (
                              <td key={index}>
                                <button
                                  className="btn"
                                  onClick={() =>
                                    props.handleSetYear(data, "fixation_period")
                                  }
                                >
                                  <FormattedMessage
                                    id={englishText.SELECT}
                                    defaultMessage={englishText.SELECT}
                                  />
                                </button>
                              </td>
                            )}
                          </>
                        )
                      )}
                    </tr>
                  </>
                ) : (
                  <>
                    <tr>
                      <td>Loading... </td>
                    </tr>
                  </>
                )}{" "}
              </tbody>
            </table>
          </div>
          <div className="col-8 calc-graph-model">
            {done_repaying_in && getTimeLineData("center")}
            <div style={{ width: "629px", margin: "0 auto" }}>
              {fixedUntilValue && (
                <GraphComponent
                  graph={graph}
                  fixedUntilValue={fixedUntilValue}
                  fixedIndex={fixedIndex}
                  locale={locale}
                />
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

const fixationPeriodFields = [
  { label: englishText.INT_RATE, values: "interest_rate_percent" },
  { label: englishText.MONTHLY_RATE, values: "monthly_rate_amount" },
  { label: englishText.REMAINING_DEBT, values: "remaining_debt" },
];

export default PaymentDetailsPopup;
