import englishText from "languages/english/english";

export const linksList = [
  { label: englishText.GENERAL_INFO, name: "general", index: 0 },
  {
    label: englishText.LOCATION,
    name: "location",
    index: 1,
  },
  {
    label: englishText.PROPERTY,
    name: "property",
    index: 2,
  },
  { label: englishText.FINANCE, name: "finance", index: 3 },
];

export const commisionType = { VALUE: "VALUE", PERCENTAGE: "PERCENTAGE" };

export const internetOptions = [
  { label: englishText.NO_INTERNET, value: 0 },
  { label: "16", value: 16 },
  { label: "50", value: 50 },
  { label: "100", value: 100 },
  { label: "100+", value: 200 },
];

export const generalTabObj = {
  title: "",
  description: "",
  isPropertyForSale: false,
  isResidentialProperty: false,
};

export const locationObj = {
  countryId: "Germany",
  quarter: "",
  city: "Hamburg",
  zipCode: "",
  street: "",
  streetNumber: "",
};

export const propertyObj = {
  propertyType: "",
  obj_telekomInternet: 200,
  features: [],
  heating: "",
  pictures: [],
  floorplan: [],
  // expose: [],
};

export const financeObj = {
  price: "",
  agentCommission: 0,
  additionalFees: "",
  purchaseNegotiationStatus: "",
  commisionType: commisionType.PERCENTAGE,
  sellerOwner: {
    name: "",
    address: "",
    phone: "",
    email: "",
  },
};

export const requiredFields = {
  general: ["title"],
  location: ["city", "quarter", "zipCode"],
  property: ["propertyType", "obj_telekomInternet"],
  finance: ["price"],
};

export const extraFaetures = [
  { label: englishText.BALCONY, name: "equip_balcony", selected: false },
  { label: englishText.GARDEN, name: "equip_garden", selected: false },
  {
    label: englishText.GUEST_TOILET,
    name: "equip_bath_guest_toilet",
    selected: false,
  },
];

export const purchaseStatus = [
  englishText.NOT_STARTED,
  englishText.ONGOING,
  englishText.COMPLETED,
];

export const propertyTypes = [
  englishText.APAART,
  englishText.HOUSE,
  englishText.OTHER,
];

export const features = [
  { label: englishText.FURNISHED, selected: false },
  { label: englishText.NEW_WIN, selected: false },
  { label: englishText.AC, selected: false },
  { label: englishText.HEATING_SYSTEM, selected: false },
  { label: englishText.FIERPLACE, selected: false },
  { label: "Balcony", selected: false },
  { label: "Garden", selected: false },
  { label: "Guest-WC", selected: false },
];

export const parkingTypes = [
  englishText.GARAGE,
  englishText.DOUBLE_GARAGE,
  englishText.PARKING_LOT,
  englishText.UND_GARAGE,
];

export const sellerInfo = [
  { label: englishText.NAME, name: "name" },
  { label: englishText.ADDRESS, name: "address" },
  { label: englishText.PHONE, name: "phone" },
  { label: englishText.EMAIL, name: "email" },
];

export const heatingTypes = [
  englishText.GAS,
  englishText.GEO_THERMAL,
  englishText.PHOTOVOLIC,
];

export const numberFieldsArray = [
  {
    label: englishText.LIVING_AREA,
    name: "livingAreaSQM",
    isRequired: true,
  },
  {
    label: englishText.PLOT_AREA_m2,
    name: "plotAreaSQM",
  },
  {
    label: englishText.AMOUNT_OF_ROOMS,
    name: "noOfRooms",
    isRequired: true,
  },
  {
    label: englishText.AMOUNT_OF_SLEEPROOMS,
    name: "noOfSleepingRooms",
  },
  {
    label: englishText.AMOUNT_OF_BATHROOMS,
    name: "noOfBathRooms",
  },
  {
    label: englishText.YEAR_OF_CONSTRUCTION,
    name: "constructionYear",
    isRequired: true,
  },
];

export const inputFields = [
  {
    label: englishText.ZIP_CODE,
    name: "zipCode",
    isRequired: true,
  },
  {
    label: englishText.STREET,
    name: "street",
  },
  {
    label: englishText.STREET_NO,
    name: "streetNumber",
  },
];

export const inputFieldsGeneral = [
  { label: englishText.NAME_OF_OBJECT, name: "title", isRequired: true },
  {
    label: englishText.DESC_OF_PROPERTY,
    name: "description",
  },
];

export const checkFields = [
  {
    label: englishText.IS_FOR_SALE,
    name: "isPropertyForSale",
  },
  {
    label: englishText.IS_RES,
    name: "isResidentialProperty",
  },
];

export const subTypes = [
  "flat",
  "attic-floor",
  "ground-floor",
  "maisonette",
  "penthouse",
  "raised-ground-floor",
  "terrace-apartment",
  "basement",
  "loft",
  "semi-detached-house",
  "villa",
  "other",
  "detached-house",
  "end-terrace-house",
  "bungalow",
  "special-property",
  "apartment-block",
  "mid-terrace-house",
  "free-hold-flat",
  "detached-house",
  "residential-and-office-building",
  "hallstorage",
  "miscellaneous",
  "apartment-block",
  "commercial-unit",
  "residential-complex",
  "shopsales-area",
  "commercial-property",
  "other",
];
