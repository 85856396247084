import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FormattedMessage } from "react-intl";
import queryString from "query-string";
// functions
import englishText from "languages/english/english";
import { API_CONFIG, HttpService } from "services";
import {
  sendAmplitudeDataEvent,
  setAmplitudeUserProperties,
  getUrlParameters,
} from "utils";
// components
import { LogoLoader } from "./SkeletonLogo/logoLoader";

let logintoken = "";
let redirectToObj = false;
let redirectWelcome = false;
let objID = "";

const MailLogin: React.FC = () => {
  const [isLoading, setisLoading] = useState(true);
  const [errorMsg, seterrorMsg] = useState("");
  const history = useHistory();

  useEffect(() => {
    const params: any = getUrlParameters();

    if (params && params["object-detail"]) {
      redirectToObj = true;
      objID = params["object-detail"];
    }
    if (params && params["welcome"]) {
      redirectWelcome = true;
    }
    if (window.location.search && window.location.search.includes("calc")) {
      const queryParams = queryString.parse(window.location.search);
      if (queryParams.calc) {
        const meta = {
          Action: "User clicked on mail login button",
        };
        sendAmplitudeDataEvent("newsletter_mail_opened", meta);
      }
    } else {
      const meta = {
        Action: "User clicked on mail login button",
      };
      sendAmplitudeDataEvent("mail_login_clicked", meta);
    }

    if (localStorage.getItem("token")) {
      history.push(`/object-detail/${objID}`);
    } else if (window.location && isLoading) {
      loginUserWithToken();
    }
  });

  const loginUserWithToken = () => {
    const tokenValue = window.location.pathname.split("/mail/");
    logintoken = tokenValue.length > 0 ? tokenValue[1] : "";

    const payload = redirectWelcome
      ? { logintoken }
      : { newslatterlogintoken: logintoken };

    HttpService.post(API_CONFIG.path.login, payload)
      .then((response) => {
        redirectToDashboard(response.data);
      })
      .catch((err) => {
        const errorMessage = err.response ? err.response.data.message : "";
        seterrorMsg(errorMessage);
        setisLoading(false);
        localStorage.removeItem("token");
        window.location.pathname = "/login";
      });
  };

  const redirectToDashboard = (authData: any) => {
    const token = authData.token;
    localStorage.setItem("token", token);
    localStorage.setItem("userId", authData.userId);
    localStorage.setItem("role", "USER");

    setAmplitudeUserProperties({
      userName: authData.userName,
      onboarding: authData.isOnBoardingCompleted,
      rolename: "USER",
    });
    const meta = {
      Action: "User clicked on mail login button",
    };
    sendAmplitudeDataEvent("newsletter_mail_clicked", meta);

    // const url = redirectToCalc ? "/calculator?contact=true" : "/dashboard";
    const url = redirectToObj ? `/object-detail/${objID}` : `/find`;
    history.push(url);
  };

  return (
    <div className="admin-conatainer">
      <h1 className="admin-login-label">
        <FormattedMessage
          id={englishText.LOGING_IN_ADMIN}
          defaultMessage={englishText.LOGING_IN_ADMIN}
        />
      </h1>
      <div className="d-flex align-items-center justify-content-center mt-5">
         <LogoLoader />
      </div>
      {errorMsg !== "" && <h3 className="error-msg-label">{errorMsg}</h3>}
    </div>
  );
};

export default MailLogin;
