import * as AWS from "aws-sdk";
import { AwsCredentials, AwsParamsSchema } from "utils/globalInterface";

/**
 * getS3UploadParams - generates s3-upload parameters
 * @param file - file
 * @param id - ID
 * @param bucket - bucket
 */
export const getS3UploadParams = (file: File, fileName: string, bucket: string) => ({
  Body: file,
  Bucket: bucket,
  ContentType: file.type,
  Key: fileName,
});

/**
 * updateAwsConfig - updates aws configuration
 * @param awsCreds - credentials retrieved from server
 */
export const updateAwsConfig = (awsCreds: AwsCredentials) => {
  AWS.config.update({
    accessKeyId: awsCreds.credentials.AccessKeyId,
    secretAccessKey: awsCreds.credentials.SecretAccessKey,
    sessionToken: awsCreds.credentials.SessionToken,
    region: awsCreds.region,
  });
  return AWS;
};

/**
 * manageUpload - returns a managedUpload object
 * @param params - aws s3 upload parameters
 */
export const manageUpload = (params: AwsParamsSchema) =>
  new AWS.S3.ManagedUpload({
    partSize: 5 * 1024 * 1024,
    params: params,
  });

  // upload file to s3
export const uploadImage = (uploadToS3: any, updateUploadStatus?: any) =>
  new Promise((resolve, reject) => {
    uploadToS3
      .on("httpUploadProgress", (progress:any) => {
        updateUploadStatus && updateUploadStatus(
          Math.round((progress.loaded / progress.total) * 100),
          true,
          false
        );
        console.log(Math.round((progress.loaded / progress.total) * 100));
      })
      .send((errS3:any, done:any) => {
        if (!errS3) {
          resolve(done.Location);
        } else {
          reject(errS3);
        }
      });
  });