import englishText from "languages/english/english";
import getLocalizeText from "utils/getLocalizeText";

export const transletedYesNo: any = {
  YES: {
    en: "Yes",
    de: "Ja",
  },
  NO: {
    en: "No",
    de: "Nein",
  },
};

export const translatedAND: any = {
  en: " to ",
  de: " und ",
};

export const onbMapper: any = {
  "592a7a62-25ae-491e-82b7-f406b57f0f4e": {
    stateKey: "reason",
    label: getLocalizeText(englishText.REASON_FOR_BUYING_PROPERTY),
  },
  "c07ba40c-2b32-4a99-b935-f3b18a4ca52e": {
    stateKey: "propertyType",
    label: getLocalizeText(englishText.DESIRED_TYPE_OF_PROPERTY),
  },
  "8baf754e-c74f-4019-90ea-96e419d6ee71": {
    stateKey: "areas",
  },
  "a1d4da38-ef3e-4786-befa-db2e52031a67": {
    stateKey: "areas",
    label: getLocalizeText(englishText.DESIRED_RESIDENTAL_LOCATION),
  },
  "adc461a8-bd9d-4fcd-8605-2994f193b738": {
    stateKey: "expectedNearbyLocations",
    label: getLocalizeText(
      englishText.PARTICULARLY_IMPORTANT_FOR_THE_RESIDENTIAL_LOCATION
    ),
  },
  "af2e72aa-922b-4d81-916d-a2b92a900748": {
    stateKey: "space",
    label: getLocalizeText(englishText.DESIRED_LIVING_SPACE),
  },
  "925d1199-d489-4648-b702-f73d64f9b0be": {
    stateKey: "rooms",
    label: getLocalizeText(englishText.DESIRED_NUMBER_OF_ROOMS),
  },
};

export const fiOnbMapper: any = {
  "01d03868-e8ad-4c8b-920b-ad2b8f368dff": {
    stateKey: [
      {
        key: "availableEquity",
        optionId: "4.1.1",
        label: getLocalizeText(englishText.AVAILABLE_EQUITY),
      },
      {
        key: "isOwner",
        optionId: "4.1.2",
        type: "SINGLE_CHECKBOX",
        label: getLocalizeText(englishText.OWNER_OF_PROPERTY),
      },
    ],
  },
  "fd20ac95-fb3c-4453-9b74-524a8a92fb59": {
    stateKey: [
      {
        key: "maxPurchasePrice",
        optionId: "4.3.1",
        label: getLocalizeText(englishText.MAXIMUM_PURCHASE_PRICE),
      },
      {
        key: "notClearYet",
        optionId: "4.3.2",
        type: "SINGLE_CHECKBOX",
        label: getLocalizeText(englishText.NOT_CLEAR_YET),
      },
    ],
  },
  "c913300c-5888-4c24-89e0-5d2def60f1f7": {
    stateKey: "hasGermanCitizenship",
    label: getLocalizeText(englishText.GERMAN_CITIZENSHIP),
  },
  "f9a4aaf7-0dcb-497d-9419-c4e105d23a50": {
    stateKey: "hasAnyPartner",
    label: getLocalizeText(englishText.PURCHASE_WITH_PARTNER),
  },
  "custom": {
    stateKey: "contactDetails",
    label: getLocalizeText(englishText.EMPLOYMENT),
    key: "employment"
  },
  "1c20bf0c-d0e7-4b0d-b5d6-6ad935095730": {
    stateKey: "netIncome",
    label: getLocalizeText(englishText.MONTHLY_NET_INCOME),
  },
};
