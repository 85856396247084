/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Component, Dispatch } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Spinner } from "react-bootstrap";
// Components
import GeneralTab from "./generalTab";
import LocationTab from "./locationTab";
import PropertyTab from "./propertyTab";
import FinanceTab from "./financeTab";
import ModalComponent from "../../commonComponents/modalComponent";
// functions and constants
import HttpService from "services/http.service";
import { addUpdateObject, clearReducer } from "actions";
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import {
  replaceMsg,
  setFinanceData,
  setLocationData,
  setPropertyData,
} from "../utils/functions";
import * as propConstants from "../utils/constants";
import {
  IAddPropertyProps,
  IAddPropertyState,
  ICustomPropReducer,
} from "../interface/myproperty.interface";
// assets
import { CloseIcon } from "components/commonComponents/icons";
import "../myprop.scss";

const initialState = {
  general: propConstants.generalTabObj,
  location: propConstants.locationObj,
  property: propConstants.propertyObj,
  finance: propConstants.financeObj,
  qtrList: [],
  features: JSON.parse(JSON.stringify(propConstants.features)),
  emptyFields: [],
};

class AddNewProperty extends Component<IAddPropertyProps> {
  state: IAddPropertyState = {
    activeTab: "general",
    visitedTabs: ["general"],
    objectId: "",
    isNewObject: true,
    isFormChanged: false,
    nextTab: "",
    showSpinner: false,
    showAbortModal: false,
    ...initialState,
    modalMsg: "",
  };
  ref: any;

  componentDidMount() {
    const { objectData } = this.props;
    this.ref = React.createRef();
    if (objectData) {
      this.setExistingData(objectData);
      this.setState({ isNewObject: objectData.status !== "ACTIVE" });
    }
    this.fetchData();
  }

  componentDidUpdate(prevProps: IAddPropertyProps) {
    const { addUpdateReducer, clearReducer } = this.props;

    if (
      addUpdateReducer !== prevProps.addUpdateReducer &&
      addUpdateReducer.status === "success"
    ) {
      const { nextTab, visitedTabs } = this.state;
      if (nextTab === "") {
        this.setState({
          showAbortModal: true,
          modalMsg: this.state.isNewObject
            ? getLocalizeText(englishText.PROPERTY_SUCCESSS)
            : getLocalizeText(englishText.PROPERTY_UPDATED),
        });
      } else {
        const visitedTabsUpdate = visitedTabs;

        if (!visitedTabs.includes(nextTab)) {
          visitedTabsUpdate.push(nextTab);
        }

        this.setState({
          activeTab: nextTab,
          objectId: addUpdateReducer.data.objectId,
          showSpinner: false,
          visitedTabs: visitedTabsUpdate,
        });
      }
      clearReducer("CLEAR_ADD_UPDATE_OBJECT");
    }

    if (
      addUpdateReducer !== prevProps.addUpdateReducer &&
      addUpdateReducer.status === "fail"
    ) {
      let msg = getLocalizeText(englishText.ERROR_CONTACT_ADMIN);
      if (addUpdateReducer.data.message) {
        msg = replaceMsg(addUpdateReducer.data.message);
      }
      this.setState({
        showSpinner: false,
      });
      alert(msg);
      this.scrollUp();
      clearReducer("CLEAR_ADD_UPDATE_OBJECT");
    }
  }

  render() {
    const {
      activeTab,
      general,
      location,
      property,
      finance,
      showSpinner,
      qtrList,
      objectId,
      showAbortModal,
      emptyFields,
      visitedTabs,
    } = this.state;

    const { closeModal } = this.props;

    return (
      <div className={`prop-popup add-prop-main`} ref={this.ref}>
        <div
          className="close-btn-div"
          onClick={() =>
            this.toggleModal(
              getLocalizeText(englishText.CANCEL_CONFIRM),
              "Abort"
            )
          }
        >
          <CloseIcon />
        </div>

        <div className="d-flex justify-content-center">
          <p className="title-add">
            {getLocalizeText(englishText.ADD_PROPERTY)}
          </p>
          {showSpinner && (
            <Spinner className="ml-2 mt-2" animation="border" variant="info" />
          )}
        </div>
        <div className="profile-head new-prop mt-4">
          {/* tab list header section */}
          <div
            style={{
              boxShadow: "rgba(0, 0, 0, 0.04) 0px 6px 8px 2px",
            }}
          >
            <ul className="nav nav-tabs" id="myTab" role="tablist">
              {propConstants.linksList.map((link: any, index) => (
                <li className="nav-item mr-3" key={index}>
                  <span
                    className={`dot-class mr-2 ${
                      (activeTab === link.name ||
                        visitedTabs.includes(link.name)) &&
                      "active"
                    }`}
                  >
                    {index + 1}.
                  </span>
                  <Link
                    className={`custom-prop-link ${
                      activeTab === link.name && "active-link"
                    } nav-item nav-link ${
                      (activeTab === link.name ||
                        visitedTabs.includes(link.name)) &&
                      "active"
                    }`}
                    id={link.id}
                    data-toggle="tab"
                    role="tab"
                    //@ts-ignore
                    name={link.name}
                    onClick={(event) => {
                      event.preventDefault();
                      // if(activeTab !== link.name && visitedTabs.includes(link.name)){
                      //   this.setActiveTab(event);
                      //   }
                    }}
                  >
                    {getLocalizeText(link.label)}
                    <p className="bottom-effect">
                      {" "}
                      <span />
                    </p>
                  </Link>
                </li>
              ))}
            </ul>
          </div>

          {/* tabs section */}
          {activeTab === "general" && (
            <GeneralTab
              generalTabObj={general}
              handleValueChange={this.handleValueChange}
              handleSaveAndContinue={this.handleSaveAndContinue}
              emptyFields={emptyFields}
            />
          )}

          {activeTab === "location" && (
            <LocationTab
              locationObj={location}
              handleValueChange={this.handleValueChange}
              handleSaveAndContinue={this.handleSaveAndContinue}
              qtrList={qtrList}
              emptyFields={emptyFields}
              handleAutosuggest={this.handleAutosuggest}
              goBack={() => this.goBack("general")}
            />
          )}

          {activeTab === "property" && (
            <PropertyTab
              propertyObj={property}
              handleValueChange={this.handleValueChange}
              handleSaveAndContinue={this.handleSaveAndContinue}
              handleMulltiChange={this.handleMulltiChange}
              features={this.state.features}
              objectId={objectId}
              updatePropertyObject={this.updatePropertyObject}
              emptyFields={emptyFields}
              setFormChanged={this.setFormChanged}
              goBack={() => this.goBack("location")}
            />
          )}

          {activeTab === "finance" && (
            <FinanceTab
              financeObj={finance}
              handleValueChange={this.handleValueChange}
              handleSaveAndContinue={this.handleSaveAndContinue}
              handleMulltiChange={this.handleMulltiChange}
              setType={this.setType}
              handleChangeForSeller={this.handleChangeForSeller}
              emptyFields={emptyFields}
              scrollUp={this.scrollUp}
              goBack={() => this.goBack("property")}
            />
          )}
        </div>

        {showAbortModal && (
          <ModalComponent
            title={this.state.modalMsg}
            closeModal={() => {
              showAbortModal === "Abort"
                ? this.setState({ showAbortModal: false })
                : closeModal(true);
            }}
            handleYes={
              showAbortModal === "Abort" ? () => closeModal(true) : undefined
            }
            btnText={
              showAbortModal === englishText.ABORT
                ? englishText.CANCEL
                : englishText.OK
            }
            width={"30%"}
          />
        )}
      </div>
    );
  }

  // // Set section for active tab
  // setActiveTab = (event: any) => {
  //   event.preventDefault();
  //   const { isNewObject, visitedTabs, emptyFields, activeTab } = this.state;

  //   if (
  //     (isNewObject && !visitedTabs.includes(event.target.name)) ||
  //     emptyFields.length > 0
  //   ) {
  //     this.handleSaveAndContinue(activeTab);
  //   } else {
  //     this.setState({ activeTab: event.target.name });
  //   }
  // };

  scrollUp = () => {
    this.ref.current.scrollTo(0, 0);
  };

  // show cancel warning if anything has changed
  toggleModal = (value: string, type: string) => {
    if (type === "Abort" && !this.state.isFormChanged) {
      this.props.closeModal();
    }
    this.setState({ modalMsg: value, showAbortModal: type });
  };

  // for input fields, dropdown and checkboxes
  handleValueChange = (event: any) => {
    const { activeTab, emptyFields } = this.state;

    const key = event.target.name;
    const type = event.target.type;
    let value = event.target.value;
    if (type === "number" || key === "obj_telekomInternet") {
      value = parseFloat(event.target.value);
    } else if (type === "checkbox") {
      value = event.target.checked;
    }

    this.setState(
      (prevState: IAddPropertyState) => ({
        [activeTab]: {
          // @ts-ignore
          ...prevState[activeTab],
          [key]: value,
        },
      }),
      () => {
        if (event.target && event.target.name === "city") {
          this.fetchData(true);
        }
      }
    );

    let emptyFieldsUpdated = emptyFields;
    if (emptyFields.includes(key)) {
      emptyFieldsUpdated = emptyFields.filter((i) => i !== key);
      this.setState({ emptyFields: emptyFieldsUpdated });
    }
    this.setFormChanged();
  };

  // Set flag if any value is changed in edit
  setFormChanged = () => {
    if (!this.state.isFormChanged) {
      this.setState({ isFormChanged: true });
    }
  };

  // hanlde seller object state
  handleChangeForSeller = (event: any) => {
    const { finance, activeTab } = this.state;
    const sellerOwnerUpdated = JSON.parse(JSON.stringify(finance.sellerOwner));

    sellerOwnerUpdated[event.target.name] = event.target.value;

    this.setState((prevState: IAddPropertyState) => ({
      [activeTab]: {
        // @ts-ignore
        ...prevState[activeTab],
        sellerOwner: sellerOwnerUpdated,
      },
    }));
    this.setFormChanged();
  };

  // features array handling for property tab
  handleMulltiChange = (event: any) => {
    const { features, property } = this.state;
    const key = event.target.name;
    const value = event.target.checked;
    const newArray: any = [];
    const updatedFeatures = JSON.parse(JSON.stringify(features));

    const updatedArray = updatedFeatures.map((i: any) => {
      if (i.label === key) i.selected = value;
      if (i.selected) {
        newArray.push(i.label);
      }
      return i;
    });

    property.features = newArray;
    this.setState({ features: updatedArray, property });
    this.setFormChanged();
  };

  // Save and Next handling for all tabs
  handleSaveAndContinue = (
    data: any,
    nextTab?: string,
    url?: string,
    method?: string
  ) => {
    const { addUpdateObject } = this.props;
    const { objectId } = this.state;

    const emptyFields = this.checkValidation();

    if (emptyFields.length === 0) {
      addUpdateObject(data, url, method, objectId);
      this.setState({ nextTab: nextTab, showSpinner: true });
    } else {
      this.setState({ emptyFields });
      if (
        emptyFields.length === 1 &&
        emptyFields.includes("constructionYear")
      ) {
        this.ref.current.scrollTo(0, 300);
      } else {
        this.scrollUp();
      }
    }
  };

  // Check if any mandatory fields are empty
  checkValidation = () => {
    const { activeTab } = this.state;
    // @ts-ignore
    const fields: any = propConstants.requiredFields[activeTab];
    const emptyFields: any = [];
    fields &&
      fields.forEach((field: any) => {
        // @ts-ignore
        const tabObject = this.state[activeTab];
        if (!tabObject[field] || tabObject[field] === "") {
          emptyFields.push(field);
        }
      });
    return emptyFields;
  };

  // fetch quarter list for location tab
  fetchData = async (changeCity?: boolean) => {
    const { qtrList, location } = this.state;

    if (qtrList.length === 0 || changeCity) {
      const { city } = location;
      HttpService.get(`v1/objects/dropdown/quarter/${city}`)
        .then((response) => {
          const data = response.data;
          if (location.quarter !== "") {
            this.setState((prevState: IAddPropertyState) => ({
              location: {
                ...prevState.location,
                quarter: data[0],
              },
            }));
          }
          this.setState({ qtrList: data });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  // Set type in Euros or % for finance tab
  setType = (type: string) => {
    const { finance } = this.state;
    finance.commisionType = type;
    this.setState({ finance });
    this.setFormChanged();
  };

  updatePropertyObject = (urlArray: string[], param: string) => {
    const { property } = this.state;
    property[param] = urlArray;
    this.setState({ property });
    this.setFormChanged();
  };

  capitalize(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  // Auto suggest field handling for location tab
  handleAutosuggest = (text: string) => {
    const locationArray = text.split(",");
    locationArray.splice(2, 1);
    const setData = ["zipCode", "quarter", "city"];
    const { location } = this.state;
    const updatedLocation = JSON.parse(JSON.stringify(location));

    locationArray &&
      locationArray.forEach((data, index) => {
        const stateName = setData[index];
        const value = data.trimStart();

        updatedLocation[stateName] = value;
      });

    this.setState({ location: updatedLocation, emptyFields: [] });
    this.setFormChanged();
  };

  // Set already added data while property editing
  setExistingData = (objectData: any) => {
    const { features, visitedTabs } = this.state;
    const { general, location, property, financial } = objectData;
    const visitedTabsUpdated = visitedTabs;

    // set general data
    const objectGeneral = {
      title: general.title,
      description: general.description,
      isPropertyForSale: general.isPropertyForSale,
      isResidentialProperty: general.isResidentialProperty,
    };
    visitedTabsUpdated.push("location");
    this.setState({
      general: objectGeneral,
      objectId: objectData.id,
      visitedTabs: visitedTabsUpdated,
    });

    // set location data
    if (location) {
      const objectLocation = setLocationData(location);
      visitedTabsUpdated.push("property");
      this.setState({
        location: objectLocation,
        visitedTabs: visitedTabsUpdated,
      });
    }

    // set property data
    if (property) {
      const objectProperty = setPropertyData(property, features, objectData);
      visitedTabsUpdated.push("finance");

      this.setState({
        property: objectProperty.objectProperty,
        features: objectProperty.updatedArray,
        visitedTabs: visitedTabsUpdated,
      });
    }

    // set finance data
    if (financial) {
      const objectFin = setFinanceData(financial);
      this.setState({
        finance: objectFin,
      });
    }
  };

  goBack = (value: string) => {
    this.setState({ activeTab: value, emptyFields: [] });
  };
}

const mapStateToProps = (state: ICustomPropReducer) => ({
  addUpdateReducer: state.addUpdateReducer,
});

const mapDispatchToProps = (dispatch: Dispatch<any>) => ({
  addUpdateObject: (
    data: any,
    url?: string,
    method?: string,
    objectId?: string
  ) => {
    dispatch(addUpdateObject(data, url, method, objectId));
  },
  clearReducer: (name: string) => {
    dispatch(clearReducer(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AddNewProperty);
