import React from 'react';
import { FormattedMessage } from "react-intl";
import { strengthIndicator, strengthColor } from "./passwordFunctions";
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import * as passMeterInterface from "../interface/auth";
import '../auth.scss';

export default function PasswordInput(props: passMeterInterface.IPasswordMeterProps) {
    const strength = strengthIndicator(props.value);

    const color = props.value ? strengthColor(strength) : '#d8d8d8';

    const getStrength = (color: string) => {
        if (color !== '') {
            return word.find((i) => i.key === color) && word.find((i) => i.key === color)?.value;
        }
        return '';
    };

    const greyBar = (
      <div className="pwd-div" style={{ backgroundColor: "#d8d8d8" }} />
    );

    const greyFirstBar = (
      <div
        className="pwd-div"
        style={{ backgroundColor: "#d8d8d8", marginLeft: "17px" }}
      />
    );

    const colorObj: any = colorArray_2.find((i) => i[color]);

    const countIndex: number = colorObj && colorObj[color ? color : "#d8d8d8"];

    return (
      <React.Fragment>
        <input
          id="inputID"
          type={props.showPassword ? "text" : "password"}
          value={props.value}
          name={props.name}
          className={
            props.from === "signup" || props.from === "reset"
              ? "login-input-box"
              : (props.errorFields &&
                  !props.errorFields.includes("password")) ||
                !props.errorFields
              ? "password-input"
              : "password-input error-border"
          }
          placeholder={getLocalizeText(
            props.placeholder ? props.placeholder : ""
          )}
          onChange={props.handleChanges}
          style={
            props.from === "signup" || props.from === "reset"
              ? {}
              : {
                  width: "100%",
                  borderRadius: "5px",
                  height: "38px",
                  backgroundColor: "#f6f6f6",
                  border: "1px solid #b4b8be",
                  padding: "5px 20px",
                }
          }
          onKeyDown={props.onKeyDown}
        />

        <div
          className="d-flex align-items-center justify-content-between"
          style={{
            marginTop: "15px",
            width: props.from === "reset" ? "419px" : "",
          }}
        >
          {colorArray.map((item, index) =>
            index < countIndex ? (
              <div
                className="pwd-div"
                style={{
                  //  marginLeft: index === 0 ? "17px" : "0px",
                  backgroundColor: color,
                }}
              />
            ) : index === 0 ? (
              greyFirstBar
            ) : (
              greyBar
            )
          )}
        </div>

        <span className="form-bar">
          <i
            className={
              props.showPassword
                ? "pwd-icon fa fa-eye-slash"
                : "pwd-icon fa fa-eye"
            }
            style={props.fromReset ? { top: "10px" } : { top: "37px" }}
            onClick={() => props.toggleShowPwd()}
          />
          <span
            className="pwd-label"
            style={{ visibility: props.value === "" ? "hidden" : "visible" }}
          >
            <FormattedMessage
              id={getStrength(color)}
              defaultMessage={getStrength(color)}
            />
          </span>
        </span>
      </React.Fragment>
    );
}

const colorArray = [{ '#d11b1b': 1 }, { '#ff9a00': 2 }, { '#ffc100': 3 }, { '#58b32d': 4 }];

const colorArray_2 = [{ '#d11b1b': 1 }, { '#ff9a00': 2 }, { '#ffc100': 3 }, { '#58b32d': 4 }, { '#d8d8d8': 5 }];

const word = [
    { key: '#d8d8d8', value: englishText.TOO_WEAK },
    { key: '#d11b1b', value: englishText.WEAK },
    { key: '#ff9a00', value: englishText.AVERAGE },
    { key: '#ffc100', value: englishText.OK },
    { key: '#58b32d', value: englishText.STRONG },
];
