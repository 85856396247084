import React, { useRef } from "react";
import PropTypes from "prop-types";
// components
import { Spinner } from "react-bootstrap";
import SwiperGallery from "./swiperGallery";
// functions
import englishText from "languages/english/english";
import { setRef } from "services/referance.service";
import { openObjectDetail } from "components/dashboard/utils/commonFunctions";
import { checkIsGuestUser, getLocalizeText } from "utils";
// assets
import { Badge } from "components/commonComponents/badge";
import directBadge from "assets/images/direct-badge.png";
import newFlag from "assets/images/New-Flag.png";
import tipTile from "assets/images/placeholder.png";
// interface
import { IPropertyCardProps } from "../interface/searchResult.interface";

const PropertyCard: React.FC<IPropertyCardProps> = (props) => {
  const startFavoriteRef: any = useRef();

  const {
    isFullMapScreen,
    listItem,
    highlightclass,
    imageLoadedList,
    topResult,
    favouritesList,
    index,
    hoveredId,
    onboarding,
    addFavourites,
    setImageLoadedList,
    addHighLightClass,
    removeHighlight,
  } = props;

  const isGuestUser = checkIsGuestUser();

  return (
    <div className={`column-wrapper ${isFullMapScreen ? "column-w-100" : ""}`}>
      {listItem.is_realtor_active &&
        !listItem.type_auction_forced &&
        imageLoadedList.includes(listItem.id) && (
          <div
            className="direct-badge-class"
            style={{ animationDelay: `${index * 0.2}s` }}
          >
            <img src={directBadge} alt="img" />
          </div>
        )}

      <div
        key={listItem.id}
        className={`property-card d-flex pointer ${
          highlightclass !== "" && highlightclass === listItem.id && "highlight"
        }`}
        style={{ animationDelay: `${index * 0.1}s` }}
        onClick={(event) =>
          listItem.isActive &&
          listItem.isEnable &&
          openObjectDetail(event, listItem.id)
        }
        onMouseEnter={() => addHighLightClass(listItem.id)}
        onMouseLeave={() => removeHighlight(listItem.id, true)}
      >
        <div className="carousel-wrapper">
          {!imageLoadedList.includes(listItem.id) && (
            <div
              className="text-center"
              style={{
                height: "83%",
              }}
            >
              {[1, 2, 3].map((count) => (
                <Spinner key={count} className="spin-img" animation="grow" />
              ))}
            </div>
          )}
          {listItem.imgList.length > 0 ? (
            <div
              className={
                listItem.isActive && listItem.isEnable
                  ? "search-img1"
                  : "search-img1 disable-div"
              }
              title={
                listItem.isActive && listItem.isEnable
                  ? ""
                  : getLocalizeText(englishText.OBJECT_DISABLED_ERROR)
              }
              style={
                !imageLoadedList.includes(listItem.id)
                  ? { display: "none" }
                  : { opacity: "1" }
              }
            >
              <SwiperGallery
                objectPictures={listItem.objectPictures}
                setImageLoadedList={setImageLoadedList}
                id={listItem.id}
                hoveredId={hoveredId}
                isFullMapScreen={isFullMapScreen}
                isdisable={!listItem.isActive || !listItem.isEnable}
              />
            </div>
          ) : (
            <img
              className="search-img-single"
              src={listItem.coverImg || tipTile}
              alt="img"
              onError={() => {
                setImageLoadedList(listItem.id);
              }}
              onLoad={() => setImageLoadedList(listItem.id)}
            />
          )}
          {topResult && onboarding && listItem.rankId > 0 && (
            <div
              className="score-icon"
              style={{ backgroundColor: listItem.iconColor }}
            >
              <p>{Math.round(listItem.rankId)}</p>
            </div>
          )}
          {(!listItem.isActive || !listItem.isEnable) && (
            <div className="disable-div-icon">
              {getLocalizeText(englishText.DEACTIVATED)}
            </div>
          )}
          {listItem.isNewProperty &&
            !listItem.type_auction_forced &&
            !listItem.is_realtor_active && (
              <>
                <div className="new-flag">
                  <img src={newFlag} alt="img" />
                </div>
                <p className="new-flag text-new">
                  {getLocalizeText(englishText.NEW)}
                </p>
              </>
            )}
          {listItem.type_auction_forced && (
            <div className="inventory-badge-class">
              <Badge text={getLocalizeText(englishText.FORCLOSURE_SALE)} />
            </div>
          )}

          <div
            className="like-icon text-center pointer"
            ref={startFavoriteRef}
            onClick={() => {
              if (!favouritesList.includes(listItem.id) && !isGuestUser) {
                setRef(startFavoriteRef, "startFavoriteRef");
                props.getFavoriteStartRef();
              }

              addFavourites(
                listItem.id,
                favouritesList.includes(listItem.id) ? "remove" : "add"
              );
              favouritesList.includes(listItem.id) &&
                !isGuestUser &&
                props.changeScrollTop();
            }}
          >
            <i
              className={
                favouritesList.includes(listItem.id)
                  ? "fa fa-heart"
                  : "fa fa-heart-o"
              }
              aria-hidden="true"
            />
          </div>
          <div className="justify-content-between">
            <p className="ml-3 adr-label text-truncate">
              {listItem.propertyType
                ? `${getLocalizeText(listItem.propertyType)}  
                                ${
                                  listItem.adrPostalCode &&
                                  " in " + listItem.adrPostalCode
                                }${
                    listItem.adrQuarter && " " + listItem.adrQuarter
                  }`
                : `${listItem.adrStreet || "-"}`}
            </p>
            <div className="d-flex justify-content-between">
              <div className="d-flex">
                <p className="location font-bold mb-0">
                  <li>{`${
                    listItem.areaLiving
                      ? Math.round(listItem.areaLiving) + " m²"
                      : "-"
                  } `}</li>
                  {/* <li>{getLocalizeText(englishText.SIZE)}</li> */}
                </p>
                <p className="location font-bold mb-0">
                  <li>
                    {listItem.rooms || "-"} {getLocalizeText(englishText.ROOM)}
                  </li>
                  {/* <li>{getLocalizeText(englishText.ROOM)}</li> */}
                </p>
              </div>
              <div>
                <p className="price-label pt-0">
                  {listItem.price || "-"}
                  {/* <li>
                                        {getLocalizeText(
                                            englishText.PRICE_SMALL
                                        )}
                                    </li> */}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

PropertyCard.propTypes = {
  listItem: PropTypes.object.isRequired,
  imageLoadedList: PropTypes.array.isRequired,
  highlightclass: PropTypes.string.isRequired,
  addHighLightClass: PropTypes.func.isRequired,
  removeHighlight: PropTypes.func.isRequired,
  favouritesList: PropTypes.array.isRequired,
  getFavoriteStartRef: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  hoveredId: PropTypes.string.isRequired,
  setImageLoadedList: PropTypes.func.isRequired,
  isFullMapScreen: PropTypes.bool.isRequired,
  addFavourites: PropTypes.func.isRequired,
  onboarding: PropTypes.bool.isRequired,
  topResult: PropTypes.bool.isRequired,
  changeScrollTop: PropTypes.func.isRequired,
};

PropertyCard.defaultProps = {
  imageLoadedList: [],
  isFullMapScreen: false,
};

export default PropertyCard;
