import React, { useState, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useHistory } from "react-router-dom";
// component
import { Spinner } from "react-bootstrap";
// functions
import HttpService from "services/http.service";
import englishText from "languages/english/english";
import { detectIsMobile, sendAmplitudeDataEvent } from "utils";
// assets
import emaillogo from "assets/images/mailcheck.png";
import { Logo } from "components/commonComponents/icons";
import "../auth.scss";

export default function VerifyUser() {
  const [isActivated, setIsActivated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [errorMsg, setErrorMsg] = useState("");

  const mobileBrowser = detectIsMobile();
  const history = useHistory();

  useEffect(
    () => {
      const token = getToken();
      activateUser(token);
    },
    // eslint-disable-next-line
    []
  );

  const getToken = () => {
    let token = "";
    if (window.location.pathname !== "") {
      token = window.location.pathname.split("/activation/")[1];
    }
    return token;
  };

  const activateUser = async (activationToken: string) => {
    // call API to activate user with recieved token
    HttpService.put(`v1/profile/activation/${activationToken}`)
      .then(() => {
        // User activated successfully
        setErrorMsg("");
        localStorage.setItem("userVerified", "true");
        // localStorage.setItem("showHint", "true");
        if (mobileBrowser) {
          const meta = {
            Action: "Verification Email link opened on mobile browser",
          };
          sendAmplitudeDataEvent("mobile_email_verified", meta);
        }
        setIsLoading(false);
        setIsActivated(true);
      })
      .catch((err) => {
        const errorMessage = err.response ? err.response.data.message : "";
        if (
          errorMessage === "Your account is already verified." ||
          errorMessage === "Dein Konto ist bereits verifiziert."
        ) {
          setIsActivated(true);
          localStorage.setItem("userVerified", "true");
        } else {
          // User activation failed
          setErrorMsg(errorMessage);
        }
        setIsLoading(false);
      });
  };

  const redirectUser = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    history.push("/find");
    // // if we already have this value of onboarding status
    // const onboardingStatus = localStorage.getItem("onboarding");
    // if (onboardingStatus) {
    //   const onboardingDone = convertBool("onboarding");
    //   if (!onboardingDone) {
    //     history.push(
    //       localStorage.getItem("showWelcome") ? "/welcome" : "/onboarding"
    //     );
    //   } else {
    //     history.push("/find");
    //   }
    // } else {
    //   // we get onboarding completed status from profile API status
    //   // if onboarding complete we rdedirct to finder otherwise to onboarding
    //   HttpService.get(API_CONFIG.path.profile)
    //     .then((response) => {
    //       if (response.data && response.data.isOnBoardingCompleted) {
    //         history.push("/find");
    //       } else {
    //         history.push("/onboarding");
    //       }
    //     })
    //     .catch(() => ({ message: "something went wrong" }));
    // }
  };

  return (
    <React.Fragment>
      <div>
        <div className="logo-imz">
          <Logo />
        </div>

        <div className="text-center email-verify-container">
          <img className="email-logo mt-2 mb-2" src={emaillogo} alt="logo" />

          {!isActivated ? (
            <p className="" style={{ textAlign: "center" }}>
              {isLoading && (
                <div className="text-center" style={{ marginTop: "-120px" }}>
                  {[1, 2, 3].map((count) => (
                    <Spinner
                      key={count}
                      className="spin-img"
                      animation="grow"
                    />
                  ))}
                </div>
              )}
              <div
                style={{
                  color: "red",
                  marginTop: "20px",
                  fontSize: "13px",
                }}
              >
                {errorMsg}
              </div>
            </p>
          ) : (
            <p className="verify-title mt-4 mb-4">
              <FormattedMessage
                id={englishText.ACTIVATE_SUCCESS_MSG}
                defaultMessage={englishText.ACTIVATE_SUCCESS_MSG}
              />
            </p>
          )}
          <p className="verify-subtitle mt-2 mb-2">
            <FormattedMessage
              id={englishText.ACTIVATE_SUBTITLE}
              defaultMessage={englishText.ACTIVATE_SUBTITLE}
            />
          </p>

          <div className="mt-4 mb-2">
            <button
              className="btn mail-send-btn mt-5"
              onClick={redirectUser}
              style={{ backgroundColor: "#932849", color: "#fff" }}
            >
              <FormattedMessage
                id={englishText.FIND_PROPS_NOW}
                defaultMessage={englishText.FIND_PROPS_NOW}
              />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}
