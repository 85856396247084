import React from "react";
import { CloseIcon } from "components/commonComponents/icons";
import { Spinner } from "react-bootstrap";

interface IContactFormProps {
  leftPannel: JSX.Element;
  rightPannel: JSX.Element;
  isLoading?: boolean;
  className?: string;
  onClose: () => void;
}

const ContactPopUpWrapper: React.FC<IContactFormProps> = (props) => {
  const { leftPannel, rightPannel, className, onClose, isLoading } = props;

  return (
    <div id="modal-content">
      <div
        className={`contact-pop-up-wrapper d-flex justify-content-start align-items-stretch ${
          className || ""
        }`}
      >
        <div className="close-btn" onClick={onClose}>
          <CloseIcon fill="white" />
        </div>
        <div className="left-pannel">
          {isLoading && (
            <div className="loader-wrapper fade-in">
              {/* <LogoLoader /> */}
              {[1, 2, 3].map((count) => (
                <Spinner
                  key={count}
                  style={{
                    height: "20px",
                    width: "20px",
                    margin: "0 10px",
                  }}
                  animation="grow"
                  variant="danger"
                />
              ))}
            </div>
          )}
          {leftPannel}
        </div>
        <div className="right-pannel">{rightPannel}</div>
      </div>
    </div>
  );
};

export default ContactPopUpWrapper;
