import queryString from 'query-string';
import { BASE_URL } from './baseUrl';

export const API_CONFIG = {
  // eslint-disable-next-line no-undef
  baseUrl: `${BASE_URL}`,
  path: {
    // v1 APIs
    login: "v1/auth/login",
    adminLogin: "v1/auth/login/admin",
    forget: "v1/password/forgot",
    reset: "v1/password/reset",
    signup: "v1/register",
    logout: "v1/logout",
    guestSignup: "v1/register/guest",
    profile: "v1/profile",
    regenerateToken: "v1/register/generatetoken",
    senderVerificationMail: "v1/profile/sendverificationmail",
    s3: "v1/profile/s3",
    changeEmail: "v1/profile/email",
    changeLanguage: "v1/profile/language/change",
    changePassword: "v1/profile/password/change",
    calculator: "v1/calculator",
    updateContact: "v1/profile/contactinfo",
    updateGuestContact: "v1/profile/contactinfo/guest",
    qualifyData: "v1/qualify/qualifydata",
    saerchcount: "v1/profile/searchcount",
    removeCustomFavorite: "v1/objects/favourite/remove/",
    calendlyinfo: "v1/calendly",
    profiles3: "v1/profile/s3",
    potentialUser: "v1/profile/potentialinteresteduser",
    addCustomFavorite: "v1/objects/favourite/add/",
    getS3Creds: "v1/qualify/getPresignURL",
    saveUploadedFile: "v1/qualify/UploadFinanceDocument",
    removeUploadedFile: "v1/qualify/removeUploadDocument",
    getUploadedFile: "v1/qualify/uploadDocumentList",
    getRealtorHandShakeDetail: "v1/profile/realtorhandshakedetails",
    saveRealtorHandShakeDetail: "v1/profile/realtorhandshakeadd",
    getRealtorHandShakeDetailForPdf: "v1/profile/realtorhandshakepdf",
    // v2
    onboardingNew: "v2/onboarding",
    financeOnboarding: "v2/onboarding/finance",
    // v3
    saveSearch: "v3/savesearch",
    // v4 APIs
    getLandingPageData: "v4/openapi/objects",
    immozyCenter: "v4/center",
    showPropertyReq: "v4/showproperty/request",
    getUserID: "v4/openapi/uuid",
    getDocumentsStatus: "v4/documents",
    requestDocuments: "v4/documents/request",
    objects: "v4/objects",
    graph: "v4/objects/minmaxgraph",
    favoritesList: "v4/objects/favourites",
    getInterestedObjList: "v4/objects/interestedPropertyList",
  },
};

export const getUrl = (url: string, params: any = {}): string => {
  Object.keys(params).forEach((key) => (params[key] == null || params[key] === '') && delete params[key]);
  if (url.includes('http')) {
    return url;
  }
  let urlString = `${BASE_URL}${url}`;
  if (params && params !== '') {
    urlString += `?${queryString.stringify(params)}`;
  }
  return urlString;
};

// beta: "https://api.immozy.de/api/"
// staging url: "http://staging-api.immozy.de/api/";
