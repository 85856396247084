/* eslint-disable @typescript-eslint/ban-ts-comment */
import jwt_decode from "jwt-decode";
import queryString from "query-string";
import { API_CONFIG, HttpService } from "services";
import { cities, setAmplitudeUserId, setAmplitudeUserProperties } from "utils";
import { SyntheticEvent } from "react";
// assets
import yellow from "assets/images/yellow-Marker.png";
import green from "assets/images/Green-Marker_2.png";
import red from "assets/images/Red-Marker.png";
import redHighlight from "assets/images/red-highlight.png";
import yellowHighlight from "assets/images/yellow-highlight.png";
import tipTile from "assets/images/placeholder.png";
import greenHighlight from "assets/images/green-highlight.png";
import berlinLoad from "assets/images/loader-Berlin.png";
import hamburgLoad from "assets/images/loader-Hamburg.png";
import hannoverLoad from "assets/images/loader-Hannover.png";
import liepzigLoad from "assets/images/loader-liepzig.png";

export const deleteIndexdDB = () => {
  try {
    const request = window.indexedDB.open("newDatabase");
    request.onsuccess = function () {
      const db = request.result;
      if (db) {
        const transaction = db.transaction(["data"], "readwrite");
        const objectStore = transaction.objectStore("data");
        objectStore && objectStore.clear();
      }
    };
  } catch (error) {
    console.log(error);
  }
};

export const getIcon = (value: number) => {
  if (value <= 33) {
    return "#d11b1b";
  } else if (value > 33 && Math.round(value) <= 67) {
    return "#ffbb00";
  } else {
    return "#65c239";
  }
};

export const getIconImg = (value: number) => {
  if (value <= 33) {
    return red;
  } else if (value > 33 && Math.round(value) <= 67) {
    return yellow;
  } else {
    return green;
  }
};

export const getIconImgHighlight = (value: number) => {
  if (value <= 33) {
    return redHighlight;
  } else if (value > 33 && Math.round(value) <= 67) {
    return yellowHighlight;
  } else {
    return greenHighlight;
  }
};

export const disableScrolling = (value: boolean) => {
  if (value) {
    document.body.style.width = "100%";
    document.body.style.overflowY = "scroll";
    document.body.style.position = "fixed";
  } else {
    document.body.style.width = "";
    document.body.style.overflowY = "";
    document.body.style.position = "";
  }
};

export const getSinglefilterParameters = (value: string) => {
  let symbol = "";
  let maxValue = 0;
  let step = 0;
  let rightMargin = "0px";
  let widthValue = 80;
  let valueStep = 1;
  let graphLabel = "";

  if (value === "price") {
    symbol = "EUR ";
    maxValue = 1000000;
    step = 0.0001;
    rightMargin = "20px";
    widthValue = 80;
    valueStep = 10000;
    graphLabel = "priceGraph";
  }

  if (value === "space") {
    symbol = "qm";
    maxValue = 200;
    step = 0.5;
    rightMargin = "60px";
    widthValue = 40;
    valueStep = 10;
    graphLabel = "spaceGraph";
  }

  if (value === "rooms") {
    maxValue = 8;
    step = 12.5;
    rightMargin = "120px";
    widthValue = 40;
    valueStep = 1;
    graphLabel = "roomsGraph";
  }
  return {
    symbol,
    maxValue,
    step,
    rightMargin,
    widthValue,
    valueStep,
    graphLabel,
  };
};

export const getStatusData = () => {
  let userVerified = true;
  let onboarding = true;

  if (localStorage.getItem("userVerified")) {
    const value = localStorage.getItem("userVerified");
    userVerified = value === "true" ? true : false;
  }

  if (localStorage.getItem("onboarding")) {
    const value = localStorage.getItem("onboarding");
    onboarding = value === "true" ? true : false;
  }

  return {
    userVerified,
    onboarding,
  };
};

export const getScripts = () => {
  const scriptsArray = [
    "https://maps.googleapis.com/maps/api/js?key=AIzaSyCp3nsSMrwMgn1xRxMARGhwuygdnKxAapk&v=3.exp&libraries=places",
    "https://unpkg.com/@googlemaps/markerclustererplus/dist/index.min.js",
  ];
  return scriptsArray;
};

// function inIframe() {
//   try {
//     return window.self !== window.top;
//   } catch (e) {
//     return false;
//   }
// }

export const convertBool = (name: string) => {
  const value = localStorage.getItem(name);
  let boolValue = false;
  if (value) {
    boolValue = value === "true" ? true : false;
  }
  return boolValue;
};

export const clearStorage = () => {
  const removeItems = [
    "propertyCount",
    "firstName",
    "username",
    "token",
    "userVerified",
    "onboarding",
    "onboardingHalfDone",
    "turnTopResult",
    "event_added_welcome",
    "event_added_Wc",
    "showWelcome",
    "userId",
    "ampSessionId",
    "ampDeviceId",
    "alertShowed",
    "utm",
    "selectedCity",
    "failed",
    "role",
    "refreshToken",
    "tryAgainCalled",
    "refreshPage",
    "buyId",
  ];

  removeItems.forEach((item) => {
    localStorage.removeItem(item);
  });

  sessionStorage.clear();
};

export const detectIsMobile = () => {
  let a = false;
  try {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      a = true;
    } else {
      a = false;
    }
  } catch (error) {
    console.log(error);
    return a;
  }

  return a;
};

export const setPlaceholderImg = (e: SyntheticEvent) => {
  (e.target as HTMLImageElement).src = tipTile;
};

export const setPlaceholderImgForImgGallary = (e: any) => {
  e.target.src = tipTile;
};

export const utmParamRetieve = () => {
  const params = ["utm_source", "utm_medium", "utm_campaign", "utm_term"];
  const utm: any = {};

  params.forEach((param: string) => {
    const value = getParam(param);
    if (value) {
      utm[param] = value;
    }
  });
  return utm;
};

export const getUrlParameters = (decode = true) => {
  let queryParams = {};
  let url = window.location.search;
  if (window.location.search.includes("Hannover")) {
    const queryStringArray = window.location.search.split("?");
    if (queryStringArray.length >= 2) {
      url = `?${queryStringArray[2]}`;
    }
  }
  queryParams = url
    .substr(1)
    .split("&")
    .reduce((obj: any, keyVal: any) => {
      const [key, value] = keyVal.split("=");
      if (value) {
        obj[key] = decode
          ? decodeURIComponent(value.replace(/\+/g, " "))
          : value;
        return obj;
      }
    }, {});

  return queryParams;
};

export const getLoadingImg = (city: string) => {
  switch (city) {
    case cities.BER:
      return berlinLoad;

    case cities.HAM:
      return hamburgLoad;

    case cities.HANNOVER:
      return hannoverLoad;

    case cities.LEIPZIG:
      return liepzigLoad;

    default:
      return hamburgLoad;
  }
};

export const getCoordinates = (city: string) => {
  HttpService.get(`v1/coordinates/boundary/${city.toLowerCase()}`, {})
    .then((response) => {
      const coordinates = response.data.data;
      localStorage.setItem("mapCoordinates", JSON.stringify(coordinates));
    })
    .catch((err) => {
      console.log(err);
    });
};

export const addZeroes = (num: any, locale: string) =>
  num.toLocaleString(locale, {
    useGrouping: false,
    minimumFractionDigits: 2,
  });

export const checkIsGuestUser = () =>
  localStorage.getItem("role")
    ? localStorage.getItem("role") === "GUEST"
    : false;

export const refreshTokenCall = () => {
  HttpService.post("v1/register/generatetoken", {
    refreshToken: localStorage.getItem("refreshToken"),
  })
    .then((response) => {
      if (response.data && response.data.token) {
        localStorage.setItem("token", response.data.token);
        window.location.reload();
      }
    })
    .catch(() => {
      HttpService.post(API_CONFIG.path.guestSignup)
        .then((response) => {
          const token = response.data.token;
          const refreshToken = response.data.refreshToken;
          const userId: string = response.data.userId;
          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", refreshToken);
          localStorage.setItem("userId", userId);
          localStorage.removeItem("alertShowed");
          localStorage.removeItem("failed");
          setAmplitudeUserId();
          setAmplitudeUserProperties({ rolename: "GUEST" });
          window.location.pathname = "/find";
        })
        .catch(() => {
          console.log("something went wrong");
          localStorage.removeItem("token");
          window.location.pathname = "/login";
        });
    });
};

export const getParam = (param: string) =>
  new URLSearchParams(window.location.search).get(param);

export const getCityFromrl = (search: any) => {
  let cityFromURl: any = cities.BER;

  const queryParams = queryString.parse(search);
  if (queryParams && queryParams.c && queryParams.c !== "") {
    cityFromURl = queryParams.c;
  }
  return cityFromURl;
};

export const loginUsingUrl = () => {
  if (
    window.location &&
    window.location.search &&
    window.location.search !== "" &&
    window.location.search.includes("token")
  ) {
    const queryParams = queryString.parse(window.location.search);
    if (queryParams && queryParams.token && queryParams.token !== "") {
      const token: any = queryParams.token || "";
      localStorage.setItem("token", token);

      const decoded: any = token ? jwt_decode(token) : null;
      localStorage.setItem("language", decoded.language);

      const userId: any = queryParams.userId || "";
      localStorage.setItem("userId", userId);
      localStorage.setItem("role", "USER");
    }
  }
};

export const setUserIdToLocal = () => {
  HttpService.get(API_CONFIG.path.profile)
    .then((response) => {
      if (response.data) {
        if (!localStorage.getItem("userId")) {
          localStorage.setItem("userId", response.data.userId);
        }
      }
    })
    .catch(() => ({ message: "something went wrong" }));
};

export const checkValidation = (details: any) => {
  const errorFields: string[] = [];

  for (const [key, value] of Object.entries(details)) {
    if (key !== "agreed") {
      if (value === "" || !value) errorFields.push(key);
    }
  }

  return errorFields;
};

export const callFirstTimeUserEvent = () => {
  HttpService.post("v3/savesearch/uniqueSerch");
};

export const logoutSession = () => {
  localStorage.setItem("token", localStorage.getItem("callLogout") || "");
  HttpService.post(API_CONFIG.path.logout, {})
    .then(() => {
      localStorage.removeItem("callLogout");
      localStorage.removeItem("token");
    })
    .catch(() => {
      clearStorage();
    });
};

export const convertDateFormatToDdMm = (dateString: string) => {
  const from = dateString && dateString.split("/");
  const dateFormatted = `${from[1]}/${from[0]}/${from[2]}`;
  return dateFormatted;
};

export const convertToLocaleFormat = (value: string) => {
  if (value) {
    const lang = localStorage.getItem("language") || "de-DE";
    const locale = lang && lang.includes("en") ? "en-US" : "de-DE";
    const a = Number(value).toLocaleString(locale);
    return a;
  }
  return value;
};