import React, { useEffect, useState } from 'react';
import ImageGallery from 'react-image-gallery';
import { setPlaceholderImgForImgGallary } from "utils/functions";
import * as imageGalleryInterface from "../../interface/objectDetails";
import realtorImg from "assets/images/special_replace_realtor.jpg";

import { CloseIcon } from "components/commonComponents/icons";

const ImageGallary: React.FC<imageGalleryInterface.IImageGalleryProps> = (
  props
) => {
  const { isReplaceImageRealtor } = props;
  const [imageList, setImageList] = useState<any[]>([]);

  useEffect(() => {
    if (imageList.length === 0) {
      const list: any[] = [];
      props.imgList.forEach((item: any) => {
        list.push({
          original: item.original,
          thumbnail: item.original,
        });
      });
      setImageList(list);
    }
  }, [imageList.length, props.imgList]);

  const setRealtorImage = (e: any) => {
    e.target.src = realtorImg;
  };

  return (
    <div
      className={`image-gallery-popup carousel ${
        imageList && imageList.length === 1 && "one-image-slider"
      }`}
    >
      <div className="close-btn-div" onClick={() => props.closeGallery()}>
        <CloseIcon />
      </div>
      <ImageGallery
        showBullets={false}
        items={imageList}
        showPlayButton={false}
        showFullscreenButton={false}
        showThumbnails={true}
        // originalAlt='Property image'
        startIndex={props.startIndex}
        onImageError={
          isReplaceImageRealtor
            ? setRealtorImage
            : setPlaceholderImgForImgGallary
        }
        onThumbnailError={
          isReplaceImageRealtor
            ? setRealtorImage
            : setPlaceholderImgForImgGallary
        }
      />
    </div>
  );
};

export default ImageGallary;
