import React from "react";
import englishText from "languages/english/english";
import { getLoadingImg, getLocalizeText } from "utils";

interface ICityLoaderProps {
  cityTemp: string;
  msgText: string;
}

const CityChangeLoader: React.FC<ICityLoaderProps> = (props) => {
  const { cityTemp, msgText } = props;
  return (
    <div className="loading-background">
      <img
        className="loading-bar loading-bar1"
        src={getLoadingImg(cityTemp)}
        alt=""
      />

      <div className="d-flex justify-content-center alight-items-center">
        <li className="loader-text loader-text-1 ">
          {getLocalizeText(englishText.LOADING_DREAM_HOMES)}
        </li>
        <li className="loader-text loader-text-2">{cityTemp}</li>

        <li key={msgText} className="loader-text loader-text-3">
          {getLocalizeText(msgText)}
        </li>
      </div>
    </div>
  );
};

export default CityChangeLoader;
