import englishText from "languages/english/english";
import React from "react";
import { FormattedMessage } from "react-intl";

interface ICalcFooterProps {
  showInterestedPopup: () => void;
}

const CalcFooter: React.FC<ICalcFooterProps> = (props) => (
  <div className="calculator-footer d-flex align-items-center justify-content-center w-100">
    <p className="mr-4 footer-text">
      <FormattedMessage
        id={englishText.GET_FREE_RECOMMENDATIONS}
        defaultMessage={englishText.GET_FREE_RECOMMENDATIONS}
      />
    </p>
    <button className="btn calc-btn red" onClick={props.showInterestedPopup}>
      <FormattedMessage
        id={englishText.GET_STARTED}
        defaultMessage={englishText.GET_STARTED}
      />
    </button>
  </div>
);

export default CalcFooter;
