import callAPI from "services/callApi";

export const addUpdateObject = (
  data: any,
  url?: string,
  method?: string,
  objectId?: string
) => {
  const dataUpdated = objectId ? { ...data, objectId } : data;
  return {
    type: "ADD_UPDATE_OBJECT",
    payload:
      url &&
      callAPI(url, method, {
        ...dataUpdated,
      }),
  };
};
