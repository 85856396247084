import React, { Component } from "react";
// components
import Form from "react-bootstrap/Form";
import { Spinner } from "react-bootstrap";
import ChangePasswordTab from "components/profile/components/changePassword";
// functions
import * as awsService from "services/aws";
import englishText from "languages/english/english";
import { disableScrolling, getStatusData, lang, getLocalizeText } from "utils";
import { API_CONFIG, HttpService } from "services";
// assets
import user from "assets/images/user-icon.png";
import { CloseIcon } from "components/commonComponents/icons";
// interface
import * as profileInterface from "../interface/profile";

class EditProfile extends Component<profileInterface.IProfileProps> {
  state: profileInterface.IProfileState = {
    changePassword: false,
    profileLoading: true,
    apiData: {},
    isError: "",
    password: "",
    newPassword: "",
    issuccess: "",
    language: "",
    userVerified: true,
  };

  componentDidMount() {
    this.getProfileInfo();
    const data = getStatusData();
    this.setState({ ...data });
    disableScrolling(true);
  }

  componentWillUnmount() {
    disableScrolling(false);
  }

  render() {
    const { changePassword, profileLoading, userVerified, isError, avatar } =
      this.state;

    const { closeEditProfile } = this.props;

    return (
      <>
        <div
          className={`edit-profile ${changePassword ? "show" : "hide-class"}`}
        >
          <div
            className="close-btn-div"
            onClick={() => closeEditProfile(this.state.languageChanged)}
          >
            <CloseIcon />
          </div>
          <div className="profile-details">
            <h2 className="section-title">
              {getLocalizeText(englishText.MY_PROFILE)}
            </h2>

            <form
              onClick={() =>
                changePassword &&
                this.setState({
                  changePassword: false,
                  isError: "",
                })
              }
            >
              <div className="row">
                <div className="col-3">
                  <div className="profile-picture">
                    <label
                      htmlFor="profile"
                      className="profile-preview pointer"
                    >
                      <img
                        className="profile-pic"
                        src={this.state.avatar || user}
                        alt=""
                      />
                      {!profileLoading && avatar && avatar !== "" && (
                        <div
                          className="remove-photo"
                          title={getLocalizeText(englishText.REMOVE_IMG)}
                          onClick={(event) => {
                            event.preventDefault();
                            this.setState({ avatar: "" });
                          }}
                        >
                          <CloseIcon />
                        </div>
                      )}
                    </label>
                    <input
                      id="profile"
                      name="avatar"
                      type="file"
                      autoComplete="no"
                      accept="image/*"
                      onChange={this.handleImage}
                      hidden
                      disabled={profileLoading || changePassword}
                    />
                  </div>
                </div>
                <div className="col-9">
                  <h3
                    className="section-title pb-3 text-left"
                    style={{ letterSpacing: "0px" }}
                  >
                    {getLocalizeText(englishText.HEY)},{" "}
                    {this.state.apiData.firstName}
                  </h3>
                  <div className="row pb-2">
                    <div className="col-3">
                      <label>Name</label>
                    </div>
                    <div className="col-8 row" style={{ paddingRight: "5px" }}>
                      <input
                        className="abc name-input-profile"
                        name={"firstName"}
                        type="text"
                        value={this.state.firstName}
                        disabled={profileLoading || changePassword}
                        placeholder={getLocalizeText(englishText.FIRST_NAME)}
                        onChange={this.handleChange}
                        style={{ marginRight: "2%", boxShadow: "none" }}
                        title={this.state.firstName}
                      />
                      <input
                        className="abc  name-input-profile"
                        name={"lastName"}
                        type="text"
                        value={this.state.lastName}
                        disabled={profileLoading || changePassword}
                        placeholder={getLocalizeText(englishText.LAST_NAME)}
                        onChange={this.handleChange}
                        title={this.state.lastName}
                        style={{ boxShadow: "none" }}
                      />
                    </div>
                  </div>

                  <div className="row pb-2">
                    <div className="col-3">
                      <label>E-Mail</label>
                    </div>
                    <div className="col-8" style={{ paddingRight: "5px" }}>
                      <input
                        className="abc"
                        name={"email"}
                        type="text"
                        value={this.state.email}
                        disabled={profileLoading || changePassword}
                        placeholder={"email"}
                        onChange={this.handleChange}
                        style={{ boxShadow: "none" }}
                      />
                      {!userVerified && (
                        <i
                          className="not-verified fa fa-exclamation-circle pointer"
                          aria-hidden="true"
                          style={{
                            top: "0px",
                            right: "-27px",
                            background: "#fff",
                          }}
                        />
                      )}
                    </div>
                  </div>
                  <div className="row pb-3">
                    <div className="col-3">
                      <label>{getLocalizeText(englishText.PASSWORD)}</label>
                    </div>
                    <div className="col-9" style={{ paddingRight: "5px" }}>
                      <p
                        className="change-password-link"
                        onClick={() =>
                          this.setState({
                            changePassword: !changePassword,
                            isError: "",
                            issuccess: "",
                          })
                        }
                      >
                        {getLocalizeText(englishText.CHANGE_PASSWORD)}
                      </p>
                    </div>
                  </div>
                  <div className="row pb-4">
                    <div className="col-3">
                      <label>{getLocalizeText(englishText.LANGUAGE)}</label>
                    </div>
                    <div className="col-9" style={{ paddingRight: "5px" }}>
                      <Form.Check
                        type="radio"
                        custom
                        label={getLocalizeText(englishText.GERMAN)}
                        name="language"
                        id={lang.GR}
                        checked={this.state.language === lang.GR}
                        onChange={this.handleChange}
                      />
                      <Form.Check
                        type="radio"
                        custom
                        label={getLocalizeText(englishText.ENGLISH)}
                        name="language"
                        id={lang.EN}
                        checked={this.state.language === lang.EN}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {isError !== "" && !changePassword && (
                <p className="profile-error">{getLocalizeText(isError)}</p>
              )}
              {this.state.issuccess !== "" && !changePassword && (
                <p className="profile-success">
                  {getLocalizeText(this.state.issuccess)}
                </p>
              )}
              <div className="d-flex justify-content-center">
                <div className="text-center d-flex justify-content-center relative">
                  <button
                    className="button btn-submit"
                    onClick={(event) => this.handleSaveProfile(event)}
                    disabled={profileLoading || changePassword}
                  >
                    {getLocalizeText(englishText.SAVE)}
                  </button>
                  {profileLoading && (
                    <div className="spinner-wrapper">
                      <Spinner
                        className="ml-2"
                        animation="border"
                        variant="info"
                      />
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
          <div className={`change-password ${changePassword ? "show" : ""}`}>
            <ChangePasswordTab
              handleChange={this.handleChange}
              submitPassword={this.submitPassword}
              state={this.state}
              onKeyDown={() => console.log("keyDown")}
              isLoading={profileLoading}
            />
          </div>
          {(!userVerified || this.state.secondaryEmail) &&
            !changePassword &&
            !profileLoading && (
              <div className="verify-email-warning text-center">
                <p>{getLocalizeText(englishText.EMAIL_NOT_VERIFIED)}</p>
                {this.state.secondaryEmail && (
                  <p>
                    {getLocalizeText(englishText.YOUR_OLD_MAIL)}
                    {getLocalizeText(englishText.WILL_BE_REMOVE)}{" "}
                    {this.state.secondaryEmail}
                  </p>
                )}

                <p
                  className="underline-text"
                  onClick={() => this.hanldeVerifyClick()}
                >
                  {getLocalizeText(englishText.RESEND_MAIL_MSG)}
                </p>
              </div>
            )}
        </div>
      </>
    );
  }

  hanldeVerifyClick = async () => {
    HttpService.post(API_CONFIG.path.senderVerificationMail)
      .then(() => {
        this.setState({ issuccess: englishText.EMAIL_SENT_SUCCESS });
      })
      .catch((err) => {
        const msg = err.message ? err.message : englishText.CANT_SEND;
        this.setState({ isError: msg });
      });
  };

  // on change event handling for input fields
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.type === "radio") {
      this.setState({ [event.target.name]: event.target.id });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
        isError: "",
        issuccess: "",
      });
    }
  };

  // Ftech profile info from API
  getProfileInfo = async () => {
    HttpService.get(API_CONFIG.path.profile)
      .then((response) => {
        if (response.data) {
          const data = response.data;
          console.log(data);

          this.setState({ apiData: data, ...data, profileLoading: false });
        }
      })
      .catch(() => ({ message: "something went wrong" }));
  };

  //image input handling
  handleImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const allowedTypes = ["image/jpeg", "image/png", "image/jpg"];
    const image = e.target.files && e.target.files[0];
    if (image && e.target.files) {
      this.state.isError && this.setState({ isError: "" });

      if (image.size / 1024 / 1024 > 1) {
        this.setState({ isError: englishText.IMAGE_SIZE_ERROR });
      } else if (!allowedTypes.includes(image.type)) {
        this.setState({ isError: englishText.IMAGE_TYPE_ERROR });
      } else {
        this.setState({
          avatar: URL.createObjectURL(e.target.files[0]),
          profileLoading: true,
        });

        this.uploadFile(e.target.files[0]);
      }
    }    
  };

  // call request to upload file to s3
  uploadFile = async (file: File) => {
    HttpService.get(API_CONFIG.path.s3)
      .then((response) => {
        awsService.updateAwsConfig(response.data);

        const promises = [];
        if (!this.state.isUploaded) {
          const timestamp = new Date().getTime();
          const fileName = `avatar/image_avatar_${timestamp}.png`;

          const params = awsService.getS3UploadParams(
            file,
            fileName,
            "immozy-user-avatar"
          );

          const uploadToS3 = awsService.manageUpload(params);
          promises.push(awsService.uploadImage(uploadToS3));
        }
        Promise.all(promises).then((value) => {
          this.setState({
            profileLoading: false,
            avatar: value[0],
          });
        });
      })
      .catch(() => this.setState({ profileLoading: false }));
  };

  // get image uploaded status
  updateUploadStatus = (
    progress: string,
    isUploading: boolean,
    isUploaded: boolean
  ) => {
    this.setState({
      isUploading: isUploading,
      isUploaded: isUploaded,
      progress: progress,
    });
  };

  // save profile click
  handleSaveProfile = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();

    //check validation fro empty fields
    const errorMsg = this.validateInput();
    if (errorMsg !== "") {
      this.setState({ isError: errorMsg });
    } else {
      //first we have to check which fields have changed as there are different APIs for each
      const changedFields = this.checkChanges();

      //perform according API calls to change each field
      if (changedFields.length > 0) {
        this.setState({ profileLoading: true });
        if (
          changedFields.includes("firstName") ||
          changedFields.includes("lastName") ||
          changedFields.includes("avatar")
        ) {
          this.setState({ profileEditLoad: true });
          this.editProfile();
        }
        if (changedFields.includes("email")) {
          this.setState({ emailEditLoad: true });
          this.changeMail();
        }
        if (changedFields.includes("language")) {
          this.setState({ langEditLoad: true });
          this.changeLanguage();
        }

        //check if we have got all resposnes from API
        this.checkChangeFinished(changedFields);
      }
    }
  };

  // check if all APIs has got rsponses
  checkChangeFinished = (changedFields: string[]) => {
    //keep interval to check if we have recived all responses
    const a = setInterval(() => {
      const { profileEditLoad, emailEditLoad, langEditLoad, isError } =
        this.state;
      if (!profileEditLoad && !emailEditLoad && !langEditLoad) {
        this.setState({ profileLoading: false });
        clearInterval(a);
        if (changedFields.includes("language")) {
          this.props.closeEditProfile(true);
        } else {
          if (isError === "") {
            this.setState({ issuccess: englishText.PROFILE_SUCCESS_MSG });
            setTimeout(() => {
              this.setState({ issuccess: "" });
            }, 3000);
          }
          this.getProfileInfo();
        }
      }
    }, 500);
  };

  // check for empty/invalid fields valisation
  validateInput = () => {
    const { firstName, email, lastName } = this.state;
    let errorMsg = "";

    if (!firstName || firstName === "") {
      errorMsg = englishText.FIRST_ERROR;
    } else if (!lastName || lastName === "") {
      errorMsg = englishText.LAST_ERROR;
    } else if (
      (firstName && firstName.length > 24) ||
      (lastName && lastName.length > 24)
    ) {
      errorMsg = englishText.CHAR_LIMIT;
    } else if (!email || email === "") {
      errorMsg = englishText.MAIL_ERROR;
    } else if (firstName && firstName.length > 25) {
      errorMsg = englishText.CHAR_LIMIT;
    }
    return errorMsg;
  };

  // check which fields are changed so we can call APIs acoordinlgy
  checkChanges = () => {
    const { apiData } = this.state;
    const changedFields: string[] = [];

    ["firstName", "lastName", "avatar", "email", "language"].forEach(
      (field) => {
        if (this.state[field] !== apiData[field]) {
          changedFields.push(field);
        }
      }
    );
    return changedFields;
  };

  // call edit profile API
  editProfile = async () => {
    const { avatar, firstName, lastName } = this.state;

    HttpService.put(API_CONFIG.path.profile, { avatar, firstName, lastName })
      .then((response) => {
        if (response.data) {
          this.setState({ profileEditLoad: false });
        }
      })
      .catch((err) => {
        this.setState({
          profileEditLoad: false,
          isError: err.message || englishText.SAVE_PROFILE_ERROR,
        });
      });
  };

  // call edit email API
  changeMail = async () => {
    const { email } = this.state;
    HttpService.put(API_CONFIG.path.changeEmail, { email })
      .then((response) => {
        if (response.data) {
          this.setState({ emailEditLoad: false });
        }
      })
      .catch((err) => {
        this.setState({
          isError: err.message || englishText.SAVE_MAIL_ERROR,
          emailEditLoad: false,
        });
      });
  };

  // call edit language API and set language in localstorage
  changeLanguage = async () => {
    const { language } = this.state;
    HttpService.put(API_CONFIG.path.changeLanguage, { language })
      .then(() => {
        this.setState({ langEditLoad: false });
        localStorage.setItem("language", language);
      })
      .catch(() => {
        this.setState({ langEditLoad: false });
      });
  };

  // call edit profile API
  submitPassword = async () => {
    const { password, newPassword, issuccess, isError } = this.state;
    if (issuccess !== "" || isError !== "") {
      this.setState({ isError: "", issuccess: "" });
    }
    if (password === "" || newPassword === "") {
      this.setState({ isError: englishText.FIELD_FILL_ERROR });
    } else {
      HttpService.put(API_CONFIG.path.changePassword, { password, newPassword })
        .then(() => {
          const msg = getLocalizeText(englishText.PWD_CHANGED_SUCCESS_ALERT);
          alert(msg);
          localStorage.removeItem("token");
          window.location.href = "/login";
        })
        .catch((err) => {
          const message =
            (err.response && err.response.data && err.response.data.message) ||
            englishText.ERROR_CONTACT_ADMIN;

          this.setState({ isError: message });
        });
    }
  };
}

export default EditProfile;
