import React, { useEffect, useRef, useState } from "react";
import { parseInt } from "lodash";
import de from "react-phone-input-2/lang/de.json";
// Components
import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import { toast } from "react-toastify";
import { DynmicTextComponent } from "./dynmicTextComponent";
import ContactPopUpWrapper from "./contactPopUpWrapper";
import RealtorHeader from "./realtorHeader";
// functions
import englishText from "languages/english/english";
import { API_CONFIG, HttpService } from "services";
import { FormattedMessage } from "react-intl";
import { lang, checkValidation, getLocalizeText } from "utils";
// Assets
import "react-phone-input-2/lib/material.css";
import "react-toastify/dist/ReactToastify.css";

const selectedLang = localStorage.getItem("language");

const genderObj = [
  { title: englishText.MR, value: "MALE" },
  { title: englishText.MS, value: "FEMALE" },
];

interface userContactProps {
  firstName: string;
  lastName: string;
  phone: string;
  gender: string;
  contact_email: string;
  street: string;
  postalcode: string;
  city: string;
}

interface IContactFormProps {
  formTypeName?: string;
  closeActivePopup?: () => void;
  openRealtorInfo: () => void;
  setBack?: () => void;
  links?: any[];
  propertyDetail?: any;
  id?: string;
  platforms?: any;
  fromCalculator?: boolean;
  selectedLang: string;
}

const ContactRealtorPopUp: React.FC<IContactFormProps> = (props) => {
  const modalRef = useRef<any>(null);
  const [checkProfile, setcheckProfile] = useState(true);
  const [valueChanged, setvalueChanged] = useState(false);
  // const [apiError, setapiError] = useState("");
  const [errorFields, seterrorFields] = useState<any[]>([]);

  const [contactDetails, setContactDetails] = useState<userContactProps>({
    firstName: "",
    lastName: "",
    phone: "",
    gender: "MALE",
    contact_email: "",
    street: "",
    postalcode: "",
    city: "",
  });

  useEffect(() => {
    checkProfile && getProfileInfo();
  });

  const { closeActivePopup } = props;

  const getProfileInfo = () => {
    HttpService.get(API_CONFIG.path.profile)
      .then((response) => {
        const details = response.data;
        if (details) {
          const contactDetails = {
            firstName: details.firstName || "",
            lastName: details.lastName || "",
            phone: details.phone || "",
            gender: details.gender || "",
            contact_email: details.contact_email || details.email,
            postalcode: details.postalcode,
            city: details.city,
            street: details.street,
          };
          setContactDetails(contactDetails);
        }
        setcheckProfile(false);
      })
      .catch(() => {
        setcheckProfile(false);
      });
  };

  const hanldeSubmit = () => {
    if (validationCheck(contactDetails)) {
      HttpService.put(API_CONFIG.path.profile, contactDetails)
        .then((response) => {
          if (response.data) {
            toast.success(getLocalizeText(englishText.DATA_SAVED));
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error(getLocalizeText(englishText.SOMETHING_WENT_WRONG));
        });
    }
  };

  const validationCheck = (contactDetails: any) => {
    const errorFields: any = checkValidation(contactDetails);
    const letters = /^([äöüÄÖÜßA-z]+)(?:[\s|-]+[äöüÄÖÜßA-z]+){0,1}$/;

    ["firstName", "lastName", "city"].forEach((field) => {
      if (
        contactDetails[field] &&
        (contactDetails[field].length > 24 ||
          !contactDetails[field].match(letters))
      ) {
        errorFields.push(field);
      }
    });

    if (errorFields.length > 0) {
      if (errorFields.includes("city") && errorFields.length === 1) {
        showError(englishText.CITY_ERROR);
      } else {
        showError(englishText.INVALID_INPUT_FIELDS);
      }
    }

    seterrorFields(errorFields);

    const streetName = /^[ÆæßäöüÄÖÜß\w]+[,| |-]+[ÆæäöüÄÖÜß\w]+/;

    if (
      contactDetails.street &&
      (!contactDetails.street.match(streetName) ||
        !/\d/.test(contactDetails.street))
    ) {
      errorFields.push("street");
      showError(englishText.STREET_ERROR);
    }

    if (contactDetails.postalcode && contactDetails.postalcode.length < 4) {
      errorFields.push("postalcode");
      showError(englishText.POSTAL_CODE_ERROR);
    } else if (contactDetails.postalcode) {
      contactDetails.postalcode = parseInt(contactDetails.postalcode);
    }

    return errorFields.length === 0;
  };

  const showError = (msg: string) => {
    toast.error(getLocalizeText(msg), {
      autoClose: 5000,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.type === "radio" ? "gender" : e.target.name;
    const value = e.target.value;
    if (name === "postalcode" && e.target.value && e.target.value.length > 5) {
      return;
    }
    setContactDetails((contactDetails) => ({
      ...contactDetails,
      [name]: value,
    }));
    errorFields.length > 0 && seterrorFields([]);
    !valueChanged && setvalueChanged(true);
  };

  const nameFields = [
    {
      label: englishText.FIRST_NAME,
      name: "firstName",
      type: "text",
      value: contactDetails.firstName,
    },
    {
      label: englishText.LAST_NAME,
      name: "lastName",
      type: "text",
      value: contactDetails.lastName,
    },
  ];

  const contactFields = [
    {
      label: englishText.PHONE,
      name: "phone",
      type: "number",
      value: contactDetails.phone,
    },
    {
      label: englishText.EMAIL,
      name: "contact_email",
      type: "email",
      value: contactDetails.contact_email,
    },
    {
      label: englishText.STREET_HOUSE,
      name: "street",
      type: "text",
      value: contactDetails.street,
    },
  ];

  const postalCodeAndCityFields = [
    {
      label: englishText.POSTAL_CODE,
      name: "postalcode",
      type: "number",
      value: contactDetails.postalcode,
    },
    {
      label: englishText.CITY_2,
      name: "city",
      type: "text",
      value: contactDetails.city,
    },
  ];

  const renderLeftPannel = () => (
    <div className="contact-form offer-container">
      {props.propertyDetail && (
        <RealtorHeader realtor={props.propertyDetail.realtor} />
      )}
      <div className="radio-container d-flex mb-12">
        <p className="mr-3 font-weight-bold radio-form-label">
          <FormattedMessage
            id={englishText.GENDER}
            defaultMessage={englishText.GENDER}
          />
        </p>
        <div className="d-flex">
          {genderObj.map((radioOptions, i) => (
            <div
              className={`check-option-for-contact-seller option-text mb-2 mr-4 ${
                errorFields.length > 0 &&
                errorFields.includes("gender") &&
                "error-border-round"
              }`}
              key={i}
            >
              <Form.Check
                type="radio"
                custom
                label={getLocalizeText(radioOptions.title)}
                name="formHorizontalRadios"
                id={`formHorizontalRadios1${i}`}
                checked={contactDetails.gender === radioOptions.value}
                onChange={handleInputChange}
                value={radioOptions.value}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="personal-info-form-wrapper">
        <div className="row">
          {nameFields.map((nameField, index) => (
            <div className="col-md-6" key={index}>
              <div className="form-group form-primary">
                <label>{getLocalizeText(nameField.label)}*</label>
                <input
                  id={nameField.name}
                  type={nameField.type}
                  name={nameField.name}
                  value={nameField.value}
                  className={`form-control ${
                    errorFields.length > 0 &&
                    errorFields.includes(nameField.name) &&
                    "error-border"
                  }`}
                  onChange={handleInputChange}
                />
                <span className="form-bar" />
              </div>
            </div>
          ))}
        </div>
        <div>
          {contactFields.map((field, index) => (
            <div className="row" key={index}>
              <div className="col-md-12">
                <div className="form-group form-primary">
                  <label>{getLocalizeText(field.label)}*</label>
                  {field.name === "phone" ? (
                    <PhoneInput
                      country={"de"}
                      localization={selectedLang === lang.GR ? de : undefined}
                      inputClass={`form-control ${
                        errorFields.length > 0 &&
                        errorFields.includes(field.name) &&
                        "error-border"
                      }`}
                      inputStyle={{ paddingLeft: "65px", height: "50px" }}
                      autoFormat={true}
                      inputProps={{
                        name: field.name,
                      }}
                      enableSearch
                      placeholder={""}
                      value={field.value}
                      onChange={(phone) =>
                        handleInputChange({
                          target: { value: phone, name: field.name },
                        } as React.ChangeEvent<HTMLInputElement>)
                      }
                      searchPlaceholder={getLocalizeText(englishText.SEARCH)}
                      searchNotFound={getLocalizeText(
                        englishText.NO_ENTRY_AVAIALBLE
                      )}
                    />
                  ) : (
                    <input
                      id={field.name}
                      type={field.type}
                      name={field.name}
                      className={`form-control ${
                        errorFields.length > 0 &&
                        errorFields.includes(field.name) &&
                        "error-border"
                      }`}
                      value={field.value || ""}
                      onChange={handleInputChange}
                    />
                  )}
                  <span className="form-bar" />
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          {postalCodeAndCityFields.map((field, index) => (
            <div className="col-md-6" key={index}>
              <div className="form-group form-primary">
                <label>{getLocalizeText(field.label)}*</label>
                <input
                  id={field.name}
                  type={field.type}
                  name={field.name}
                  className={`form-control ${
                    errorFields.length > 0 &&
                    errorFields.includes(field.name) &&
                    "error-border"
                  }`}
                  value={field.value}
                  onChange={handleInputChange}
                />
                <span className="form-bar" />
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="contact-seller-btn d-flex align-items-center justify-content-between">
        <button
          type="submit"
          className="white-btn welcome-btn text-center"
          style={{ padding: "unset", width: "198px", height: "64px" }}
          onClick={props.openRealtorInfo}
        >
          <FormattedMessage
            id={englishText.BACK_TO_REQUEST}
            defaultMessage={englishText.BACK_TO_REQUEST}
          />
        </button>

        <button
          type="submit"
          className={`forward-btn welcome-btn text-center mb-0`}
          onClick={() => hanldeSubmit()}
          style={{
            padding: "unset",
            width: "198px",
            height: "64px",
            fontSize: "14px",
          }}
        >
          <FormattedMessage
            id={englishText.SAVE_DATA}
            defaultMessage={englishText.SAVE_DATA}
          />
        </button>
      </div>
    </div>
  );

  const handleClosePopUp = () => {
    closeActivePopup && closeActivePopup();
  };

  return (
    <div
      style={{ padding: "50px" }}
      className="d-flex justify-content-center align-items-center model-wrapper show fade-in"
      ref={modalRef}
    >
      <ContactPopUpWrapper
        className="contact-seller-popup"
        onClose={handleClosePopUp}
        leftPannel={renderLeftPannel()}
        rightPannel={
          <DynmicTextComponent propertyDetail={props.propertyDetail} />
        }
      />
    </div>
  );
};

export default ContactRealtorPopUp;
