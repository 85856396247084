/* eslint-disable no-undef */
import React, { createRef } from "react";
import { connect } from "react-redux";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import ReactPaginate from "react-paginate-next";
// functions
import { FormattedMessage } from "react-intl";
import { LazyLoadComponent } from "react-lazy-load-image-component";
import { clearReducer, getMapFilterResult } from "actions";
import { getRef, setRef } from "services/referance.service";
import { initialState, setIcon } from "../../dashboard/utils/searchFunctions";
import { lang, recordPerPage, getLocalizeText } from "utils";
import englishText from "languages/english/english";
// components
import MapFilters from "../components/mapFilters";
import PropertyCard from "../components/propertyCard";
import MyMapComponent from "../../map/container/mapContainer";
import SkeletonLoading from "../components/skeletonLoading";
// style files
import bannerBg from "assets/images/on-banner.png";
import { CloseIcon } from "components/commonComponents/icons";
import "../search.scss";
import "react-loading-skeleton/dist/skeleton.css";
import "font-awesome/css/font-awesome.min.css";

interface IProps {
  searchList: any[];
  favouritesList: [];
  totalRecords: number;
  mapData: [];
  mapFilterReducer: any;
  multiMapData: [];
  totalPages: number;
  currentPage: number;
  showSpinner?: boolean;
  isfavorite: boolean;
  pageChanged: boolean;
  showResultSpinner: boolean;
  isFullMapScreen: boolean;
  onboarding: boolean;
  emptyListError?: string;
  currentView: string;
  showModalName: string;
  topResult: boolean;
  showBg: boolean;
  scrollY: number;
  selectedCity: string;
  cities: string[];
  showBanner: boolean;
  clearReducer: (action: string) => void;
  onPageChange: (page: number) => void;
  addFavourites: (id: string) => void;
  resetPageChange: () => void;
  addToFavourites: (id: string) => void;
  getMapFilterResult: (type: string, cities: string) => void;
  removeFromFavourites: (id: string) => void;
  handleCloseBanner: () => void;
}

const selectedLang = localStorage.getItem("language");

class SearchResult extends React.Component<any> {
  state: any = {
    showType: null,
    disableClick: false,
    iconFile: null,
    isfavorite: this.props.isfavorite || false,
    addHighLightId: "",
    removeHighlightId: "",
    scrollTop: 0,
    footerOffset: 0,
    hoveredId: "",
    showFullMap: false,
    imageLoadedList: [],
    animationStartPosition: { x: 0, y: 0 },
    showAnimation: false,
    ...initialState,
  };
  startFavoriteRef = createRef();
  wrapperRefMa: any;
  wrapperRefMap: any;

  componentDidMount() {
    if (window.location.pathname === "/favourites") {
      this.setState({ isfavorite: true });
    }
    this.setState({ checkedType: [] });
    this.setFooterRef();
    window.addEventListener("scroll", this.onScroll);
    document.addEventListener("mousedown", this.handleClickOutsideForMap);
  }

  componentDidUpdate(prevProps: any) {
    const { mapFilterReducer, clearReducer, isFullMapScreen } = this.props;
    if (
      mapFilterReducer !== prevProps.mapFilterReducer &&
      mapFilterReducer.status === "success" &&
      mapFilterReducer.data.data
    ) {
      const { resultArray } = this.state;
      resultArray[mapFilterReducer.data.type] = mapFilterReducer.data.data;
      this.setState({
        resultArray,
        disableClick: false,
      });
      clearReducer("CLEAR_FETCH_MAP_SEARCH");
    }

    if (
      isFullMapScreen !== prevProps.isFullMapScreen &&
      !isFullMapScreen &&
      this.state.showFullMap
    ) {
      this.setState({ showFullMap: false });
    }
  }

  componentWillUnmount() {
    this.clearCheckedArray();
    document.removeEventListener("mousedown", this.handleClickOutsideForMap);
    window.removeEventListener("scroll", this.onScroll);
  }

  changeScrollTop = () => {
    const { isfavorite, scrollTop } = this.state;
    if (isfavorite && this.props.isFullMapScreen) {
      window.scrollTo(0, scrollTop - 50);
      this.setState({ scrollTop: scrollTop - 50 });
    }
  };

  render() {
    const {
      favouritesList,
      addFavourites,
      searchList,
      totalRecords,
      mapData,
      multiMapData,
      totalPages,
      showSpinner,
      currentPage,
      resetPageChange,
      pageChanged,
      showResultSpinner,
      isFullMapScreen,
      emptyListError,
      showBg,
      onboarding,
      cities,
      currentView,
      showBanner,
    } = this.props;

    const {
      // selectedResultId,
      checkedType,
      iconFile,
      resultArray,
      showType,
      removeMarker,
      visibleIndex,
      isOpen,
      map,
      isOpenMulti,
      mapMulti,
      isfavorite,
      highlightclass,
      disableClick,
      addHighLightId,
      removeHighlightId,
      hoveredId,
      scrollTop,
      footerOffset,
      imageLoadedList,
      showFullMap,
      animationStartPosition,
      showAnimation,
    } = this.state;

    const cssVariable: any = {
      "--element-y-position": `${animationStartPosition.y + 5}px`,
      "--element-x-position": `${animationStartPosition.x + 6}px`,
    };

    return (
      <>
        {/* Search result list section */}
        {(showSpinner || showResultSpinner) && (
          <div
            style={{
              backgroundColor: !showBg ? "rgba(0, 0, 0, 0.1)" : "transparent",
              minHeight: isFullMapScreen
                ? "calc(100vh + 100px)"
                : "calc(100vh - 100px)",
            }}
          >
            <div style={{ paddingTop: showBanner ? "95px" : "130px" }}>
              <div
                className={`property-card-wrapper d-flex flex-wrap justify-content-start ${
                  isFullMapScreen ? "map-screen" : ""
                }`}
                style={{
                  maxWidth: isFullMapScreen
                    ? window.innerWidth < 1050
                      ? "33%"
                      : "29%"
                    : "100%",
                  pointerEvents: !showBg ? "none" : "all",
                  padding: "0 20px 0 10px",
                }}
              >
                <SkeletonLoading
                  className={`column-wrapper ${
                    this.props.isFullMapScreen ? "column-w-100" : ""
                  } skeleton-property-card`}
                />
              </div>
            </div>
          </div>
        )}
        {searchList && searchList.length > 0 && (
          <div
            style={{
              backgroundColor: !showBg ? "rgba(0, 0, 0, 0.1)" : "transparent",
              minHeight: isFullMapScreen
                ? "calc(100vh + 100px)"
                : "calc(100vh - 100px)",
            }}
          >
            <div style={{ paddingTop: showBanner ? "95px" : "130px" }}>
              {currentView !== "map" && !onboarding && showBanner && (
                <div
                  className="banner-div fade-in"
                  style={{
                    transform: ` ${
                      this.state.scrollY > 50
                        ? "translateY(-50px)"
                        : `translateY(-${
                            this.state.scrollY < 50 ? this.state.scrollY : ""
                          }px)`
                    }`,
                  }}
                >
                  <p className="banner-title">
                    <FormattedMessage
                      id={englishText.WANT_PRECIE_RESULT}
                      defaultMessage={englishText.WANT_PRECIE_RESULT}
                    />
                  </p>
                  {selectedLang === lang.GR ? (
                    <p className="banner-subtitle">
                      Beantworte weitere Fragen, um dein Suchen und Finden mit
                      Hilfe <br />
                      unseres <b> Matching Algorithmus</b> zu optimieren
                    </p>
                  ) : (
                    <p className="banner-subtitle">
                      Answer more questions to optimize your searching and
                      finding <br />
                      with the help of our <b>Matching Algorithm</b>
                    </p>
                  )}

                  <button className="banner-btn">
                    <Link to="/onboarding">
                      <FormattedMessage
                        id={englishText.START_ONBOARDING}
                        defaultMessage={englishText.START_ONBOARDING}
                      />
                    </Link>
                  </button>
                  <div
                    className="close-btn-div"
                    onClick={() => {
                      this.props.handleCloseBanner();
                    }}
                  >
                    <CloseIcon />
                  </div>
                  <img src={bannerBg} alt="img" />
                </div>
              )}

              <div
                className={`property-card-wrapper d-flex flex-wrap justify-content-start ${
                  isFullMapScreen ? "map-screen" : ""
                }`}
                style={{
                  maxWidth: isFullMapScreen
                    ? window.innerWidth < 1050
                      ? "33%"
                      : "29%"
                    : "100%",
                  pointerEvents: !showBg ? "none" : "all",
                  padding: "0 20px 0 10px",
                }}
              >
                {!showSpinner &&
                  searchList.length > 0 &&
                  !showFullMap &&
                  searchList.map((listItem: any, index: number) => (
                    <LazyLoadComponent
                      visibleByDefault={true}
                      key={index}
                      delayTime={2000}
                    >
                      <PropertyCard
                        isFullMapScreen={isFullMapScreen}
                        listItem={listItem}
                        highlightclass={highlightclass}
                        addHighLightClass={this.addHighLightClass}
                        removeHighlight={this.removeHighlight}
                        imageLoadedList={imageLoadedList}
                        topResult={this.props.topResult}
                        favouritesList={favouritesList}
                        index={index}
                        hoveredId={hoveredId}
                        addFavourites={addFavourites}
                        setImageLoadedList={this.setImageLoadedList}
                        getFavoriteStartRef={this.getFavoriteStartRef}
                        // showAnimation={showAnimation}
                        onboarding={onboarding}
                        changeScrollTop={this.changeScrollTop}
                      />
                    </LazyLoadComponent>
                  ))}

                {/* Map section */}
                <div
                  className={`map-wrapper ${
                    showFullMap
                      ? isfavorite
                        ? "sticky-full-map-fav"
                        : "sticky-full-map"
                      : ""
                  } ${
                    isFullMapScreen
                      ? `${scrollTop <= 102 ? "" : "sticky"} ${
                          footerOffset <= window.innerHeight && scrollTop >= 149
                            ? `sticky-footer`
                            : ""
                        }`
                      : "no-show"
                  }`}
                  style={
                    showFullMap ? { width: "100%", maxWidth: "1920px" } : {}
                  }
                >
                  <div
                    className="map-content"
                    style={{
                      marginTop: `${
                        isfavorite &&
                        footerOffset <= window.innerHeight &&
                        scrollTop >= 149
                          ? "15px"
                          : "0"
                      }`,
                    }}
                    onMouseEnter={() => {
                      if (this.state.addHighLightId !== "") {
                        this.setState({
                          removeHighlightId: addHighLightId,
                          highlightclass: "",
                          addHighLightId: "",
                        });
                      }
                    }}
                    onMouseLeave={() => {
                      if (this.state.removeHighlightId !== "") {
                        this.setState({
                          removeHighlightId: "",
                        });
                      }
                    }}
                  >
                    <MapFilters
                      disableClick={disableClick}
                      checkedType={checkedType}
                      onLegendClick={this.onLegendClick}
                      showFullMap={showFullMap}
                      scrollTop={scrollTop}
                      footerOffset={footerOffset}
                      searchList={searchList}
                      toggleFullMap={() => {
                        window.scrollTo(0, 0);
                        this.setState({ showFullMap: !showFullMap });
                      }}
                    />

                    <div
                      className={
                        searchList.length > 0 ? "map-box" : "map-box mapborder"
                      }
                    >
                      {isFullMapScreen && (
                        <MyMapComponent
                          mapData={mapData}
                          favouritesList={favouritesList}
                          addFavourites={addFavourites}
                          showType={showType}
                          iconFile={iconFile}
                          resultArray={resultArray}
                          removeMarker={removeMarker}
                          clearRemoveMarker={this.clearRemoveMarker}
                          multiMapData={multiMapData}
                          visibleIndex={visibleIndex}
                          handleNext={this.handleNext}
                          handlePrev={this.handlePrev}
                          isOpen={isOpen}
                          isOpenMulti={isOpenMulti}
                          onToggleOpen={this.onToggleOpen}
                          onToggleMultiOpen={this.onToggleMultiOpen}
                          mapMulti={mapMulti}
                          closeInfo={this.closeInfo}
                          setWrapperRefForInfowindow={
                            this.setWrapperRefForInfowindow
                          }
                          map={map}
                          lat={this.state.lat}
                          lon={this.state.lon}
                          addHighLightId={addHighLightId}
                          removeHighlightId={removeHighlightId}
                          resetPageChange={resetPageChange}
                          pageChanged={pageChanged}
                          clearCheckedArray={this.clearCheckedArray}
                          isFromSearch={this.props.topResult && onboarding}
                          addHighLightClass={this.addHighLightClass}
                          removeHighlight={this.removeHighlight}
                          clearAdd={() => this.setState({ addHighLightId: "" })}
                          clearRemove={() =>
                            this.setState({ removeHighlightId: "" })
                          }
                          selectedCity={this.props.selectedCity}
                          showBoth={
                            isfavorite &&
                            cities &&
                            (cities.length >= 2 ||
                              (cities.length === 1 &&
                                !cities.includes(this.props.selectedCity)))
                          }
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {!isfavorite &&
                !showSpinner &&
                !showFullMap &&
                searchList.length > 0 &&
                totalRecords > 1 && (
                  <>
                    {totalPages > 1 && (
                      <div
                        style={{
                          width: isFullMapScreen
                            ? window.innerWidth < 1050
                              ? "33%"
                              : "28%"
                            : "",
                        }}
                      >
                        <div
                          className={`page-bottom-fixed pagination-width ${
                            isFullMapScreen && "map-pagination"
                          }`}
                        >
                          <ReactPaginate
                            previousLabel="<"
                            previousClassName={`paginate-btn ${
                              currentPage === 1 ? "prev-class" : ""
                            }`}
                            nextClassName={`paginate-btn ${
                              currentPage === totalPages ? "prev-class" : ""
                            }`}
                            nextLabel=">"
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={totalPages}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={isFullMapScreen ? 1 : 5}
                            onPageChange={this.onPageChange}
                            containerClassName={
                              isFullMapScreen ? `pagination ` : "pagination"
                            }
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                            initialPage={0}
                            disableInitialCallback={true}
                          />
                        </div>
                        <div
                          className={
                            isFullMapScreen
                              ? "page-info-label-map mt-3"
                              : "page-info-label mt-3 mb-3"
                          }
                          style={{ width: isFullMapScreen ? "100%" : "" }}
                        >
                          {`${
                            currentPage === 1
                              ? "1"
                              : currentPage * recordPerPage - recordPerPage
                          }-${
                            currentPage === 1
                              ? "24"
                              : currentPage * recordPerPage
                          } ${getLocalizeText(
                            englishText.OUT_OF
                          )} ${totalRecords} ${getLocalizeText(
                            englishText.PAGE_RESULTS
                          )}`}
                          {showResultSpinner && (
                            <Spinner
                              style={{ marginLeft: "1rem" }}
                              animation="border"
                              variant="info"
                            />
                          )}
                        </div>
                      </div>
                    )}
                  </>
                )}
            </div>
          </div>
        )}
        {emptyListError && emptyListError !== "" && (
          <h5 style={{ padding: "15%", textAlign: "center" }}>
            {getLocalizeText(emptyListError)}
          </h5>
        )}

        {showAnimation && (
          <div className="favorites-animation" style={cssVariable}>
            <i className={`fa fa-heart`} aria-hidden="true" />
          </div>
        )}
      </>
    );
  }

  setImageLoadedList = (id: string) => {
    const a = this.state.imageLoadedList;
    a.push(id);
    this.setState({ imageLoadedList: a });
  };

  // change map height on scroll
  onScroll = () => {
    try {
      const scrollY = window.scrollY; //Don't get confused by what's scrolling - It's not the window
      const footerRef = getRef("footerRef").getBoundingClientRect().top;
      this.setState({
        scrollTop: scrollY,
        footerOffset: footerRef,
      });
    } catch (error) {
      console.log("some error accoured");
    }
  };

  setFooterRef = () => {
    try {
      const footerRef = getRef("footerRef").getBoundingClientRect().top;
      this.setState({
        footerOffset: footerRef,
      });
    } catch (error) {
      console.log("some error accoured");
    }
  };

  setFavoriteStartRef = () => {
    setRef(this.startFavoriteRef, "startFavoriteRef");
  };

  getFavoriteStartRef = () => {
    const aniStart = getRef("startFavoriteRef").getBoundingClientRect();
    this.setState({
      animationStartPosition: { x: aniStart.x, y: aniStart.y },
      showAnimation: true,
    });
    setTimeout(() => this.setState({ showAnimation: false }), 900);
  };

  // handle features (markets, schools etc.) show/hide on map
  onLegendClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    let iconFile = null;
    const { resultArray, checkedType } = this.state;
    this.setState({ disableClick: true });
    if (event.target.checked) {
      if (!checkedType.includes(type)) {
        checkedType.push(type);
      }
      iconFile = setIcon(type, iconFile);
      this.setState({ showType: type, iconFile });
      const { getMapFilterResult, cities } = this.props;

      const showBoth =
        cities &&
        (cities.length >= 2 ||
          (cities.length === 1 && !cities.includes(this.props.selectedCity)));

      if (showBoth) {
        getMapFilterResult(type, "Hamburg,Berlin,Hannover");
      } else {
        getMapFilterResult(type, "");
      }
    } else {
      resultArray[type] = [];
      const index = checkedType.indexOf(type);
      this.setState({
        resultArray,
        showType: checkedType.splice(index, 1),
        removeMarker: type,
        disableClick: false,
      });
    }
  };

  clearRemoveMarker = () => {
    this.setState({ removeMarker: null, showType: null });
  };

  // clear selection for features checkboxes on map
  clearCheckedArray = () => {
    this.setState({
      checkedType: [],
      showType: null,
      resultArray: {
        schools: [],
        supermarkets: [],
        transports: [],
        medicals: [],
        parks: [],
      },
    });
  };

  // handle next click for map property in info window
  handleNext = (total: number, currentIndex: number) => {
    const { visibleIndex } = this.state;
    if (currentIndex < total) {
      this.setState({ visibleIndex: visibleIndex + 1 });
    }
  };

  // handle previous click for map property in info window
  handlePrev = (currentIndex: number) => {
    const { visibleIndex } = this.state;
    if (currentIndex !== 1) {
      this.setState({ visibleIndex: visibleIndex - 1 });
    }
  };

  // for single map property close/open handling
  onToggleOpen = (
    event: any,
    lat: string,
    lon: string,
    map: { id: string }
  ) => {
    if (this.state.lat !== lat && this.state.lon !== lon && this.state.isOpen) {
      this.setState({ isOpen: false }, () => {
        this.removeHighlight(map.id, this.state.openId === map.id);
      });
    }
    if (this.state.lat === lat && this.state.lon === lon && this.state.isOpen) {
      this.setState(
        { isOpen: false, visibleIndex: 0, lat: "", lon: "" },
        () => {
          this.removeHighlight(map.id, this.state.openId === map.id);
        }
      );
    } else {
      this.addHighLightClass(map.id);
      this.setState({
        isOpen: true,
        lat,
        lon,
        map,
        isOpenMulti: false,
        openId: map.id,
      });
    }
  };

  // for multiple map property close/open handling
  onToggleMultiOpen = (event: any, lat: number, lon: number, mapMulti: any) => {
    if (
      this.state.lat !== lat &&
      this.state.lon !== lon &&
      this.state.isOpenMulti
    ) {
      this.setState({ isOpenMulti: false, visibleIndex: 0 }, () => {
        this.removeHighlight(
          mapMulti.id[0],
          this.state.openId === mapMulti.id[0]
        );
      });
    }
    if (
      this.state.lat === lat &&
      this.state.lon === lon &&
      this.state.isOpenMulti
    ) {
      this.setState({ isOpenMulti: false, visibleIndex: 0 }, () => {
        this.removeHighlight(
          mapMulti.id[0],
          this.state.openId === mapMulti.id[0]
        );
      });
    } else {
      this.addHighLightClass(mapMulti.id[0]);
      this.setState({
        isOpenMulti: true,
        lat,
        lon,
        mapMulti,
        isOpen: false,
        openId: mapMulti.id[0],
      });
    }
  };

  // close property infor window
  closeInfo = () => {
    this.removeHighlight(this.state.openId, true);
    this.setState({
      isOpen: false,
      isOpenMulti: false,
      lat: "",
      lon: "",
      openId: "",
    });
  };

  //highlight map property on hover, add id to highlight
  addHighLightClass = (id: string) => {
    if (this.props.currentView === "map") {
      this.setState({ addHighLightId: id, highlightclass: id });
    }
    this.setState({ hoveredId: id });
  };

  //highlight map property on hover, remove id
  removeHighlight = (id: string, fromClose: boolean) => {
    if (
      (this.props.currentView === "map" && this.state.openId !== id) ||
      fromClose
    ) {
      this.setState({ removeHighlightId: id, highlightclass: "" });
      fromClose && this.setState({ openId: "" });
    }
    this.setState({ hoveredId: "" });
  };

  onPageChange = (page: number) => {
    this.setState({ imageLoadedList: [] });
    this.props.onPageChange(page);
  };

  setWrapperRefForInfowindow = (node: any) => {
    const { isOpen, isOpenMulti } = this.state;
    if (!this.wrapperRefMa && this.wrapperRefMap !== node) {
      if (isOpen) {
        this.wrapperRefMap = node;
      }
      if (isOpenMulti) {
        setTimeout(() => {
          this.wrapperRefMap = node;
        }, 100);
      }
    }
  };

  // to close info window on click outside
  handleClickOutsideForMap = (event: MouseEvent) => {
    const { isOpen, isOpenMulti } = this.state;

    if (
      isOpen &&
      this.wrapperRefMap &&
      !this.wrapperRefMap.contains(event.target)
    ) {
      this.setState({ isOpen: false, lat: "", lon: "" });
      this.wrapperRefMap = null;
      this.removeHighlight(this.state.openId, true);
    }

    if (
      isOpenMulti &&
      this.wrapperRefMap &&
      !this.wrapperRefMap.innerHTML.includes(
        (event.target as HTMLElement).innerHTML
      )
    ) {
      this.setState({ isOpenMulti: false, lat: "", lon: "", visibleIndex: 0 });
      this.wrapperRefMap = null;
      this.removeHighlight(this.state.openId, true);
    }
  };
}

const mapStateToProps = (state: any) => ({
  addFavoReducer: state.favoReducer,
  mapFilterReducer: state.mapFilterReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  getMapFilterResult: (type: string, cities: string) => {
    dispatch(getMapFilterResult(type, cities));
  },

  clearReducer: (name: string) => {
    dispatch(clearReducer(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(SearchResult);
