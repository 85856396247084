import React, { useState } from 'react';
import { CloseIcon } from 'components/commonComponents/icons';
import englishText from 'languages/english/english';
import getLocalizeText from 'utils/getLocalizeText';
import { FormattedMessage } from 'react-intl';
import HttpService from 'services/http.service';
import { API_CONFIG } from 'services/api';
import * as verifyEmailInterface from '../interface/layout';

const VerifyEmail: React.FC<verifyEmailInterface.IVerifyEmailProps> = (
  props
) => {
  const [successMsg, setSuccess] = useState("");

  const hanldeVerifyClick = async () => {
    HttpService.post(API_CONFIG.path.senderVerificationMail)
      .then(() => {
        setSuccess(englishText.EMAIL_SENT_SUCCESS);
      })
      .catch((err) => {
        const msg = err.message ? err.message : englishText.CANT_SEND;
        setSuccess(msg);
      });
  };

  return (
    <div className="verify-email medium-modal">
      <h2 className="title">
        {getLocalizeText(englishText.VERIFY_YOUR_EMAIL)}
        <div
          className="email-close close-btn-div"
          onClick={() => props.toggleMailPopup(false)}
        >
          <CloseIcon />
        </div>
      </h2>
      <p className="desc-dark mb-2">
        {getLocalizeText(englishText.VERIFY_MAIL_MSG)}
      </p>
      {listItem.map((item, index) => (
        <p className="list-item" key={index}>
          {getLocalizeText(item)}
        </p>
      ))}
      {successMsg !== "" && (
        <p className="verify-mail-success">{getLocalizeText(successMsg)}</p>
      )}
      <button
        className="button mt-3"
        onClick={() => hanldeVerifyClick()}
        style={{ fontSize: "13px" }}
      >
        <FormattedMessage
          id={englishText.RESEND_MAIL}
          defaultMessage={englishText.RESEND_MAIL}
        />
      </button>
    </div>
  );
};

const listItem = [englishText.OP_1, englishText.OP_2, englishText.OP_3];

export default VerifyEmail;
