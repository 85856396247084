/* eslint-disable no-extra-boolean-cast */
import React from "react";
import { FormattedMessage } from "react-intl";
import { v4 as uuidv4 } from "uuid";
import englishText from "languages/english/english";
import { API_CONFIG, HttpService } from "services";
import { ExclamationMark } from "../../commonComponents/icons";
import { Form } from "react-bootstrap";
import { lang, getLocalizeText } from "utils";
import { IUploadFileProps } from "../interface/immozyhome";
import { financeOptions } from "../utils/constantsAndFunctions";
import { toast } from "react-toastify";

interface UploadFileStates {
  [key: string]: any;
}

class UploadFile extends React.Component<IUploadFileProps> {
  state: UploadFileStates = {
    isLoading: false,
    files: [],
    currentSchufaDocs: {},
    currentFinanceCertificate: {},
    uploadingProgressForSchufa: {},
    uploadingProgressForFinanceCertificate: {},
  };

  componentDidMount() {
    this.getUploadededFile();
  }

  render() {
    const {
      uploadingProgressForSchufa,
      uploadingProgressForFinanceCertificate,
      currentSchufaDocs,
      currentFinanceCertificate,
    } = this.state;
    const { data } = this.props;
    const { hintDe, hintEn, schufacheck, financecheck } = data;

    const combinedSchufaDocs = {
      ...currentSchufaDocs,
      ...uploadingProgressForSchufa,
    };
    const combinedFinance = {
      ...currentFinanceCertificate,
      ...uploadingProgressForFinanceCertificate,
    };

    return (
      <div className="custom-file-upload-wrapper d-flex justify-content-center align-items-start flex-column">
        <div
          className="hint-note-wrapper full-width"
          style={{ maxWidth: "unset", textAlign: "left" }}
        >
          <div className="note-heading d-flex align-items-center">
            <ExclamationMark className="mr-2" />
            <FormattedMessage
              id={englishText.WHY_QUESTION_HEADING}
              defaultMessage={englishText.WHY_QUESTION_HEADING}
            />
          </div>
          <p>{this.props.selectedLang === lang.EN ? hintEn : hintDe}</p>
        </div>
        {this.financeOnboarding()}
        {this.uploadSection(
          schufacheck,
          combinedSchufaDocs,
          financecheck,
          combinedFinance,
          this.changeCheckValue
        )}
        {/* <div>
          <h5 className="upload-file-sub-text font-weight-500 mt-4">
            I need financing for the purchase of a property
          </h5>
          <div
            className="form-group form-primary radio-container d-flex"
            style={{ width: "220px", marginBottom: "20px" }}
          >
            <div className="d-flex full-width justify-content-between align-items-center">
              {needFinancingOptions.map((radioOptions, i) => (
                <div
                  className="check-option-for-contact-seller option-text mr-4"
                  key={i}
                >
                  <Form.Check
                    type="radio"
                    custom
                    label={getLocalizeText(radioOptions.label)}
                    name="needFinancing"
                    id={`needFinancing${i}`}
                    checked={needFinancing === radioOptions.value}
                    onChange={() => this.changeCheckValue("needFinancing")}
                    disabled={this.state.isLoading}
                    // value={radioOptions.value}
                  />
                </div>
              ))}
            </div>
          </div>
        </div> */}

        {/* {needFinancing ? (
          <>
            {this.financeOnboarding()}
            {this.uploadSection(
              schufacheck,
              combinedSchufaDocs,
              financecheck,
              combinedFinance,
              this.changeCheckValue
            )}
          </>
        ) : (
          <>
            {this.uploadSection(
              schufacheck,
              combinedSchufaDocs,
              financecheck,
              combinedFinance,
              this.changeCheckValue
            )}
            {this.financeOnboarding()}
          </>
        )} */}
      </div>
    );
  }

  changeCheckValue = (event: any) => {
    this.setState({ isLoading: true });

    const name = event.target.name;
    const value = event.target.id.includes("1") ? false : true;

    HttpService.put("v1/qualify/UpdateFinancefields", {
      [name]: value,
    })
      .then(() => {
        toast.success(getLocalizeText(englishText.DATA_SAVED));
        this.props.updatePage();
        this.setState({ isLoading: false });
      })
      .catch((e) => {
        console.log("Error : ", e);
        this.setState({ isLoading: false });
      });
  };

  saveUploadedFile = (data: any) =>
    new Promise((resolve, reject) => {
      HttpService.put(API_CONFIG.path.saveUploadedFile, data)
        .then((response: any) => {
          console.log("Response : ", response);
          resolve(true);
          this.props.updatePage();
        })
        .catch((e) => {
          console.log("Error : ", e);
          reject(true);
        });
    });

  getUploadededFile = () => {
    HttpService.get(API_CONFIG.path.getUploadedFile)
      .then((response: any) => {
        const currentSchufaDocs: any = {};
        const currentFinanceCertificate: any = {};

        (response.data.schufacreditcheck || []).map(
          (entry: any) =>
            (currentSchufaDocs[entry.name] = { ...entry, fileName: entry.name })
        );
        (response.data.financingconfirmation || []).map(
          (entry: any) =>
            (currentFinanceCertificate[entry.name] = {
              ...entry,
              fileName: entry.name,
            })
        );
        this.setState({ currentSchufaDocs, currentFinanceCertificate });
      })
      .catch((e) => {
        console.log("Error : ", e);
      });
  };

  removeUploadedFile = (
    id: string,
    type: string,
    currentFileStateLabel: string,
    currentFileState: any,
    key: string
  ) => {
    if (!!id) {
      HttpService.deleteRequest(API_CONFIG.path.removeUploadedFile + "/" + id, {
        type,
      })
        .then(() => {
          const ele = document.getElementById(id);
          if (ele) {
            ele.classList.remove("fade-in");
            ele.classList.add("fade-out");
          }
          setTimeout(() => {
            delete currentFileState[key];
            this.setState({ [currentFileStateLabel]: currentFileState });
            this.props.updatePage();
          }, 1000);
        })
        .catch((e) => {
          console.log("Error : ", e);
        });
    }
  };

  handleChange = (files: File[], filesFor: string) => {
    for (let index = 0; index < files.length; index++) {
      setTimeout(() => {
        this.handleUploadAssets(files[index], filesFor);
      }, 200);
    }
  };

  handleUploadAssets = (file: File, fileFor: string) => {
    if (!!file) {
      this.setState({ isUploading: true });
      const timestamp = new Date().getTime();
      const fileNameWithTimeStamp = timestamp + "_" + file.name;
      const folder = docUploadEnum[fileFor].keyPrefix;
      const key = folder + "/" + fileNameWithTimeStamp;
      this.getCredentialsAndUploadFile(
        { key },
        file,
        fileNameWithTimeStamp,
        fileFor
      );
    }
  };

  getCredentialsAndUploadFile = (
    data: any,
    file: File,
    fileNameWithTimeStamp: string,
    fileFor: string
  ) => {
    HttpService.get(API_CONFIG.path.getS3Creds, data, {
      contentType: "multipart/form-data",
    })
      .then((response: any) => {
        const { Final, userid } = response.data;
        const path = userid + "/" + data.key;
        this.uploadFileToS3(Final, file, fileFor, path, fileNameWithTimeStamp)
          .then(() => {
            this.setState({ isUploading: false });
          })
          .catch(() => {
            this.setState({ isUploading: false });
          });
      })
      .catch((e) => {
        console.log("Error : ", e);
      });
  };

  uploadFileToS3 = (
    presignedData: any,
    file: any,
    fileFor: string,
    path: string,
    fileNameWithTimeStamp: string
  ) =>
    new Promise((resolve, reject) => {
      const fileName = file.name;
      const formData = new FormData();
      Object.keys(presignedData.fields).forEach((key) => {
        formData.append(key, presignedData.fields[key]);
      });
      // Actual file has to be appended last.
      formData.append("file", file);

      const xhr = new XMLHttpRequest();

      const currentFiles = {
        ...this.state[docUploadEnum[fileFor].currentFilesStateLabel],
      };

      xhr.upload.addEventListener(
        "progress",
        ({ loaded, total }) => {
          let uploadingProgress = { ...this.state[fileFor] };
          const progress = parseFloat(((loaded * 100) / total).toFixed(0));

          uploadingProgress = {
            ...uploadingProgress,
            [fileNameWithTimeStamp]: {
              fileName,
              id: uuidv4(),
              progress,
              uploaded: progress === 100,
              path,
            },
          };
          this.setState({ [fileFor]: uploadingProgress });

          const allUploaded = Object.values(uploadingProgress).every(
            (entry: any) => entry.uploaded
          );

          if (allUploaded) {
            const params = {
              [docUploadEnum[fileFor].uploadLabel]: Object.keys(
                uploadingProgress
              ).map((name) => ({
                id: uploadingProgress[name].id,
                name: uploadingProgress[name].fileName,
                url: presignedData.url + "/" + uploadingProgress[name].path,
              })),
            };
            this.saveUploadedFile(params).then(() => {
              const updatedUploadingStats: any = {};
              for (const key in uploadingProgress) {
                updatedUploadingStats[uploadingProgress[key].fileName] = {
                  ...uploadingProgress[key],
                  progress: undefined,
                };
              }
              setTimeout(() => {
                this.setState({
                  [docUploadEnum[fileFor].currentFilesStateLabel]: {
                    ...currentFiles,
                    ...updatedUploadingStats,
                  },
                  [fileFor]: {},
                });
              }, 1000);
            });
          }
        },
        false
      );

      xhr.onload = function () {
        this.status === 204 ? resolve(true) : reject(this.responseText);
      };
      xhr.open("POST", presignedData.url, true);
      xhr.send(formData);
    });

  uploadSection = (
    schufacheck: any,
    combinedSchufaDocs: any,
    financecheck: any,
    combinedFinance: any,
    changeCheckValue: (event: any) => void
  ) => (
    <>
      {/* <h2 className="upload-file-header font-weight-bold">
        <FormattedMessage
          id={englishText.SCHUFA_CREDIT_CHECK_LABEL}
          defaultMessage={englishText.SCHUFA_CREDIT_CHECK_LABEL}
        />
      </h2> */}
      {/* <h5 className="upload-file-sub-text font-weight-500 mt-3">
        <FormattedMessage
          id={englishText.ALREADY_HAVE_SCHUFA_CREDIT_CHECK_LABEL}
          defaultMessage={englishText.ALREADY_HAVE_SCHUFA_CREDIT_CHECK_LABEL}
        />
      </h5>

      <div
        className="form-group form-primary radio-container d-flex fade-in"
        style={{ width: "220px" }}
      >
        <div className="d-flex full-width justify-content-between align-items-center">
          {schufaOptions.map((radioOptions, i) => (
            <div
              className="check-option-for-contact-seller option-text mr-4"
              key={i}
            >
              <Form.Check
                type="radio"
                custom
                // disabled={actionLoading}
                label={getLocalizeText(radioOptions.label)}
                name="schufacheck"
                id={`schufaCheck${i}`}
                checked={schufacheck === radioOptions.value}
                onChange={changeCheckValue}
                // value={radioOptions.value}
              />
            </div>
          ))}
        </div>
      </div> */}
      {/* {schufacheck && (
        <>
          <FileUploader
            classes="drop_area mb-30px"
            multiple={true}
            handleChange={(data: any) =>
              this.handleChange(data, "uploadingProgressForSchufa")
            }
            name="file"
            types={fileTypes}
          >
            <label
              htmlFor="file-upload"
              className="d-flex justify-content-center align-items-center flex-column cursor-pointer"
            >
              <p className="mb-4 upload-text">
                <FormattedMessage
                  id={englishText.UPLOAD_SCHUFA}
                  defaultMessage={englishText.UPLOAD_SCHUFA}
                />
              </p>
              <UploadIcon />
              <p className="file-upload-label">
                <FormattedMessage
                  id={englishText.UPLOAD_FILE_LABEL}
                  defaultMessage={englishText.UPLOAD_FILE_LABEL}
                />
              </p>
              <p className="file-upload-description">
                <FormattedMessage
                  id={englishText.UPLOAD_FILE_DESCRIPTION}
                  defaultMessage={englishText.UPLOAD_FILE_DESCRIPTION}
                />
              </p>
              <button className="btn primary-outline-btn">
                <FormattedMessage
                  id={englishText.SELECT_FILE}
                  defaultMessage={englishText.SELECT_FILE}
                />
              </button>
            </label>
          </FileUploader>

          <div className="uploading-file-wrapper width-500px">
            {Object.keys(combinedSchufaDocs).map(
              (key: string, index: number) => {
                const progress = combinedSchufaDocs[key].progress;
                const { id, fileName } = combinedSchufaDocs[key];
                return (
                  <div
                    className="fade-in d-flex justify-content-between align-items-stretch mb-30px"
                    id={id}
                    key={index}
                  >
                    <div className="image-icon" style={{ width: "5%" }}>
                      <ImageIcon width="25px" height="30px" />
                    </div>
                    <div
                      className={`d-flex justify-content-${
                        !!progress ? "between" : "center"
                      }  align-items-center flex-column`}
                      style={{ width: "85%", padding: "15px 0" }}
                    >
                      <div className="d-flex justify-content-between align-items-center full-width">
                        <div
                          title={fileName}
                          style={{ width: "75%" }}
                          className="truncate"
                        >
                          {fileName}
                        </div>
                        {!!progress && (
                          <div
                            style={{ width: "10%" }}
                            className="d-flex justify-content-end align-items-center"
                          >
                            {progress + " %"}
                          </div>
                        )}
                      </div>
                      {!!progress && (
                        <div className="cssProgress full-border-radius overflow-hidden">
                          <div className="progress4">
                            <div
                              className="cssProgress-bar cssProgress-glow-active cssProgress-4x full-border-radius overflow-hidden"
                              style={{ width: `${progress}%` }}
                            ></div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div
                      className="delete-icon"
                      onClick={() =>
                        this.removeUploadedFile(
                          id,
                          docUploadEnum.uploadingProgressForSchufa.uploadLabel,
                          docUploadEnum.uploadingProgressForSchufa
                            .currentFilesStateLabel,
                          this.state[
                            docUploadEnum.uploadingProgressForSchufa
                              .currentFilesStateLabel
                          ],
                          fileName
                        )
                      }
                    >
                      <DeleteIcon width="22px" height="26px" />
                    </div>
                  </div>
                );
              }
            )}
          </div>
        </>
      )} */}

      {/* ------------------------------------------------------------------------------------- */}

      {/* <h2 className="upload-file-header font-weight-bold">
        <FormattedMessage
          id={englishText.SCHUFA_FIN_CHECK_LABEL}
          defaultMessage={englishText.SCHUFA_FIN_CHECK_LABEL}
        />
      </h2> */}
      <>
        <h5 className="upload-file-sub-text font-weight-500 mt-4">
          <FormattedMessage
            id={englishText.ALREADY_HAVE_FINANCING_CONFIRMATION_LABEL}
            defaultMessage={
              englishText.ALREADY_HAVE_FINANCING_CONFIRMATION_LABEL
            }
          />
        </h5>

        <div
          className="form-group form-primary radio-container d-flex fade-in"
          style={{ width: "220px" }}
        >
          <div className="d-flex full-width justify-content-between align-items-center">
            {financeOptions.map((radioOptions, i) => (
              <div
                className="check-option-for-contact-seller option-text mr-4"
                key={i}
              >
                <Form.Check
                  type="radio"
                  custom
                  label={getLocalizeText(radioOptions.label)}
                  name="financecheck"
                  id={`fincanceCertificateCheck${i}`}
                  checked={financecheck === radioOptions.value}
                  onChange={changeCheckValue}
                // value={radioOptions.value}
                />
              </div>
            ))}
          </div>
        </div>
        {/* {financecheck && (
          <>
            <FileUploader
              classes="drop_area mb-30px"
              multiple={true}
              handleChange={(data: any) =>
                this.handleChange(
                  data,
                  "uploadingProgressForFinanceCertificate"
                )
              }
              name="file"
              types={fileTypes}
            >
              <label
                htmlFor="file-upload"
                className="d-flex justify-content-center align-items-center flex-column cursor-pointer"
              >
                <p className="upload-text mb-4">
                  <FormattedMessage
                    id={englishText.UPLOAD_FINANCE}
                    defaultMessage={englishText.UPLOAD_FINANCE}
                  />
                </p>

                <UploadIcon />
                <p className="file-upload-label">
                  <FormattedMessage
                    id={englishText.UPLOAD_FILE_LABEL}
                    defaultMessage={englishText.UPLOAD_FILE_LABEL}
                  />
                </p>
                <p className="file-upload-description">
                  <FormattedMessage
                    id={englishText.UPLOAD_FILE_DESCRIPTION}
                    defaultMessage={englishText.UPLOAD_FILE_DESCRIPTION}
                  />
                </p>
                <button className="btn primary-outline-btn">
                  <FormattedMessage
                    id={englishText.SELECT_FILE}
                    defaultMessage={englishText.SELECT_FILE}
                  />
                </button>
              </label>
            </FileUploader>
            <div className="uploading-file-wrapper width-500px">
              {Object.keys(combinedFinance).map(
                (key: string, index: number) => {
                  const progress = combinedFinance[key].progress;
                  const { id, fileName } = combinedFinance[key];
                  return (
                    <div
                      className="d-flex justify-content-between align-items-stretch mb-30px"
                      id={id}
                      key={index}
                    >
                      <div className="image-icon" style={{ width: "5%" }}>
                        <ImageIcon width="25px" height="30px" />
                      </div>
                      <div
                        className={`d-flex justify-content-${
                          !!progress ? "between" : "center"
                        }  align-items-center flex-column`}
                        style={{ width: "85%", padding: "15px 0" }}
                      >
                        <div className="d-flex justify-content-between align-items-center full-width">
                          <div
                            title={fileName}
                            style={{ width: "75%" }}
                            className="truncate"
                          >
                            {fileName}
                          </div>
                          {!!progress && (
                            <div
                              style={{ width: "10%" }}
                              className="d-flex justify-content-end align-items-center"
                            >
                              {progress + " %"}
                            </div>
                          )}
                        </div>
                        {!!progress && (
                          <div className="cssProgress full-border-radius overflow-hidden">
                            <div className="progress4">
                              <div
                                className="cssProgress-bar cssProgress-glow-active cssProgress-4x full-border-radius overflow-hidden"
                                style={{ width: `${progress}%` }}
                              ></div>
                            </div>
                          </div>
                        )}
                      </div>
                      <div
                        className="delete-icon"
                        onClick={() =>
                          this.removeUploadedFile(
                            id,
                            docUploadEnum.uploadingProgressForFinanceCertificate
                              .uploadLabel,
                            docUploadEnum.uploadingProgressForFinanceCertificate
                              .currentFilesStateLabel,
                            this.state[
                              docUploadEnum
                                .uploadingProgressForFinanceCertificate
                                .currentFilesStateLabel
                            ],
                            fileName
                          )
                        }
                      >
                        <DeleteIcon width="22px" height="26px" />
                      </div>
                    </div>
                  );
                }
              )}
            </div>
          </>
        )} */}
      </>
    </>
  );

  financeOnboarding = () => (
    <button
      type="button"
      className="btn immozy-home-action-btn mb-4 fade-in"
      onClick={() => this.props.openFinance()}
    >
      <FormattedMessage
        id={englishText.FIN_START}
        defaultMessage={englishText.FIN_START}
      />
    </button>
  );
}

const docUploadEnum: any = {
  uploadingProgressForSchufa: {
    keyPrefix: "schufaCreditCheck",
    uploadLabel: "schufaCreditCheck",
    currentFilesStateLabel: "currentSchufaDocs",
  },
  uploadingProgressForFinanceCertificate: {
    keyPrefix: "financeCertificate",
    uploadLabel: "financingConfirmation",
    currentFilesStateLabel: "currentFinanceCertificate",
  },
};

export default UploadFile;
