import React, { SyntheticEvent } from "react";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { ArrowLeft } from "components/commonComponents/icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { setPlaceholderImg } from "utils/functions";
import realtorImg from "assets/images/special_replace_realtor.jpg";

// Import Swiper styles
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import "swiper/components/pagination/pagination.scss";

interface Props {
  objectPictures: any;
  setImageLoadedList?: (id: string) => void;
  id?: string;
  hoveredId?: string;
  isFullMapScreen: boolean;
  fromLandingPage?: boolean;
  isdisable: boolean;
  isReplaceImageRealtor?: boolean;
  dynamicBullets?: boolean;
}

// install Swiper modules
SwiperCore.use([Pagination, Navigation]);

const SwiperGallery: React.FC<Props> = (props) => {
  const breakpoints = {
    1920: {
      width: 508,
    },
    1600: {
      width: 483,
    },
    1440: {
      width: 412,
    },
    1366: {
      width: 449,
    },
    1280: {
      width: 426,
    },
    1120: {
      width: 396,
    },
    1024: {
      width: 345,
    },
  };

  const setRealtorImage = (e: SyntheticEvent) => {
    (e.target as HTMLImageElement).src = realtorImg;
  };

  return (
    <div
      className={
        props.objectPictures.length === 1
          ? `swiper-wrapper-div hide-bullets-hide hide-arrow`
          : `swiper-wrapper-div 
        ${
          props.hoveredId &&
          props.hoveredId === props.id &&
          !props.isdisable &&
          "hide-bullets"
        }  ${props.isdisable && "hide-arrow"}  `
      }
    >
      <Swiper
        spaceBetween={0}
        slidesPerView={1}
        pagination={{
          clickable: true,
          dynamicBullets:
            props.dynamicBullets !== undefined ? props.dynamicBullets : true,
        }}
        loop={props.objectPictures.length === 1 ? false : true}
        onError={() =>
          props.setImageLoadedList &&
          props.id &&
          props.setImageLoadedList(props.id)
        }
        navigation={{
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        }}
        onImagesReady={() =>
          !props.fromLandingPage &&
          props.id &&
          props.setImageLoadedList &&
          props.setImageLoadedList(props.id)
        }
        updateOnImagesReady={true}
        key={props.id}
        centeredSlides={true}
        width={props.isFullMapScreen ? 508 : undefined}
        updateOnWindowResize={true}
        breakpoints={
          !props.fromLandingPage && props.isFullMapScreen
            ? breakpoints
            : undefined
        }
        allowSlideNext={!props.isdisable}
        allowSlidePrev={!props.isdisable}
        observer={true}
        observeParents={true}
      >
        {props.objectPictures.map((img: string, index: number) => (
          <SwiperSlide key={index}>
            <img
              src={img}
              alt=""
              // style={{ width: "100%" }}
              onError={
                props.isReplaceImageRealtor
                  ? setRealtorImage
                  : setPlaceholderImg
              }
            />
          </SwiperSlide>
        ))}
        <div className="swiper-button-prev">
          <ArrowLeft />
        </div>
        <div className="swiper-button-next">
          <ArrowLeft />
        </div>
      </Swiper>
    </div>
  );
};

export default SwiperGallery;
