/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from "react";
import ButtonDiv from "./buttonDiv";
import { Form } from "react-bootstrap";
import { Spinner } from "react-bootstrap";
import * as propConstants from "../utils/constants";
import * as awsServices from "services/aws";
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import { FormattedMessage } from "react-intl";
import { CloseIcon } from "components/commonComponents/icons";
import CustomSelect from "./customselect";
import { IPropertyProps } from "../interface/myproperty.interface";
import { API_CONFIG, HttpService } from "services";

interface IPropertyTabState {
  file: any[];
  fileObj: any[];
  floorplan: any;
  expose: any;
  showSpinner: string;
  showClear: boolean;
  imgSuccessMsg: string;
  imageErr: string;
  toggleOptions: boolean;
  showDrop: string;
  showSubmit: boolean;
  isUploaded: boolean;
}

class PropertyTab extends React.Component<IPropertyProps> {
  state: IPropertyTabState = {
    file: [],
    fileObj: [],
    floorplan: null,
    expose: null,
    showSubmit: false,
    isUploaded: false,
    showSpinner: "",
    showClear: false,
    imgSuccessMsg: "",
    imageErr: "",
    toggleOptions: false,
    showDrop: "",
  };

  componentDidMount() {
    const { propertyObj } = this.props;

    this.setState({
      file: propertyObj.pictures,
      floorplan: propertyObj.floorplan,
      expose: propertyObj.expose,
      showClear: propertyObj.pictures && propertyObj.pictures.length > 0,
    });
  }

  render() {
    const {
      propertyObj,
      handleValueChange,
      handleMulltiChange,
      features,
      emptyFields,
    } = this.props;

    const { file, showSpinner, imageErr, showClear, showDrop, toggleOptions } =
      this.state;

    const pdfFields = [
      {
        label: englishText.FLOORPLAN,
        name: "floorplan",
        id: "floorplan-input",
      },
      // { label: englishText.EXPOSE, name: "expose", id: "expose-input" },
    ];

    const stateValue: any = this.state;

    return (
      <>
        <div className="general-main-div">
          <div className="general-main-div-wrapper">
            <div className="row">
              <div className="col-sm-5">
                {/* Internet */}
                <div className="row form-group mb-3">
                  <label className="col-sm-5 form-check-label">
                    <FormattedMessage
                      id={englishText.INT_SPEED}
                      defaultMessage={englishText.INT_SPEED}
                    />
                    *
                  </label>

                  <div
                    className="col-sm-4"
                    style={{
                      padding: "0",
                      marginLeft: "25px",
                    }}
                  >
                    <CustomSelect
                      name={"obj_telekomInternet"}
                      options={propConstants.internetOptions}
                      selectedOption={propertyObj.obj_telekomInternet}
                      emptyFields={emptyFields}
                      changeSelectedOption={handleValueChange}
                      toggleOptions={toggleOptions}
                      showDrop={showDrop}
                      changeToggleOptions={(value) =>
                        this.setState({ toggleOptions: value })
                      }
                      changeShowDrop={(value) =>
                        this.setState({ showDrop: value })
                      }
                    />
                  </div>
                </div>

                {/* Type of building */}
                <div className="row form-group mb-3">
                  <label className="col-sm-4 form-check-label">
                    {getLocalizeText(englishText.BUILDING_TYPE)}*
                  </label>
                  <div
                    className="col-sm-5"
                    style={{
                      padding: "0",
                      marginLeft: "25px",
                    }}
                  >
                    <CustomSelect
                      name={"propertyType"}
                      options={propConstants.subTypes}
                      selectedOption={propertyObj.propertyType}
                      changeSelectedOption={handleValueChange}
                      emptyFields={emptyFields}
                      toggleOptions={toggleOptions}
                      showDrop={showDrop}
                      changeToggleOptions={(value) =>
                        this.setState({ toggleOptions: value })
                      }
                      changeShowDrop={(value) =>
                        this.setState({ showDrop: value })
                      }
                    />
                  </div>
                </div>

                {/* Heating */}
                <div className="row form-group mb-3">
                  <label className="col-sm-4 form-check-label">
                    <FormattedMessage
                      id={englishText.HEATING}
                      defaultMessage={englishText.HEATING}
                    />
                  </label>
                  <div
                    className="col-sm-5"
                    style={{
                      padding: "0",
                      marginLeft: "25px",
                    }}
                  >
                    <CustomSelect
                      name={"heating"}
                      options={propConstants.heatingTypes}
                      selectedOption={propertyObj.heating}
                      emptyFields={emptyFields}
                      changeSelectedOption={handleValueChange}
                      toggleOptions={toggleOptions}
                      showDrop={showDrop}
                      changeToggleOptions={(value) =>
                        this.setState({ toggleOptions: value })
                      }
                      changeShowDrop={(value) =>
                        this.setState({ showDrop: value })
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                {/* Floorplan and expose */}
                {pdfFields.map((field, index) => (
                  <div
                    className="row form-group fileinput pdf-section mb-3"
                    key={index}
                  >
                    <label className="col-sm-2 form-check-label">
                      {getLocalizeText(field.label)}
                    </label>
                    <input
                      type="file"
                      className="form-control col-sm-5"
                      onChange={this.handlePdf}
                      accept={"application/pdf"}
                      name={field.name}
                      id={field.id}
                      disabled={
                        stateValue[field.name] &&
                        stateValue[field.name].length > 0
                      }
                    />
                    {stateValue[field.name] &&
                      stateValue[field.name].length > 0 && (
                        <>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={stateValue[field.name][0]}
                            className="ml-3 mt-2"
                          >
                            <i
                              className="ml-2 mr-2 fa fa-file-pdf-o pdf"
                              aria-hidden="true"
                            />
                          </a>
                          <button
                            type="button"
                            className="btn color-green ml-3 clear-btn mt-0"
                            onClick={() =>
                              this.clearInputbox(field.name, field.id)
                            }
                            disabled={showSpinner !== ""}
                          >
                            <FormattedMessage
                              id={englishText.CLEAR}
                              defaultMessage={englishText.CLEAR}
                            />
                          </button>
                          {showSpinner === field.name && (
                            <Spinner
                              className="ml-3"
                              animation="border"
                              variant="info"
                            />
                          )}
                        </>
                      )}
                  </div>
                ))}

                {/* Pictures */}
                <div className="row form-group mb-3 fileinput">
                  <label className="col-sm-2 margin-15 form-check-label">
                    <FormattedMessage
                      id={englishText.EXPOSE}
                      defaultMessage={englishText.EXPOSE}
                    />
                    {/* {imgSuccessMsg !== "" && (
                    <font className="succs-msg">
                      {getLocalizeText(imgSuccessMsg)}
                    </font>
                  )} */}
                  </label>

                  <div className="form-group col-sm-10">
                    <div
                      className={`upload-attachments-wrapper d-flex ${
                        file.length > 0 && "flex-wrap"
                      }`}
                    >
                      {file.length > 0 &&
                        file.map((attachment, index) => (
                          <div
                            key={index}
                            className="upload-file-wrapper text-center"
                          >
                            <a
                              href={attachment}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <img
                                src={attachment}
                                alt="..."
                                key={index}
                                className="preview-img"
                              />
                            </a>
                            {this.state.showSubmit && (
                              <button
                                type="button"
                                disabled={showSpinner !== ""}
                                onClick={() =>
                                  this.handleRemoveAttachment(index)
                                }
                              >
                                <CloseIcon />
                              </button>
                            )}
                          </div>
                        ))}
                      {file.length < 10 && (
                        <div className="upload-file-wrapper mt-2">
                          <label
                            htmlFor="select-attachment"
                            className="text-center cursor-pointer mb-0"
                          >
                            <abbr
                              title={getLocalizeText(englishText.SELECT_FILE)}
                              className="custom-abbr"
                            >
                              <i
                                className={`fa fa-upload ${
                                  showClear && "fa-upload-disabled"
                                }`}
                              />
                            </abbr>
                          </label>
                          <input
                            className="hide"
                            type="file"
                            onChange={this.uploadMultipleFiles}
                            id="select-attachment"
                            accept={"image/png,image/jpeg"}
                            multiple
                            name="file"
                            disabled={showClear}
                          />
                        </div>
                      )}
                      <div className="attachments-note mt-2 mb-2 ml-0 col-sm-4">
                        {imageErr !== "" && (
                          <p className="error">{getLocalizeText(imageErr)}</p>
                        )}
                        <span className="mr-2 note-text">
                          {getLocalizeText(englishText.IMG_INFO_UPLOAD)}
                        </span>
                      </div>
                      {this.state.showSubmit && file.length > 0 && (
                        <>
                          <button
                            className="ml-3 mt-2 color-green btn btn-primary"
                            onClick={() =>
                              this.uploadFiles(
                                this.state.fileObj,
                                "png",
                                "pictures"
                              )
                            }
                            style={{ height: "37px" }}
                          >
                            <FormattedMessage
                              id={englishText.SUBMIT_IMG}
                              defaultMessage={englishText.SUBMIT_IMG}
                            />
                          </button>
                          {showSpinner === "pictures" && (
                            <Spinner
                              className="ml-3"
                              animation="border"
                              variant="info"
                              style={{ marginTop: "10px" }}
                            />
                          )}
                        </>
                      )}
                    </div>

                    {showClear && (
                      <button
                        type="button"
                        className="btn color-red clear-btn ml-3 mt-2"
                        onClick={() => this.clearImages()}
                        style={{ float: "left" }}
                      >
                        <FormattedMessage
                          id={englishText.REMOVE_ALL}
                          defaultMessage={englishText.REMOVE_ALL}
                        />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            </div>

            {/* faetures */}
            <div
              className="form-group d-flex flex-wrap"
              style={{ width: "86%" }}
            >
              <label className="col-sm-2 margin-15 form-check-label">
                <FormattedMessage
                  id={englishText.FEATURES}
                  defaultMessage={englishText.FEATURES}
                />
              </label>
              <span>
                {features.map((feat, i) => (
                  <label className="checkbox-align col-sm-2 mt-2 mb-2" key={i}>
                    <Form.Check
                      type={"checkbox"}
                      custom
                      id={feat.label}
                      label={getLocalizeText(feat.label)}
                      checked={feat.selected}
                      name={feat.label}
                      onChange={handleMulltiChange}
                    />
                  </label>
                ))}
                <br />
              </span>
            </div>
          </div>
        </div>
        <ButtonDiv
          handleSaveClick={this.handleSaveClick}
          goBack={this.props.goBack}
          emptyFields={emptyFields}
        />
      </>
    );
  }

  // Send all files to upload
  uploadMultipleFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const fileObj = this.state.fileObj;
    const length = e.target.files
      ? this.state.file.length + e.target.files.length
      : 0;

    const isValid = this.validateImages(e, length);
    if (isValid) {
      const fileArray = JSON.parse(JSON.stringify(this.state.file));
      const startIndex =
        this.state.file.length === 0 ? 0 : this.state.file.length;
      e.target.files && fileObj.push(...e.target.files);

      for (let i = startIndex; i < fileObj.length; i++) {
        fileArray.push(URL.createObjectURL(fileObj[i]));
      }

      this.setState({
        file: fileArray,
        fileObj,
        imageErr: "",
        showSubmit: true,
        imgSuccessMsg: "",
      });
    }
    this.props.setFormChanged();
  };

  // check if given images are of proper type and format
  // Should be count < 10, type of png or jpg, size < 512mb
  validateImages = (e: React.ChangeEvent<HTMLInputElement>, length: number) => {
    let isSizeExceeded = false;
    let isNotImg = false;
    const temp = e.target.files;
    const supportedTypes = ["image/jpg", "image/jpeg", "image/png"];

    if (temp && temp.length > 0) {
      for (let i = 0; i < temp.length; i++) {
        if (temp[i].size / 1024 / 1024 > 0.5) {
          isSizeExceeded = true;
          break;
        }
        if (!supportedTypes.includes(temp[i].type)) {
          isNotImg = true;
          break;
        }
      }
    }

    if (length > 10) {
      this.setState({ imageErr: englishText.IMG_ERROR_10 });
      return false;
    } else if (isSizeExceeded) {
      this.setState({ imageErr: englishText.IMAGE_SIZE_ERR });
      return false;
    } else if (isNotImg) {
      this.setState({ imageErr: englishText.IMAGE_TYPE_ERROR });
      return false;
    }
    return true;
  };

  // Remove uploaded images
  handleRemoveAttachment = (index: number) => {
    const temparr = this.state.file;
    const fileObjArr = this.state.fileObj;
    temparr.splice(index, 1);
    fileObjArr.splice(index, 1);
    this.setState({ file: temparr, imgSuccessMsg: "", fileObj: fileObjArr });
    this.forceUpdate();
  };

  // upload images to s3
  uploadFiles = async (fileObj: any, type: string, param: string) => {
    const { objectId, updatePropertyObject } = this.props;

    this.setState({ showSpinner: param });

    HttpService.get(API_CONFIG.path.profiles3, {})
      .then((response) => {
        awsServices.updateAwsConfig(response.data);

        const promises = [];
        if (!this.state.isUploaded) {
          for (let i = 0; i < fileObj.length; i++) {
            const timestamp = new Date().getTime();
            const fileName = `${objectId}/${timestamp}.${type}`;

            const params = awsServices.getS3UploadParams(
              fileObj[i],
              fileName,
              "immozy-images"
            );

            const uploadToS3 = awsServices.manageUpload(params);
            promises.push(
              awsServices.uploadImage(uploadToS3, this.updateUploadStatus)
            );
          }
        }
        Promise.all(promises).then((value) => {
          this.setState({
            showSpinner: "",
            [param]: value,
          });
          if (param === "pictures") {
            this.setState({
              showSubmit: false,
              imgSuccessMsg: englishText.IMG_SUCCESS,
              showClear: true,
            });
          }
          // @ts-ignore
          updatePropertyObject(value, param);
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //check if images are uploaded
  updateUploadStatus = (
    progress: any,
    isUploading: boolean,
    isUploaded: boolean
  ) => {
    this.setState({
      isUploading: isUploading,
      isUploaded: isUploaded,
      progress: progress,
    });
  };

  // handle pdf upload
  handlePdf = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const file =
      event.target && event.target.files ? [event.target.files[0]] : [];
    if (file[0].type.includes("pdf")) {
      this.setState({
        [name]: file,
        showSpinner: name,
      });
      this.props.setFormChanged();
      this.uploadFiles(file, "pdf", name);
    } else {
      // @ts-ignore
      document.getElementById(event.target.id).value = "";
      const msg = getLocalizeText(englishText.PDF_ERR);
      alert(msg);
    }
  };

  //remove selected images
  clearImages = () => {
    this.setState({
      file: [],
      fileObj: [],
      showClear: false,
      imageErr: "",
      imgSuccessMsg: "",
    });
    this.props.updatePropertyObject([], "pictures");
  };

  //remove pdf
  clearInputbox = (name: string, id: string) => {
    this.setState({
      [name]: [],
    });
    // @ts-ignore
    document.getElementById([id]).value = "";
    this.props.updatePropertyObject([], name);
  };

  // Save and next
  handleSaveClick = () => {
    const { propertyObj, handleSaveAndContinue } = this.props;
    const { showSubmit } = this.state;

    if (showSubmit) {
      const msg = getLocalizeText(englishText.IMG_SUBMIT_ERR);
      alert(msg);
    } else {
      handleSaveAndContinue(
        propertyObj,
        "finance",
        "v1/objects/property",
        "PUT"
      );
    }
  };
}

export default PropertyTab;
