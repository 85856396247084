import React from "react";
import { ProgressBar, Spinner } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
// components
import ModalComponent from "components/commonComponents/modalComponent";
import ThanksPageOnboarding from "./thanksPageOnboarding";
// functions
import englishText from "languages/english/english";
import FinanceOptions from "./financeOptions";
import { optionInputTypes } from "components/onboarding-new/interface/utils";
import {
  getAnswerObject,
  getAnswerValuesForApi,
} from "components/onboarding-new/interface/onboardingFunctions";
import { setLanguageInLocalStorage } from "utils/globalFunctions";
import { API_CONFIG, HttpService } from "services";
// assets
import ralf from "assets/images/ralf.png";
import close from "assets/images/close.png";
// interface
import { IQuestionProps } from "components/onboarding-new/interface/onboarding";

const lang = setLanguageInLocalStorage();
let isEdit = false;

interface IFinanceOnbProps {
  closepopup: () => void;
  data: any;
  finData: any;
}

class FinanceOnboarding extends React.Component<IFinanceOnbProps> {
  state: any = {
    isLoading: true,
    questionsList: [],
    currentStep: 0,
    visitedSteps: [0],
    showModal: false,
    edited: false,
    errorMsg: "",
    completedSteps: 0,
    showThanksPage: false,
    contactDetails: {
      firstName: "",
      lastName: "",
      phone: "",
      gender: "MALE",
      contact_email: "",
    },
    noSupportText: false,
  };

  componentDidMount() {
    const { firstname, lastname, phone, gender, email } = this.props.data;

    const { firstOnboarding } = this.props.finData;
    isEdit = firstOnboarding === 50;

    this.setState({
      contactDetails: {
        firstName: firstname || "",
        lastName: lastname || "",
        phone: phone || "",
        gender: gender || "MALE",
        contact_email: email || "",
      },
    });

    this.state.isLoading && this.getQuestionsList();
  }

  render() {
    const { questionsList, currentStep, completedSteps, showThanksPage } =
      this.state;
    const totalQuestions = questionsList.length;
    const completedPercentage = (completedSteps * 100) / totalQuestions;

    return (
      <div className="welcome-container finance-onb">
        <img
          className="close-btn-fin pointer"
          onClick={() => this.props.closepopup()}
          src={close}
          alt="img"
        />
        {showThanksPage ? (
          <>
            <div className="ralf-section d-flex justify-content-center align-items-center">
              <p className="finance-title mt-4">
                <FormattedMessage
                  id={englishText.FINANCE_CHECK}
                  defaultMessage={englishText.FINANCE_CHECK}
                />
              </p>
              <img className="ralf-photo show-margin" src={ralf} alt="img" />
            </div>
            <ThanksPageOnboarding
              closepopup={this.props.closepopup}
              noSupportText={this.state.noSupportText}
            />
          </>
        ) : this.state.isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            {[1, 2, 3].map((count) => (
              <Spinner
                key={count}
                className="spin-img"
                animation="grow"
                variant="danger"
              />
            ))}
          </div>
        ) : (
          <>
            <div className="ralf-section d-flex justify-content-center align-items-center">
              <p className="finance-title mt-2">
                {questionsList[currentStep].header[lang]}
              </p>
              <ProgressBar
                className="onboarding-progress"
                min={0}
                max={100}
                now={completedPercentage}
              />
              <img className="ralf-photo" src={ralf} alt="img" />
            </div>
            {questionsList[currentStep] && (
              <div className="question-container mb-3">
                <p className="question-text" style={{ width: "90%" }}>
                  {questionsList[currentStep].question[lang]}
                </p>
                <FinanceOptions
                  questionsList={questionsList}
                  type={questionsList[currentStep].type}
                  lang={lang}
                  handleInputchange={this.handleInputchange}
                  selectOption={this.selectOption}
                  currentStep={currentStep}
                  edited={this.state.edited}
                  handleTextareachnage={this.handleTextareachnage}
                />
                <div
                  className={`action-section-1 d-flex align-items-center ${
                    currentStep !== 0
                      ? "justify-content-between"
                      : "justify-content-end"
                  } 
                  //${currentStep === 6 && "show-up"} 
                  `}
                  style={{ marginTop: "-2%" }}
                >
                  {currentStep !== 0 && (
                    <button
                      className="prev-button pointer"
                      onClick={() => this.handlePrev()}
                      disabled={currentStep === 0}
                    >
                      <FormattedMessage
                        id={englishText.PREV_STEP}
                        defaultMessage={englishText.PREV_STEP}
                      />
                    </button>
                  )}
                  <button
                    className={`onboarding-button-sm ${
                      this.checkButtonActiveStatus() ? "active" : ""
                    }`}
                    onClick={() => this.handleNextCilck()}
                  >
                    <FormattedMessage
                      id={
                        currentStep === 6
                          ? englishText.COMPLETE
                          : englishText.NEXT
                      }
                      defaultMessage={
                        currentStep === 6
                          ? englishText.COMPLETE
                          : englishText.NEXT
                      }
                    />
                  </button>
                </div>
              </div>
            )}
          </>
        )}

        {this.state.showModal && (
          <ModalComponent
            title={englishText.FILL_ANS_ERROR}
            closeModal={() => this.toggleModal(false)}
            width={"370px"}
            className="error-popup"
            btnText={"OK"}
          />
        )}
      </div>
    );
  }

  checkButtonActiveStatus = () => {
    try {
      if (!this.state.isLoading && this.state.questionsList.length > 0) {
        const { currentStep, questionsList } = this.state;

        const options = questionsList[currentStep].options;
        const optionIds = getAnswerObject(options);

        if (
          optionIds &&
          optionIds.length === 1 &&
          optionIds[0].type === "INPUT" &&
          !optionIds[0].value
        ) {
          return false;
        }
        return optionIds.length > 0;
      }
    } catch (error) {
      return false;
    }
  };

  getFilterdData = (data: any) => {
    const resultData: any = {};
    for (const key in data) {
      if (data[key]) {
        resultData[key] = data[key];
      }
    }
    return resultData;
  };

  sendContactRequest = () => {
    let payload: any = this.getFilterdData(this.state.contactDetails);

    payload = {
      ...payload,
      context: "finance-onboarding",
      agreed: false,
    };

    this.setState({ isLoading: true });
    HttpService.put(`${API_CONFIG.path.updateContact}`, payload)
      .then(() => {
        this.setState({ showThanksPage: true, isLoading: false });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getQuestionsList = () => {
    HttpService.get(API_CONFIG.path.financeOnboarding, {})
      .then((response) => {
        this.setState({
          questionsList: response.data,
          isLoading: false,
        });

        if (!isEdit) {
          this.resumeOnboarding(response.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // start from the question where user left off
  resumeOnboarding = (questionsList: IQuestionProps[]) => {
    let currentStep = 0;
    const visitedSteps = [0];
    const visitedQuestions = questionsList.filter((i) => i.isAnswerGiven);

    if (visitedQuestions.length > 0 && !questionsList[0].isAnswerGiven) {
      return;
    } else {
      if (visitedQuestions.length !== 0) {
        questionsList.forEach((q: IQuestionProps, index: number) => {
          if (!q.isAnswerGiven && currentStep === 0) {
            currentStep = index;
          }

          if (q.isAnswerGiven && !visitedSteps.includes(index)) {
            visitedSteps.push(index);
          }
        });
      }
      if (visitedSteps.length === 6) {
        visitedSteps.push(7);
      }

      this.setState({
        currentStep,
        visitedSteps,
        completedSteps: visitedQuestions
          ? visitedQuestions.length
          : visitedSteps.length,
      });
    }
  };

  selectOption = (index: number) => {
    const { questionsList, currentStep } = this.state;
    const question = questionsList[currentStep].options[index];

    const singleSelectTypes = [
      optionInputTypes.RADIO,
      optionInputTypes.PICTURE,
    ];
    if (!question.isSelected) {
      question.isSelected = true;

      if (
        questionsList[currentStep].slug === "1.6" &&
        question.optionid !== "1.6.5" &&
        questionsList[currentStep].options.filter(
          (i: any) => i.isSelected && i.optionid === "1.6.5"
        ).length > 0
      ) {
        questionsList[currentStep].options[3].isSelected = false;
      }

      if (
        singleSelectTypes.includes(questionsList[currentStep].type) ||
        question.optionid === "1.6.5"
      ) {
        questionsList[currentStep].options.map((op: any, i: number) => {
          if (i !== index) {
            questionsList[currentStep].options[i].isSelected = false;
          }
        });
      }
    } else {
      questionsList[currentStep].options[index].isSelected = false;
    }

    this.setState({ questionsList });
  };

  handleInputchange = (value: any) => {
    const { questionsList, currentStep } = this.state;
    questionsList[currentStep].options[0].value = value;

    this.setState({
      questionsList,
      edited: true,
    });
  };

  handleTextareachnage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { questionsList, currentStep } = this.state;
    if (
      questionsList[currentStep].options &&
      questionsList[currentStep].options.length >= 3
    ) {
      questionsList[currentStep].options[2].value = e.target.value;
    }
    this.setState({
      questionsList,
      edited: true,
    });
  };

  handleNextCilck = () => {
    const { currentStep, questionsList, completedSteps, visitedSteps } =
      this.state;

    const currentOptions = questionsList[currentStep].options;
    const questionId = questionsList[currentStep].questionid;

    // object of answers given and checked
    let optionIds = getAnswerObject(currentOptions);
    const checkEmptyEquaity = optionIds.filter(
      (i: any) => i.optionid === "4.1.1" && (!i.value || i.value === "")
    );
    if (checkEmptyEquaity && checkEmptyEquaity.length > 0) {
      this.toggleModal(true);
      return false;
    } else if (optionIds.length === 0) {
      // if user has not selected any answers, show error
      this.toggleModal(true);
      return false;
    } else {
      let laststep = false;
      let sendContact = true;

      if (currentStep !== questionsList.length - 1) {
        this.setState({
          currentStep: currentStep + 1,
          visitedSteps,
          completedSteps:
            currentStep + 1 > completedSteps
              ? Number(this.state.completedSteps) + 1
              : Number(this.state.completedSteps),
        });
      } else {
        laststep = true;
      }
      optionIds = getAnswerValuesForApi(optionIds);

      if (currentStep === 6) {
        const notSupport = optionIds.filter((i: any) => i.optionId === "1.6.5");
        if (notSupport.length > 0) {
          this.setState({ noSupportText: true });
          sendContact = false;
        }
      }

      HttpService.post(API_CONFIG.path.financeOnboarding, {
        questionId,
        answers: optionIds,
      })
        .then(() => {
          if (laststep) {
            if (sendContact) {
              this.sendContactRequest();
            } else {
              this.setState({ showThanksPage: true, isLoading: false });
            }
          }
        })
        .catch((err) => {
          this.setState({ currentStep: currentStep - 1 });
          console.log(err);
        });
    }
  };

  toggleModal = (value: boolean) => {
    this.setState({ showModal: value });
  };

  handlePrev = () => {
    const { currentStep, errorMsg } = this.state;
    if (errorMsg === "") {
      this.setState({ currentStep: currentStep - 1 });
    }
  };

  handleNext = () => {
    const { currentStep, completedSteps, visitedSteps } = this.state;
    visitedSteps.push(currentStep + 1);
    this.state.edited && this.setState({ edited: false });

    this.setState({
      currentStep: currentStep + 1,
      visitedSteps,
      completedSteps:
        currentStep + 1 > completedSteps
          ? Number(this.state.completedSteps) + 1
          : Number(this.state.completedSteps),
    });
    window.scrollTo(0, 0);
  };
}

export default FinanceOnboarding;
