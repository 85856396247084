import { BASE_URL } from "services/baseUrl";
import { lang } from "./constants";
import { getUrlParameters } from "./functions";

export const setLanguageInLocalStorage = () => {
  let langValue = lang.GR;
  const urlParams = getUrlParameters();
  if (urlParams && urlParams.lang) {
    localStorage.setItem("language", urlParams.lang);
    langValue = urlParams.lang;
  } else {
    if (localStorage.getItem("language")) {
      langValue = localStorage.getItem("language") || "de";
    } else {
      let browserLang =
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        window.navigator["userLanguage"];

      if (browserLang.includes("de")) {
        langValue = lang.GR;
      } else if (browserLang.includes("en")) {
        langValue = lang.EN;
      }

      localStorage.setItem("language", langValue);
    }
  }

  return langValue;
};

export const addGoogleAdsPixelEvent = () => {
  if (!BASE_URL.includes("staging")) {
    window.gtag("event", "conversion", {
      send_to: "AW-407865401/frasCJKp2vwBELmQvsIB",
    });
  }
};

export const addFbPixelEvent = (eventName, action) => {
  if (!BASE_URL.includes("staging")) {
    window.fbq(eventName, action);
  }
};

export const addMicrosoftUetEvent = () => {
  try {
    if (!BASE_URL.includes("staging")) {
      window.uetq = window.uetq || [];
      window.uetq.push("event", "register_user", {});
    }
  } catch (error) {
    console.error(error);
    return;
  }
};
