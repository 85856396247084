import React, { useState, useEffect } from "react";
import englishText from "languages/english/english";
import HttpService from "services/http.service";
import CustomSpinner from "components/commonComponents/customSpinner";
import SwiperGallery from "components/searchResult/components/swiperGallery";
import { primaryColor, getLocalizeText } from "utils";
import immozyLogo from "assets/images/01_Logo.png";
import "../landingPage/landingPage.scss";

let realtorId = "";

const RealtorListing: React.FC = () => {
  const [propertyList, setPropertyList] = useState([]);
  // const [propertyListBackup, setPropertyListBAckup] = useState([]);
  const [realtorData, setRealtorData] = useState<any>({});
  const [isLoading, setLoding] = useState(true);
  const [hoveredId, setHoverId] = useState("");

  useEffect(
    () => {
      isLoading && getData();
    },
    // eslint-disable-next-line
    []
  );

  const getData = async () => {
    realtorId = window.location.pathname.replace("/realtor/", "");
    HttpService.post(`v4/objects/realtor_new/${realtorId}`, {
      currentPage: 1,
      recordPerPage: 1000,
    })
      .then((response) => {
        if (
          response.data &&
          response.data.objects &&
          response.data.objects.data
        ) {
          setLoding(false);
          setRealtorData(response.data.realtor);
          filterData(response.data.objects.data);
          //  setPropertyListBAckup(response.data.objects.data);
        }
      })
      .catch((err) => {
        console.log(err.message);
        setLoding(false);
      });
  };

  const openSignup = (event: any) => {
    const classes = [
      "fa fa-heart-o",
      "fa fa-close",
      "fa fa-heart",
      "like-icon text-center pointer",
    ];
    if (
      event.target.className.animVal ||
      event.target.className.animVal === "" ||
      classes.includes(event.target.className)
    ) {
      event.preventDefault();
      return false;
    }
  };

  const filterData = (response: any) => {
    const list: any = [];
    response.forEach((property: any) => {
      property.isActive && list.push(property);
    });
    setPropertyList(list);
  };

  const checkRealtor = () =>
    realtorId === "591b851a-f929-4bc0-b83c-882b00b1b203";

  return (
    <div>
      <div>
        {!isLoading && (
          <>
            <div
              className="realtor-page-container"
              style={{ backgroundColor: primaryColor }}
            >
              <div className="logo-img-realtor">
                <img src={immozyLogo} alt="logo" />
              </div>
              <h3 className="mb-3 text-center">
                {realtorData.provider_company_name || "-"}
              </h3>

              <h5 className="text-center">{realtorData.adr_city}</h5>
            </div>
            {/* <div className="realtor-type">
              <button
                className={`mr-3 ${
                  showDatatType === dataType.ALL ? "btn modal-btn" : ""
                }`}
                onClick={() => filterData(dataType.ALL)}
                title={getLocalizeText(englishText.ALL_OFFER_TITLE)}
              >
                <FormattedMessage
                  id={englishText.ALL_OFFERS}
                  defaultMessage={englishText.ALL_OFFERS}
                />
              </button>
              <button
                className={
                  showDatatType === dataType.ACTIVE ? "btn modal-btn" : ""
                }
                onClick={() => filterData(dataType.ACTIVE)}
                title={getLocalizeText(englishText.ACTIVE_OFFER_TITLE)}
              >
                <FormattedMessage
                  id={englishText.ACTIVE_OFFERS}
                  defaultMessage={englishText.ACTIVE_OFFERS}
                />
              </button>
            </div> */}
          </>
        )}
      </div>
      {!isLoading && propertyList.length === 0 && (
        <h5 className="text-center mt-5">
          {getLocalizeText(englishText.NO_OFFER_AVAILABLE)}
        </h5>
      )}
      {isLoading && (
        <div className="onboarding-spinner-wrapper d-flex align-items-center justify-content-center">
          <CustomSpinner />
        </div>
      )}

      <div className="landing-page-wrapper property-card-wrapper d-flex flex-wrap justify-content-start ">
        {propertyList &&
          propertyList.map((listItem: any, index) => (
            <div
              className="column-wrapper"
              key={index}
              onMouseEnter={() => setHoverId(listItem.id)}
              onMouseLeave={() => setHoverId("")}
            >
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={`/realtor-object-detail/${listItem.id}`}
                onClick={openSignup}
              >
                <div className="property-card d-flex pointer">
                  <div className="carousel-wrapper">
                    <div className={"search-img1"}>
                      <SwiperGallery
                        objectPictures={listItem.objectPictures}
                        id={listItem.id}
                        hoveredId={hoveredId}
                        isFullMapScreen={false}
                        fromLandingPage={true}
                        isdisable={false}
                        isReplaceImageRealtor={checkRealtor()}
                      />
                      <div className="like-icon text-center pointer">
                        <i className={"fa fa-heart-o"} aria-hidden="true" />
                      </div>
                      <div className="justify-content-between">
                        <p className="ml-3 adr-label text-truncate">
                          {listItem.propertyType
                            ? `${getLocalizeText(listItem.propertyType)}  
                                ${
                                  listItem.adrPostalCode &&
                                  " in " + listItem.adrPostalCode
                                }${
                                listItem.adrQuarter && " " + listItem.adrQuarter
                              }${
                                listItem.adrQuarter !== listItem.city
                                  ? ", " + listItem.city
                                  : ""
                              }`
                            : `${listItem.adrStreet || "-"}`}
                        </p>
                        <div className="d-flex justify-content-between">
                          <div className="d-flex">
                            <p className="location font-bold mb-0">
                              <li>{`${
                                listItem.areaLiving
                                  ? Math.round(listItem.areaLiving) + " m²"
                                  : "-"
                              } `}</li>
                              {/* <li>{getLocalizeText(englishText.SIZE)}</li> */}
                            </p>
                            <p className="location font-bold mb-0">
                              <li>
                                {listItem.propRoomsAmount || "-"}{" "}
                                {getLocalizeText(englishText.ROOM)}
                              </li>
                              {/* <li>{getLocalizeText(englishText.ROOM)}</li> */}
                            </p>
                          </div>
                          <div>
                            <p className="price-label pt-0">
                              {listItem.formatedPrice || "-"}
                              {/* <li>{getLocalizeText(englishText.PRICE)}</li> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
      </div>
    </div>
  );
};

export default RealtorListing;
