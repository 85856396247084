import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { primaryColor } from 'utils/constants';
import * as graphComInterface from '../interface/calculator';
import getLocalizeText from 'utils/getLocalizeText';
import englishText from 'languages/english/english';

const GraphComponent: React.FC<graphComInterface.IGraphComProps> = (props) => {
  const {
    graph,
    locale,
    fixedIndex,
    // fixedUntilValue,
  } = props;

  const [stateSeries, setstate] = useState<string[]>([]);
  // const [fixedUntil, setfixedUntil] = useState(0);

  useEffect(
    () => {
      if (graph && graph.length > 0) {
        setstate(graph);
        //  setfixedUntil(fixedUntilValue);
      }
    },
    // eslint-disable-next-line
    [graph, graph.length]
  );

  const options = {
    chart: {
      type: "area",
      alignTicks: true,
    },
    title: {
      text: " ",
    },
    yAxis: {
      title: {
        text: getLocalizeText(englishText.LOAN_AMOUNT),
      },
    },

    tooltip: {
      y: 0,
      x: 0,
      color: primaryColor,
      borderColor: "#fff",
      backgroundColor: "#fff",
      borderRadius: 25,
      borderWidth: 0,
      shadow: true,
      padding: 10,
      style: {
        fontSize: 14,
        textAlign: "center",
      },
      className: "chart-tooltip",
      formatter: function (): string {
        try {
          // eslint-disable-next-line
          const date = new Date(graph[this.x][0]).toLocaleDateString(locale, {
            year: "numeric",
            month: "long",
            day: "numeric",
          });
          return (
            `<span><b>${date}</b>` +
            "<br />" +
            `<span>€ ` +
            this.y.toLocaleString(locale) +
            `</span></span>`
          );
        } catch (error) {
          return `${this.y.toLocaleString(locale)} €`;
        }
      },
    },

    xAxis: {
      tickInterval: 50,

      labels: {
        value: 0,
        enabled: true,
        formatter: function () {
          const value: string =
            stateSeries &&
            stateSeries[this.value] &&
            stateSeries[this.value].length !== 0
              ? stateSeries[this.value][0].substring(11)
              : "";
          return value;
        },
      },
      title: {
        text: getLocalizeText(englishText.YEARS),
      },
      plotLines: [
        {
          color: primaryColor,
          backgroundColor: "#fff",
          width: 1,
          value: fixedIndex,
        },
      ],
    },

    plotOptions: {
      series: {
        name: " ",
        pointPlacement: "on",
        marker: {
          enabled: false,
          fillColor: primaryColor,
        },
        lineColor: primaryColor,
        fillColor: {
          background: "#EA3B45",
          linearGradient: [10, 10, 0, 250],
          stops: [
            [0, "#EA3B45"],
            [1, Highcharts.color("#EA3B45").setOpacity(0).get("rgba")],
          ],
        },
      },
    },
    series: [
      {
        data: stateSeries,
      },
    ],
  };

  return (
    <div className="pt-4">
      {/* <span className="highcharts-plot-line-label " data-z-index="999">
          <div className="annotation">
            <div className="annotation-head">
              {getLocalizeText(englishText.FIXED_UNTIL)}
            </div>
            <div className="annotation-body">{fixedUntil}</div>
          </div>
        </span> */}
      <HighchartsReact highcharts={Highcharts} options={options} />
    </div>
  );
};

export default GraphComponent;
