import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { getLocalizeText } from "utils";
import { EnergyBarRange, getEnergyData } from "../shared/objectFunctions";
import englishText from "languages/english/english";

interface IEnergyProps {
  energy_data: any;
}

export const EnergyDetail: React.FC<IEnergyProps> = (props) => {
  const { energy_data } = props;

  const [heatCount, setHeatCount] = useState(
    energy_data && energy_data.energy_demand
      ? parseFloat(energy_data.energy_demand)
      : -1
  );

  const energyArray = energy_data && getEnergyData(energy_data);

  useEffect(() => {
    setHeatCount(
      energy_data && energy_data.energy_demand
        ? parseFloat(energy_data.energy_demand)
        : -1
    );
  }, [energy_data]);

  useEffect(() => {
    if (heatCount >= 0) {
      const dotEle: any = document.getElementById("dot");
      const plusValue = heatCount > 250 ? 20 : 120;
      const translateX =
        heatCount > 400 ? "97%" : (heatCount * 100) / 400 + "%";
      if (dotEle) {
        dotEle.style.transform =
          "translateX(calc(" +
          translateX +
          " - " +
          (heatCount > 400 ? 0 : (heatCount + plusValue) / 10) +
          "px))";
      }
    }
  }, [heatCount]);

  return (
    <div>
      <h2 className="energy-title  mt-3 mb-3">
        <FormattedMessage
          id={englishText.ENERGY_HEATING}
          defaultMessage={englishText.ENERGY_HEATING}
        />
      </h2>

      <div className="enerdy-data-container">
        <div className="heat-bar-wrapper">
          <div className="count-slide-wrapper white-bg">
            {EnergyBarRange.map((index, i) => (
              <span key={i}>{index}</span>
            ))}
          </div>
          {heatCount >= 0 && (
            <div className="current-temp-dot">
              <div className="dot" id="dot">
                <i className="fa fa-caret-up" />
              </div>
            </div>
          )}
        </div>

        <div className="energry-data-points">
          {energyArray && energyArray.length > 0 ? (
            energyArray.map((enData: any, i: number) => (
              <React.Fragment key={i}>
                {enData.value !== "-" && (
                  <div className={`energy-info ${i === 0 && "w-100"}`}>
                    <p className="energy-label">
                      {getLocalizeText(enData.label)}
                    </p>
                    <p
                      className={`energy-value ${
                        enData.label === englishText.ENERGY_DEMAND && "small"
                      }`}
                    >
                      {enData.value}
                    </p>
                  </div>
                )}
              </React.Fragment>
            ))
          ) : (
            <div className="no-data">
              <p>
                <FormattedMessage
                  id={englishText.NO_ENERGY_CERT}
                  defaultMessage={englishText.NO_ENERGY_CERT}
                />
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
