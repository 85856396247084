import { Action } from "utils/globalInterface";

export const INITIAL_STATE = {
  response: [],
};

export const topResultCountReducer = (state = INITIAL_STATE, action: Action)=> {
  switch (action.type) {
    case "FETCH_TOP_RESULTS_COUNT_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "FETCH_TOP_RESULTS_COUNT_PENDING":
      return {
        status: "pending",
      };

    case "FETCH_TOP_RESULTS_COUNT_REJECTED":
      return {
        status: "fail",
      };

    case "CLEAR_COUNT":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};

export const changeCityReducer = (state = INITIAL_STATE, action: Action)=> {
  switch (action.type) {
    case "CHNAGE_CITY_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "CHNAGE_CITY_PENDING":
      return {
        status: "pending",
      };

    case "CHNAGE_CITY_REJECTED":
      return {
        status: "fail",
        data: action.payload
      };

    default:
      return {
        ...state,
      };
  }
};

export const profileCountReducer = (state = INITIAL_STATE, action: Action)=> {
  switch (action.type) {
    case "FETCH_COUNTS_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "FETCH_COUNTS_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_FETCH_COUNT":
      return {
        response: [],
      };

    default:
      return {
        ...state,
      };
  }
};
