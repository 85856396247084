import german from './german/german';
import { lang } from 'utils/constants';

const Text = {
    [lang.GR]: {
        ...german,
    },
};

export default Text;
