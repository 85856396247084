import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import getLocalizeText from 'utils/getLocalizeText';
import mapArrow from 'assets/images/icon_Push-In.png';
import { FullScreenIcon, MinimizeIcon } from 'components/commonComponents/icons';
import mapArrowHide from 'assets/images/icon_Pull-out.png';
import { mapLegends } from '../../dashboard/utils/searchFunctions';
import englishText from 'languages/english/english';
import { ImapFiltersProps } from '../interface/searchResult.interface';

const MapFilters: React.FC<ImapFiltersProps> = (props) => {
  const [showLegends, setShowLegends] = useState(true);
  const { showFullMap, scrollTop, searchList, footerOffset } = props;

  return (
    <>
      <div
        className={`${
          footerOffset <= window.innerHeight && scrollTop >= 149
            ? "full-screen-btn-fixed"
            : ""
        } full-screen-btn pointer`}
        title={
          showFullMap
            ? getLocalizeText(englishText.EXIT_FULL_SCREEN)
            : getLocalizeText(englishText.FULL_SCREEN)
        }
        onClick={() => {
          props.toggleFullMap();
          if (!showFullMap) {
            if (searchList.length < 3) {
              document.body.style.overflow = "hidden";
            }
          }
        }}
      >
        {showFullMap ? <MinimizeIcon /> : <FullScreenIcon />}
      </div>
      <div
        className={`${
          footerOffset <= window.innerHeight && scrollTop >= 149
            ? "custom-filters-fixed"
            : ""
        } ${
          showLegends ? "" : showFullMap ? "hide-filters" : "hide-filters"
        } custom-filters MuiGrid-root MuiGrid-container MuiGrid-align-items-xs-center d `}
        style={showFullMap ? { width: "760px" } : {}}
      >
        <div className="MuiGrid-root MuiGrid-container e">
          {!showLegends && (
            <img
              className="pointer"
              src={mapArrowHide}
              alt="img"
              onClick={() => setShowLegends(true)}
            />
          )}
          {showLegends && (
            <img
              className="pointer"
              src={mapArrow}
              alt="img"
              onClick={() => setShowLegends(false)}
            />
          )}
          <div className={`map-filter row`}>
            {mapLegends.length > 0 &&
              mapLegends.map((legend, index) => (
                <div className="mr-2 ml-2" key={index}>
                  <Form.Check
                    id={legend.name}
                    disabled={props.disableClick}
                    type="checkbox"
                    custom
                    checked={
                      props.checkedType
                        ? props.checkedType.includes(legend.name)
                        : false
                    }
                    name={legend.name}
                    onChange={(event) =>
                      props.onLegendClick(event, legend.name)
                    }
                  >
                    <input
                      name={legend.name}
                      type="checkbox"
                      disabled={props.disableClick}
                      id={legend.name}
                      className="custom-control-input"
                      checked={
                        props.checkedType
                          ? props.checkedType.includes(legend.name)
                          : false
                      }
                      onChange={(event) =>
                        props.onLegendClick(event, legend.name)
                      }
                    />
                    <label
                      htmlFor={legend.name}
                      className="custom-control-label flex align-items-center"
                    >
                      {legend.icon}
                      {getLocalizeText(legend.label)}
                    </label>
                  </Form.Check>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default MapFilters;
