/* eslint-disable no-undef */
import amplitude from "amplitude-js";
import { checkIsGuestUser } from "./functions";

export const initAmplitude = () => {
  const key: any =
    process.env.REACT_APP_API_URL &&
    process.env.REACT_APP_API_URL.includes("staging")
      ? process.env.REACT_APP_AMPLITUDE_STAGING
      : "f8667abeab743910a427c454e5e69b45";
  try {
    amplitude.getInstance().init(key, undefined, {
      includeUtm: true,
      includeGclid: true,
      saveParamsReferrerOncePerSession: false,
    });
    setAmplitudeUserId();
    setAmpltiudeAttributes();
  } catch (error) {
    console.log(error);
  }
};

export const setAmplitudeUserProperties = (properties: any) => {
  try {
    amplitude.getInstance().setUserProperties(properties);
    setAmplitudeUserId();
  } catch (error) {
    console.log(error);
  }
};

export const logAmplitudeData = (eventType: string, eventProperties: any) => {
  try {
    amplitude.getInstance().logEvent(eventType, eventProperties);
  } catch (error) {
    console.log(error);
  }
};

export const setAmplitudeUserId = () => {
  try {
    const userId = localStorage.getItem("userId");
    if (userId) {
      amplitude.getInstance().setUserId(userId);
    }
  } catch (error) {
    console.log(error);
  }
};

export const getMetadata = async () => {
  if (navigator) {
    let osName = "Not known";
    // let ipData: any = {};
    let metaObject: any = {};

    try {
      if (navigator.appVersion.indexOf("Win") !== -1) osName = "Windows OS";
      if (navigator.appVersion.indexOf("Mac") !== -1) osName = "MacOS";
      if (navigator.appVersion.indexOf("X11") !== -1) osName = "UNIX OS";
      if (navigator.appVersion.indexOf("Linux") !== -1) osName = "Linux OS";

      // ipData = await fetch(`https://geolocation-db.com/json/`)
      //   .then((res) => res.json())
      //   .then(() => {
      //     metaObject = {
      //       ...metaObject,
      //       ...ipData,
      //     };
      //   })
      // .catch((err) => console.log(err));
    } catch (error) {
      console.log(error);
    }

    metaObject = {
      appVersion: navigator.appVersion,
      // geolocation: navigator.geolocation,
      language: navigator.language,
      platform: navigator.platform,
      OS: osName,
    };
    return metaObject;
  }
  return {};
};

export const sendAmplitudeDataEvent = async (
  eventName: string,
  metadata: any,
  user_id?: string | null,
  rolename?: string
) => {
  const metaObject = await getMetadata();
  const isGuestUser = checkIsGuestUser();
  const rolenameFromToken = isGuestUser ? "GUEST" : "USER";

  logAmplitudeData(eventName, {
    meta: {
      ...metadata,
      user_id: user_id || localStorage.getItem("userId"),
      rolename: rolename || rolenameFromToken,
      search_city: localStorage.getItem("selectedCity") || "Hannover",
    },
    payload: {
      ...metaObject,
    },
  });
};

const setAmpltiudeAttributes = async () => {
  try {
    const ampDeviceId: any = amplitude.getInstance().options.deviceId;
    localStorage.setItem("ampDeviceId", ampDeviceId);

    let ampSessionId: any = null;
    ampSessionId = await amplitude.getInstance().getSessionId();

    new Promise((resolve, reject) => {
      if (
        ampSessionId &&
        ampSessionId !== null &&
        typeof ampSessionId === "number"
      ) {
        localStorage.setItem("ampSessionId", String(ampSessionId));
        resolve(ampSessionId);
      } else {
        ampSessionId = amplitude.getInstance().getSessionId();
        localStorage.setItem("ampSessionId", ampSessionId);
        reject();
      }
    });
  } catch (error) {
    console.log("Some error has occured in amplitude", error);
    return;
  }
};
