import React, { createRef } from "react";
import { connect } from "react-redux";
import queryString from "query-string";
import { FormattedMessage } from "react-intl";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import Modal from "react-awesome-modal";
// components
import CalculatorForm from "../components/calculatorForm";
import VerifyEmail from "components/layout/components/verifyEmail";
import InterestDetails from "../components/interestDetails";
import PaymentDetailsPopup from "../components/paymentDetailsPopup";
import CalendlyPopup from "components/commonComponents/calendly";
import GraphComponent from "../components/graphComponent";
import ContactScreen from "components/objectDetails/components/popups/contactScreenForWordpress";
import CalcFooter from "../components/calcFooter";
// functions & services
import englishText from "languages/english/english";
import {
  disableScrolling,
  getUrlParameters,
  getLocalizeText,
  citiesForCalculator,
  lang,
} from "utils";
import { API_CONFIG, HttpService } from "services";
// assets
import { CloseIcon, ScrollToTopArrow } from "components/commonComponents/icons";
import "../calculator.scss";
// interface
import { ICalcProps, IRepayProps } from "../interface/calculator";
import { Range } from "react-input-range";

let timerInput: any = null;
const WAIT_INTERVAL = 1000;

let langSelected = localStorage.getItem("language") || "de";
let locale = langSelected.includes("en") ? "en-US" : "de-DE";
let isPublicPage = false;

class Calculator extends React.Component<ICalcProps, any> {
  constructor(props: ICalcProps) {
    super(props);

    this.interestDetailRef = createRef();
    this.repaymentRef = createRef();

    this.state = {
      property_price: 400000,
      annual_repayment_rate: 2,
      savings: 150000,
      fixation_period: 5,
      estate_agent_fee: false,
      location: "",
      isPopup: true,
      headerObj: {
        net_loan_amount: 0,
        interest_rate_percent: 0,
        monthly_rate_amount: 0,
        annual_repayment_rate: 0,
      },
      purchaseCost: purchaseCostInitial,
      years: [],
      graph: [],
      fixedIndex: 0,
      fistLoad: false,
      property_price_api: 0,
      total_puchase_cost_api: 0,
      showMailPopup: false,
      activePopup: "",
      showGraph: false,
      isMobileView: false,
      showTopArrow: false,
    };
  }

  componentDidMount() {
    const isPopup =
      !window.location.pathname.includes("calc") &&
      !window.location.pathname.includes("baufinanzierungsrechner");
    this.setState({ isPopup });
    isPopup && disableScrolling(true);

    const params: any = getUrlParameters();
    if (params && params.lang) {
      const langFromUrl = params.lang;
      if (langFromUrl && langFromUrl !== "" && langSelected !== langFromUrl) {
        langSelected = langFromUrl;
        locale = langSelected.includes("en") ? "en-US" : "de-DE";
      }
    }

    if (window.innerHeight > window.innerWidth) {
      this.setState({ isMobileView: true });
    }

    if (
      (window.location &&
        window.location.pathname.includes("mortgage-calculator")) ||
      window.location.pathname.includes("baufinanzierungsrechner")
    ) {
      isPublicPage = true;
      this.setState({ location: citiesForCalculator.HAM.value }, () =>
        this.callCalculateAPI()
      );
      // if (window.location.search && window.location.search.includes("city")) {
      //   const queryParams = queryString.parse(window.location.search);
      //   this.setState({ location: queryParams.city });
      // } else {
      //   this.setState({ location: citiesForCalculator.HAM.value });
      // }
    }

    if (window.location.search && window.location.search.includes("contact")) {
      const queryParams = queryString.parse(window.location.search);
      if (queryParams.contact) {
        this.setState({ activePopup: "interested" });
        this.props.history.push("/calculator");
      }
    }

    if (localStorage.getItem("role")) {
      this.getAndSetCalculatorParams(isPopup);
    } else {
      if (this.state.location === "" && !isPublicPage) {
        const a = citiesForCalculator.NIED.value;
        this.setState({ location: a }, () => this.callCalculateAPI());
      }
    }
  }

  componentDidUpdate() {
    const { location, fistLoad } = this.state;
    const { profileCountReducer, changeCityReducer } = this.props;
    if (
      profileCountReducer &&
      profileCountReducer.status === "success" &&
      profileCountReducer.data &&
      profileCountReducer.data.city !== location &&
      fistLoad
    ) {
      this.setState(
        { location: profileCountReducer.data.city, fistLoad: false },
        () => this.callCalculateAPI()
      );
    }

    if (
      changeCityReducer.data &&
      changeCityReducer.data.city &&
      changeCityReducer.status === "success"
    ) {
      window.location.reload();
    }
  }

  componentWillUnmount() {
    this.state.isPopup && disableScrolling(false);
  }

  render() {
    const {
      isPopup,
      headerObj,
      purchaseCost,
      years,
      fixation_period,
      graph,
      selectedCity,
      done_repaying_in,
      isLoading,
      activePopup,
      fixedUntil,
      isMobileView,
      fixedIndex,
    } = this.state;

    const getTimeLineData = (className: string) => (
      <div
        className={`section-description d-flex justify-content-${className} ${
          !fixedUntil && "mb-3 mt-5"
        }`}
      >
        {langSelected === lang.EN ? (
          <p className="time-line-text">
            You would be done repaying in{" "}
            <span>{this.getTimeString(done_repaying_in)} </span>
            and would have a loan balance of{" "}
            <span>
              {done_repaying_in.loan_balance.toLocaleString(locale)} &euro;
            </span>{" "}
            after <span>{done_repaying_in.after} years.</span>{" "}
          </p>
        ) : (
          <p className="time-line-text">
            Du wärst nach <span>{this.getTimeString(done_repaying_in)} </span>{" "}
            mit der Rückzahlung fertig und hättest nach
            <span> {done_repaying_in.after} Jahren</span> noch eine Restschuld
            von{" "}
            <span>
              {done_repaying_in.loan_balance.toLocaleString(locale)} &euro;
            </span>{" "}
          </p>
        )}
      </div>
    );

    if (isMobileView) {
      return (
        <div className="calculator calc-mobile relative">
          <div
            className={`col-12 filter-part ${isPopup && "mb-3"}`}
            style={isPopup ? { padding: "0 4%" } : {}}
          >
            <CalculatorForm
              state={this.state}
              handleOptionchange={this.handleOptionchange}
              handleRadio={this.handleRadio}
              isMobileView={isMobileView}
              handleDropdown={this.handleDropdown}
              locale={locale}
              selectedCity={selectedCity}
              handlePriceChange={this.handlePriceChange}
              handleRangechange={this.handleRangechange}
            />
            <button
              className="btn calc-btn red full-width"
              onClick={() => this.handleMobileCalculate()}
            >
              <FormattedMessage
                id={englishText.CALCULATE_NOW}
                defaultMessage={englishText.CALCULATE_NOW}
              />
            </button>
          </div>
          <div
            className={`${!isPopup ? "calculator-container" : ""} row`}
            style={{ height: "100%" }}
          >
            <InterestDetails
              interestDetailRef={this.interestDetailRef}
              repaymentRef={this.repaymentRef}
              purchaseCost={purchaseCost}
              locale={locale}
              isMobileView={isMobileView}
              fixedIndex={this.state.fixedIndex}
              done_repaying_in={this.state.done_repaying_in}
              property_price_api={this.state.property_price_api}
              total_puchase_cost_api={this.state.total_puchase_cost_api}
              headerObj={headerObj}
              langSelected={langSelected}
              isLoading={isLoading}
              getTimeLineData={getTimeLineData}
              showGraph={() => this.setState({ showGraph: true })}
            />
          </div>
          <div className="col-12 calc-graph-model" ref={this.repaymentRef}>
            <p className="calc-title">
              <FormattedMessage
                id={englishText.SHOW_REPAYMENT_PROGRESSION}
                defaultMessage={englishText.SHOW_REPAYMENT_PROGRESSION}
              />
            </p>
            {this.state.showTopArrow && (
              <div
                className="scroll-to-top-btn"
                onClick={() => {
                  window.scroll({ left: 0, top: 0, behavior: "smooth" });
                  this.setState({ showTopArrow: false });
                }}
              >
                <ScrollToTopArrow width="15" />
              </div>
            )}
            {done_repaying_in && getTimeLineData("center")}
            <div style={{ width: "100%", margin: "0 auto" }}>
              {fixedUntil && (
                <GraphComponent
                  graph={graph}
                  fixedUntilValue={fixedUntil}
                  fixedIndex={fixedIndex}
                  locale={locale}
                />
              )}
            </div>
          </div>
          <div className="col-12">
            <a
              href="https://app.immozy.de/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              <button className="btn calc-btn red full-width">
                <FormattedMessage
                  id={englishText.GET_FRWW_FINANCING_OFFER}
                  defaultMessage={englishText.GET_FRWW_FINANCING_OFFER}
                />
              </button>
            </a>
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={`${isPopup && "cal-popup relative"}`}
          style={{ borderRadius: "8px", overflow: "hidden" }}
        >
          {isPopup && (
            <div
              className="close-btn-div"
              onClick={() => this.props.closeCalc()}
              style={{ top: "10px", right: "15px" }}
            >
              <CloseIcon />
            </div>
          )}
          <div
            className={`calculator ${isPopup ? "cal-popup" : "calc-window"}`}
          >
            <div
              className={`${!isPopup ? "calculator-container" : ""} row`}
              style={{ height: "100%" }}
            >
              <div
                className={`col-6 ${isPopup && "mb-3"}`}
                style={isPopup ? { padding: "0 4%" } : {}}
              >
                <CalculatorForm
                  state={this.state}
                  handleOptionchange={this.handleOptionchange}
                  handleRadio={this.handleRadio}
                  isMobileView={isMobileView}
                  handleDropdown={this.handleDropdown}
                  locale={locale}
                  selectedCity={selectedCity}
                  handlePriceChange={this.handlePriceChange}
                  handleRangechange={this.handleRangechange}
                />
              </div>
              <InterestDetails
                purchaseCost={purchaseCost}
                locale={locale}
                fixedIndex={this.state.fixedIndex}
                done_repaying_in={this.state.done_repaying_in}
                property_price_api={this.state.property_price_api}
                total_puchase_cost_api={this.state.total_puchase_cost_api}
                headerObj={headerObj}
                langSelected={langSelected}
                isLoading={isLoading}
                getTimeLineData={getTimeLineData}
                showGraph={() => this.setState({ showGraph: true })}
              />
            </div>
          </div>
          {this.state.showGraph && (
            <PaymentDetailsPopup
              done_repaying_in={this.state.done_repaying_in}
              years={years}
              fixation_period={fixation_period}
              locale={locale}
              graph={graph}
              fixedUntilValue={fixedUntil}
              getTimeLineData={getTimeLineData}
              fixedIndex={this.state.fixedIndex}
              closeGraph={() => this.setState({ showGraph: false })}
              handleSetYear={this.handleDropdown}
            />
          )}

          {this.state.showMailPopup && (
            <div className="model-wrapper show">
              <VerifyEmail
                toggleMailPopup={() => this.setState({ showMailPopup: false })}
              />
            </div>
          )}
          {activePopup === "interested" && (
            <Modal visible={true} width={"930px"} borderRadius={"10px"}>
              {/* <InterestedPopupNew closeClick={this.closeActivePopup} /> */}
              <div className="contact-form-pop-up-wrapper">
                <div
                  className="close-btn"
                  onClick={() => this.setState({ activePopup: "" })}
                >
                  <CloseIcon fill="white" />
                </div>

                <ContactScreen
                  fromCalc={true}
                  openCalendly={this.openCalendly}
                />
              </div>
            </Modal>
          )}

          {activePopup === "calendly" && (
            <CalendlyPopup closeActivePopup={this.closeActivePopup} />
          )}

          {!isPopup && !isPublicPage && (
            <CalcFooter
              showInterestedPopup={() =>
                this.setState({ activePopup: "interested" })
              }
            />
          )}
        </div>
      );
    }
  }

  interestDetailRef: any;
  repaymentRef: React.RefObject<HTMLInputElement>;

  openCalendly = () => {
    this.setState({ activePopup: "calendly" });
  };

  handleMobileCalculate = () => {
    const { top } = this.interestDetailRef.current.getBoundingClientRect();
    const currentScrollPos = document.documentElement.scrollTop;
    window.scroll({
      left: 0,
      top: currentScrollPos + top,
      behavior: "smooth",
    });
    this.setState({ showTopArrow: true });
  };

  getTimeString = (repay_ob: IRepayProps) => {
    const years = repay_ob.years;
    const month = repay_ob.months;

    let title = "";
    if (years > 0) {
      title = ` ${years} ${getLocalizeText(englishText.YEARS_2)}`;
    }
    if (month > 0) {
      title = `${title} ${getLocalizeText(
        englishText.AND
      )} ${month} ${getLocalizeText(englishText.MONTHS)}`;
    }
    return title;
  };

  getAndSetCalculatorParams = (isPopup: boolean) => {
    HttpService.get(`${API_CONFIG.path.calculator}`, {})
      .then((response) => {
        if (!response.data) {
          if (isPopup) {
            const { savings } = this.props;
            const { summaryReducer } = this.props;

            const savingsFromReducer =
              (summaryReducer &&
                summaryReducer.data &&
                summaryReducer.data.Equity &&
                summaryReducer.data.Equity.data) ||
              null;
            if (savingsFromReducer || savings) {
              this.setState({
                savings: parseInt(savingsFromReducer || savings),
              });
            }
            if (this.props.price) {
              this.setState({
                property_price: this.props.price,
                estate_agent_fee: this.props.hasCourtage || false,
              });
            }
          }
          if (this.state.location === "") {
            const a =
              this.props.profileCountReducer &&
              this.props.profileCountReducer.data
                ? this.props.profileCountReducer.data.city
                : citiesForCalculator.NIED.value;
            this.setState({ location: a }, () => this.callCalculateAPI());
          }
        } else {
          const data = response.data;
          if (isPopup && this.props.price) {
            this.setState({
              property_price: this.props.price,
              estate_agent_fee: this.props.hasCourtage || false,
            });
          } else {
            this.setState({
              property_price: data.property_price,
              estate_agent_fee: data.estate_agent_fee,
            });
          }

          this.setState(
            {
              annual_repayment_rate: data.annual_repayment_rate,
              fixation_period: data.fixation_period,
              location: data.location,
              savings: data.savings,
              firstLoad: true,
            },
            () => this.callCalculateAPI()
          );
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  closeActivePopup = () => {
    disableScrolling(false);
    this.setState({ activePopup: "" });
  };

  handleRadio = (value: boolean) => {
    this.setState({ estate_agent_fee: value }, () => this.callCalculateAPI());
  };

  handleDropdown = (value: string, name: string) => {
    if (value !== this.state[name]) {
      this.setState({ [name]: value }, () => this.callCalculateAPI());
    }
  };

  handleOptionchange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = parseInt(event.target.value);
    this.setState({ [event.target.name]: value });
  };

  handlePriceChange = (value: string | undefined, name: string | undefined) => {
    clearTimeout(timerInput);

    const valueInt = value ? parseInt(value) : value;
    timerInput = setTimeout(
      () => value && this.callCalculateAPI(),
      WAIT_INTERVAL
    );
    this.setState({ [name || "property_price"]: valueInt });
  };

  handleRangechange = (value: number | Range) => {
    this.setState({ annual_repayment_rate: value });
    timerInput = setTimeout(
      () => value && this.callCalculateAPI(),
      WAIT_INTERVAL
    );
  };

  callCalculateAPI = async () => {
    let data: any;
    const {
      property_price,
      annual_repayment_rate,
      savings,
      fixation_period,
      estate_agent_fee,
      location,
    } = this.state;

    this.setState({ isLoading: true });
    HttpService.post(API_CONFIG.path.calculator, {
      property_price,
      annual_repayment_rate,
      savings: savings ? parseInt(savings) : 0,
      fixation_period,
      estate_agent_fee,
      location,
    })
      .then((response: any) => {
        if (response.data) {
          data = response.data;
          this.setState((prevState: any) => ({
            headerObj: {
              ...prevState.headerObj,
              interest_rate_percent: data.interest_rate_percent,
              annual_repayment_rate: data.annual_repayment_rate,
              monthly_rate_amount: Math.round(data.monthly_rate_amount),
              net_loan_amount: Math.round(data.net_loan_amount),
            },
          }));
          const graph: any = [];
          let fixedUntil: any = null;
          let fixedIndex = 0;

          data.graph.forEach((element: any, index: number) => {
            //var date = new Date(element.date).toLocaleDateString(locale, { year: 'numeric', month: 'long', day: 'numeric' });
            const date = new Date(element.date).toDateString();
            graph.push([date, Math.round(element.remaining_debt)]);
            if (element.fixedUntil) {
              fixedUntil = element;
              fixedIndex = index;
            }
          });

          const fixedDate: Date | null = fixedUntil
            ? new Date(fixedUntil.date)
            : new Date();

          this.setState({
            purchaseCost: data.ADDITIONAL_COST_ATTRIBUTES,
            years: data.years,
            done_repaying_in: data.done_repaying_in,
            graph,
            fixedUntil: fixedDate ? fixedDate.getFullYear() : null,
            fixedIndex: fixedIndex,
            property_price_api: data.property_price,
            total_puchase_cost_api: Math.round(data.total_puchase_cost),
          });
          setTimeout(() => {
            this.setState({ isLoading: false });
          }, 500);
        }
      })
      .catch(() => {
        this.setState({ isLoading: false });
        return false;
      });
  };
}

const mapStateToProps = (state: any) => ({
  profileCountReducer: state.profileCountReducer,
  summaryReducer: state.summaryReducer,
  changeCityReducer: state.changeCityReducer,
});

export default connect(mapStateToProps, {})(Calculator);

const purchaseCostInitial = [
  {
    title: englishText.ESTATE_AGENT_FEE,
    percent: "0,00",
    amount: "0",
  },
  {
    title: englishText.PURCHASE_TAX,
    percent: "0,00",
    amount: "0",
  },
  {
    title: englishText.LAND_RESGISTER_ENTRY,
    percent: "0,00",
    amount: "0",
  },
  {
    title: englishText.NOTARY_FEES,
    percent: "0,00",
    amount: "0",
  },
];

// interface ICalcStateProps {
//     property_price: number;
//     annual_repayment_rate: number;
//     savings: number | string;
//     fixation_period: number;
//     selectedCity?: string;
//     isLoading?: boolean;
//     estate_agent_fee: boolean;
//     location: string;
//     isPopup: boolean;
//     headerObj: {
//         net_loan_amount: number;
//         interest_rate_percent: number;
//         monthly_rate_amount: number;
//         annual_repayment_rate: number;
//     };
//     purchaseCost: {}[];
//     years: [];
//     graph: [];
//     fixedIndex: number;
//     fistLoad: boolean;
//     activePopup: string;
//     showMailPopup: boolean;
//     fixedUntilValue: number | string;
//     property_price_api: number | string;
//     done_repaying_in: number | string;
// }
