import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
// functions
import { clearStorage, getLocalizeText } from "utils";
import englishText from "languages/english/english";
// components
import CustomProperties from "components/myProperty/container/customPropertiesList";
import VerifyEmail from "components/layout/components/verifyEmail";
import EditProfile from "components/profile/components/profile";
// assets
import { Menu, Profile } from "components/commonComponents/icons";

let wrapperRef: any;

interface ILayoutDropMenuProps {
  favoCount: number;
  isFavoActive: boolean;
  favoriteEndRef: any;
  isEmailVerified: boolean;
  onboarding: boolean;
  isGuestUser: boolean;
  updateFavoActive: () => void;
  getFavoCount: () => void;
}

const LayoutDropMenu: React.FC<ILayoutDropMenuProps> = (props) => {
  const {
    favoCount,
    isFavoActive,
    favoriteEndRef,
    isEmailVerified,
    onboarding,
    isGuestUser,
  } = props;

  const [showMenu, setshowMenu] = useState(false);
  const [showModal, setshowModal] = useState("");

  const history = useHistory();

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]);

  const handleClickOutside = (event: MouseEvent) => {
    if (showMenu && wrapperRef && !wrapperRef.contains(event.target)) {
      setshowMenu(false);
    }
  };

  const setWrapperRef = (node: any) => {
    wrapperRef = node;
  };

  const logoutSession = () => {
    clearStorage();
    window.location.pathname = "/login";
  };

  const closeEditProfile = (languageChanged: boolean) => {
    setshowModal("");

    if (languageChanged) {
      if (history.location.pathname === "/find/my-search") {
        window.location.href = "/find";
      } else {
        window.location.href = history.location.pathname;
      }
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div
        className={`like-icon-favo text-center mr-5 ${
          isFavoActive ? "active" : ""
        } ${favoCount > 0 ? "show" : ""}`}
        ref={favoriteEndRef}
      >
        {favoCount > 0 && (
          <Link
            to={isFavoActive ? "/find" : "/favourites"}
            onClick={props.updateFavoActive}
          >
            <p className="favorites-count">{favoCount}</p>
            <i
              className={isFavoActive ? "fa fa-heart" : "fa fa-heart-o"}
              aria-hidden="true"
            />
          </Link>
        )}
      </div>
      <div
        className={`profile-dropdown pointer d-flex justify-content-center align-items-center ${
          showMenu ? "active" : ""
        }`}
        ref={setWrapperRef}
        onClick={() => setshowMenu(!showMenu)}
      >
        <Menu className="menu-icon" />
        <Profile className="profile-icon" />

        {/* Menu Options */}
        {showMenu && (
          <div className="profile-dropdown-menu  pointer">
            <li onClick={() => setshowModal("profile")} className="d-flex">
              {getLocalizeText(englishText.PROFILE)}
              {!isEmailVerified && <p className="error-profile">i</p>}
            </li>

            {isEmailVerified && (
              <Link to={"/find/my-search"}>
                <li>{getLocalizeText(englishText.MY_SEARCHES)}</li>
              </Link>
            )}
            {isEmailVerified && (
              <Link to={"/find/notif"}>
                <li>{getLocalizeText(englishText.NOTIFICATION)}</li>
              </Link>
            )}
            <Link
              to={
                localStorage.getItem("showWelcome")
                  ? "/welcome"
                  : onboarding
                  ? "/onboarding/true"
                  : "/onboarding"
              }
            >
              <li>{getLocalizeText(englishText.ONBOARDING)}</li>
            </Link>
            {isEmailVerified && (
              <li
                style={{ color: "#4e5d75" }}
                onClick={() => setshowModal("showPropertyList")}
              >
                {getLocalizeText(englishText.MY_PROP_LIST)}
              </li>
            )}
            <li>
              <Link to="/calculator">
                {getLocalizeText(englishText.MORTGAGE_CALCULATOR)}
              </Link>
            </li>

            {isGuestUser ? (
              <li>{getLocalizeText(englishText.LOGIN)}</li>
            ) : (
              <Link to={"/login"} onClick={() => logoutSession()}>
                <li>{getLocalizeText(englishText.LOGOUT)}</li>
              </Link>
            )}
          </div>
        )}
        {/* Menu Options end*/}

        {!isEmailVerified && (
          <p
            className="unverified-user-indicator"
            title={getLocalizeText(englishText.UNVERIFIED_USER)}
            onClick={(e: React.MouseEvent<HTMLElement>) => {
              setshowModal("email-popup");
              e.stopPropagation();
            }}
          >
            !
          </p>
        )}
      </div>

      {/* Edit Profile Popup */}
      {showModal === "profile" && (
        <div className={`model-wrapper show`}>
          <EditProfile closeEditProfile={closeEditProfile} />
        </div>
      )}

      {/* Custom Property List Popup */}
      {showModal === "showPropertyList" && (
        <div
          className={`model-wrapper d-flex align-items-center justify-content-center show hide-scroll`}
        >
          <CustomProperties
            closeAddProperty={() => setshowModal("")}
            getFavoCount={props.getFavoCount}
          />
        </div>
      )}

      {/* Email Verify Warning Popup */}
      {showModal === "email-popup" && (
        <div className="model-wrapper show">
          <VerifyEmail toggleMailPopup={() => setshowModal("")} />
        </div>
      )}
    </div>
  );
};

export default LayoutDropMenu;
