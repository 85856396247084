import React from "react";
import englishText from "languages/english/english";
import { FormattedMessage } from "react-intl";
import { lang, addZeroes, getLocalizeText } from "utils";

export const InterestDetails: React.FC<any> = (props) => {
  const {
    purchaseCost,
    locale,
    done_repaying_in,
    property_price_api,
    total_puchase_cost_api,
    langSelected,
    headerObj,
    isLoading,
    getTimeLineData,
    isMobileView,
    interestDetailRef,
    repaymentRef,
  } = props;
  return (
    <div
      className={`col-${isMobileView ? "12" : "6"}`}
      style={{ backgroundColor: "#F6F6F8", paddingTop: "30px" }}
      ref={interestDetailRef}
    >
      <div className={`calculator-details ${isLoading && "animate-flicker"}`}>
        {langSelected === lang.EN ? (
          <h2 className="title">
            Borrowing
            <span>
              {headerObj.net_loan_amount.toLocaleString(locale)} &euro;
            </span>
            today at an interest rate of
            <span>{addZeroes(headerObj.interest_rate_percent, locale)}%</span>
            and a repayment rate of
            <span>{addZeroes(headerObj.annual_repayment_rate, locale)}%</span>
            means paying
            <span>
              {headerObj.monthly_rate_amount.toLocaleString(locale)} &euro;
            </span>
            per month
          </h2>
        ) : (
          <h2 className="title">
            Wenn du dir
            <span>
              {headerObj.net_loan_amount.toLocaleString(locale)}
              &euro;
            </span>
            mit einem Zinssatz von{" "}
            <span>{addZeroes(headerObj.interest_rate_percent, locale)}%</span>
            leihst und eine Tilgung von
            <span>{addZeroes(headerObj.annual_repayment_rate, locale)}%</span>
            planst, zahlst du monatlich
            <span>
              {headerObj.monthly_rate_amount.toLocaleString(locale)} &euro;.
            </span>
          </h2>
        )}

        <div className="calculator-detail-section">
          <h3>
            <FormattedMessage
              id={englishText.COMPOSITION_OF_COST}
              defaultMessage={englishText.COMPOSITION_OF_COST}
            />
          </h3>
          {purchaseCost.map((data: any, i: number) => (
            <div className="calc-detail-row" key={i}>
              <p className="table-title">{getLocalizeText(data.title)}</p>
              <p className="table-per-data">
                {data.percent?.toLocaleString(locale)}%
              </p>
              <p className="table-data">
                {data.amount
                  ? Math.round(data.amount).toLocaleString(locale)
                  : 0}{" "}
                €
              </p>
            </div>
          ))}

          <div className="calc-detail-row">
            <p className="table-title">
              {getLocalizeText(englishText.PROPERTY_PRICE)}
            </p>
            <p className="table-per-data"> </p>
            <p className="table-data">
              {property_price_api?.toLocaleString(locale)} €
            </p>
          </div>
          <hr />
          <div className="calc-detail-row">
            <p className="table-title">
              {getLocalizeText(englishText.TOTAL_PURCHASE_COSTS)}
            </p>
            <p className="table-per-data"> </p>
            <p className="table-data">
              {total_puchase_cost_api?.toLocaleString(locale)} €
            </p>
          </div>

          {done_repaying_in && getTimeLineData("start")}
          <div
            className="d-flex justify-content-center mt-4"
            onClick={() => {
              if (isMobileView) {
                const { top } = repaymentRef.current.getBoundingClientRect();
                const currentScrollPos = document.documentElement.scrollTop;
                window.scroll({
                  left: 0,
                  top: currentScrollPos + top,
                  behavior: "smooth",
                });
              }
            }}
          >
            <button className="btn calc-btn" onClick={props.showGraph}>
              <FormattedMessage
                id={englishText.VIEW_HISTROY}
                defaultMessage={englishText.VIEW_HISTROY}
              />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InterestDetails;
