import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import englishText from "languages/english/english";
import { API_CONFIG, HttpService } from "services";
import { Logo } from "./icons";
import { sendAmplitudeDataEvent } from "utils/amplitude";

let userId = "";

const Unsubscribe: React.FC = () => {
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    if (isLoading) {
      userId = window.location.pathname.replace("/unsubscribe/", "");

      const meta = {
        Action: "User clicked on unsubscribe link",
        userId,
      };
      sendAmplitudeDataEvent("unsubscribe_page_opened", meta);
      unsubscribeUser();
    }
  });

  const unsubscribeUser = () => {
    HttpService.put(`${API_CONFIG.path.profile}/unsubscribemail/${userId}`, {})
      .then(() => {
        setisLoading(false);
      })
      .catch((err) => {
        setisLoading(false);
        console.log(err);
      });
  };

  return (
    <div>
      <div>
        <section className="login-block">
          <div className="container-fluid auth-box card">
            <div className="unsub-block text-center">
              <Logo />
              <h5 className="mt-5 mb-3">
                <FormattedMessage
                  id={englishText.UNSUBSCRIBE_MESSAGE}
                  defaultMessage={englishText.UNSUBSCRIBE_MESSAGE}
                />
              </h5>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Unsubscribe;
