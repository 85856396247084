import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
// Components
import ProgressBar from "react-bootstrap/ProgressBar";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { EnergyDetail } from "./energyDetailSection";
// functions
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import * as objectFunctions from "../shared/objectFunctions";
import * as objectDescInterface from "../interface/objectDetails";
// Assets
import {
  DetailsIcon,
  FinancesIcon,
  MapPinIcon,
  ObjectDataIcon,
  QuestionMark,
} from "components/commonComponents/icons";
import { Badge } from "components/commonComponents/badge";
import directBadge from "assets/images/direct-badge.png";

const ObjectDescription: React.FC<objectDescInterface.IObjectDescProps> = (
  props
) => {
  const {
    propertyDetail,
    objectDetails,
    initialColorState,
    setStateForObject,
    isFav,
    propertyInfoLoading,
    onboarding,
  } = props;

  const [toolTipType, settoolTipType] = useState("");
  const [showTooltip, setshowTooltip] = useState(false);
  const [showReadMore, setshowReadMore] = useState(false);
  let wrapperRef: any;

  const detailsArray =
    propertyDetail &&
    objectDetails &&
    objectFunctions.getDetail(propertyDetail, objectDetails);

  const factsArray =
    propertyDetail &&
    objectDetails &&
    objectFunctions.getFacts(propertyDetail, objectDetails, initialColorState);

  const financeArray =
    propertyDetail && objectFunctions.getFinance(propertyDetail);

  const scoreArray =
    propertyDetail && objectFunctions.getScoreArray(propertyDetail);

  const descArray =
    propertyDetail &&
    propertyDetail.objectDescription &&
    objectFunctions.getDescription(propertyDetail, showReadMore);

  const privacyLinksArray =
    propertyDetail && propertyDetail.is_realtor_active && propertyDetail.realtor
      ? objectFunctions.getPrivacyLinkArray(propertyDetail.realtor)
      : [];

  const setWrapperRef = (node: any) => {
    wrapperRef = node;
  };

  // to show tooltip on hover
  const handleClickOutside = (event: MouseEvent) => {
    if (showTooltip && wrapperRef && !wrapperRef.contains(event.target)) {
      setshowTooltip(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  });

  return (
    <>
      <div className="object-description">
        <div className="row">
          <div className="col-lg-8 col-md-7 col-6">
            <div className="column-wrapper">
              <div className="d-flex justify-content-between align-items-center">
                <div>
                  <h2 className="title"> {propertyDetail.objectTitle}</h2>
                  <div className="d-flex justify-content-start align-items-center">
                    <div>
                      <MapPinIcon className="map-icon" />
                      <span
                        className="pointer"
                        onClick={() => {
                          setStateForObject("showMapPopup", true);
                        }}
                        style={{ textDecoration: "underline" }}
                      >
                        {getLocalizeText(englishText.SHOW_ON_MAP)}
                      </span>
                    </div>
                  </div>
                </div>
                <p className="object-status">
                  {propertyDetail.isNewProperty && (
                    <span>{getLocalizeText(englishText.NEW)}</span>
                  )}
                  {propertyDetail.dayDiffrence}
                </p>
              </div>
              {propertyDetail.objectDescription && (
                <>
                  <h2 className="title mt-4 mb-4">
                    {getLocalizeText(englishText.OBJ_DESC)}
                  </h2>
                  <div className="object-para">
                    {descArray.map((text: string, i: number) => (
                      <p key={i}>
                        {text}
                        {i === descArray.length - 1 &&
                          !showReadMore &&
                          propertyDetail.objectDescription.length >
                            objectFunctions.charLimit &&
                          "..."}
                        <br />
                      </p>
                    ))}
                  </div>
                  {propertyDetail.objectDescription.length >
                    objectFunctions.charLimit && (
                    <p
                      className="read-more"
                      onClick={() => {
                        if (showReadMore) {
                          window.scrollTo(0, 200);
                        }
                        setshowReadMore(!showReadMore);
                      }}
                    >
                      {showReadMore
                        ? getLocalizeText(englishText.SHOW_LESS)
                        : getLocalizeText(englishText.READ_MORE)}
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
          <div className="col-lg-4 col-md-5 col-6">
            <div className="description-card">
              {propertyDetail.type_auction_forced && (
                <div className="inventory-badge-class">
                  <Badge text={getLocalizeText(englishText.FORCLOSURE_SALE)} />
                </div>
              )}

              {propertyDetail.is_realtor_active && (
                <div
                  className={`realtor-badge ${
                    propertyDetail.type_auction_forced
                      ? "side-badge"
                      : "top-badge"
                  }`}
                >
                  <img src={directBadge} alt="img" />
                </div>
              )}
              <div className="card-item">
                <div
                  className="d-flex"
                  style={
                    propertyDetail.is_realtor_active ||
                    propertyDetail.type_auction_forced
                      ? { marginTop: "20px" }
                      : {}
                  }
                >
                  <h2 className="card-title">
                    {propertyDetail.propertyType
                      ? `${getLocalizeText(propertyDetail.propertyType)} ${
                          propertyDetail.adrPostalCode &&
                          " in " + propertyDetail.adrPostalCode
                        } 
                      ${
                        propertyDetail.adrQuarter &&
                        ", " + propertyDetail.adrQuarter
                      }`
                      : `${propertyDetail.adrStreet || "-"}`}
                  </h2>

                  {!isFav ? (
                    <div
                      className="heart-icon"
                      onClick={() => props.handleFavoClick()}
                    >
                      <i className={"fa fa-heart-o"} aria-hidden="true" />
                    </div>
                  ) : (
                    <div
                      className="heart-icon"
                      title={getLocalizeText(englishText.REMOVE_FAVO)}
                      onClick={() => props.toggleModal(true)}
                    >
                      <i className={"fa fa-heart"} aria-hidden="true" />
                    </div>
                  )}
                </div>
                <div
                  className="d-flex align-items-end mt-3"
                  style={{ placeContent: "space-between" }}
                >
                  <div className="object-items">
                    <h2>{propertyDetail.pricePerSqm || "-"}</h2>
                    <h6>
                      <FormattedMessage
                        id={englishText.PRICE_PER_METER}
                        defaultMessage={englishText.PRICE_PER_METER}
                      />
                    </h6>
                  </div>
                  <div className="object-items" style={{ overflow: "hidden" }}>
                    <h2 className="price">
                      {propertyDetail.formatedPrice
                        ? `${propertyDetail.formatedPrice}`
                        : "-"}
                    </h2>
                    <h6>
                      {" "}
                      <FormattedMessage
                        id={englishText.PRICE}
                        defaultMessage={englishText.PRICE}
                      />
                    </h6>
                  </div>
                </div>
              </div>
              <div className="card-item">
                <div
                  className="d-flex align-items-start"
                  style={{ placeContent: "space-between" }}
                >
                  <div className="object-items">
                    <h2>
                      {propertyDetail.areaLiving
                        ? propertyDetail.areaLiving
                            .toString()
                            .replace(",", ".") + " m²"
                        : "-"}
                    </h2>
                    <h6>
                      <FormattedMessage
                        id={englishText.SIZE}
                        defaultMessage={englishText.SIZE}
                      />
                    </h6>
                  </div>
                  <div className="object-items">
                    <h2>
                      {propertyDetail.propRoomsAmount
                        ? propertyDetail.propRoomsAmount
                        : "-"}
                    </h2>
                    <h6>
                      <FormattedMessage
                        id={englishText.ROOMS_OP}
                        defaultMessage={englishText.ROOMS_OP}
                      />
                    </h6>
                  </div>
                  <div className="object-items">
                    <h2>{propertyDetail.yearConstructed || "-"}</h2>
                    <h6>
                      <FormattedMessage
                        id={englishText.YEAR_OF_CONSTRUCTION}
                        defaultMessage={englishText.YEAR_OF_CONSTRUCTION}
                      />
                    </h6>
                  </div>
                </div>
                <div className="d-flex align-items-start mt-4">
                  <div className="object-items d-flex align-items-center">
                    <i className={"fa fa-check"} aria-hidden="true" />
                    {!propertyDetail.hasCourtage ? (
                      <span>
                        <FormattedMessage
                          id={englishText.NO_COMMISSION}
                          defaultMessage={englishText.NO_COMMISSION}
                        />
                      </span>
                    ) : (
                      <span>
                        <FormattedMessage
                          id={englishText.WITH_COMMISSION}
                          defaultMessage={englishText.WITH_COMMISSION}
                        />
                      </span>
                    )}
                  </div>
                  {propertyDetail.isPrivate && (
                    <div className="object-items d-flex align-items-center">
                      <i className={"fa fa-check"} aria-hidden="true" />
                      <span>Private add</span>
                    </div>
                  )}
                </div>
              </div>
              <div className="card-item text-center">
                <button
                  className="button d-flex align-items-center justify-content-center"
                  onClick={() => props.interestedClickHandle()}
                  disabled={propertyInfoLoading}
                >
                  <FormattedMessage
                    id={englishText.I_AM_INTERESTED}
                    defaultMessage={englishText.I_AM_INTERESTED}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-5">
        <div
          className={`object-score col-lg-3 col-md-3 col-3 ${
            onboarding ? "" : "disabled"
          }`}
        >
          <div>
            <h2 className="object-score-title">
              <FormattedMessage
                id={englishText.MATCHING_SCORE}
                defaultMessage={englishText.MATCHING_SCORE}
              />
            </h2>
            <div
              className="score"
              style={{
                backgroundColor: onboarding
                  ? objectFunctions.getIcon(Math.round(propertyDetail.score))
                  : "#cecece",
              }}
            >
              {Math.round(propertyDetail.score) || 0}
              <p
                className="question-icon"
                onMouseEnter={() => {
                  setshowTooltip(true);
                  settoolTipType("score");
                }}
                onMouseLeave={() => {
                  setshowTooltip(false);
                  settoolTipType("");
                }}
              >
                <QuestionMark />
              </p>

              {showTooltip && toolTipType === "score" && (
                <div className="object-tooltip" ref={setWrapperRef}>
                  <p>{getLocalizeText(englishText.TOAL_SCORE_SUBTITLE)}</p>
                </div>
              )}
            </div>
            <h3 className="score-label">
              <FormattedMessage
                id={englishText.TOTAL_SCORE}
                defaultMessage={englishText.TOTAL_SCORE}
              />
            </h3>

            {/* Score meters and tool tips */}
            {scoreArray &&
              scoreArray.length > 0 &&
              scoreArray.map((scoreData: any, index: number) => (
                <div className="score-item" key={index}>
                  <div className="d-flex align-items-center relative">
                    <div className="score-item-icon home-icon">
                      {scoreData.icon}
                    </div>
                    <ProgressBar
                      now={Math.round(scoreData.label) || 0}
                      label={`${Math.round(scoreData.label)}`}
                      variant={objectFunctions.getProgress(
                        Math.round(scoreData.label)
                      )}
                    />
                    <div
                      className="question-icon"
                      onMouseEnter={() => {
                        setshowTooltip(true);
                        settoolTipType(scoreData.tooltip);
                      }}
                      onMouseLeave={() => {
                        setshowTooltip(false);
                        settoolTipType("");
                      }}
                    >
                      <QuestionMark />
                    </div>
                    {showTooltip && toolTipType === scoreData.tooltip && (
                      <div
                        className="object-tooltip progress-tooltip"
                        ref={setWrapperRef}
                      >
                        <p>{getLocalizeText(scoreData.subtitle)}</p>
                      </div>
                    )}
                  </div>
                  <p className="score-item-title">
                    {getLocalizeText(scoreData.title)}
                  </p>
                </div>
              ))}

            {onboarding && (
              <p className="mt-5">
                {getLocalizeText(englishText.SCORE_BASED)}
                <Link
                  to={"/onboarding/true"}
                  className="text--primary font-weight-bold"
                >
                  {getLocalizeText(englishText.PROFILE)}
                </Link>{" "}
              </p>
            )}
            {!onboarding && (
              <div className="object-score-overlay">
                <p>
                  {`${getLocalizeText(englishText.FINISH_THE)} `}
                  <Link to="/onboarding">
                    {getLocalizeText(englishText.ONBOARDING)}
                  </Link>{" "}
                  {` ${getLocalizeText(englishText.TO_SEE_PROPERTY)}`}
                </p>
              </div>
            )}
          </div>
        </div>

        <div className="col-lg-5 col-md-6 col-6 tab-view">
          <Tabs defaultActiveKey="FACTS" id="uncontrolled-tab-example">
            <Tab
              eventKey="FACTS"
              title={
                <p className="d-flex align-items-center">
                  <ObjectDataIcon className="object-data-svg" />
                  {getLocalizeText(englishText.FACTS)}
                </p>
              }
            >
              <>
                {factsArray &&
                  factsArray.length > 0 &&
                  factsArray.map((fact: any, index: number) => (
                    <div
                      className="detail-item d-flex align-items-center"
                      key={index}
                    >
                      <p className="w-50">{getLocalizeText(fact.label)}</p>
                      <p className="w-50 d-flex align-items-center">
                        <span
                          className="color"
                          style={{
                            backgroundColor: fact.color,
                          }}
                        />
                        <span>{fact.value}</span>
                      </p>
                    </div>
                  ))}
              </>
            </Tab>

            <Tab
              eventKey="FINANCES"
              title={
                <p className="d-flex align-items-center">
                  <FinancesIcon className="object-data-svg" />
                  {getLocalizeText(englishText.FINANCES)}
                </p>
              }
            >
              {financeArray &&
                financeArray.length > 0 &&
                financeArray.map((fact: any, index: number) => (
                  <div
                    className="detail-item d-flex align-items-center"
                    key={index}
                  >
                    <p className="w-50">{getLocalizeText(fact.label)}</p>
                    <p className="w-50 d-flex align-items-center">
                      <span
                        className="color"
                        style={{
                          backgroundColor: fact.color,
                        }}
                      />
                      <span>{fact.value}</span>
                    </p>
                  </div>
                ))}
              <button
                className="btn mt-3 button-filter text-center"
                onClick={props.openCalc}
                style={{ width: "130px" }}
              >
                <FormattedMessage
                  id={englishText.FINANCING}
                  defaultMessage={englishText.FINANCING}
                />
              </button>
            </Tab>
            <Tab
              eventKey="DETAILS"
              title={
                <p className="d-flex align-items-center">
                  <DetailsIcon className="object-data-svg" />
                  {getLocalizeText(englishText.DETAILS)}
                </p>
              }
            >
              {detailsArray &&
                detailsArray.length > 0 &&
                detailsArray.map((fact: any, index: number) => (
                  <div
                    className="detail-item d-flex align-items-center"
                    key={index}
                  >
                    <p className="w-50">{getLocalizeText(fact.label)}</p>
                    <p className="w-50 d-flex align-items-center">
                      <span
                        className="color"
                        style={{
                          backgroundColor: fact.color,
                        }}
                      />
                      <span>{getLocalizeText(fact.value)}</span>
                    </p>
                  </div>
                ))}
            </Tab>
          </Tabs>
        </div>
        <div className="col-lg-4 col-md-3 col-3 d-flex flex-column justify-content-between">
          <div className="extra-section">
            <h2 className="object-score-title">Extras</h2>
            <div>
              {propertyDetail.tags && propertyDetail.tags.length > 0 ? (
                propertyDetail.tags.map((tag: string, ind: number) => (
                  <p key={ind}>{tag}</p>
                ))
              ) : (
                <h2>
                  <p>{getLocalizeText(englishText.NO_EXTRA)}</p>
                </h2>
              )}
            </div>
          </div>
          <EnergyDetail energy_data={propertyDetail.energy_data} />
        </div>
      </div>
      <div className="data-privacy-links row mt-3">
        {privacyLinksArray.length > 0 &&
          privacyLinksArray.map((data: any, i: number) => (
            <a
              className="d-flex align-items-center"
              href={data.link}
              key={i}
              target="_blank"
              rel="noreferrer"
            >
              <p> {data.label} </p>
              <i className="fa fa-external-link ml-1" aria-hidden="true" />
            </a>
          ))}
      </div>
    </>
  );
};

export default ObjectDescription;
