import { Action } from "utils/globalInterface";

export const INITIAL_STATE = {
  response: [],
};

export const favoUpdateReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case "ADD_FAVO_FULFILLED":
    case "REMOVE_FAVO_FULFILLED":
      return {
        status: "success",
        data: action.payload.data,
      };

    case "ADD_FAVO_PENDING":
    case "REMOVE_FAVO_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_FAVO_CHANGE":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};

export const favoCountReducer = (state = INITIAL_STATE, action: Action) => {
  switch (action.type) {
    case "GET_FAVO_COUNT_FULFILLED":
      return {
        status: "success",
        data: action.payload.data.data ? action.payload.data.data : [],
      };

    case "GET_FAVO_COUNT_PENDING":
      return {
        status: "pending",
      };

    case "CLEAR_FAVO_COUNT":
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};
