import React, { useState, useEffect } from "react";
// componets
import { Spinner } from "react-bootstrap";
import AddNewProperty from "../components/addNewProperty";
// constants and functions
import englishText from "languages/english/english";
import { secondaryColor, getLocalizeText } from "utils";
import { ICustomProps } from "../interface/myproperty.interface";
import HttpService from "services/http.service";
// assets
import logoWhite from "assets/images/LOGO_beta.png";
import { CloseIcon } from "components/commonComponents/icons";
import "../myprop.scss";

const CustomProperties: React.FC<ICustomProps> = (props) => {
  const [show, setShow] = useState(false);
  const [list, setList] = useState([]);
  const [showSpinner, setSpinner] = useState(true);
  const [objectData, setData] = useState(null);

  useEffect(() => {
    if (list.length === 0) {
      fetchList();
    }
    document.body.style.overflowY = "hidden";
    return () => {
      document.body.style.overflowY = "";
    };
  }, [list.length]);

  // Fetch list of custom properties
  const fetchList = async () => {
    HttpService.get("v1/objects")
      .then((response) => {
        setList(response.data);
        setSpinner(false);
      })
      .catch(() => {
        setSpinner(false);
      });
  };

  // Open any property to edit
  const openEdit = (index: number) => {
    setData(list[index]);
    setShow(true);
  };

  // Delete custom property from list
  const deleteObject = async (id: string) => {
    setSpinner(true);
    HttpService.deleteRequest(`v1/objects/${id}`)
      .then(() => {
        fetchList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Close add property popup
  const closeModal = (isRefreshRequired?: boolean) => {
    setData(null);
    setShow(false);
    if (isRefreshRequired) {
      fetchList();
      setSpinner(true);
    }
  };

  // Call favorite API to update wishlist
  const updateFavorite = async (id: string, type: string) => {
    HttpService.post(`v1/objects/favourite/${type}/${id}`)
      .then(() => {
        setTimeout(() => {
          props.getFavoCount();
        }, 700);
        fetchList();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="custom-properties-div">
      {!show && (
        <div className="close-btn-div" onClick={() => props.closeAddProperty()}>
          <CloseIcon />
        </div>
      )}
      <div className="section-title p-0 d-flex align-items-center justify-content-center w-100">
        <h6
          className="mb-2 ml-3"
          style={{ fontWeight: 700, color: secondaryColor }}
        >
          {getLocalizeText(englishText.MY_PROPERTIES)}
          {showSpinner && (
            <div className="spinner-wrapper">
              <Spinner animation="border" variant="info" />
            </div>
          )}
        </h6>
      </div>
      <div
        className={`properties-table mt-4 ${
          list.length > 5 && "overflow-table"
        }`}
      >
        {list.length > 0 &&
          list.map((item: any, index: number) => (
            <div
              className="prop-list row mb-3"
              key={index}
              style={{ animationDelay: `${index * 0.2}s` }}
            >
              <div className="custom-thumbnail">
                <img
                  src={
                    item.pictures && item.pictures.length > 0
                      ? item.pictures[0]
                      : logoWhite
                  }
                  alt={"img"}
                />
              </div>
              <div className="custom-prop-item d-flex justify-content-between">
                <p>{item.general.title}</p>
                <div className="custom-prop-icons">
                  {/* Add/Remove favorite button */}
                  {item.status === "ACTIVE" && (
                    <i
                      className={
                        item.isfavourite ? "fa fa-heart" : "fa fa-heart-o"
                      }
                      aria-hidden="true"
                      onClick={() =>
                        updateFavorite(
                          item.id,
                          item.isfavourite ? "remove" : "add"
                        )
                      }
                      title={
                        item.isfavourite
                          ? getLocalizeText(englishText.REMOVE_FAVO)
                          : getLocalizeText(englishText.ADD_TO_FAVO)
                      }
                    />
                  )}
                  {/* Show object details button */}
                  {item.status === "ACTIVE" && (
                    <a
                      href={`/object-detail/${item.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i
                        className="fa fa-eye"
                        aria-hidden="true"
                        title={getLocalizeText(englishText.SEE_DETAILS)}
                      />
                    </a>
                  )}
                  {/* Edit property button */}
                  <i
                    className="fa fa-pencil"
                    aria-hidden="true"
                    onClick={() => openEdit(index)}
                    title={getLocalizeText(englishText.EDIT_PROPERTY)}
                  />
                  {/* Delete property button */}
                  <i
                    className="fa fa-trash"
                    aria-hidden="true"
                    onClick={() => deleteObject(item.id)}
                    title={getLocalizeText(englishText.DELETE_PROPERTY)}
                  />
                </div>
              </div>
            </div>
          ))}
        {/* No custom property found */}
        {list.length === 0 && !showSpinner && (
          <p className="text-center w-100">
            {getLocalizeText(englishText.NO_DATA)}
          </p>
        )}
      </div>

      {/* Add new property button */}
      <div className="w-100 text-center">
        <button
          className="mt-4 add-prop-btn"
          onClick={() => {
            setShow(true);
          }}
          title={getLocalizeText(englishText.ADD_PROPERTY)}
        >
          +
        </button>
      </div>
      {show && (
        <div
          className={`model-wrapper d-flex align-items-center justify-content-center show hide-scroll`}
        >
          <AddNewProperty closeModal={closeModal} objectData={objectData} />
        </div>
      )}
    </div>
  );
};

export default CustomProperties;
