import React, { useEffect, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
// components
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";
import ContactPopUpWrapper from "./contactPopUpWrapper";
import QualifyPDF from "components/qualifyPDF/container/qualifyPDF";
// Functions
import englishText from "languages/english/english";
import { primaryColor, sendAmplitudeDataEvent, getLocalizeText } from "utils";
import { DynmicTextComponent } from "./dynmicTextComponent";
import { BASE_URL } from "services/baseUrl";
// Assets
import exclaimation from "assets/images/icon.svg";

interface IPlatformContactProps {
  closepopup: () => void;
  propertyDetail: any;
  links: any[];
}

function PlatformcontactPopup(props: IPlatformContactProps) {
  const [uniqueplatforms, setuniqueplatforms] = useState<any>([]);
  const [qualifyComplete, setqualifyComplete] = useState(false);

  useEffect(
    () => {
      uniqueplatforms.length === 0 && platformsComponent();
    },
    // eslint-disable-next-line
    []
  );

  const platformsComponent = () => {
    const platformNames: any = [];
    props.links &&
      props.links.length > 0 &&
      props.links.forEach((i: any) => {
        if (i.active && i.url)
          platformNames.push({ platform: i.platform, link: i.url });
      });

    const uniquePlatforms = [...new Set(platformNames)];

    const names: any = [];
    let updatedPlatforms: any = [];

    uniquePlatforms &&
      uniquePlatforms.length > 0 &&
      uniquePlatforms.forEach((platform: any) => {
        if (!names.includes(platform.platform)) {
          names.push(platform.platform);
          updatedPlatforms.push(platform);
        }
      });

    const omPlatform =
      uniquePlatforms.length > 0 &&
      uniquePlatforms.filter(
        (i: any) => i.platform === "om" && i.platform === "om"
      );

    if (omPlatform && omPlatform.length > 0) {
      updatedPlatforms = omPlatform;
    }
    setuniqueplatforms(updatedPlatforms);
  };

  const renderLeftPannel = () => (
    <div
      className="full-width d-flex flex-column justify-content-between"
      style={{ height: "100%" }}
    >
      <div>
        <p className="platform-contact-header">
          <FormattedMessage
            id={englishText.CONTACT_PROVIDER}
            defaultMessage={englishText.CONTACT_PROVIDER}
          />
        </p>
        <p className="platform-contact-detail">
          <FormattedMessage
            id={englishText.USE_LINK_BELOW}
            defaultMessage={englishText.USE_LINK_BELOW}
          />
        </p>
        <div
          className="platform-links d-flex align-items-center justify-content-center pointer"
          style={{ flexWrap: "wrap" }}
        >
          {uniqueplatforms &&
            uniqueplatforms.length > 0 &&
            uniqueplatforms.map((link: any, index: number) => (
              <React.Fragment key={index}>
                <a
                  className="mr-1 cursor"
                  onClick={() => {
                    const meta = {
                      Action: "External platform link clicked",
                      platform: link.platform,
                    };
                    sendAmplitudeDataEvent(
                      "External_platform_link_clicked",
                      meta
                    );
                  }}
                  href={link.link}
                  target={"_blank"}
                  rel="noopener noreferrer"
                  style={{
                    textDecoration: "underline",
                    color: primaryColor,
                  }}
                >
                  {link.platform === "om" ? "Ohne-Makler.net" : link.platform}{" "}
                </a>
                <i
                  className="fa fa-external-link mr-3"
                  aria-hidden="true"
                  style={{ marginTop: "5px" }}
                />
              </React.Fragment>
            ))}
        </div>
        <div
          className="pdf-section"
          style={qualifyComplete ? {} : { opacity: "0.5" }}
        >
          <p className="pdf-title mb-2">
            <FormattedMessage
              id={englishText.INCREASE_CHANCES_VISIT}
              defaultMessage={englishText.INCREASE_CHANCES_VISIT}
            />
          </p>
          <p>
            <FormattedMessage
              id={englishText.SHARE_AS_PDF}
              defaultMessage={englishText.SHARE_AS_PDF}
            />
          </p>
        </div>
        <div style={qualifyComplete ? {} : { opacity: "0.5" }}>
          <InputGroup className="mb-2 mt-3">
            <FormControl
              className="copy-input"
              placeholder="https://share.comps.com/1sft2YAL4Tn-$M"
              readOnly
              disabled={!qualifyComplete}
            />
            <CopyToClipboard
              onCopy={() => {
                qualifyComplete &&
                  toast.success(getLocalizeText(englishText.URL_COPIED));
              }}
              text={`${
                BASE_URL && BASE_URL.includes("staging")
                  ? "https://staging.immozy.de"
                  : "https://app.immozy.de"
              }/user-profile/${localStorage.getItem("userId")}`}
            >
              <InputGroup.Text
                id="basic-addon2"
                className="copy-btn mr-2"
                style={
                  !qualifyComplete
                    ? {
                        cursor: "not-allowed",
                        backgroundColor: "#e9ecef",
                        color: "#000",
                      }
                    : {}
                }
                title={
                  qualifyComplete
                    ? ""
                    : getLocalizeText(englishText.COMPLETE_QUALIFY_WARNING)
                }
              >
                <i className="ml-2 fa fa-clone mr-2" />

                <FormattedMessage
                  id={englishText.COPY}
                  defaultMessage={englishText.COPY}
                />
              </InputGroup.Text>
            </CopyToClipboard>
          </InputGroup>
          <a
            className="pdf-link"
            href={`/user-profile/${localStorage.getItem("userId")}`}
            target="_blank"
            style={!qualifyComplete ? { pointerEvents: "none" } : {}}
            rel="noopener noreferrer"
            onClick={(e) => {
              !qualifyComplete && e.preventDefault();
            }}
          >
            <FormattedMessage
              id={englishText.PREVIEW_LINK}
              defaultMessage={englishText.PREVIEW_LINK}
            />
            <i
              className="fa fa-external-link ml-2"
              aria-hidden="true"
              title={
                qualifyComplete
                  ? ""
                  : getLocalizeText(englishText.COMPLETE_QUALIFY_WARNING)
              }
            />
          </a>
        </div>
        <div className="pdf-btn">
          <QualifyPDF enabled={qualifyComplete} />
        </div>
        {!qualifyComplete && (
          <div className={`contact-warning d-flex align-items-center `}>
            <div className="d-flex">
              <img src={exclaimation} alt="img" />
              <div>
                <p className="warning-contact-text">
                  <FormattedMessage
                    id={englishText.QUALIFY_PDF_WARNING}
                    defaultMessage={englishText.QUALIFY_PDF_WARNING}
                  />
                </p>
                <p className="warning-contact-text mt-2">
                  <Link className="warning-contact-link ml-0" to={"/prepare"}>
                    <FormattedMessage
                      id={englishText.GO_TO_QUALIFY}
                      defaultMessage={englishText.GO_TO_QUALIFY}
                    />
                  </Link>
                </p>
              </div>
            </div>
          </div>
        )}
        <p className="platform-contact-footer mb-2">
          <FormattedMessage
            id={englishText.CONDITION_BROKER}
            defaultMessage={englishText.CONDITION_BROKER}
          />
        </p>
      </div>
    </div>
  );

  return (
    <div className="d-flex justify-content-center align-items-center model-wrapper show fade-in">
      <ContactPopUpWrapper
        className="platform-contact-popup"
        onClose={props.closepopup}
        leftPannel={renderLeftPannel()}
        rightPannel={
          <DynmicTextComponent
            propertyDetail={props.propertyDetail}
            setqualifyComplete={setqualifyComplete}
          />
        }
      />
    </div>
  );
}

export default PlatformcontactPopup;
