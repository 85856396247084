import englishText from "languages/english/english";
import { lang, getLocalizeText } from "utils";

export const setLocationData = (location) => {
  const objectLocation = {
    countryId: location.countryId,
    city: location.city,
    region: location.region,
    quarter: location.quarter,
    zipCode: location.zipCode,
    street: location.street,
    streetNumber: location.streetNumber,
  };
  return objectLocation;
};

export const setPropertyData = (property, features, objectData) => {
  const objectProperty = {
    propertyType: property.propertyType,
    obj_telekomInternet: property.obj_telekomInternet,
    features: property.features,
    pictures: objectData.pictures,
    floorplan: objectData.floorplan,
    heating: property.heating,
  };

  const updatedArray = features.map((i) => {
    if (property.features.includes(i.label)) i.selected = true;
    return i;
  });

  return { objectProperty, updatedArray };
};

export const setFinanceData = (financial) => {
  const objectFin = {
    price: financial.price,
    agentCommission: financial.agentCommission,
    additionalFees: financial.additionalFees,
    purchaseNegotiationStatus: financial.purchaseNegotiationStatus,
    commisionType: financial.commisionType,
    sellerOwner: {
      name: financial.sellerOwner.name,
      address: financial.sellerOwner.address,
      phone: financial.sellerOwner.phone,
      email: financial.sellerOwner.email,
    },
  };
  return objectFin;
};

export const replaceMsg = (msg) => {
  const langValue = localStorage.getItem("language");
  if (langValue === lang.GR) {
    msg = getLocalizeText(englishText.FILL_ERROR_OBJECT);
  } else {
    if (msg.includes("livingAreaSQM")) {
      msg = msg.replace("livingAreaSQM", "Living area");
    } else if (msg.includes("noOfRooms")) {
      msg = msg.replace("noOfRooms", "Amount of rooms");
    } else if (msg.includes("obj_telekomInternet")) {
      msg = msg.replace("obj_telekomInternet", "Internet speed");
    } else if (msg.includes("phone")) {
      msg = msg.replace("phone", "Phone number");
    }
  }

  return msg;
};
