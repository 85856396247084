// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import { compose, withProps, lifecycle } from "recompose";
import { withGoogleMap, GoogleMap, Polygon } from "react-google-maps";
import { Spinner } from "react-bootstrap";
import { legendTypes } from "utils/constants";
import pin from "assets/images/pin.png";
import pinHighlight from "assets/images/pin-highlight.png";
import multihover from "assets/images/multi-hover.png";
import * as mapFunctions from "../components/mapData";
import multiIcon from "assets/images/purple.png";
import SingleMapWindow from "../components/singleMapWindow";
import MultiMapWindow from "../components/multiMapWindow";
import {
  IMapFilterDataProps,
  IMapFilterProps,
  IMapLifeCycle,
  IMapProps,
} from "../interface/map.interface";

const MyMapComponent = compose<any, IMapProps>(
  withProps({
    loadingElement: (
      <div style={{ height: `100%`, marginTop: "40%", textAlign: "center" }}>
        <Spinner animation="border" variant="info" />
      </div>
    ),
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  lifecycle<IMapLifeCycle, any>({
    componentWillMount() {
      const refs: any = {};
      this.setState({
        onMapMounted: (ref: any) => {
          refs.map = ref;
          maprefs = refs.map;

          const { mapData, multiMapData } = this.props;
          isFromSearch = this.props.isFromSearch || false;

          //show single pin type of map data
          if (maprefs && mapData && mapData.length > 0) {
            showSinglePins(this.props);
          }

          //show multi pin type of map data
          if (maprefs && multiMapData && multiMapData.length > 0) {
            showMultiPins(this.props);
          }
        },
        zoom: this.props.showBoth ? 8 : 11,
        markers: [],
      });
    },
    componentDidMount() {
      const { closeInfo } = this.props;
      closeInfo();
    },
    componentDidUpdate(prevProps) {
      const {
        showType,
        resultArray,
        iconFile,
        removeMarker,
        mapData,
        multiMapData,
        pageChanged,
        addHighLightId,
        removeHighlightId,
      } = this.props;

      // to show location pin highlighted on hover
      if (addHighLightId !== prevProps.addHighLightId) {
        updateHighlights(addHighLightId);
      }

      //remove highlighted id
      if (
        addHighLightId === prevProps.addHighLightId &&
        addHighLightId !== ""
      ) {
        this.props.clearAdd();
      }

      // to remove location pin highlighted on hover
      if (removeHighlightId !== prevProps.removeHighlightId) {
        removeHighlights(removeHighlightId);
      }

      //remove removeID
      if (
        removeHighlightId === prevProps.removeHighlightId &&
        removeHighlightId !== ""
      ) {
        this.props.clearRemove();
      }

      //show checkboxes data : schools, hospitals etc. on checked
      if (
        maprefs &&
        showType &&
        markerArray[showType].length === 0 &&
        !removeMarker
      ) {
        showFilterData(resultArray, showType, iconFile);
      }

      // update single pin type of data for objects
      if (prevProps.mapData !== mapData && pageChanged) {
        updateSinglePins(this.props);
      }

      // update multiple pin type of data for objects
      if (prevProps.multiMapData !== multiMapData && pageChanged) {
        updateMultiPins(this.props);
      }

      //remove checkboxes data : schools, hospitals etc.
      if (removeMarker) {
        markerArray[showType].length > 0 &&
          markerArray[showType].forEach((marker: any) => {
            marker.setMap(null);
          });
        this.props.clearRemoveMarker();
        markerArray[showType] = [];
      }
    },
    componentWillUnmount() {
      //clear single pins data
      clearAllMapData(this.props);
    },
  }),
  withGoogleMap
)((props: any) => (
  <GoogleMap
    zoom={props.zoom}
    defaultCenter={mapFunctions.getCenterCoordinates(
      props.showBoth ? "fav" : props.selectedCity
    )}
    ref={props.onMapMounted}
    options={{
      disableDoubleClickZoom: props.isOpen || props.isOpenMulti,
      scrollwheel: false,
      fullscreenControl: false,
      zoomControlOptions: {
        /* eslint-disable no-undef */
        position: google.maps.ControlPosition.RIGHT_CENTER,
      },
    }}
  >
    {/* Selectet city region shape pn map */}
    <Polygon
      path={mapFunctions.getPathCoordinates()}
      options={{
        fillColor: "#753de3",
        fillOpacity: 0.05,
        strokeColor: "#753de3",
        strokeOpacity: 0.8,
        strokeWeight: 1.5,
      }}
    />

    {/* Show both city boundaries in zoomed out state when showing favorite's map */}
    <>
      {/* {props.showBoth && (
        <Polygon
          path={
            props.selectedCity === cities.HAM
              ? mapFunctions.berlinCoords
              : mapFunctions.hamburgCoords
          }
          options={{
            fillColor: "#753de3",
            fillOpacity: 0.05,
            strokeColor: "#753de3",
            strokeOpacity: 0.8,
            strokeWeight: 1.5,
          }}
        />
      )} */}
    </>

    {/* Single Marker's infowindow */}
    <SingleMapWindow dataObj={props} />

    {/* Multiple Marker's infowindow */}
    <MultiMapWindow dataObj={props} />
  </GoogleMap>
));

let maprefs: any = "";
let markerArray: any = {
  schools: [],
  supermarkets: [],
  transports: [],
  medicals: [],
  parks: [],
};
let markerClusterArray: any = null;
let markerClusterArrayMulti: any = null;
let mapArray: any = [];
let multiMapArray: any = [];
let isFromSearch = true;

// Show single markers on first load
const showMultiPins = (props: any) => {
  const { multiMapData, addHighLightClass, removeHighlight } = props;
  multiMapData.forEach((map: any, index: number) => {
    if (index < 25) {
      mapFunctions.createMultiMarker(
        map,
        props.onToggleMultiOpen,
        addHighLightClass,
        removeHighlight,
        maprefs,
        multiMapArray,
        index
      );
    }
  });
  // eslint-disable-next-line no-unused-vars
  markerClusterArrayMulti = mapFunctions.createCluster(multiMapArray, maprefs);
  props.pageChanged && props.resetPageChange();
};

// Show multiple type of markers on first load
const showSinglePins = (props: any) => {
  const { mapData, onToggleOpen, addHighLightClass, removeHighlight } = props;
  mapData.forEach((map: any, index: number) => {
    if (index < 25) {
      mapFunctions.createSingleMarker(
        maprefs,
        onToggleOpen,
        addHighLightClass,
        removeHighlight,
        mapArray,
        map,
        index
      );
    }
  });
  // eslint-disable-next-line no-unused-vars
  markerClusterArray = mapFunctions.createCluster(mapArray, maprefs);
};

// Update multiple markers when page/filters changes
const updateMultiPins = (props: any) => {
  const { closeInfo, multiMapData, addHighLightClass, removeHighlight } = props;

  //clear old makrers and cluster array
  multiMapArray.length > 0 &&
    multiMapArray.forEach((marker: any) => {
      marker.setMap(null);
    });
  multiMapArray = [];
  if (markerClusterArrayMulti) {
    markerClusterArrayMulti.clearMarkers();
    markerClusterArrayMulti.setMap(null);
    markerClusterArrayMulti = null;
  }
  closeInfo();

  if (maprefs && multiMapData && multiMapData.length > 0) {
    multiMapData.forEach((map: any, index: number) => {
      if (index < 25) {
        mapFunctions.createMultiMarker(
          map,
          props.onToggleMultiOpen,
          addHighLightClass,
          removeHighlight,
          maprefs,
          multiMapArray,
          index
        );
      }
    });

    markerClusterArrayMulti = mapFunctions.createCluster(
      multiMapArray,
      maprefs
    );
  }
  props.resetPageChange();
};

// Update single markers when page/filters changes
const updateSinglePins = (props: any) => {
  const { closeInfo, mapData, addHighLightClass, removeHighlight } = props;

  //clear old makrers and cluster array
  mapArray.length > 0 &&
    mapArray.forEach((marker: any) => {
      marker.setMap(null);
    });
  mapArray = [];
  if (markerClusterArray) {
    markerClusterArray.clearMarkers();
    markerClusterArray.setMap(null);
    markerClusterArray = null;
  }
  closeInfo();

  if (maprefs && mapData && mapData.length > 0) {
    mapData.forEach((map: any, index: number) => {
      mapFunctions.createSingleMarker(
        maprefs,
        props.onToggleOpen,
        addHighLightClass,
        removeHighlight,
        mapArray,
        map,
        index
      );
    });

    markerClusterArray = mapFunctions.createCluster(mapArray, maprefs);
  }
};

// Update highlighted icon on map
const updateHighlights = (addHighLightId: string) => {
  const mark = mapArray.find(
    (i: any) => i.metadata && i.metadata.id === addHighLightId
  );

  if (mark) {
    const markerIcon = {
      url: isFromSearch ? mark.metadata.highlightIcon : pinHighlight,
      origin: new google.maps.Point(0, 0),
    };
    mark.setIcon(markerIcon);
  } else {
    const isMultiMarker = multiMapArray.find((i: any) =>
      i.metadata.id.includes(addHighLightId)
    );

    if (isMultiMarker) {
      const markerIcon = {
        url: multihover,
        origin: new google.maps.Point(0, 0),
        labelOrigin: new google.maps.Point(25, 62),
      };

      const index = isMultiMarker.metadata.id.findIndex(
        (i: any) => i === addHighLightId
      );

      if (index >= 0) {
        isMultiMarker.setIcon(markerIcon);
      }
    }
  }
};

// Remove highlighted icon on map
const removeHighlights = (removeHighlightId: string) => {
  let oldmark = mapArray.find(
    (i: any) => i.metadata && i.metadata.id === removeHighlightId
  );

  oldmark && oldmark.setIcon(isFromSearch ? oldmark.metadata.icon : pin);

  if (!oldmark) {
    oldmark = multiMapArray.find((i: any) =>
      i.metadata.id.includes(removeHighlightId)
    );
    const markerIcon = {
      url: multiIcon,
      origin: new google.maps.Point(0, 0),
      labelOrigin: new google.maps.Point(20, 45),
    };
    oldmark && oldmark.setIcon(markerIcon);
  }
};

// Show checked filter data for shcools, hospitals etc.
const showFilterData = (
  resultArray: IMapFilterProps,
  showType: string,
  iconFile: any
) => {
  if (resultArray[showType].length > 0) {
    resultArray[showType].forEach(
      (place: IMapFilterDataProps, index: number) => {
        if (index < 300) {
          const marker = new google.maps.Marker({
            position: new google.maps.LatLng(
              parseFloat(place.latitude),
              parseFloat(place.longitude)
            ),
            title: place.name,
            icon: iconFile,
          });
          marker.setMap(
            maprefs.context.__SECRET_MAP_DO_NOT_USE_OR_YOU_WILL_BE_FIRED
          );
          markerArray[showType].push(marker);
        }
      }
    );
  }
};

// Clear all markers | filters | clusters on map unmount
const clearAllMapData = (props: any) => {
  for (let i = 0; i < mapArray.length; i++) {
    mapArray[i].setMap(null);
  }

  //clear multi pins data
  for (let i = 0; i < multiMapArray.length; i++) {
    multiMapArray[i].setMap(null);
  }
  mapArray = [];
  multiMapArray = [];

  //clear checkbox pins data
  legendTypes.forEach((type) => {
    for (let i = 0; i < markerArray[type].length; i++) {
      markerArray[type][i].setMap(null);
    }
  });
  markerArray = {
    schools: [],
    supermarkets: [],
    transports: [],
    medicals: [],
    parks: [],
  };
  props.clearCheckedArray();
};

export default MyMapComponent;
