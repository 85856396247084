import React, { useEffect, useState } from 'react';
import './fadeInSection.scss';

export const FadeInSection = (props: any) => {
    const [isVisible, setVisible] = useState(false);
    const [hideContent, setHideContent] = useState(false);
    const domRef: any = React.useRef();

    useEffect(() => {
      // eslint-disable-next-line no-extra-boolean-cast
      if (!!props.hide) {
        setTimeout(() => {
          setHideContent(true);
        }, 500);
      } else {
        setHideContent(false);
      }
    }, [props.hide])


    React.useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                // console.log(`entry`, entry, `is = ${entry.isIntersecting}`);
                setVisible(entry.isIntersecting);
            });
        });

        const { current } = domRef;
        observer.observe(current);

        return () => observer.unobserve(current);
    }, []);
    return (
        <div
            className={`${props.className || ""} fade-in-section ${isVisible && !props.hide ? "is-visible" : ""}`}
            style={props.style || {}}
            ref={domRef}
        >
            {!hideContent && props.children}
        </div>
    );
}