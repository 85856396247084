import { Arrow, VerifiedIcon } from "components/commonComponents/icons";
import React, { useRef } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FormattedMessage } from "react-intl";
import { importanceLevelConstant } from "utils/constants";
import { IAccordionContentProps } from "../interface/immozyhome";

const AccordionContent: React.FC<IAccordionContentProps> = (props) => {
  const {
    eventKey,
    title,
    progressPercentage,
    icon,
    children,
    importanceLevel,
    id,
    activeTab,
    handleTabChange,
  } = props;
  const accordianRef: any = useRef();

  return (
    <div>
      <div className="accordion-item" id={id}>
        {/* Header of collapse menu */}
        <Accordion.Toggle
          eventKey={eventKey}
          className="accordion-header"
          onClick={() => handleTabChange(eventKey)}
        >
          <div className="header-content d-flex justify-content-between align-items-center mb-6px full-width">
            <div className="left d-flex justify-content-center align-items-center">
              <div className="icon position-relative mr-15px">
                {icon}
                {progressPercentage === 100 && (
                  <VerifiedIcon
                    className="verified-badge"
                    width="18"
                    height="18"
                  />
                )}
              </div>
              <span>{title}</span>
            </div>
            <div className="right d-flex justify-content-center align-items-center">
              <div className="progress-detail mr-15px">
                {/* <p>{(remainingSteps * 100) / totalSteps}% for completing this task</p> */}
                {importanceLevel !== 4 && (
                  <p
                    className="importance-level-badge"
                    style={{
                      backgroundColor:
                        importanceLevelConstant[importanceLevel].color + "1a",
                      color: importanceLevelConstant[importanceLevel].color,
                    }}
                  >
                    {/* <UpSideArrowIcon
                    className="mr-6px"
                    fill={importanceLevelConstant[importanceLevel].color}
                  /> */}
                    <FormattedMessage
                      id={importanceLevelConstant[importanceLevel].label}
                      defaultMessage={
                        importanceLevelConstant[importanceLevel].label
                      }
                    />
                  </p>
                )}
              </div>
              <Arrow
                className={`arrow-icon ${
                  activeTab === eventKey ? "rotate-180" : ""
                }`}
              />
            </div>
          </div>
          <div
            className={`accordion-header-progress-bar ${
              activeTab === eventKey ? "" : "bottom-border-radius"
            }`}
          >
            <div className="cssProgress">
              <div className="progress4">
                <div
                  className={`cssProgress-bar cssProgress-success cssProgress-glow-active cssProgress-4x`}
                  style={{ width: `${progressPercentage}%` }}
                />
              </div>
            </div>
          </div>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey={eventKey} ref={accordianRef}>
          <div className="accordion-body">
            <div>{children}</div>
          </div>
        </Accordion.Collapse>
      </div>
    </div>
  );
};

export default AccordionContent;
