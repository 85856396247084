import React from "react";
import { FormattedMessage } from "react-intl";
import { InlineWidget } from "react-calendly";
import { lang } from "utils";
import { BASE_URL } from "services/baseUrl";
import englishText from "languages/english/english";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import Modal from "react-awesome-modal";
import closebtn from "assets/images/Icon-BTN-Clsoe.svg";

interface ICalendlyProps {
  closeActivePopup: () => void;
  id?: string;
}

const CalendlyPopup: React.FC<ICalendlyProps> = ({ ...props }) => {
  const prefill = {
    email: localStorage.getItem("email") || "",
    name: localStorage.getItem("name") || "",
  };

  const pageSettings = {
    backgroundColor: "ffffff",
    hideEventTypeDetails: false,
    hideLandingPageDetails: false,
    primaryColor: "932849",
    textColor: "374b6a",
  };

  const userID = localStorage.getItem("userId") || "";
  const language = localStorage.getItem("language") || lang.GR;

  return (
    <div>
      <Modal visible={true} width={"1050px"}>
        <img
          src={closebtn}
          alt="img"
          className="close-btn-calendly"
          onClick={props.closeActivePopup}
        />
        <div>
          <p className="calendly-title">
            <FormattedMessage
              id={englishText.CALENDLY_TITLE}
              defaultMessage={englishText.CALENDLY_TITLE}
            />
          </p>
          <InlineWidget
            url={
              language === lang.EN
                ? "https://calendly.com/immozy/en-30"
                : "https://calendly.com/immozy/de-30"
            }
            pageSettings={pageSettings}
            prefill={prefill}
            utm={{
              utmContent: props.id, // object ID
              utmSource: userID, // user ID
              utmTerm:
                BASE_URL && BASE_URL.includes("staging") ? "staging" : "app", // server type
            }}
          />
          {/* <CalendlyEventListener
                        onEventScheduled={onEventScheduled}
                    /> */}
        </div>
      </Modal>
    </div>
  );
};

export default CalendlyPopup;
