import { combineReducers } from "redux";
import { addUpdateReducer } from "./customObjectReducer";
import * as searchReducers from "./searchReducer";
import * as objectDetailReducers from "./mapFilterReducer";
import * as favReducers from "./favoReducer";
import * as onboardReducers from "./summaryReducer";

const rootReducer: any = combineReducers({
  addUpdateReducer: addUpdateReducer,
  topResultCountReducer: searchReducers.topResultCountReducer,
  changeCityReducer: searchReducers.changeCityReducer,
  profileCountReducer: searchReducers.profileCountReducer,
  mapFilterReducer: objectDetailReducers.mapFilterReducer,
  favoUpdateReducer: favReducers.favoUpdateReducer,
  favoCountReducer: favReducers.favoCountReducer,
  summaryReducer: onboardReducers.summaryReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;