// eslint-disable-next-line @typescript-eslint/no-unused-vars
import React from "react";
import {
  getSinglefilterParameters,
  houseTypesArray,
  extraFeatures,
  lang,
  recordPerPage,
} from "utils";
import {
  Hospital,
  Parc,
  School,
  ShoppingCart,
  Transportation,
} from "components/commonComponents/icons";
import englishText from "languages/english/english";
import buy from "assets/images/buy.svg";
import bus from "assets/images/bus.svg";
import school from "assets/images/school.svg";
import clinic from "assets/images/clinic.svg";
import park from "assets/images/park.svg";
import { API_CONFIG, HttpService } from "services";

export const paginationInitalState = {
  currentPage: 1,
  totalRecords: 0,
  totalPages: 1,
  pageChanged: false,
};

export const newSearchState = {
  showSmallFilter: false,
  isMoreFilterActive: false,
  emptyListError: "",

  // min max values
  toPrice: "100000",
  fromPrice: "0",
  fromAreaLiving: "0",
  toAreaLiving: "5000",
  fromNumberOfRooms: "0",
  toNumberOfRooms: "0",

  // actual filters
  fromPlotApprox: "",
  toPlotApprox: "",
  fromYearOfContruction: "",
  toYearOfContruction: "",
  fromAgeOfadvert: "",
  toAgeOfadvert: "",
  objectType: [],
  otherFeatures: [],
  internet: "",
  rooms: "",
  price: "",
  space: "",
  houseTypesArray: JSON.parse(JSON.stringify(houseTypesArray)),
  extraFeatures: JSON.parse(JSON.stringify(extraFeatures)),
};

// Get existing filters data and get search object
export const createSearchSummary = (
  data: any,
  getSearchResults: any,
  turntopResult: boolean
) => {
  let isMoreFilterActive = false;
  const objectType = data.objectType || [];

  const rooms = data.toNumberOfRooms || "0";
  const space = data.toAreaLiving || "0";
  const price = data.toPrice || "0";
  const fromPrice = data.fromPrice || "0";
  const fromAreaLiving = data.fromAreaLiving || "0";
  const fromNumberOfRooms = data.fromNumberOfRooms || "0";
  const fromPlotApprox = data.fromPlotApprox || "0";
  const toPlotApprox = data.toPlotApprox || "0";
  const fromYearOfContruction = data.fromYearOfContruction;
  const toYearOfContruction = data.toYearOfContruction;
  const fromAgeOfadvert = data.fromAgeOfadvert || "0";
  const toAgeOfadvert = data.toAgeOfadvert || "0";
  const internet = data.internet || "";
  const location = data.location || "";
  const typeAuctionForced = data.typeAuctionForced || null;
  const houseTypesArrayState = JSON.parse(JSON.stringify(houseTypesArray));
  const extraFeaturesState = JSON.parse(JSON.stringify(extraFeatures));

  let obj = {
    currentPage: 1,
    recordPerPage: recordPerPage,
    fromAreaLiving,
    fromNumberOfRooms,
    fromPrice,
    toPrice: price,
    toAreaLiving: space,
    toNumberOfRooms: rooms,
    objectType,
    internet,
    fromPlotApprox,
    toPlotApprox,
    fromYearOfContruction,
    toYearOfContruction,
    fromAgeOfadvert,
    toAgeOfadvert,
    location,
    typeAuctionForced,
  };

  let scoreObj = null;

  if (data.topResult || turntopResult) {
    scoreObj = {
      totalScoreFrom: !turntopResult ? data.totalScoreFrom : 30,
      requirementsScoreFrom: !turntopResult ? data.requirementsScoreFrom : 0,
      locationsScoreFrom: !turntopResult ? data.locationsScoreFrom : 0,
      financialsScoreFrom: !turntopResult ? data.financialsScoreFrom : 0,
      topResult: true,
    };
    const newObj = { ...obj, ...scoreObj };
    obj = newObj;
  }

  getSearchResults(obj);

  houseTypesArrayState.forEach((element: any) => {
    if (objectType && objectType.includes(element.label)) {
      element.value = true;
      isMoreFilterActive = true;
    }
  });

  extraFeaturesState.forEach((element: any) => {
    if (data.otherFeatures && data.otherFeatures.includes(element.countName)) {
      element.value = true;
      isMoreFilterActive = true;
    }
  });

  isMoreFilterActive = checkMoreFilterEnabled(
    isMoreFilterActive,
    fromYearOfContruction,
    toYearOfContruction,
    fromPlotApprox,
    toPlotApprox,
    internet,
    fromAgeOfadvert,
    toAgeOfadvert,
    location,
    typeAuctionForced
  );

  const stateObject = {
    objectType,
    rooms: getValues(data.toNumberOfRooms, "rooms"),
    space: getValues(data.toAreaLiving, "space"),
    price: getValues(data.toPrice, "price"),
    internet,
    fromAreaLiving,
    fromNumberOfRooms,
    fromPrice,
    fromPlotApprox,
    toPlotApprox,
    fromYearOfContruction,
    toYearOfContruction,
    refreshLoad: true,
    houseTypesArray: houseTypesArrayState,
    extraFeatures: extraFeaturesState,
    isMoreFilterActive,
    pageChanged: true,
    fromAgeOfadvert,
    toAgeOfadvert,
    scoreObj,
    location,
    typeAuctionForced,
  };
  return stateObject;
};

export const getValues = (value: string, name: string) => {
  const result = getSinglefilterParameters(name);
  if (!value) {
    return "0";
  }
  if (value === "ALL") {
    if (name === "rooms") {
      return result.maxValue + 0.1;
    } else {
      return result.maxValue + result.step;
    }
  }
  return value;
};

// search API call object
export const createApiObject = (
  state: any,
  isMoreFilterActive: boolean,
  sortObj: any,
  fromTopresult: boolean
) => {
  const {
    extraFeatures,
    houseTypesArray,
    space,
    price,
    rooms,
    internet,
    fromYearOfContruction,
    toYearOfContruction,
    fromPlotApprox,
    toPlotApprox,
    // objectType,
    fromPrice,
    fromAreaLiving,
    fromNumberOfRooms,
    topResult,
    fromAgeOfadvert,
    toAgeOfadvert,
    scoreObj,
    selectedSortType,
    selectedSortName,
    location,
    typeAuctionForced,
  } = state;

  const extraFeaturesUpdated: any = [];
  const houseTypesUpdated: any = [];

  extraFeatures.forEach((element: any) => {
    if (element.value) {
      extraFeaturesUpdated.push(element.countName);
      isMoreFilterActive = true;
    }
  });

  houseTypesArray.forEach((element: any) => {
    if (element.value) {
      houseTypesUpdated.push(element.label);
      isMoreFilterActive = true;
    }
  });

  isMoreFilterActive = checkMoreFilterEnabled(
    isMoreFilterActive,
    fromYearOfContruction,
    toYearOfContruction,
    fromPlotApprox,
    toPlotApprox,
    internet,
    fromAgeOfadvert,
    toAgeOfadvert,
    location,
    typeAuctionForced
  );

  const needChange = validateSingleFilters(state);
  let obj = {
    currentPage: 1,
    recordPerPage: recordPerPage,
    toAreaLiving: space && space.toString(),
    toNumberOfRooms: rooms && rooms.toString(),
    toPrice: price && price.toString(),
    fromAreaLiving: fromAreaLiving ? fromAreaLiving.toString() : "0",
    fromNumberOfRooms: fromNumberOfRooms ? fromNumberOfRooms.toString() : "0",
    fromPrice: fromPrice ? fromPrice.toString() : "0",
    fromPlotApprox: fromPlotApprox ? fromPlotApprox.toString() : "0",
    toPlotApprox: toPlotApprox && toPlotApprox.toString(),
    internet,
    fromYearOfContruction: fromYearOfContruction
      ? fromYearOfContruction.toString()
      : fromYearOfContruction,
    toYearOfContruction: toYearOfContruction
      ? toYearOfContruction.toString()
      : toYearOfContruction,
    location: location || "",
    typeAuctionForced: typeAuctionForced,
    fromAgeOfadvert: fromAgeOfadvert ? fromAgeOfadvert.toString() : "0",
    toAgeOfadvert: toAgeOfadvert ? toAgeOfadvert.toString() : toAgeOfadvert,
    otherFeatures: extraFeaturesUpdated,
    objectType: houseTypesUpdated,
  };

  if (fromTopresult || topResult) {
    const scoreData =
      fromTopresult && fromTopresult !== null ? fromTopresult : scoreObj;

    if (scoreData) {
      const newObj = {
        ...obj,
        locationsScoreFrom:
          scoreData.locationMin || scoreData.locationsScoreFrom,
        requirementsScoreFrom:
          scoreData.propertyMin || scoreData.requirementsScoreFrom,
        financialsScoreFrom:
          scoreData.financeMin || scoreData.financialsScoreFrom,
        totalScoreFrom: scoreData.totalMin || scoreData.totalScoreFrom,
        topResult: true,
      };
      obj = newObj;
    }
  }
  if (needChange.length > 0) {
    needChange.forEach((menuName: string) => {
      const name = getStateNameTo(menuName);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      obj[name] = "ALL";
    });
  }

  const newObj = {
    ...obj,
    sortType: sortObj ? sortObj.sortType : selectedSortType,
    sortBy: sortObj ? sortObj.sortBy : selectedSortName,
  };

  obj = newObj;
  return { obj, isMoreFilterActive };
};

export const validateSingleFilters = (state: any) => {
  const changeNeeded: any = [];
  ["rooms", "space", "price"].forEach((menuName) => {
    const result = getSinglefilterParameters(menuName);
    if (state[menuName] > result.maxValue) {
      changeNeeded.push(menuName);
    }
  });
  return changeNeeded;
};

// for setting active/inactive state of button
export const checkMoreFilterEnabled = (
  isMoreFilterActive: boolean,
  fromYearOfContruction: string,
  toYearOfContruction: string,
  fromPlotApprox: string,
  toPlotApprox: string,
  internet: string,
  fromAgeOfadvert: string,
  toAgeOfadvert: string,
  location: string,
  typeAuctionForced?: boolean
) => {
  const checkArray = [
    fromYearOfContruction,
    toYearOfContruction,
    fromPlotApprox,
    toPlotApprox,
    fromAgeOfadvert,
    toAgeOfadvert,
    location,
  ];
  const isActive = checkArray.filter((i) => i && parseInt(i) !== 0);

  if ((isActive && isActive.length > 0) || (internet && internet !== "")) {
    isMoreFilterActive = true;
  }
  if (typeAuctionForced) {
    isMoreFilterActive = true;
  }
  return isMoreFilterActive;
};

//for small filter value handle
export const getStateNameTo = (menuName: string) => {
  let stateName = "";
  if (menuName === "price") {
    stateName = "toPrice";
  } else if (menuName === "space") {
    stateName = "toAreaLiving";
  } else if (menuName === "rooms") {
    stateName = "toNumberOfRooms";
  }
  return stateName;
};

//for small filter value handle
export const getStateName = (menuName: string) => {
  let stateName = "";
  if (menuName === "price") {
    stateName = "fromPrice";
  } else if (menuName === "space") {
    stateName = "fromAreaLiving";
  } else if (menuName === "rooms") {
    stateName = "fromNumberOfRooms";
  }
  return stateName;
};

export const resetStateObject = (fromMore: boolean, onboarding: boolean) => {
  const scoreObj = {
    financialsScoreFrom: 0,
    locationsScoreFrom: 0,
    requirementsScoreFrom: 0,
    totalScoreFrom: 30,
  };

  const resetObj = {
    showSpinner: true,
    fromPlotApprox: "",
    toPlotApprox: "",
    fromYearOfContruction: "",
    toYearOfContruction: "",
    fromAgeOfadvert: "",
    toAgeOfadvert: "",
    objectType: [],
    otherFeatures: [],
    internet: "",
    isMoreFilterActive: false,
    houseTypesArray: JSON.parse(JSON.stringify(houseTypesArray)),
    extraFeatures: JSON.parse(JSON.stringify(extraFeatures)),
    location: "",
    typeAuctionForced: null,
  };

  if (!fromMore) {
    let updatedObject = {
      ...resetObj,
      rooms: "0",
      price: "0",
      space: "0",
      fromPrice: "0",
      fromAreaLiving: "0",
      fromNumberOfRooms: "0",
      currentValueMax: 0,
      currentValueMin: 0,
      showSmallFilter: false,
      showSortMenu: false,
      showResultSpinner: true,
      optionChangedForMore: false,
      selectedSortType: "DESC",
      selectedSortLabel: "Match",
      selectedSortName: "score",
      menuName: "",
      scoreObj,
    };

    if (onboarding) {
      const updated = {
        ...updatedObject,
        topResult: true,
      };
      updatedObject = updated;
    }
    return updatedObject;
  }

  return resetObj;
};

export const getMenuName = (menu: string, selectedLang: string | null) => {
  if (selectedLang === lang.GR) {
    if (menu === "preis") {
      return "price";
    }
    if (menu === "wohnfläche") {
      return "space";
    }
    if (menu === "räume") {
      return "rooms";
    }
    if (menu === "mehr filter") {
      return "more filters";
    }
  }
  return menu;
};


export const initialState = {
  resultArray: {
    schools: [],
    supermarkets: [],
    transports: [],
    medicals: [],
    parks: [],
  },

  removeMarker: null,
  visibleIndex: 0,
  isOpen: false,
  isOpenMulti: false,
  lat: "",
  lon: "",
  map: null,
  mapMulti: null,
  highlightclass: "",
  checkedType: [],
};

export const mapLegends = [
  {
    name: "schools",
    label: englishText.SCHOOLS,
    icon: <School className="school-icon map-icon" />,
  },
  {
    name: "transports",
    label: englishText.TRANSPORTS,
    icon: <Transportation className="transport-icon map-icon" />,
  },
  {
    name: "medicals",
    label: englishText.MEDICLES,
    icon: <Hospital className="medical-icon map-icon" />,
  },
  {
    name: "parks",
    label: englishText.PARKS,
    icon: <Parc className="park-icon map-icon" />,
  },
  {
    name: "supermarkets",
    label: englishText.SUPERMARKETS,
    icon: <ShoppingCart className="supermarket-icon map-icon" />,
  },
];

// set icon for features checkbox on map
export const setIcon = (type: string, iconFile: any) => {
  if (type === "schools") {
    iconFile = school;
  } else if (type === "supermarkets") {
    iconFile = buy;
  } else if (type === "transports") {
    iconFile = bus;
  } else if (type === "medicals") {
    iconFile = clinic;
  } else if (type === "parks") {
    iconFile = park;
  }
  return iconFile;
};

export const searchCountApiForAmplitude = () => {
  HttpService.post(API_CONFIG.path.saerchcount, {});
};
