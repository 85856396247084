import React from "react";
import * as objectFunctions from "../shared/objectFunctions";
import MapWithAMarker from "components/objectDetails/components/mapForObject";
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import { FormattedMessage } from "react-intl";
import * as objectLocaInterface from "../interface/objectDetails";

const ObjectLocation: React.FC<objectLocaInterface.IObjectLocProps> = (
  props
) => {
  const { state, setStateForObject } = props;
  const { propertyDetail } = state;

  const localSupplyArray =
    propertyDetail &&
    propertyDetail.closestDistance &&
    objectFunctions.getLocalSupplyArray(propertyDetail);

  const localInfoArray =
    propertyDetail && objectFunctions.getLocalInfoArray(propertyDetail);

  return (
    <>
      <div className="row mt-4">
        <div className="col-md-8 col-sm-7 map-location">
          <div className="map-view">
            {propertyDetail.location && propertyDetail.location.lat ? (
              <MapWithAMarker
                loadingElement={<div style={{ height: `100%` }} />}
                containerElement={<div style={{ height: `100%` }} />}
                mapElement={<div style={{ height: `100%` }} />}
                lat={parseFloat(propertyDetail.location.lat)}
                lon={parseFloat(propertyDetail.location.lon)}
                title={propertyDetail.objectTitle}
                score={propertyDetail.score}
                fromPopup={false}
                onMarkerClick={() => {
                  setStateForObject("showMapPopup", true);
                }}
              />
            ) : (
              <div className="empty-map" />
            )}
          </div>
          {/* <h3 className="mb-3 mt-3">DESCRIPTION OF THE LOCATION</h3>
              <p>
                Die angebotene nahezu neuwertige Immobilie befindet sich in
                einem ortszentrumsnahen Mischgebiet in Hamburg-DUVENSTEDT - ganz
                weiterlesen…
              </p> */}
        </div>
        <div className="col-md-4 col-sm-5 location-info">
          <h2 className="title mb-3 mt-4">
            {getLocalizeText(englishText.LOCATION_INFORMATION)}
          </h2>

          {localInfoArray &&
            localInfoArray.map((info: any, index: number) => (
              <div
                className="detail-item d-flex align-items-center"
                key={index}
              >
                <p className="w-50">{getLocalizeText(info.label)}</p>
                <p className="w-50">{info.value}</p>
              </div>
            ))}

          <h2 className="title mb-4 mt-4">
            {getLocalizeText(englishText.LOCAL_SUPPLY)}
          </h2>
          {localSupplyArray &&
            localSupplyArray.map((local: any, index: number) => (
              <div
                className="detail-item d-flex align-items-center"
                key={index}
              >
                <p className="w-50">{getLocalizeText(local.label)}</p>
                <p className="w-50">{local.value}</p>
              </div>
            ))}
        </div>
      </div>
      <div className="owner-info d-flex align-items-center">
        <div className="owner-detail d-flex align-items-center">
          <div className="owner-img">
            <img
              src="https://ca.slack-edge.com/T012B3P0HRP-U011X3H4MKP-5ed411ebdb1e-512"
              alt=""
            />
          </div>
          <div className="owner-detail-content">
            <h2>Ralf Baumann</h2>
            <p>Immozy</p>
            <a
              onClick={() =>
                props.sendAmplitudeData("Contact us clicked", "interested_user")
              }
              href="tel:04052477494"
            >
              Tel.: 040-524 77 49 4
            </a>
          </div>
        </div>
        <div className="owner-description d-flex align-items-center">
          <p className="ow-details">
            "{getLocalizeText(englishText.HELP_TEXT_1)} <br />{" "}
            {getLocalizeText(englishText.HELP_TEXT_2)}"
          </p>
          <button className="button" onClick={props.openContactUs}>
            <FormattedMessage
              id={englishText.CONTINUE}
              defaultMessage={englishText.CONTINUE}
            />
          </button>
        </div>
      </div>
    </>
  );
};

export default ObjectLocation;
