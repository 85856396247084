import React from "react";
import { connect } from "react-redux";
import MapWithAMarker from "components/objectDetails/components/mapForObject";
import Form from "react-bootstrap/Form";
import mapArrow from "assets/images/icon_Push-In.png";
import { clearReducer } from "actions";
import {
  Hospital,
  Parc,
  School,
  ShoppingCart,
  Transportation,
} from "components/commonComponents/icons";
import { CloseIcon } from "components/commonComponents/icons";
import englishText from "languages/english/english";
import mapArrowHide from "assets/images/icon_Pull-out.png";
import buy from "assets/images/buy.svg";
import bus from "assets/images/bus.svg";
import school from "assets/images/school.svg";
import clinic from "assets/images/clinic.svg";
import park from "assets/images/park.svg";
import { getMapFilterResult } from "actions";
import getLocalizeText from "utils/getLocalizeText";
import * as mapPopupInterface from "../../interface/objectDetails";
import "components/searchResult/search.scss";

const initialMapFilterState = {
  resultArray: {
    schools: [],
    supermarkets: [],
    transports: [],
    medicals: [],
    parks: [],
  },
};

class MapPopup extends React.Component<mapPopupInterface.IMapPopupProps> {
  state: any = {
    showLegends: true,
    showLegendsIcons: true,
    disableClick: false,
    checkedType: [],
    removeMarker: null,
    iconFile: null,
    ...initialMapFilterState,
  };

  setShow = () => {
    const { showLegends } = this.state;
    this.setState({ showLegends: !showLegends });
    if (showLegends) {
      this.setState({ showLegendsIcons: false });
    } else {
      setTimeout(() => {
        this.setState({ showLegendsIcons: true });
      }, 300);
    }
  };

  componentDidMount() {
    this.setState({ checkedType: [] });
    document.body.style.overflowY = "hidden";
  }

  componentDidUpdate(prevProps: any) {
    const { mapFilterReducer, clearReducer } = this.props;
    if (
      mapFilterReducer !== prevProps.mapFilterReducer &&
      mapFilterReducer.status === "success" &&
      mapFilterReducer.data.data
    ) {
      const { resultArray } = this.state;
      resultArray[mapFilterReducer.data.type] = mapFilterReducer.data.data;
      this.setState({
        resultArray,
        disableClick: false,
      });
      clearReducer("CLEAR_FETCH_MAP_SEARCH");
    }
  }

  componentWillUnmount() {
    document.body.style.overflowY = "";
  }

  render() {
    const {
      showLegends,
      checkedType,
      disableClick,
      resultArray,
      removeMarker,
      showType,
      iconFile,
      showLegendsIcons,
    } = this.state;

    const { propertyDetail, closeMap } = this.props;

    return (
      <div className={`image-gallery-popup relative`}>
        <div
          className="close-btn-div"
          onClick={closeMap}
          style={{ top: "8px", right: "8px" }}
        >
          <CloseIcon />
        </div>
        <div
          className={`${
            showLegends ? "" : "hide-filters"
          }  custom-filters-fixed custom-filters MuiGrid-root MuiGrid-container MuiGrid-align-items-xs-center`}
          style={showLegends ? { width: "810px" } : { width: "100px" }}
        >
          <div className="MuiGrid-root MuiGrid-container e">
            {!showLegends ? (
              <img
                className="pointer"
                src={mapArrowHide}
                alt="img"
                onClick={this.setShow}
              />
            ) : (
              <img
                className="pointer"
                src={mapArrow}
                alt="img"
                onClick={this.setShow}
              />
            )}
            <div
              className={`map-filter row`}
              style={showLegendsIcons ? {} : { display: "none" }}
            >
              {mapLegends.map((legend, index) => (
                <div className="mr-2 ml-2" key={index}>
                  <Form.Check
                    id={legend.name}
                    disabled={disableClick}
                    type="checkbox"
                    custom
                    checked={
                      checkedType ? checkedType.includes(legend.name) : false
                    }
                    name={legend.name}
                    onChange={(event) => this.onLegendClick(event, legend.name)}
                  >
                    <input
                      name={legend.name}
                      type="checkbox"
                      disabled={disableClick}
                      id={legend.name}
                      className="custom-control-input"
                      checked={
                        checkedType ? checkedType.includes(legend.name) : false
                      }
                      onChange={(event) =>
                        this.onLegendClick(event, legend.name)
                      }
                    />
                    <label
                      htmlFor={legend.name}
                      className={`custom-control-label flex align-items-center`}
                    >
                      {legend.icon}
                      {getLocalizeText(legend.label)}
                    </label>
                  </Form.Check>
                </div>
              ))}
            </div>
          </div>
        </div>
        <MapWithAMarker
          loadingElement={<div style={{ height: `100%` }} />}
          containerElement={
            <div style={{ height: `96%`, padding: "2% 18px 0 18px" }} />
          }
          mapElement={
            <div
              style={{
                height: `100%`,
                width: " 95%",
                margin: " 0 auto",
              }}
            />
          }
          lat={parseFloat(propertyDetail.location.lat)}
          lon={parseFloat(propertyDetail.location.lon)}
          title={propertyDetail.objectTitle}
          score={propertyDetail.score}
          fromPopup={true}
          resultArray={resultArray}
          removeMarker={removeMarker}
          clearRemoveMarker={this.clearRemoveMarker}
          showType={showType}
          iconFile={iconFile}
          clearCheckedArray={this.clearCheckedArray}
        />
      </div>
    );
  }

  // handle features (markets, schools etc.) show/hide on map
  onLegendClick = (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string
  ) => {
    let iconFile = null;
    const { resultArray, checkedType } = this.state;
    this.setState({ disableClick: true });
    if (event.target.checked) {
      if (!checkedType.includes(type)) {
        checkedType.push(type);
      }
      iconFile = this.setIcon(type, iconFile);

      this.setState({ showType: type, iconFile });
      const { getMapFilterResult } = this.props;
      getMapFilterResult(type);
    } else {
      resultArray[type] = [];
      const index = checkedType.indexOf(type);
      this.setState({
        resultArray,
        showType: checkedType.splice(index, 1),
        removeMarker: type,
        disableClick: false,
      });
    }
  };

  // clear selection for features checkboxes on map
  clearCheckedArray = () => {
    const resultArray = {
      schools: [],
      supermarkets: [],
      transports: [],
      medicals: [],
      parks: [],
    };
    this.setState({
      checkedType: [],
      showType: null,
      resultArray,
      removeMarker: null,
    });
  };

  // set icon for features checkbox on map
  setIcon(type: string, iconFile: string | null) {
    if (type === "schools") {
      iconFile = school;
    } else if (type === "supermarkets") {
      iconFile = buy;
    } else if (type === "transports") {
      iconFile = bus;
    } else if (type === "medicals") {
      iconFile = clinic;
    } else if (type === "parks") {
      iconFile = park;
    }
    return iconFile;
  }

  clearRemoveMarker = () => {
    this.setState({ removeMarker: null, showType: null });
  };
}

const mapLegends = [
  {
    name: "schools",
    label: englishText.SCHOOLS,
    icon: <School className="school-icon map-icon" />,
  },
  {
    name: "transports",
    label: englishText.TRANSPORTS,
    icon: <Transportation className="transport-icon map-icon" />,
  },
  {
    name: "medicals",
    label: englishText.MEDICLES,
    icon: <Hospital className="medical-icon map-icon" />,
  },
  {
    name: "parks",
    label: englishText.PARKS,
    icon: <Parc className="park-icon map-icon" />,
  },
  {
    name: "supermarkets",
    label: englishText.SUPERMARKETS,
    icon: <ShoppingCart className="supermarket-icon map-icon" />,
  },
];

const mapStateToProps = (state: any) => ({
  addFavoReducer: state.favoReducer,
  mapFilterReducer: state.mapFilterReducer,
});

const mapDispatchToProps = (dispatch: any) => ({
  getMapFilterResult: (type: string) => {
    dispatch(getMapFilterResult(type));
  },
  clearReducer: (name: string) => {
    dispatch(clearReducer(name));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(MapPopup);
