import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import queryString from "query-string";
import { FormattedMessage } from "react-intl";
// functions
import englishText from "languages/english/english";
import {
  cities,
  lang,
  utmOrganic,
  addGoogleAdsPixelEvent,
  addMicrosoftUetEvent,
  getLocalizeText,
  disableScrolling,
  // logoutSession,
  sendAmplitudeDataEvent,
  setAmplitudeUserId,
  setAmplitudeUserProperties,
} from "utils";
import { API_CONFIG, HttpService } from "services";
// component
import { Spinner } from "react-bootstrap";
import Slider from "react-slick";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login";
import PasswordInput from "components/auth/components/passwordMeter";
// assets
import signup1 from "assets/images/Group15.png";
import signup2 from "assets/images/Group16.png";
import signup3 from "assets/images/Group17.png";
import { Logo } from "components/commonComponents/icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

let cityFromURl: any = "";
let fromWordpress = false;

const AuthScreenSocial: React.FC = () => {
  const [email, setemail] = useState("");
  const [password, setpassword] = useState("");
  const [showFullLoader, setshowFullLoader] = useState(false);
  const [showLoginScreen, setShowLoginScreen] = useState(
    window.location.pathname.includes("login")
  );
  const [imageLoadedList, setImageLoaded] = useState<any>([]);
  const [agreed, setagreed] = useState(false);
  const [showPassword, setshowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const firstPartRef = useRef<any>(null);
  const history = useHistory();

  const langSelected = localStorage.getItem("language") || "de";

  useEffect(() => {
    if (
      localStorage.getItem("token") &&
      window.location.pathname !== "/dev-login"
    ) {
      history.push(`/find`);
    }
    // if (localStorage.getItem("callLogout")) {
    //   logoutSession();
    // }
    let queryParams = null;

    if (window.location.search) {
      queryParams = queryString.parse(window.location.search);
      if (queryParams.city) {
        cityFromURl = queryParams.city;
      }
    } else {
      cityFromURl = cities.BER;
    }
    logAmplitudeEvents(queryParams);
  });

  const logAmplitudeEvents = (queryParams: any) => {
    const utm = localStorage.getItem("utm");
    const utmParsed = utm ? JSON.parse(utm) : utmOrganic;

    if (queryParams && queryParams.w) {
      fromWordpress = true;

      if (!localStorage.getItem("utm")) {
        const meta = {
          Action: "First load Register popup from wordpress page",
        };
        sendAmplitudeDataEvent("wp_signup_loaded", meta);
      } else {
        let meta = {
          Action: "Register popup opened from wordpress page",
        };

        meta = {
          ...utmParsed,
        };
        sendAmplitudeDataEvent("wp_signup_open", meta);
      }
    } else {
      const meta = {
        Action: "Register page visited",
        ...utmParsed,
      };
      sendAmplitudeDataEvent(
        "view_signup",
        meta,
        localStorage.getItem("userId"),
        "GUEST"
      );
    }
  };

  const registerUser = () => {
    if (email === "" || password === "") {
      setErrorMessage(englishText.FILL_ALL);
    } else if (!agreed) {
      setErrorMessage(englishText.TERMS_ERROR);
    } else {
      setshowFullLoader(true);

      // proceed with signup if all validations are correct
      let regObj: any = {
        email,
        password,
      };

      if (cityFromURl !== "") {
        regObj = {
          ...regObj,
          searchcity: cityFromURl,
        };
      }

      const utmData = localStorage.getItem("utm");
      const utm: any = utmData ? JSON.parse(utmData) : utmOrganic;
      regObj = {
        ...regObj,
        utm,
      };

      const userId = localStorage.getItem("userId");
      if (userId) {
        regObj = { ...regObj, userId };
      }

      HttpService.post(API_CONFIG.path.signup, regObj)
        .then((response) => {
          if (fromWordpress) {
            const meta = {
              Action: "Register user done from wordpress site",
            };
            sendAmplitudeDataEvent("register_from_wordpress", meta);
          }
          if (utm && utm.utm_source && utm.utm_source === "bing") {
            localStorage.setItem("addUet", "true");
          } else {
            // Add google ads event
            addGoogleAdsPixelEvent();
          }
          redirectToDashboard(response.data, utm);
        })
        .catch((err) => {
          const errorMessage = err.response ? err.response.data.message : "";
          setErrorMessage(errorMessage);
          setshowFullLoader(false);
          // alert(errorMessage);
        });
    }
  };

  const redirectToDashboard = (response: any, utm: any) => {
    if (response && response.token) {
      const token = response.token;
      localStorage.setItem("token", token);
      localStorage.setItem("firstName", response.userName);
      localStorage.setItem("userId", response.userId);
      // localStorage.setItem("showHint", "true");
      localStorage.setItem("role", "USER");
      localStorage.setItem("showWelcome", "true");
      localStorage.setItem("onboarding", "false");

      if (!fromWordpress) {
        try {
          setAmplitudeUserId();
          setAmplitudeUserProperties({
            userName: response.userName,
            rolename: "USER",
            utm,
          });
        } catch (error) {
          history.push(`/find`);
          //history.push(`/welcome`);
          console.log(error);
          return;
        }
      }

      disableScrolling(false);
      setshowFullLoader(false);
      history.push(`/find`);
    }
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter" && email !== "" && password !== "") {
      event.preventDefault();
      event.stopPropagation();
      showLoginScreen ? submitLogin() : registerUser();
    }
  };

  const emailInputBox = (
    <div className="form-group form-primary">
      <label className="login-label-popup">
        {getLocalizeText(englishText.EMAIL_ADDRESS_1)}
      </label>
      <input
        id="inputID"
        type="text"
        name="email"
        placeholder={getLocalizeText(englishText.EXAMPLE_EMAIL)}
        className="login-input-box"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          errorMessage !== "" && setErrorMessage("");
          setemail(event.target.value);
        }}
        value={email}
        onKeyDown={onKeyDown}
        autoComplete="none"
      />
      <span className="form-bar" />
    </div>
  );

  const passwordInputBox = (
    <div className="form-group form-primary">
      <label className="login-label-popup">
        {getLocalizeText(englishText.PASSWORD)}
      </label>
      {showLoginScreen ? (
        <input
          id="inputPassword"
          // ref={passwordInputRef}
          type="password"
          name="password"
          placeholder={getLocalizeText(englishText.PASSWORD)}
          className="login-input-box"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            errorMessage !== "" && setErrorMessage("");
            setpassword(event.target.value);
            // checkPasswordConstraint("all", event.target.value);
          }}
          value={password}
          onKeyDown={onKeyDown}
          autoComplete="none"
        />
      ) : (
        <PasswordInput
          handleChanges={(event: React.ChangeEvent<HTMLInputElement>) => {
            errorMessage !== "" && setErrorMessage("");
            setpassword(event.target.value);
            // checkPasswordConstraint("all", event.target.value);
          }}
          value={password}
          name={"password"}
          onKeyDown={onKeyDown}
          showPassword={showPassword}
          toggleShowPwd={() => setshowPassword(!showPassword)}
          errorFields={[]}
          from={"signup"}
        />
      )}
      {/* {!showLoginScreen && (
        <PasswordAssistent value={password} target={passwordInputRef} />
      )} */}
      <span className="form-bar" />
    </div>
  );

  const errorDiv = () => (
    <label
      className="error-msg-label mb-2 truncate"
      title={
        errorMessage === ""
          ? "Error message space"
          : getLocalizeText(errorMessage)
      }
    >
      {getLocalizeText(errorMessage)}
    </label>
  );

  const getTermsAndConditionText = () => {
    switch (showLoginScreen ? "login" : "signup") {
      case "login":
        return (
          <span className="text-inverse-popup" style={{ fontSize: "14px" }}>
            <FormattedMessage
              id={englishText.REMEMBER_ME}
              defaultMessage={englishText.REMEMBER_ME}
            />
          </span>
        );

      default:
        return (
          <>
            {langSelected === lang.EN ? (
              <span className="text-inverse-popup">
                {`I confirm that I have read and understood the `}
                <a
                  href="https://immozy.de/termsandcondition/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="signup-link"
                >
                  terms and conditions{" "}
                </a>
                {" and "}
                <a
                  href="https://immozy.de/privacy/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="signup-link"
                >
                  data protection guidelines
                </a>
                {" of IMMOZY and I hereby accept them."}
              </span>
            ) : (
              <span className="text-inverse-popup">
                {`Ich bestätige, dass ich `}
                <a
                  href="https://immozy.de/termsandcondition/"
                  rel="noopener noreferrer"
                  className="signup-link"
                  target="_blank"
                >
                  die AGBs
                </a>
                {" und "}
                <a
                  href="https://immozy.de/privacy/"
                  rel="noopener noreferrer"
                  target="_blank"
                  className="signup-link"
                >
                  Datenschutzrichtlinien
                </a>
                {
                  " von IMMOZY gelesen und verstanden habe und diese hiermit akzeptiere."
                }
              </span>
            )}
          </>
        );
    }
  };

  const toggleSignupAndLogin = () => {
    // eslint-disable-next-line no-extra-boolean-cast
    if (!!firstPartRef.current) {
      firstPartRef.current.classList.add("fade-in-out");
    }
    setTimeout(() => {
      setShowLoginScreen(!showLoginScreen);
      setErrorMessage("");
    }, 400);
    setTimeout(() => {
      firstPartRef.current.classList.remove("fade-in-out");
    }, 800);
  };

  const submitLogin = () => {
    if (email === "" || password === "") {
      setErrorMessage(englishText.FILL_ALL);
    } else {
      setshowFullLoader(true);

      HttpService.post(API_CONFIG.path.login, { email, password })
        .then((response) => {
          setAmplitudeUserProperties({
            userName: response.data.userName,
            onboarding: response.data.isOnBoardingCompleted,
            rolename: "USER",
          });
          const token = response.data.token;
          localStorage.setItem("token", token);
          localStorage.setItem("userId", response.data.userId);
          localStorage.setItem("role", "USER");
          localStorage.setItem("email", email);

          history.push(`/find`);
        })
        .catch((err) => {
          const errorMessage = err.response ? err.response.data.message : "";
          setshowFullLoader(false);
          setErrorMessage(errorMessage);
        });
    }
  };

  const setImageLoadedList = (index: number) => {
    if (!imageLoadedList.includes(index)) {
      setImageLoaded((imageLoadedList: any) => [...imageLoadedList, index]);
    }
  };

  const responseGoogle = (response: any) => {
    console.log("Google data", response.data);
    console.log("Google response", response);
    registerUserWithSocial(response.tokenId, "GOOGLE");
  };

  const responseFacebook = (response: any) => {
    console.log("Facebook data", response.data);
    console.log("Facebook response", response);
    registerUserWithSocial(response.accessToken, "FACEBOOK");
  };

  const registerUserWithSocial = (token: string, type: string) => {
    setshowFullLoader(true);
    let paylaod: any = {
      token: token,
      type: type,
      searchcity: cities.BER,
    };

    if (cityFromURl !== "") {
      paylaod = {
        ...paylaod,
        searchcity: cityFromURl,
      };
    }

    const utmData = localStorage.getItem("utm");
    const utm: any = utmData ? JSON.parse(utmData) : utmOrganic;
    paylaod = {
      ...paylaod,
      utm,
    };

    HttpService.post("v1/auth/social", paylaod)
      .then((response) => {
        const isFirstTimeLogin = response.data.isFirstTimeLogin;

        if (isFirstTimeLogin) {
          const meta = {
            Action: "Signup using social login",
            loginType: type,
          };
          sendAmplitudeDataEvent("register_from_social", meta);
          if (fromWordpress) {
            const meta = {
              Action: "Register user done from wordpress site",
            };
            sendAmplitudeDataEvent("register_from_wordpress", meta);
          }
          if (utm && utm.utm_source && utm.utm_source === "bing") {
            addMicrosoftUetEvent();
          } else {
            // Add google ads event
            addGoogleAdsPixelEvent();
          }
          redirectToDashboard(response.data, utm);
        } else {
          // login user using social login
          const token = response.data.token;
          localStorage.setItem("token", token);
          localStorage.setItem("userId", response.data.userId);
          localStorage.setItem("role", "USER");
          const meta = {
            Action: "Login using social login",
            type: type,
          };
          sendAmplitudeDataEvent("login_from_social", meta);
          setAmplitudeUserProperties({
            userName: response.data.userName,
            onboarding: response.data.isOnBoardingCompleted,
            rolename: "USER",
          });

          history.push(`/find`);
        }
      })
      .catch((err) => {
        setshowFullLoader(false);
        const errorMessage = err.response ? err.response.data.message : "";
        console.log(errorMessage);
        if (
          errorMessage === "Email is mandatory for the social account login."
        ) {
          setErrorMessage(
            getLocalizeText(englishText.SOCIAL_LOGIN_ERROR_EMAIL)
          );
        } else {
          setErrorMessage(getLocalizeText(englishText.SOCIAL_LOGIN_ERROR));
        }
      });
  };

  return (
    <div className="landing-page-signup">
      <div className="d-flex fade-in justify-content-center">
        <div
          ref={firstPartRef}
          className="first-part d-flex align-items-start justify-content-center flex-column"
        >
          <>
            <div className="mb-3 logo-immozy">
              <Logo />
            </div>
            <p className="guest-headline" style={{ fontSize: "32px" }}>
              <FormattedMessage
                id={
                  showLoginScreen
                    ? englishText.WELCOME_BACK
                    : englishText.FIRST_STEP
                }
                defaultMessage={
                  showLoginScreen
                    ? englishText.WELCOME_BACK
                    : englishText.FIRST_STEP
                }
              />
            </p>
            <p className="logo-text-popup">
              {showLoginScreen ? (
                <FormattedMessage
                  id={englishText.REG_WITH_EXISTING_ACC}
                  defaultMessage={englishText.REG_WITH_EXISTING_ACC}
                />
              ) : (
                <>
                  <FormattedMessage
                    id={englishText.REG_NOW_FOR_FREE}
                    defaultMessage={englishText.REG_NOW_FOR_FREE}
                  />
                  <br />
                  <FormattedMessage
                    id={englishText.IMMOZY}
                    defaultMessage={englishText.IMMOZY}
                  />
                </>
              )}
            </p>

            <div className="login-form-popup fade-in" id="signup-form">
              {emailInputBox}
              {passwordInputBox}
              {errorMessage !== "" && errorDiv()}
              <div
                className="position-relative row text-left full-width"
                style={{ marginLeft: "0" }}
              >
                <div
                  className={`d-flex align-items-center justify-content-between full-width custom-checkbox checkbox-fade fade-in-primary ${
                    agreed ? "checked" : ""
                  }`}
                >
                  <label className="d-flex align-items-center justify-content-start cursor-pointer position-relative">
                    <input
                      className="d-none"
                      type="checkbox"
                      checked={agreed}
                      onChange={() => {
                        setagreed(!agreed);
                        setErrorMessage("");
                      }}
                    />
                    {getTermsAndConditionText()}
                  </label>
                  {showLoginScreen && (
                    <Link
                      className="forget-password pointer"
                      to={`/forgot-password`}
                    >
                      <FormattedMessage
                        id={englishText.FORGOT_PWD}
                        defaultMessage={englishText.FORGOT_PWD}
                      />
                    </Link>
                  )}
                </div>

                <div className="text-center submit-btn-popup">
                  <div className="social-div d-flex align-items-center justify-content-center">
                    <button
                      type="submit"
                      className="forward-btn text-center d-flex align-items-center justify-content-center"
                      style={{ margin: "unset" }}
                      onClick={() =>
                        showLoginScreen ? submitLogin() : registerUser()
                      }
                      tabIndex={1}
                    >
                      {showFullLoader ? (
                        <Spinner
                          animation="border"
                          className="login-btn-loader"
                        />
                      ) : (
                        getLocalizeText(
                          showLoginScreen
                            ? englishText.LOGIN
                            : englishText.REGISTER_NOW
                        )
                      )}
                    </button>
                    <div className="social-login-btn ml-3">
                      <span className="divider-signup" />
                      <span className="mr-3 signup-with">
                        <FormattedMessage
                          id={englishText.CONTINUE_WITH}
                          defaultMessage={englishText.CONTINUE_WITH}
                        />
                      </span>
                      <GoogleLogin
                        clientId="805948448220-9unrffc17sfln1vvmi8djskiortg6nuf.apps.googleusercontent.com"
                        onSuccess={responseGoogle}
                        onFailure={responseGoogle}
                        cookiePolicy={"single_host_origin"}
                        autoLoad={false}
                        buttonText={""}
                        className="login-google"
                      />
                      <FacebookLogin
                        appId="1049251205666252"
                        fields="name,email"
                        // onClick={this.componentClicked}
                        callback={responseFacebook}
                        autoLoad={false}
                        textButton={""}
                        icon={"fa-facebook"}
                      />
                    </div>
                  </div>
                </div>
                <div
                  className="bottom-text-popup mb-2 mt-2"
                  style={{ margin: "0 auto" }}
                >
                  {" "}
                  <strong>
                    <FormattedMessage
                      id={
                        showLoginScreen
                          ? englishText.DONT_HAVE_AN_ACCOUNT
                          : englishText.ALREADY_HAVE_AN_ACCOUNT
                      }
                      defaultMessage={
                        showLoginScreen
                          ? englishText.DONT_HAVE_AN_ACCOUNT
                          : englishText.ALREADY_HAVE_AN_ACCOUNT
                      }
                    />{" "}
                    <button
                      onClick={() => toggleSignupAndLogin()}
                      style={{
                        textDecoration: "underline",
                        cursor: "pointer",
                      }}
                    >
                      <FormattedMessage
                        id={
                          showLoginScreen
                            ? englishText.REGISTER_NOW_LINK
                            : englishText.LOGIN
                        }
                        defaultMessage={
                          showLoginScreen
                            ? englishText.REGISTER_NOW_LINK
                            : englishText.LOGIN
                        }
                      />
                    </button>
                  </strong>
                </div>
              </div>
            </div>
          </>
        </div>
        <div className="second-part d-flex position-relative">
          <div className="slider-wrapper position-relative">
            <Slider {...settings}>
              {details.map((detail, index) => (
                <div
                  className="slide d-flex justify-content-center align-items-center flex-column"
                  key={index}
                >
                  {!imageLoadedList.includes(index) && (
                    <div
                      className="text-center load-wait"
                      style={{
                        height: "83%",
                      }}
                    >
                      {[1, 2, 3].map((count) => (
                        <Spinner
                          key={count}
                          className="spin-img"
                          animation="grow"
                        />
                      ))}
                    </div>
                  )}
                  <img
                    src={detail.img}
                    alt=""
                    style={{
                      maxWidth: "80%",
                      width: "500px",
                      minHeight: "425px",
                      objectFit: "contain",
                    }}
                    onLoad={() => setImageLoadedList(index)}
                  />
                  <p className="big-text mt-3">
                    {getLocalizeText(detail.title)}
                  </p>
                  <p className="small-text d-flex justify-content-center align-items-center flex-column">
                    <span>{getLocalizeText(detail.subtitle1)}</span>
                    <span>{getLocalizeText(detail.subtitle2)}</span>
                  </p>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
};

const details = [
  {
    title: englishText.COMPREHENSIVE_OFFER,
    subtitle1: englishText.DIVERSE_RANGE,
    subtitle2: englishText.REGIONAL_SPECIALIST,
    img: signup3,
  },
  {
    title: englishText.SMART_FINDER,
    subtitle1: englishText.MATCHING_ALGO_FINDS,
    subtitle2: englishText.SUIT_NEEDS,
    img: signup1,
  },
  {
    title: englishText.PERSONAL_AND_PROFESSIONAL,
    subtitle1: englishText.IF_YOU_HAVE_QUEST,
    subtitle2: englishText.IMMOZY_EXPRTS_ADVICE,
    img: signup2,
  },
];

const settings = {
  draggable: true,
  arrows: false,
  dots: true,
  autoplay: true,
  autoplaySpeed: 6000,
  speed: 900,
  infinite: true,
  pauseOnHover: true,
};

export default AuthScreenSocial;
