import React from "react";
import { Spinner } from "react-bootstrap";

const CustomSpinner = () => (
  <React.Fragment>
    {
      <div className="load-div fade-in d-flex flex-column align-items-center justify-content-center">
        <Spinner animation="border" variant="info" className="mb-3" />
      </div>
    }
  </React.Fragment>
);

export default CustomSpinner;
