import React from "react";
import { FormattedMessage } from "react-intl";
import englishText from "languages/english/english";
import { lang } from "utils/constants";
import { IOnboardingComponentProps } from "../interface/immozyhome";
import { ExclamationMark } from "components/commonComponents/icons";

const OnboardingComponent: React.FC<IOnboardingComponentProps> = (props) => {
  const { data } = props;
  const { hintDe, hintEn } = data;

  return (
    <div className="onboarding-section-wrapper">
      <div
        className="hint-note-wrapper full-width mt-0"
        style={{ width: "463px", margin: "0 auto" }}
      >
        <div className="note-heading d-flex align-items-center justify-content-start">
          <ExclamationMark className="mr-2" />
          <FormattedMessage
            id={englishText.WHY_QUESTION_HEADING}
            defaultMessage={englishText.WHY_QUESTION_HEADING}
          />
        </div>
        <p>{props.selectedLang === lang.EN ? hintEn : hintDe}</p>
      </div>
      <div className="form-group form-primary d-flex justify-content-center mt-5 mb-0">
        <button
          type="button"
          className="btn immozy-home-action-btn"
          onClick={() => props.showOnb()}
        >
          <FormattedMessage
            id={
              data.calculatePer === 100
                ? englishText.OPEN_ONBOARDING
                : englishText.START_ONBOARDING
            }
            defaultMessage={
              data.calculatePer === 100
                ? englishText.OPEN_ONBOARDING
                : englishText.START_ONBOARDING
            }
          />
        </button>
      </div>
    </div>
  );
};

export default OnboardingComponent;