import React from "react";
import InputRange from "react-input-range";
import CurrencyInput from "react-currency-input-field";
import { Form } from "react-bootstrap";
// functions
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import { IOptionProps, IQuestionProps } from "../interface/onboarding";
import { imageOptions, optionInputTypes } from "../interface/utils";
// import { getDefautValue } from "../interface/onboardingFunctions";
import { checkDisable } from "../interface/onboardingFunctions";
// assets
import {
  optionsIconMapper,
  QuestionIcon1_1_1,
} from "components/onboarding-new/component/optionIcons";
import "react-input-range/lib/css/index.css";

interface IOptionsProps {
  currentStep: number;
  questionsList: IQuestionProps[];
  edited: boolean;
  hanldeRangeChange: (value: any) => void;
  selectOption: (i: number) => void;
  handleInputchange: (value: any) => any;
}

const lang = localStorage.getItem("language") || "de";
const locale = lang.includes("en") ? "en-US" : "de-DE";

const OptionsComponent = (props: IOptionsProps) => {
  const { questionsList, currentStep } = props;

  const optionArray = props.questionsList[props.currentStep].options;
  const question = props.questionsList[props.currentStep].question[lang];
  const type = questionsList[currentStep].type;

  return (
    <div
      className={`options-container ${optionArray.length > 4 ? "wrapped" : ""}`}
    >
      {/* Checkbox type options */}
      {type === optionInputTypes.CHECKBOX && (
        <div
          className={`d-flex justify-content-center align-items-start ${
            optionArray.length >= 4 ? "check-options" : ""
          }  ${optionArray.length > 4 ? "flex-wrap" : ""}`}
        >
          {optionArray &&
            optionArray.map((op: any, i: number) => {
              const Icon = optionsIconMapper[op.optionId] || QuestionIcon1_1_1;
              return (
                <div
                  className={`options m-2 pointer ${
                    op.isSelected ? "selected" : ""
                  }`}
                  key={`${
                    i + props.questionsList[props.currentStep].questionId
                  }`}
                  style={{ animationDelay: `${i * 0.2}s` }}
                  onClick={() => props.selectOption(i)}
                >
                  <div className="options-rect d-flex justify-content-center align-items-center">
                    <Icon className="ans-icon" />
                  </div>
                  <p className="options-text">{op.option[lang]}</p>
                </div>
              );
            })}
        </div>
      )}
      {/* Radio type options */}
      {type === optionInputTypes.RADIO && (
        <div
          className={`d-flex justify-content-center align-items-start ${
            optionArray.length >= 4 ? "check-options" : ""
          }  ${optionArray.length > 4 ? "flex-wrap" : ""}`}
        >
          {optionArray &&
            optionArray.map((op: any, i: number) => {
              const Icon = optionsIconMapper[op.optionId] || QuestionIcon1_1_1;
              return (
                <div
                  className={`options m-2 pointer ${
                    op.isSelected ? "selected" : ""
                  }`}
                  key={`${
                    i + props.questionsList[props.currentStep].questionId
                  }`}
                  style={{ animationDelay: `${i * 0.2}s` }}
                  onClick={() => props.selectOption(i)}
                >
                  <div className="options-rect d-flex justify-content-center align-items-center">
                    <Icon className="ans-icon" />
                  </div>
                  <p className="options-text">{op.option[lang]}</p>
                </div>
              );
            })}
        </div>
      )}
      {/* City options  */}
      {type === optionInputTypes.PICTURE && (
        <div
          className={`image-options d-flex justify-content-center ${
            imageOptions.length >= 4 ? "check-options" : ""
          }`}
        >
          {imageOptions &&
            imageOptions.map((option: any, index: number) => (
              <div
                key={`${
                  index + props.questionsList[props.currentStep].questionId
                }`}
                className="img-anim "
                style={{ animationDelay: `${index * 0.2}s` }}
                onClick={() => props.selectOption(index)}
              >
                <div
                  className={`column-wrapper pointer ${
                    optionArray &&
                    optionArray[index] &&
                    optionArray[index].isSelected
                      ? "selected"
                      : ""
                  }`}
                  //selected word add
                >
                  <div className="card-img">
                    <img
                      src={option.img}
                      alt=""
                      className="h-100 object-fit-cover"
                    />
                  </div>
                  <div className="card-desc d-flex align-items-center flex-column justify-content-center">
                    <h2>{option.title}</h2>
                  </div>
                </div>
              </div>
            ))}
        </div>
      )}
      {/* Double range options */}
      {type === optionInputTypes.RANGE && (
        <div
          className={`option-range mb-2 pointer ${
            optionArray[0].optionId === "3.2.1" && "show-meter"
          }`}
        >
          {optionArray &&
            optionArray.map((op: IOptionProps, i: number) => (
              <div className="d-flex align-items-center" key={i}>
                {question &&
                question.includes(getLocalizeText(englishText.ROOMS_OP)) ? (
                  <InputRange
                    minValue={1}
                    maxValue={15}
                    value={{
                      min: op.value ? op.value[0] : 3,
                      max: op.value ? op.value[1] : 5,
                    }}
                    onChange={props.hanldeRangeChange}
                  />
                ) : (
                  <InputRange
                    minValue={20}
                    maxValue={200}
                    value={{
                      min: op.value ? op.value[0] : 80,
                      max: op.value ? op.value[1] : 150,
                    }}
                    onChange={props.hanldeRangeChange}
                  />
                )}
              </div>
            ))}
        </div>
      )}{" "}
      {type === optionInputTypes.INPUTFIELD && (
        <div
          className={`option-input mb-2 relative currency-input-style-wrapper ${
            checkDisable(optionArray) ? "disabled" : ""
          }`}
          style={{ justifyContent: "center" }}
        >
          {optionArray &&
            optionArray.map(
              (op: IOptionProps, i: number) =>
                op.optionId !== "4.3.2" &&
                op.optionId !== "4.1.2" && (
                  <React.Fragment key={i + op.optionId || op.slug}>
                    {/* <label
                      htmlFor="input-example"
                      className="option-input-label"
                    >
                      {" " + getLocalizeText(englishText.PRICE)}
                    </label> */}
                    <CurrencyInput
                      key={i + op.optionId || op.slug}
                      id="input-example"
                      //  defaultValue={getDefautValue(op, props.edited)}
                      value={op.value}
                      decimalsLimit={2}
                      onValueChange={(value) => props.handleInputchange(value)}
                      intlConfig={{
                        locale: locale,
                        currency: "EUR",
                      }}
                      prefix={" "}
                      suffix={" €"}
                      className="currency-input-style"
                      disabled={checkDisable(optionArray)}
                    />
                  </React.Fragment>
                )
            )}
        </div>
      )}
      {type === optionInputTypes.INPUTFIELD && (
        <>
          {optionArray &&
            optionArray.map(
              (op: IOptionProps, i: number) =>
                (op.optionId === "4.1.2" || op.optionId === "4.3.2") && (
                  <React.Fragment key={i + op.optionId || op.slug}>
                    <Form.Group
                      className="d-flex align-items-center justify-content-center form-group mt-3"
                      controlId="formBasicCheckbox"
                      key={i}
                    >
                      <Form.Check
                        id={op.optionId}
                        custom
                        type="checkbox"
                        label={op.option[lang]}
                        checked={op.isSelected}
                        name={op.option[lang]}
                        onChange={() => props.selectOption(i)}
                        style={{ width: "391px" }}
                      />
                    </Form.Group>
                  </React.Fragment>
                )
            )}
        </>
      )}
    </div>
  );
};

export default OptionsComponent;
