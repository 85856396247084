import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";
// component
import PasswordInput from "./passwordMeter";
// functions
import getLocalizeText from "utils/getLocalizeText";
import englishText from "languages/english/english";
import HttpService from "services/http.service";
import { API_CONFIG } from "services/api";
import { Logo } from "components/commonComponents/icons";
// assets
import { toast } from "react-toastify";
import "../auth.scss";

const token = "";

export default function ResetPassword(props: any) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorFields, setErrorFields] = useState<string[]>([]);
  const [showPassword, setShowPassword] = useState(false);
  const [successPage, setsuccessPage] = useState(false);

  const resetPassword = async (token: string, password: string) => {
    const { match } = props;
    token = match.params.token;

    HttpService.post(API_CONFIG.path.reset, { token, password })
      .then(() => {
        // Password reset successfully
        setsuccessPage(true);
      })
      .catch((err) => {
        const errorMessage = err.response ? err.response.data.message : "";
        toast.error(errorMessage);
      });
  };

  const handleSubmit = () => {
    setErrorBorder(password, confirmPassword);
    if (password !== confirmPassword) {
      toast.error(getLocalizeText(englishText.PWD_NOT_MATCH));
    } else {
      resetPassword(token, password);
    }
  };

  // Password and confirm password field handling
  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (errorFields.length > 0) {
      const updatedfields = errorFields.filter((i) => i !== event.target.name);
      setErrorFields(updatedfields);
    }

    if (event.target.name === "password") {
      setPassword(event.target.value);
    } else {
      setConfirmPassword(event.target.value);
    }
  };

  const setErrorBorder = (password: string, confirmPassword: string) => {
    //indicate if any field value is not entered
    const fields = [
      { name: "password", value: password },
      { name: "confirmPassword", value: confirmPassword },
    ];
    let errorFields: string[] = [];

    if (confirmPassword !== password) {
      errorFields = ["password", "confirmPassword"];
    } else {
      fields.forEach((field) => {
        if (field.value === "") errorFields.push(field.name);
      });
    }
    setErrorFields(errorFields);
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      handleSubmit();
    }
  };

  return (
    <div>
      <div className="forgot-password-container reset-pwd-container">
        <div className="logo-imz">
          <Logo />
        </div>
        <div className="email-verify-container">
          <p className="verify-title mt-2 mb-2">
            <FormattedMessage
              id={englishText.RESET_YOUR_PWD}
              defaultMessage={englishText.RESET_YOUR_PWD}
            />
          </p>
          <p className="verify-subtitle mt-2 mb-2">
            <FormattedMessage
              id={englishText.ENTER_NEW_PWD}
              defaultMessage={englishText.ENTER_NEW_PWD}
            />
          </p>
          <div className="form-group form-primary mt-3">
            <div
              className="d-flex align-items-center"
              style={{ height: "45px" }}
            >
              <label className="input-label">
                <FormattedMessage
                  id={englishText.PASSWORD}
                  defaultMessage={englishText.PASSWORD}
                />
              </label>
            </div>
            <PasswordInput
              handleChanges={handlePasswordChange}
              value={password}
              name={"password"}
              onKeyDown={onKeyDown}
              showPassword={showPassword}
              toggleShowPwd={() => setShowPassword(!showPassword)}
              errorFields={[]}
              from={"reset"}
            />
            <span className="form-bar" />
          </div>
          <div className="form-group form-primary mt-3">
            <div
              className="d-flex align-items-center"
              style={{ height: "45px" }}
            >
              <label className="input-label">
                <FormattedMessage
                  id={englishText.CONFIRM_PASSWORD}
                  defaultMessage={englishText.CONFIRM_PASSWORD}
                />
              </label>
            </div>
            <input
              id="inputPassword"
              type="password"
              className="login-input-box"
              onKeyDown={onKeyDown}
              autoComplete="none"
              name="confirmPassword"
              value={confirmPassword}
              onChange={handlePasswordChange}
            />
            <span className="form-bar" />
          </div>
          <button
            className="btn mail-send-btn mt-5"
            onClick={handleSubmit}
            disabled={password === "" || confirmPassword === ""}
          >
            <FormattedMessage
              id={englishText.UPDATE_PWD}
              defaultMessage={englishText.UPDATE_PWD}
            />
          </button>
          <div className="d-flex align-items-center justify-content-between link-section">
            <Link to="/login">
              <FormattedMessage
                id={englishText.LOGIN}
                defaultMessage={englishText.LOGIN}
              />
            </Link>
            <Link to="/forgot-password">
              <FormattedMessage
                id={englishText.FORGOT_PWD}
                defaultMessage={englishText.FORGOT_PWD}
              />
            </Link>
          </div>
        </div>
      </div>
      {successPage && (
        <div className="model-wrapper show">
          <div className="verify-email medium-modal p-3">
            <p className="desc-dark mb-2" style={{ fontSize: "16px" }}>
              {getLocalizeText(englishText.PWD_CHANGED_SUCCESS_ALERT)}
            </p>
            <Link to="/login" style={{ fontSize: "14px" }}>
              <button className="button mt-3">
                <FormattedMessage
                  id={englishText.LOGIN}
                  defaultMessage={englishText.LOGIN}
                />
              </button>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

ResetPassword.propTypes = {
    match: PropTypes.object,
};
