import React from 'react';
import getLocalizeText from "utils/getLocalizeText";
import "./checkbox.scss";

interface Options {
  label: string;
  isChecked: boolean;
}

interface IProps {
  options: {
    [key: string]: Options;
  };
  handleChange: (key: string, value: boolean) => void;
  className?: string;
}

export const CustomCheckBox: React.FC<IProps> = ({ ...props }) => {
  const { options, handleChange } = props;
  return (
    <div className={props.className || ""}>
      {Object.keys(options).map((key: string, index: number) => {
        const { label, isChecked } = options[key];
        return (
          <label key={index} className="custom-checkbox-container">
            <input
              type="checkbox"
              checked={isChecked}
              onChange={() => handleChange(key, !isChecked)}
            />
            <span className="checkmark" />
            <span>{getLocalizeText(label)}</span>
          </label>
        );
      })}
    </div>
  );
};